import { ActivatedRoute } from '@angular/router';
import { Component, ViewChild } from '@angular/core';
import { DataService } from '../../../service/data.service';
import { TransportEditService } from '../transport-edit.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { iBisFileService, iBisUIService, iBisViewComponent } from '@dohu/ibis-common';

@Component({
	templateUrl: 'scale.component.html',
})
export class ScaleComponent extends iBisViewComponent {
	@ViewChild('gridScale') protected grid: DxDataGridComponent;

	public onEditTransportClick: any;

	constructor(public ds: DataService, iui: iBisUIService, public edit: TransportEditService,
		public file: iBisFileService, activatedRoute: ActivatedRoute) {
		super(edit, ds.lg, iui, activatedRoute);

		this.onEditTransportClick = this.onEdit.bind(this);
	}

	onRaportTransport(e: any, type: number) {
		this.edit.downloadRaportTransport(e.row.data.transportId, type);
	}

	onNoticeClick(e: any) {
		this.edit.downloadNotice(e.row.data.trasnportId);
	}

	onEdit(e: any) {
		e.event.preventDefault();
		this.grid.instance.beginCustomLoading('');
		if (e.row.data.transportId) {
			this.edit.showPopup(e.row.data.transportId, true).then(() => {
				this.grid.instance.endCustomLoading();
				this.grid.instance.refresh();
			}, error => this.ds.lg.showError(error));
		}

		const tr = {
			t_weighing: 1, t_sendDate: e.row.data.scaleDate,
			selectedActions: [1], wc_parentId: null, t_workCenterId: null,
			t_transporterId: e.row.data.transporterId,
			t_recipientId: e.row.data.recipientId,
			t_carNumber: e.row.data.carNumber,
			t_driverName: e.row.data.driverName,
			t_wasteTypeId: e.row.data.wasteTypeId,
			t_quantity: e.row.data.quantity,
			t_destinationTypeId: e.row.data.destinationTypeId,
			t_transportTypeId: e.row.data.transportTypeId,
			scaleId: e.row.data.id
		};

		if (this && this.ds && this.ds.defaultParty) {
			tr.wc_parentId = this.ds.defaultParty.ent;
			tr.t_workCenterId = this.ds.defaultParty.wc;
		}

		this.edit.showPopup(tr, false).then(() => {
			this.grid.instance.endCustomLoading();
			this.grid.instance.refresh();
		}, error => this.ds.lg.showError(error));
	}
}
