import { Component } from '@angular/core';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { ProxyService } from '../../../service/proxy.service';
import { ControlSanctionEditService } from './control-sanction-edit.service';

@Component({
	selector: 'app-control-sanction-edit',
	templateUrl: 'control-sanction-edit.component.html'
})
export class ControlSanctionEditComponent extends iBisBaseComponent {
	public edit: ControlSanctionEditService;

	constructor(public proxy: ProxyService) {
		super(proxy.controlSanction, proxy.lg, proxy.iui, proxy.activatedRoute);
	}
}
