<dx-data-grid #gridUnitWaste [height]="300" (onToolbarPreparing)="toolbarPreparing($event, proxy.allowEdit)"
	(onInitialized)="onGridInit($event)">
	<dxo-scrolling [mode]="ui.scrollingMode" [showScrollbar]="ui.showScrollbar" [useNative]="!ui.isWindows">
	</dxo-scrolling>

	<dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" [storageKey]='key'>
	</dxo-state-storing>

	<dxi-column type="buttons" fixedPosition="left" [minWidth]="ui.actionWidth" [width]="ui.actionWidth" 
		[fixed]="true" [allowResizing]="false" [showInColumnChooser]="false" [visible]="proxy.showActions">
		<dxi-button [hint]="L('EDIT')" icon="edit" [onClick]="onEditClick" [visible]="proxy.allowEdit">
		</dxi-button>
	</dxi-column>

	<dxi-column [caption]="L('WASTE_CODE')" dataField="wt_code" dataType="string"> </dxi-column>
	<dxi-column [caption]="L('STORAGE_TYPE')" dataField="wtp_storageTypeId" dataType="string">
		<dxo-lookup [dataSource]="proxy.wasteType.storageType" valueExpr="id" [displayExpr]="proxy.enumDisplay">
		</dxo-lookup>
	</dxi-column>
	<dxi-column [caption]="L('U')" dataField='uom' dataType='number'>
		<dxo-lookup [dataSource]='proxy.wasteType.uom' valueExpr='id' displayExpr='value'></dxo-lookup>
	</dxi-column>
	<dxi-column [caption]="L('ELIMINATION_CODE')" dataField="wtp_eliminationId" dataType="string">
		<dxo-lookup [dataSource]="proxy.wasteType.eliminationCode" valueExpr="id" [displayExpr]="proxy.enumDisplay">
		</dxo-lookup>
	</dxi-column>
	<dxi-column [caption]="L('CAPITALIZATION_CODE')" dataField="wtp_capitalizationId" dataType="string">
		<dxo-lookup [dataSource]="proxy.wasteType.capitalizationCode" valueExpr="id" [displayExpr]="proxy.enumDisplay">
		</dxo-lookup>
	</dxi-column>
	<dxi-column [caption]="L('REQUIRE_APPROVAL')" dataField="wtp_requireApproval" dataType="boolean"></dxi-column>
	<dxi-column [caption]="L('ACTIVE')" dataField="wtp_isActiv" dataType="boolean"></dxi-column>
</dx-data-grid>