
import { iBisBaseComponent } from '@dohu/ibis-common';
import { Component, AfterViewInit } from '@angular/core';
import { ProxyService } from '../../service/proxy.service';

@Component({
	selector: 'app-analyzes',
	templateUrl: './analize.component.html'
})
export class AnalizeComponent extends iBisBaseComponent implements AfterViewInit {

	constructor(public proxy: ProxyService) {
		super(null, proxy.lg, proxy.iui, proxy.activatedRoute);
	}

	ngAfterViewInit(): void {
		document.getElementById('analize-container').style.height = this.ui.fullHeight;
	}
}
