import notify from 'devextreme/ui/notify';
import { Injectable } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { DataService } from '../../../service/data.service';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import { iBisAuthService, iBisSecureEditService } from '@dohu/ibis-auth';
import { iBisLanguageService, iBisServerConfig } from '@dohu/ibis-common';

@Injectable({
	providedIn: 'root'
})
export class TaxAnalyzesFilterService extends iBisSecureEditService {

	public result: Result;
	public data: CustomStore;
	public interval: any = [{ id: 1, text: this.L('DAILY') }, { id: 2, text: this.L('MONTHLY') }];
	public typeOp: any;
	constructor(auth: iBisAuthService, entity: iBisEntityService, config: iBisServerConfig, lg: iBisLanguageService,
		public ds: DataService) {
		super(auth, entity, config, lg);

		this.result = new Result();
		this.setTypeOp();
	}

	createDefault() {
		return { workCenter: [], interval: 1, date: new Date(), lastYear: false };
	}
	getById(id: string, serverUrl?: string): Promise<any> {
		throw new Error('Method not implemented.');
	}
	onRemove(id: string): Promise<void> {
		throw new Error('Method not implemented.');
	}
	onSaveEv(serverUrl?: string): Promise<any> {
		throw new Error('Method not implemented.');
	}
	reset(): void {
		this.model = this.createDefault();
	}

	public filterData = (): void => {
		if (this.model.workCenter && this.model.workCenter.length < 1) {
			notify('Selectați punct de lucru pentru analiză.', 'error', 3000);
			return;
		}
		if (this.model.date == null) {
			notify('Selectați data pentru analiză.', 'error', 3000);
			return;
		}
		sessionStorage.setItem('tax-analyzes', JSON.stringify(this.model));
		const obj: any = {};
		Object.assign(obj, this.model);
		let d = new Date(obj.date);
		d = new Date(d.setDate(1));
		obj.fromDate = this.entity.toDateFilter(new Date(d));
		this.model.fromDate = new Date(d);
		if (obj.interval === 1) {
			obj.thruDate = this.entity.toDateFilter(new Date(d.getFullYear(), d.getMonth() + 1, 0));
			this.model.thruDate = new Date(d.getFullYear(), d.getMonth() + 1, 0);
		} else {
			obj.thruDate = this.entity.toDateFilter(new Date(d.getFullYear() + 1, 0, 0));
			this.model.thruDate = new Date(d.getFullYear() + 1, 0, 0);
		}
		this.entity.execute('GetTaxAnalyzes', obj).then((result) => {
			this.initDashboard(result);
			this.setTypeOp();
			this.data = this.getTaxByDate();
		}, error => {
			this.ds.lg.showError(error);
		});
	};

	protected load(): void {
	}

	private setTypeOp() {
		if (this.model.lastYear) {
			this.typeOp = [{ value: 'tax', name: this.L('TAXES') }, { value: 'lasttax', name: this.L('LAST_TAXES') }];
		} else {
			this.typeOp = [{ value: 'tax', name: this.L('TAXES') }];
		}
	}

	private initDashboard(data: any) {
		this.result.tax = this.getValues(data.data, data.lastData ? data.lastData : null, 'tax');
		this.result.totalTaxText = this.totalValueTxt(this.result.tax, 'tax');
		this.result.intervalPeriod = 'Interval: ' + this.formatDate(this.model.fromDate) + ' - '
			+ this.formatDate(this.model.thruDate);
	}

	private getTaxByDate() {
		let fromDate = new Date(this.model.fromDate);
		let thruDate = new Date(this.model.thruDate);
		thruDate = new Date(thruDate.setDate(thruDate.getDate() + 1));
		if (this.model.lastYear) {
			fromDate = new Date(fromDate.setFullYear(fromDate.getFullYear() - 1));
		}
		const q = new EntityQuery('TaxView').in('t.workCenterId', this.model.workCenter).neq('t.payDate', null).addOrderBy(['t.no']);
		q.gte('t.payDate', this.entity.toDateTimeFilter(this.convertToDate(fromDate)));
		q.lte('t.payDate', this.entity.toDateTimeFilter(this.convertToDate(thruDate)));
		q.fields.push('t.id', 't.payDate', 't.value', 'fullName');
		return this.entity.store(q, false, 't_id');
	}

	private getValues(actualData: any[], lastData: any, key: string) {
		if (!lastData) {
			return actualData;
		}
		lastData.forEach(element => {
			actualData[element.day - 1]['last' + key] = element[key];
		});
		return actualData;
	}

	private totalValueTxt(ds: any, key: string) {
		let text = this.L('TOTAL_VALUE', null) + ' ' + this.getSum(ds, key) + ' ' + this.L('CURRENCY', null);
		if (this.model.lastYear) {
			text += '\n' + this.L('LAST_YEAR_TOTAL_VALUE', null) + ' ' + this.getSum(ds, 'last' + key) + ' ' + this.L('CURRENCY', null);
		}
		return text;
	}

	private getSum(arr: any[], field: string) {
		let total = 0;
		for (const item of arr) {
			total += item[field];
		}
		return total.toFixed(2);
	}

	private convertToDate(date: any) {
		const d = new Date(date);
		d.setHours(0, 0, 0, 0);
		return d;
	}

	private formatDate(date: any) {
		const d = new Date(this.convertToDate(date));
		return this.entity.toD2(d.getDate()) + '.' + this.entity.toD2((d.getMonth() + 1)) + '.' + d.getFullYear();
	}
}

export class Result {
	public tax: any[];
	public totalTaxText: string;
	public intervalPeriod: string;
	constructor() {
		this.tax = [];
		this.totalTaxText = '';
		this.intervalPeriod = '';
	};
}
