import { Component } from '@angular/core';
import { iBisBaseComponent, iBisUIService } from '@dohu/ibis-common';

@Component({
	selector: 'app-login-info',
	templateUrl: 'login-info.component.html',
	styleUrls: ['./login-info.component.css']
})
export class LoginInfoComponent extends iBisBaseComponent {
	constructor(iui: iBisUIService) {
		super(null, null, iui, null);
	}
}
