import { Component } from '@angular/core';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { ProxyService } from '../../../service/proxy.service';
import { DashboardBoxesService } from './dashboard-boxes.service';

@Component({
	selector: 'app-dashboard-boxes',
	templateUrl: './dashboard-boxes.component.html'
})
export class DashboardBoxesComponent extends iBisBaseComponent {

	public edit: DashboardBoxesService;
	constructor(public proxy: ProxyService) {
		super(proxy.dashboardBoxes, proxy.lg, proxy.iui, proxy.activatedRoute);
	}
}
