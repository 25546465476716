import { Component, ViewChild } from '@angular/core';
import { iBisViewComponent } from '@dohu/ibis-common';
import { AuditorEditService } from './auditor-edit.service';
import { ProxyService } from '../../../service/proxy.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';

@Component({
	selector: 'app-auditor',
	templateUrl: './auditor.component.html'
})
export class AuditorComponent extends iBisViewComponent {
	@ViewChild('gridAuditor') protected grid: DxDataGridComponent;

	public edit: AuditorEditService;
	constructor(public proxy: ProxyService) {
		super(proxy.auditor, proxy.lg, proxy.iui, proxy.activatedRoute);
	}
}
