import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../../../service/data.service';
import { InvoiceItemEditService } from './invoice-item-edit.service';
import { iBisBaseComponent, iBisUIService } from '@dohu/ibis-common';

@Component({
	selector: 'app-invoice-item-edit',
	templateUrl: 'invoice-item-edit.component.html'
})
export class InvoiceItemEditComponent extends iBisBaseComponent {

	constructor(public ds: DataService, public edit: InvoiceItemEditService, iui: iBisUIService, activeRoute: ActivatedRoute) {
		super(edit, ds.lg, iui, activeRoute);
	}
}
