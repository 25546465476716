import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxCheckBoxModule } from 'devextreme-angular/ui/check-box';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { DxDropDownBoxModule } from 'devextreme-angular/ui/drop-down-box';
import { DxFormModule } from 'devextreme-angular/ui/form';
import { DxoLookupModule } from 'devextreme-angular/ui/nested';
import { DxPopoverModule } from 'devextreme-angular/ui/popover';
import { DxPopupModule } from 'devextreme-angular/ui/popup';
import { DxScrollViewModule } from 'devextreme-angular/ui/scroll-view';
import { DxTabPanelModule } from 'devextreme-angular/ui/tab-panel';
import { DxTagBoxModule } from 'devextreme-angular/ui/tag-box';
import { DxTreeViewModule } from 'devextreme-angular/ui/tree-view';
import { DxTextBoxModule } from 'devextreme-angular/ui/text-box';
import { DxValidationGroupModule } from 'devextreme-angular/ui/validation-group';
import { DxValidatorModule } from 'devextreme-angular/ui/validator';
import { DxValidationSummaryModule } from 'devextreme-angular/ui/validation-summary';

import { iBisAuthModule } from '@dohu/ibis-auth';

import { AccountComponent } from './account.component';
import { AddCompanyComponent } from './add-company/add-company.component';
import { BillingComponent } from './billing/billing.component';
import { HistoryComponent } from './history/history.component';
import { LoginComponent } from './login/login.component';
import { LoginInfoComponent } from './login-info/login-info.component';
import { RegisterComponent } from './register/register.component';
import { UserAccessComponent } from './user/user-access/user-access.component';
import { UserComponent } from './user/user.component';

@NgModule({
	declarations: [
		UserComponent,
		LoginComponent,
		AccountComponent,
		BillingComponent,
		HistoryComponent,
		RegisterComponent,
		LoginInfoComponent,
		AddCompanyComponent,
		UserAccessComponent
	],
	imports: [
		BrowserModule,
		iBisAuthModule,
		DxFormModule,
		DxDataGridModule,
		DxDropDownBoxModule,
		DxTreeViewModule,
		DxTabPanelModule,
		DxScrollViewModule,
		DxPopoverModule,
		DxPopupModule,
		DxoLookupModule,
		DxCheckBoxModule,
		DxButtonModule,
		DxTagBoxModule,
		DxTextBoxModule,
		DxValidationGroupModule,
		DxValidatorModule,
		DxValidationSummaryModule
	],
	exports: [
		UserComponent,
		LoginComponent,
		AccountComponent,
		BillingComponent,
		HistoryComponent,
		RegisterComponent,
		LoginInfoComponent,
		AddCompanyComponent
	]
})
export class AccountModule { }
