import { Component } from '@angular/core';
import { ArchiveEditService } from './archive-edit.service';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { ProxyService } from '../../../service/proxy.service';
import { DataService } from '../../../service/data.service';

@Component({
	selector: 'app-archive-edit',
	templateUrl: 'archive-edit.component.html'
})
export class ArchiveEditComponent extends iBisBaseComponent {
	public edit: ArchiveEditService;

	constructor(public proxy: ProxyService) {
		super(proxy.archive, proxy.lg, proxy.iui, proxy.activatedRoute);
	}

	onReportIntervalValueChanged = (ev: any) => {
		if (ev.event) {
			ev.event.preventDefault();
			this.edit.model.month = null;
			this.edit.model.semester = null;
		}
	};

	onDateValueChanged = (ev: any) => {
		if (ev.event) {
			ev.event.preventDefault();
			this.edit.model.year = new Date(ev.value).getFullYear();
		}
	};
}
