<dx-data-grid #gridMonthly class="gridContainer" [dataSource]="edit.data" [height]="ui.fullHeight"
	[remoteOperations]="true" (onRowPrepared)="onRowPrepared($event)"
	(onToolbarPreparing)="toolbarPreparing($event, false)">

	<dxo-filter-row [visible]="true"></dxo-filter-row>
	<dxo-export [enabled]="ui.exportEnabled" fileName="monthly"></dxo-export>
	<dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" storageKey="qhseMonthly">
	</dxo-state-storing>
	<dxo-scrolling [mode]="ui.scrollingMode" [showScrollbar]="ui.showScrollbar" [useNative]="!ui.isWindows">
	</dxo-scrolling>

	<dxi-column type="buttons" fixedPosition="left" [minWidth]="ui.actionWidth" [width]="ui.actionWidth" [fixed]="true"
		[allowResizing]="false" [showInColumnChooser]="false" [visible]="proxy.allowEdit">
		<dxi-button [hint]="L('EDIT')" icon="edit" [onClick]="onEditClick">
		</dxi-button>
	</dxi-column>

	<dxi-column [caption]="L('ENTITY')" dataField='company' dataType='string'></dxi-column>
	<dxi-column [caption]="L('WORK_CENTER')" dataField="workCenter" dataType="string"></dxi-column>
	<dxi-column [caption]="L('YEAR')" dataField="year" dataType="number"></dxi-column>
	<dxi-column [caption]="L('MONTH')" dataField="month" dataType="number">
		<dxo-lookup [dataSource]="proxy.archive.months" displayExpr="value" valueExpr="id"></dxo-lookup>
	</dxi-column>
	<dxi-column [caption]="L('WASTE_CODE')" dataField="wasteCode" dataType="string"></dxi-column>
	<dxi-column name='entryHeader' headerCellTemplate='entryHeader'>
		<dxi-column [caption]="L('ADJUSTED_GENERATE')" dataField="adjustment" dataType="number"></dxi-column>
		<dxi-column [caption]="L('GENERATED')" dataField="generated" dataType="number"></dxi-column>
		<dxi-column [caption]="L('COLLECTED')" dataField="collected" dataType="number"></dxi-column>
		<dxi-column [caption]="L('TAKEN')" dataField="taken" dataType="number"></dxi-column>
	</dxi-column>
	<dxi-column name='exitHeader' headerCellTemplate='exitHeader'>
		<dxi-column [caption]="L('TRANSFER')" dataField="transfer" dataType="number"></dxi-column>
		<dxi-column [caption]="L('IN_PROGRESS')" dataField="inProgress" dataType="number"></dxi-column>
		<dxi-column [caption]="L('REMOVED')" dataField="eliminated" dataType="number"></dxi-column>
		<dxi-column [caption]="L('VALORIFIED')" dataField="valued" dataType="number"></dxi-column>
	</dxi-column>
	<dxi-column name='stockHeader' headerCellTemplate='stockHeader'>
		<dxi-column [caption]="L('PREV_STOCK')" dataField="previousStock" dataType="number"></dxi-column>
		<dxi-column [caption]="L('ACTUAL_MONTH_STOCK')" dataField="stock" dataType="number"></dxi-column>
		<dxi-column [caption]="L('STOCK')" dataType="number" [calculateCellValue]="calculateTotalStock"></dxi-column>
	</dxi-column>
	<dxi-column [caption]="L('U')" dataField="wasteUom" dataType="number">
		<dxo-lookup [dataSource]="proxy.wasteType.uom" valueExpr="id" displayExpr="value"></dxo-lookup>
	</dxi-column>
	<dxi-column name='auditHeader' headerCellTemplate='auditHeader'>
		<dxi-column [caption]="L('MADE_BY')" dataField="createdBy" dataType="string"></dxi-column>
		<dxi-column [caption]="L('MODIFIED_BY')" dataField="modifiedBy" dataType="string"></dxi-column>
		<dxi-column [caption]="L('CREATION_DATE')" dataField="created" dataType="datetime" calculateSortValue="created"
			[calculateDisplayValue]="iui.formatDateTime">
		</dxi-column>
		<dxi-column [caption]="L('LAST_MODIFIED')" dataField="modified" dataType="datetime"
			calculateSortValue="modified" [calculateDisplayValue]="iui.formatDateTime">
		</dxi-column>
	</dxi-column>
	<div *dxTemplate="let data of 'breadcrumb'">
		<ibis-breadcrumb></ibis-breadcrumb>
	</div>
	
	<div *dxTemplate="let en of 'entryHeader'" class="dx-datagrid-text-content" role="presentation">
		<i class="fas fa-arrow-circle-down"></i> Intrare
	</div>
	<div *dxTemplate="let ex of 'exitHeader'" class="dx-datagrid-text-content" role="presentation">
		<i class="fas fa-arrow-circle-up"></i> Ieșire
	</div>
	<div *dxTemplate="let s of 'stockHeader'" class="dx-datagrid-text-content" role="presentation">
		<i class="fas fa-boxes"></i> Stoc
	</div>
	<div *dxTemplate="let a of 'auditHeader'" class="dx-datagrid-text-content" role="presentation">
		<i class="far fa-calendar-alt"></i> Audit
	</div>
</dx-data-grid>

<ibis-edit-popup [title]="edit.title" [edit]="edit" [showAdd]="true">
	<app-monthly-stock-edit></app-monthly-stock-edit>
</ibis-edit-popup>

<ibis-edit-popup [title]="editR.title" [edit]="editR" [showAdd]="true">
	<app-monthly-stock-recheck></app-monthly-stock-recheck>
</ibis-edit-popup>