import { Injectable } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import { iBisEditService, iBisLanguageService } from '@dohu/ibis-common';
import { TransportEditService } from '../../../waste/transport/transport-edit.service';

@Injectable()
export class TaxEditService extends iBisEditService {

	public data: CustomStore;
	public finishedTransport: DataSource;

	constructor(public entity: iBisEntityService, private transport: TransportEditService, lg: iBisLanguageService) {
		super(lg);
		this.title = 'TAXES';
		this.validation = 'TaxValidation';

		this.data = this.getData();
		this.finishedTransport = this.getFinishedTransports();
	}

	createDefault(): any {
		return { sendDate: new Date(), perTransport: false };
	}

	getById(id: any): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			this.entity.getById('Tax', id).then((result) => {
				if (!result) {
					reject();
				} else {
					resolve(result);
				}
			}, error => {
				this.lg.showError(error);
			});
		});
	}

	onRemove(id: string): Promise<void> {
		return this.entity.remove('Tax', id);
	}

	onSaveEv(): Promise<any> {
		return this.entity.save('Tax', this.model.id, this.model);
	}

	reset(): void {
		this.model = this.createDefault();
	}

	public getFinishedTransports(workCenterId?: string): DataSource {
		if (workCenterId) {
			return new DataSource({ store: this.transport.getFinishedTransports(workCenterId), paginate: true, pageSize: 10 });
		}
		return new DataSource({ store: this.transport.getFinishedTransports(), paginate: true, pageSize: 10 });
	}

	private getData(): CustomStore {
		return this.entity.store(new EntityQuery('TaxView').addOrderByDesc(['t.sendDate']), false, 't_id');
	}

}
