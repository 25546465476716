import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { iBisCommonModule } from '@dohu/ibis-common';
import { iBisNavigationModule } from '@dohu/ibis-navigation';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { DxDateBoxModule } from 'devextreme-angular/ui/date-box';
import { DxDropDownBoxModule } from 'devextreme-angular/ui/drop-down-box';
import { DxFormModule } from 'devextreme-angular/ui/form';
import { DxListModule } from 'devextreme-angular/ui/list';
import { DxPopupModule } from 'devextreme-angular/ui/popup';
import { DxSelectBoxModule } from 'devextreme-angular/ui/select-box';
import { DxTextBoxModule } from 'devextreme-angular/ui/text-box';
import { DxValidationGroupModule } from 'devextreme-angular/ui/validation-group';
import { DxValidationSummaryModule } from 'devextreme-angular/ui/validation-summary';
import { DxValidatorModule } from 'devextreme-angular/ui/validator';
import { FrameworkModule } from '../../framework/framework.module';
import { StockModule } from '../stock/stock.module';
import { ScaleComponent } from './scale/scale.component';
import { TransportApprovalEditComponent } from './transport-approval/transport-approval-edit.component';
import { TransportApprovalEditService } from './transport-approval/transport-approval-edit.service';
import { TransportApprovalPartyEditComponent } from './transport-approval/transport-approval-party/transport-approval-party-edit.component';
import { TransportApprovalPartyComponent } from './transport-approval/transport-approval-party/transport-approval-party.component';
import { TransportApprovalPartyEditService } from './transport-approval/transport-approval-party/transport-approval-party-edit.service';
import { TransportApprovalComponent } from './transport-approval/transport-approval.component';
import { TransportEditComponent } from './transport-edit.component';
import { TransportEditService } from './transport-edit.service';
import { TransportFinishComponent } from './transport-finish/transport-finish.component';
import { TransportFinishService } from './transport-finish/transport-finish.service';
import { TransportFullEditComponent } from './transport-full/transport-full-edit.component';
import { TransportFullComponent } from './transport-full/transport-full.component';
import { TransportHistoryComponent } from './transport-history/transport-history.component';
import { TransportResultComponent } from './transport-result/transport-result.component';
import { DxTabPanelModule } from 'devextreme-angular/ui/tab-panel';
import { TransportComponent } from './transport.component';
@NgModule({
	declarations: [
		ScaleComponent,
		TransportComponent,
		TransportEditComponent,
		TransportFullComponent,
		TransportFinishComponent,
		TransportResultComponent,
		TransportHistoryComponent,
		TransportFullEditComponent,
		TransportApprovalComponent,
		TransportApprovalEditComponent,
		TransportApprovalPartyComponent,
		TransportApprovalPartyEditComponent,
	],
	imports: [
		StockModule,
		FormsModule,
		RouterModule,
		DxListModule,
		DxFormModule,
		BrowserModule,
		DxPopupModule,
		DxButtonModule,
		DxDateBoxModule,
		DxTextBoxModule,
		FrameworkModule,
		iBisCommonModule,
		DxTabPanelModule,
		DxDataGridModule,
		DxSelectBoxModule,
		DxValidatorModule,
		DxDropDownBoxModule,
		iBisNavigationModule,
		DxValidationGroupModule,
		DxValidationSummaryModule,
	],
	exports: [
		ScaleComponent,
		TransportComponent,
		TransportFullComponent,
		TransportEditComponent,
		TransportFinishComponent,
		TransportHistoryComponent,
		TransportFullEditComponent,
		TransportApprovalComponent,
		TransportApprovalEditComponent,
		TransportApprovalPartyComponent,
		TransportApprovalPartyEditComponent,
	],
	providers: [
		TransportEditService,
		TransportFinishService,
		TransportApprovalEditService,
		TransportApprovalPartyEditService,
	]
})
export class TransportModule { }
