import { Injectable } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import { iBisEditService, iBisLanguageService } from '@dohu/ibis-common';

@Injectable({
	providedIn: 'root'
})
export class PermissionEditService extends iBisEditService {

	public data: CustomStore;
	public activity: CustomStore;

	constructor(private entity: iBisEntityService, lg: iBisLanguageService) {
		super(lg);
		this.title = 'PERMISSION';
		this.validation = 'permissionValidator';

		this.activity = this.getActivity();
	}
	createDefault(): any {
		return {};
	}
	getById(id: string, serverUrl?: string): Promise<any> {
		throw new Error('Method not implemented.');
	}
	onRemove(id: string): Promise<void> {
		throw new Error('Method not implemented.');
	}
	onSaveEv(serverUrl?: string): Promise<any> {
		throw new Error('Method not implemented.');
	}
	reset(): void {
		this.createDefault();
	}

	public getDataByAuditor(auditorId: string): CustomStore {
		return this.entity.store(new EntityQuery('SafetyAuditorPermission')
			.eq('auditorId', auditorId).addOrderBy(['activityId']), false, 'id');
	}

	private getActivity(): CustomStore {
		const q = new EntityQuery('EnumValue').addOrderBy(['orderIndex', 'code']).
			linkEq('typeId', 'id', 'EnumType', 'code', 'SAFETY_ACTIVITY');
		q.fields.push('id', 'code', 'description');
		return this.entity.store(q, false, 'id');
	}
}
