import { Component } from '@angular/core';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { ProxyService } from '../../service/proxy.service';
import { CollectEditService } from './collect-edit.service';
import { UOMHelper } from '../waste-type/waste-type-edit.service';

@Component({
	selector: 'app-collect-edit',
	templateUrl: './collect-edit.component.html'
})
export class CollectEditComponent extends iBisBaseComponent {
	public edit: CollectEditService;
	public uomHelper: UOMHelper;

	public noFatQtyCollected: string;

	private cboFAT: any;
	private cboCar: any;
	private cboDriver: any;
	private cboWasteType: any;


	constructor(public proxy: ProxyService) {
		super(proxy.collect, proxy.lg, proxy.iui, proxy.activatedRoute);
		this.uomHelper = new UOMHelper(this.proxy.wasteType);

		proxy.wasteType.onDataChanged.subscribe((data) => {
			if (data === 'code' && this.cboWasteType) {
				this.proxy.resetValidation(this.cboWasteType);
			}
			if (data === 'selected') {
				this.edit.model.wasteType = this.proxy.wasteType.selected;
				if (this.edit.model.uom) {
					this.proxy.wasteType.selected.uom = this.edit.model.uom;
					this.uomHelper.update(this.proxy.wasteType.selectedUomName);
				}
			}
		});
		proxy.transporter.onDataChanged.subscribe((data) => {
			if (data === 'selected' && this.cboCar && this.cboDriver) {
				this.proxy.resetValidation(this.cboCar);
				this.proxy.resetValidation(this.cboDriver);
			}
		});
		proxy.transportApproval.onDataChanged.subscribe((data) => {
			if (data === 'code' && this.cboFAT) {
				this.proxy.resetValidation(this.cboFAT);
			}
			if (data === 'selected') {
				this.edit.model.fat = this.proxy.transportApproval.selected;
			}
		});
	}

	onFATInit = (ev: any) => {
		this.cboFAT = ev.component;
	};

	onCarInit = (ev: any) => {
		this.cboCar = ev.component;
	};

	onDriverInit = (ev: any) => {
		this.cboDriver = ev.component;
	};

	onWasteTypeInit = (ev: any) => {
		this.cboWasteType = ev.component;
	};

	onFatChanged = (ev: any) => {
		this.proxy.transportApproval.selectedId = ev.value;
	};

	onGeneratorChanged = (ev: any) => {
		this.proxy.wasteType.workCenterId = ev.value;
		this.proxy.transportApproval.generatorId = ev.value;
	};

	onTransporterChanged = (ev: any) => {
		this.proxy.transporter.selectedId = ev.value;
		this.proxy.transportApproval.transporterId = ev.value;
	};

	onWorkCenterChanged = (ev: any) => {
		this.proxy.transportApproval.destinationId = ev.value;
	};

	onWasteTypeChanged = (ev: any) => {
		this.proxy.wasteType.selectedId = ev.value;
		this.proxy.transportApproval.wasteTypeId = ev.value;
	};

	public get qtyHelpText(): string {
		return this.proxy.transportApproval.collectNoFAT ?
			this.L('FAT_QUANTITY_HELP', [this.proxy.transportApproval.collectNoFAT.toString(), this.proxy.wasteType.selectedUomName]) : '';
	}

	validateCode = (op: any) => {
		const other = op.formItem.dataField === 'eliminationId' ?
			this.edit.model.capitalizationId : this.edit.model.eliminationId;
		return op.value || other;
	};

	validateQuantity = (options: any) => {
		if (this.edit.model.approvalId && this.edit.model.fat && this.edit.model.fat.quantityLeft < this.edit.model.quantity) {
			return false;
		} return true;
	};

	validateFAT = (options: any) => {
		if (this.edit.model.wasteType.hazard === 1 && this.edit.model.quantity >= (this.proxy.wasteType.selected.uom === 1 ? 1 : 1000)
			&& !this.edit.model.approvalId) {
			return false;
		} else {
			const totalQuantity = this.proxy.transportApproval.transportNoFAT +
				this.proxy.transportApproval.collectNoFAT + this.edit.model.quantity;
			if (totalQuantity >= (this.proxy.wasteType.selected.uom === 1 ? 1 : 1000)
				&& this.edit.model.wasteType.hazard === 1 && !this.edit.model.approvalId) {
				return false;
			} else {
				return true;
			}
		}
	};
}
