import { Injectable } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import { environment } from '../../../../environments/environment';
import { iBisEditService, iBisLanguageService } from '@dohu/ibis-common';

@Injectable({
	providedIn: 'root'
})
export class ContractAnnexEditService extends iBisEditService {

	constructor(private entity: iBisEntityService, lg: iBisLanguageService) {
		super(lg);
		this.title = 'ANNEX';
		this.validation = 'AnnexValidation';
	}

	public getData(contractId: string): CustomStore {
		const q = new EntityQuery('ContractAnnex').eq('contractId', contractId).addOrderBy(['no']);
		return this.entity.store(q, false, 'id');
	}
	public removeFile(id: string) {
		return this.entity.remove('FileInfo', id, environment.saasServer);
	}

	createDefault(): any {
		return {};
	}

	getById(id: string): Promise<any> {
		return new Promise((resolve, reject) => {
			this.entity.getById('ContractAnnex', id).then(result => {
				if (!result) {
					reject();
				}
				resolve(result);
			}, error => {
				this.lg.showError(error);
			});
		});
	}

	onRemove(id: string): Promise<void> {
		return this.entity.remove('ContractAnnex', id);
	}

	onSaveEv(): Promise<any> {
		return this.entity.save('ContractAnnex', this.model.id, this.model);
	}

	reset(): void {
		this.model = this.createDefault();
	}
}
