<dx-form [colCount]="2" [formData]="edit.model" [showValidationSummary]="false" [validationGroup]="edit.validation"
	labelLocation="left">
	<dxi-item [label]="{text: L('REPORT_TYPE')}" dataField="typeId" editorType="dxSelectBox" [colSpan]="2"
		[editorOptions]="{dataSource: edit.reportTypes, valueExpr: 'id', displayExpr: 'value', searchEnabled: true, showClearButton: true }">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{ text: L('ENT_WORKC')}" dataField="partyId" editorType="dxSelectBox" [colSpan]="2"
		[editorOptions]="{dataSource: edit.parties, displayExpr: 'fullName', valueExpr:'c_id', searchEnabled: true}">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('REPORT_INTERVAL')}" dataField="interval" editorType="dxSelectBox" [colSpan]="2"
		[editorOptions]="{dataSource: edit.intervalReport, valueExpr: 'id', displayExpr: 'value', onValueChanged: onReportIntervalValueChanged }">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('MONTH')}" dataField="month" editorType="dxSelectBox" [colSpan]="1"
		[visible]="edit.model.interval == 1"
		[editorOptions]="{ dataSource: edit.months, valueExpr: 'id', displayExpr: 'value' }">
	</dxi-item>
	<dxi-item [label]="{text: L('SEMESTER')}" dataField="semester" editorType="dxSelectBox" [colSpan]="1"
		[visible]="edit.model.interval == 2"
		[editorOptions]="{ dataSource: edit.semesters, valueExpr: 'id', displayExpr: 'value' }">
	</dxi-item>
	<dxi-item [label]="{text: L('YEAR')}" dataField="date" editorType="dxDateBox"
		[colSpan]="edit.model.interval == 3 || edit.model.interval == undefined ? 2 : 1"
		[editorOptions]="{ displayFormat: 'yyyy', width: 'auto', calendarOptions: {minZoomLevel: 'decade', maxZoomLevel: 'decade' }, onValueChanged: onDateValueChanged }">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>

	<dxi-item [label]="{text: L('DESCRIPTION')}" dataField="comments" editorType="dxTextArea" [colSpan]="2"
		[editorOptions]="{ }">
	</dxi-item>
</dx-form>