import { Params } from '@angular/router';
import { Component, ViewChild } from '@angular/core';
import { ProxyService } from '../../service/proxy.service';
import { PartyEditService } from './party-edit/party-edit.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { BreadcrumbViewComponent } from '../../framework/breadcrumb-view.component';

@Component({
	templateUrl: 'party.component.html'
})
export class PartyComponent extends BreadcrumbViewComponent {
	@ViewChild('gridParty') grid: DxDataGridComponent;

	public edit: PartyEditService;
	public partyType: any;
	constructor(public proxy: ProxyService) {
		super(proxy.party, proxy.lg, proxy.iui, proxy.activatedRoute);
		this.id = 'p_id';
		this.key = 'party';
	}

	get showGenerate(): boolean {
		return this.partyType && this.partyType === '1';
	}

	get showTransporter(): boolean{
		return this.partyType && this.partyType === '4';
	}

	onCellPrepared(e: any) {
		if (e.rowType === 'data' && e.column.command === 'expand') {
			if (e.data.p_isActiv === false) {
				e.cellElement.children[0].classList.remove('dx-datagrid-group-closed');
			}
		}
	}

	onCellClick(e: any) {
		if (e.rowType === 'data') {
			if (e.cellElement.classList[0] === 'dx-command-expand' && e.data.p_isActiv === false) {
				e.component.collapseAll(-1);
			}
		}
	}

	protected queryParams(pr: Params): void {
		this.partyType = pr['partyType'];
		if (this.partyType) {
			if(this.grid){
				this.grid.instance.collapseAll(-1);
			}
			this.edit.types = this.partyType;
		}
		this.edit.contractType = pr['ctrCode'];
	}
}
