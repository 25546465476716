import { Injectable } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { DataService } from '../../../service/data.service';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import { iBisEditService, iBisLanguageService } from '@dohu/ibis-common';

@Injectable({
	providedIn: 'root'
})
export class AlertEditService extends iBisEditService {
	public actionStatus: { id: number; value: string }[];

	public permits: CustomStore;
	public contracts: CustomStore;
	public transports: CustomStore;
	public permitsInt: CustomStore;
	public controlMeasures: CustomStore;

	private monthTwo: Date;
	constructor(private entity: iBisEntityService, private ds: DataService, lg: iBisLanguageService) {
		super(lg);

		this.monthTwo = new Date();
		this.actionStatus = this.getActionsStatus();
		this.permits = this.getPermits();
		this.contracts = this.getContracts();
		this.transports = this.getTransports();
		this.permitsInt = this.getPermitsInt();
		this.controlMeasures = this.getControlMeasures();
	}

	createDefault(): any {
		return {};
	}
	getById(id: string, serverUrl?: string): Promise<any> {
		throw new Error('Method not implemented.');
	}
	onRemove(id: string): Promise<void> {
		throw new Error('Method not implemented.');
	}
	onSaveEv(serverUrl?: string): Promise<any> {
		throw new Error('Method not implemented.');
	}
	reset(): void {
		this.model = this.createDefault();
	}

	private getActionsStatus(): { id: number; value: string }[] {
		const list = [{ 'id': 0, 'value': 'IN_PROGRESS' }, { 'id': 1, 'value': 'ACCOMPLISHED' }];
		return this.ds.getEnum('actionStatus', list);
	}

	private getPermits(): CustomStore {
		this.monthTwo.setMonth(new Date().getMonth() + 2);
		const q = new EntityQuery('PermitView').eq('p.typeId', 2).lt('p.validity', this.monthTwo).eq('p.isValid', true);
		return this.entity.store(q, false, 'p_id');
	}

	private getPermitsInt(): CustomStore {
		this.monthTwo.setMonth(this.monthTwo.getMonth() + 4);
		const q = new EntityQuery('PermitView').eq('p.typeId', 3).lt('p.validity', this.monthTwo).eq('p.isValid', true);
		return this.entity.store(q, false, 'p_id');
	}

	private getContracts(): CustomStore {
		this.monthTwo.setMonth(new Date().getMonth() + 3);
		const q = new EntityQuery('ContractView').eq('c.isValid', true).lt('c.endDate', this.monthTwo);
		return this.entity.store(q, false, 'c_id');
	}

	private getTransports(): CustomStore {
		const days = new Date();
		days.setDate(days.getDate() - 3);
		const q = new EntityQuery('TransportView').eq('t_finishDate', null).lt('t_sendDate', days);
		return this.entity.store(q, false, 't_id');
	}

	private getControlMeasures(): CustomStore {
		const q = new EntityQuery('ControlMeasure').neq('actionStatus', 1);
		return this.entity.store(q, false, 'id');
	}
}
