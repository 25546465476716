import { Component, ViewChild } from '@angular/core';
import { iBisViewComponent } from '@dohu/ibis-common';
import { ProxyService } from '../../service/proxy.service';
import { TreatmentEditService } from './treatment-edit.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';

@Component({
	selector: 'app-treatment-item',
	templateUrl: './treatment-item.component.html'
})
export class TreatmentItemComponent extends iBisViewComponent {
	@ViewChild('gridTreatmentOut') protected grid: DxDataGridComponent;

	public edit: TreatmentEditService;
	constructor(public proxy: ProxyService) {
		super(proxy.treatment, proxy.lg, proxy.iui, proxy.activatedRoute);
		this.key = 'treatmentItem';
	}
}
