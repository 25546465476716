import { Injectable } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import { iBisEditService, iBisLanguageService } from '@dohu/ibis-common';

@Injectable({
	providedIn: 'root'
})
export class SafetyPlanningEditService extends iBisEditService {
	public data: CustomStore;
	public filteredData: CustomStore;

	private date: Date;

	constructor(private entity: iBisEntityService, lg: iBisLanguageService) {
		super(lg);
		this.title = 'PLANNING';
		this.validation = 'sftPlanning';

		this.data = this.getData();
		this.date = new Date();
		this.filteredData = this.getFilteredData();
	}

	public get PlannedDate(){
		return this.date;
	}

	public set PlannedDate(d: Date){
		this.date = d;
		this.filteredData = this.getFilteredData();
	}

	createDefault() {
		return { monthYear: this.date };
	}
	getById(id: any): Promise<any> {
		return new Promise((resolve, reject) => {
			this.entity.getById('SafetyPlanning', id).then(result => {
				if (!result) {
					reject();
				} else{
					result.monthYear = new Date(result.year, result.month - 1, 1);
					resolve(result);
				}
			}, error => {
				this.lg.showError(error);
			});
		});
	}

	onRemove(id: string): Promise<void> {
		return this.entity.remove('SafetyPlanning', id);
	}

	onSaveEv(): Promise<any> {
		this.model.year = new Date(this.model.monthYear).getFullYear();
		this.model.month = new Date(this.model.monthYear).getMonth() + 1;
		return this.entity.save('SafetyPlanning', this.model.id, this.model);
	}

	reset(): void {
		this.model = this.createDefault();
	}

	private getData(): CustomStore {
		const q = new EntityQuery('SafetyPlanningView').addOrderByDesc(['p.year', 'p.month']);
		return this.entity.store(q, false, 'p_id');
	}

	private getFilteredData(): CustomStore {
		const q = new EntityQuery('SafetyPlanningView').eq('p.month', this.date.getMonth() + 1)
			.eq('p.year', this.date.getFullYear()).addOrderByDesc(['p.year', 'p.month']);
		return this.entity.store(q, false, 'p_id');
	}
}
