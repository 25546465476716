<dx-data-grid #departmentGrid class="gridContainer" [height]="ui.fullHeight"
    [remoteOperations]="true" [dataSource]="edit.data" (onInitNewRow)="onInitNewRow($event)"
    (onToolbarPreparing)="toolbarPreparing($event, false)">
    <dxo-scrolling mode="infinite" [showScrollbar]="ui.showScrollbar" [useNative]="!ui.isWindows">
    </dxo-scrolling>
    <dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" [storageKey]="key">
    </dxo-state-storing>
    <dxo-editing mode="row" [allowAdding]="true" [allowUpdating]="true" [allowDeleting]="true" [useIcons]="true">
    </dxo-editing>

    <dxi-column [caption]="L('NAME')" dataField="name" dataType="string"></dxi-column>
        <dxi-validation-rule type="required"></dxi-validation-rule>
		<dxi-validation-rule type="stringLength"  [max]="256" [message]="L('NAME_LENGTH')">
		</dxi-validation-rule>
    <dxi-column [caption]="L('ENT_WORKC')" dataField="entityId" dataType="string">
        <dxo-lookup [dataSource]="proxy.party.ownWorkCenter.store()" valueExpr="c_id" displayExpr="fullName">
        </dxo-lookup>
    </dxi-column>
    <dxi-column [caption]="L('ACTIVE')" dataField="isActiv" dataType="boolean" [width]="100"></dxi-column>

</dx-data-grid>