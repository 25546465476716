import { Component, ViewChild } from '@angular/core';
import { iBisViewComponent } from '@dohu/ibis-common';
import { ProxyService } from '../../../../service/proxy.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { SafetyPlanningExceptEditService } from './safety-planning-except.service';
import { SafetyPlanningCopyService } from '../safety-planning-copy/safety-planning-copy.service';

@Component({
	selector: 'app-safety-planning-except',
	templateUrl: './safety-planning-except.component.html'
})
export class SafetyPlanningExceptComponent extends iBisViewComponent {
	@ViewChild('gridSafetyPlanningExcept') grid: DxDataGridComponent;

	public edit: SafetyPlanningExceptEditService;
	constructor(public proxy: ProxyService, public copy: SafetyPlanningCopyService) {
		super(proxy.safetyPlanningExcept, proxy.lg, proxy.iui, proxy.activatedRoute);
		this.key = 'safetyPlanningExcept';
	}

	onEditExceptClick = (ev: any) => {
		this.edit.showPopup({ id: ev.row.data.id, isExcept: true }).catch(error => {
			this.proxy.lg.showError(error);
		});
	};

	toolbarPreparing(ev: any) {
		super.toolbarPreparing(ev, true);
		ev.toolbarOptions.items.unshift({
			text: this.L('EXCEPT'),
			location: 'before'
		});
	}
}
