<dx-data-grid #gridInvoices class="gridContainer" [dataSource]="edit.data"
	[height]="ui.fullHeight" [remoteOperations]="true" (onToolbarPreparing)="toolbarPreparing($event)">

	<dxo-scrolling [mode]="ui.scrollingMode" [showScrollbar]="ui.showScrollbar" [useNative]="!ui.isWindows">
	</dxo-scrolling>
	<dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" [storageKey]="key">
	</dxo-state-storing>
	<dxo-master-detail [enabled]="true" template="details"></dxo-master-detail>

	<dxi-column [caption]="L('ENT_WORKC')" dataField="fullName" dataType="string"></dxi-column>
	<dxi-column [caption]="L('SUPPLIER')" dataField="supplierName" dataType="string"></dxi-column>
	<dxi-column [caption]="L('NO')" dataField="i_no" dataType="string"></dxi-column>
	<dxi-column [caption]="L('DATE')" dataField="i_date" dataType="date" calculateSortValue="date"
		[calculateDisplayValue]="iui.formatDate">
	</dxi-column>
	<dxi-column [caption]="L('AMOUNT_WITH_VAT')" dataField="i_amount" dataType="number"></dxi-column>
	<dxi-column [caption]="L('CONTRACT_TYPE')" dataField="i_isSales" dataType="boolean">
		<dxo-lookup [dataSource]='proxy.invoice.type' valueExpr='id' displayExpr='value'></dxo-lookup>
	</dxi-column>
	<dxi-column [caption]="L('DESCRIPTION')" dataField="i_comments" dataType="string"></dxi-column>
	
	<dxi-column type="buttons" fixedPosition="left" [minWidth]="proxy.actionsWidth" [width]="proxy.actionsWidth" 
		[fixed]="true" [allowResizing]="false" [showInColumnChooser]="false" [visible]="proxy.showActions">
		<dxi-button [hint]="L('EDIT')" icon="edit" [onClick]="onEditClick" [visible]="proxy.allowEdit">
		</dxi-button>
		<dxi-button [hint]="L('DELETE')" icon="trash" [onClick]="onDeleteClick" [visible]="proxy.allowDelete">
		</dxi-button>
	</dxi-column>

	<div *dxTemplate="let data of 'details'">
		<ibis-file [entityId]="data.data.i_id" [allowDelete]='proxy.allowEdit'></ibis-file>
	</div>

</dx-data-grid>

<ibis-edit-popup [edit]="edit" [showAdd]="true">
	<app-invoice-edit></app-invoice-edit>
</ibis-edit-popup>