<dx-data-grid #gridGa class="gridContainer box-shadow" [dataSource]="edit.data" [height]="ui.fullHeight"
	[remoteOperations]="true" (onToolbarPreparing)="toolbarPreparing($event, proxy.allowEdit)">

	<dxo-scrolling [mode]="ui.scrollingMode" [showScrollbar]="ui.showScrollbar" [useNative]="!ui.isWindows">
	</dxo-scrolling>
	<dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" [storageKey]='key'>
	</dxo-state-storing>

	<dxi-column type="buttons" fixedPosition="left" [minWidth]="proxy.actionsWidth" [width]="proxy.actionsWidth" 
		[fixed]="true" [allowResizing]="false" [showInColumnChooser]="false" [visible]="proxy.showActions">
		<dxi-button [hint]="L('EDIT')" icon="edit" [onClick]="onEditClick" [visible]="proxy.allowEdit">
		</dxi-button>
		<dxi-button [hint]="L('DELETE')" icon="trash" [onClick]="onDeleteClick" [visible]="proxy.allowDelete">
		</dxi-button>
	</dxi-column>

	<dxi-column [caption]="L('NAME')" dataField="name" dataType="string"></dxi-column>
	<dxi-column [caption]="L('ZONE_TYPE')" dataField="typeId" dataType="number">
		<dxo-lookup [dataSource]="edit.zoneType" valueExpr="id" displayExpr="value"></dxo-lookup>
	</dxi-column>
	<dxi-column [caption]="L('PARENT_ZONE')" dataField="parentId" dataType="string">
		<dxo-lookup [dataSource]="edit.data" valueExpr="id" displayExpr="name"></dxo-lookup>
	</dxi-column>
	<div *dxTemplate="let data of 'breadcrumb'">
		<ibis-breadcrumb></ibis-breadcrumb>
	</div>
</dx-data-grid>

<ibis-edit-popup [edit]="edit" [showAdd]="true">
	<app-geographic-area-edit></app-geographic-area-edit>
</ibis-edit-popup>