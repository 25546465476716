import { iBisViewComponent } from '@dohu/ibis-common';
import { Component, Input, ViewChild } from '@angular/core';
import { ProxyService } from '../../../service/proxy.service';
import { TransporterEditService } from './transporter-edit.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';

@Component({
	selector: 'app-driver',
	templateUrl: 'driver.component.html'
})
export class DriverComponent extends iBisViewComponent {

	@Input() partyId: any;
	@ViewChild('gridDriver') grid: DxDataGridComponent;
	constructor(public proxy: ProxyService) {
		super(proxy.transporter, proxy.lg, proxy.iui, proxy.activatedRoute);
		this.key = 'driver';
	}

	onInitRow(ev: any) {
		ev.data.transporterId = this.partyId;
	}

	onGridInit(e: any) {
		if (e.component.option('dataSource') == null) {
			e.component.option('dataSource', (this.edit as TransporterEditService).getDriver(this.partyId));
		}
	}
}
