<dx-form #treatmentForm [formData]="edit.model" [showValidationSummary]="false" labelLocation="left" [colCount]="2"
	[validationGroup]="edit.validation">
	<dxi-item [label]="{text: L('ENT_WORKC')}" dataField="workCenterId" [colSpan]="2" editorType="dxSelectBox"
		[editorOptions]="{ dataSource: proxy.party.ownWorkCenter, valueExpr: 'c_id', displayExpr: 'fullName', onValueChanged: onWorkCenterChanged, searchEnabled: true,
		disabled: edit.model.id != undefined || edit.model.id != null}">
	</dxi-item>
	<dxi-item [label]="{text: L('TREATMENT_DATE')}" dataField="date" editorType="dxDateBox"
		[editorOptions]="{ width: '100%', displayFormat: 'dd.MM.yyyy' }">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('ELIMINATION_TYPE')}" dataField="typeId" editorType="dxSelectBox"
		[editorOptions]="{ dataSource: edit.type, valueExpr: 'id', displayExpr: 'value' }">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('WASTE_CODE')}" editorType="dxTextBox" dataField="wasteType.code"
		[editorOptions]="{ buttons: [{name: 'Search', options:{icon: 'find', text: L('SELECT_PLACEHOLDER'), onClick: onOpenStockPopup, stylingMode: 'text' }}], onInitialized: onWasteTypeInit,
          onValueChanged: onWastecodeChanged ,disabled: !edit.model.workCenterId || edit.model.id != undefined || edit.model.id != null, onFocusIn: onFocusEv }">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('QUANTITY')}" dataField="quantity" editorType="dxNumberBox"
		[editorOptions]="{ buttons: [{name: 'uom', location: 'after', options: uomHelper.options }], onFocusIn: onFocusEv}">
	</dxi-item>
	<dxi-item [label]="{text: L('WASTE_NAME')}" dataField="wasteType.name" editorType="dxTextBox" [colSpan]="2"
		[editorOptions]="{ readOnly: true }">
	</dxi-item>
	<dxi-item [label]="{text: L('PHYSICAL_STATE')}" dataField="physicalCondition" editorType="dxSelectBox"
		[editorOptions]="{ dataSource: proxy.wasteType.physicalState, valueExpr: 'id', displayExpr: 'value'}">
	</dxi-item>
	<dxi-item [label]="{text: L('WASTE_TYPE')}" dataField="wasteType.hazardName" editorType="dxTextBox"
		[editorOptions]="{ readOnly: true }">
	</dxi-item>
	<dxi-item [label]="{text: L('OBSERVATIONS')}" dataField="observations" editorType="dxTextArea" [editorOptions]="{ }"
		[colSpan]="2">
	</dxi-item>
</dx-form>
<hr>
<dx-data-grid style="margin-top: 5px" #gridTreatmentItem [columnAutoWidth]="true" [allowColumnResizing]="true"
	[allowColumnReordering]="true" [rowAlternationEnabled]="ui.rowAlternationEnabled"
	[showColumnLines]="ui.showColumnLines" [showRowLines]="ui.showRowLines" [height]="300" [showBorders]="true"
	[dataSource]="edit.item" (onToolbarPreparing)="onItemToolbarPreparing($event)"
	(onRowInserted)="onItemInserted($event)" (onRowRemoving)="onItemRemoving($event)"
	(onRowUpdating)="onQuantityUpdating($event)">
	<dxo-editing mode="cell"
		[allowAdding]="edit.model.physicalCondition !== undefined && edit.model.physicalCondition !== null && edit.model.workCenterId"
		[allowUpdating]="true" [allowDeleting]="true">
	</dxo-editing>

	<dxi-column [caption]="L('WASTE_CODE')" dataField="wasteTypeId" dataType="string">
		<dxo-lookup [dataSource]="proxy.wasteType.allCode" valueExpr="id" displayExpr="code"></dxo-lookup>
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-column>

	<dxi-column [caption]="L('PHYSICAL_STATE')" dataField="physicalCondition" dataType="number">
		<dxo-lookup [dataSource]="proxy.wasteType.physicalState" valueExpr="id" displayExpr="value">
		</dxo-lookup>
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-column>

	<dxi-column [caption]="L('QUANTITY')" dataField="quantity" dataType="number">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-column>

	<div *dxTemplate="let data of 'treatmentTemplate'">
		<h3>Total: {{edit.model.totalQty}} {{proxy.wasteType.selectedUomName}}</h3>
	</div>
</dx-data-grid>

<app-actual-stock-edit></app-actual-stock-edit>