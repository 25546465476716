import { Component, ViewChild } from '@angular/core';
import { ProxyService } from '../../service/proxy.service';
import { TreatmentEditService } from './treatment-edit.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { BreadcrumbViewComponent } from '../../framework/breadcrumb-view.component';

@Component({
	selector: 'app-treatment',
	templateUrl: './treatment.component.html'
})

export class TreatmentComponent extends BreadcrumbViewComponent {
	@ViewChild('gridTreatmentOut') protected grid: DxDataGridComponent;
	public edit: TreatmentEditService;

	constructor(public proxy: ProxyService) {
		super(proxy.treatment, proxy.lg, proxy.iui, proxy.activatedRoute);
		this.id = 't_id';
		this.key = 'treatment';
	}

	onDeleteTreatmentClick = (ev: any) => {
		if(ev.row.data){
			this.edit.onRemove(ev.row.data.t_id);
		}
	};
}
