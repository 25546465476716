<div class="globalContainer box-shadow">
    <dx-tab-panel height="calc(100vh - 158px)">
        <dxi-item title="Grafice">
            <dx-validation-group>
                <div class="dx-fieldset">
                    <div class="row">
                        <div class="col">
                            <div class="dx-field">
                                <div class="dx-field-label">{{L('GROUP')}}</div>
                                <div class="dx-field-value">
                                    <dx-select-box [(value)]="edit.reportObj.bu" [dataSource]="edit.dsBu" displayExpr="fullName"
                                        valueExpr="c_id" [searchEnabled]="true" [showClearButton]="true">
                                        <dx-validator>
                                            <dxi-validation-rule type="required" message="L('GROUP_REQ')">
                                            </dxi-validation-rule>
                                        </dx-validator>
                                    </dx-select-box>
                                </div>
                            </div>
                            <div class="dx-field">
                                <div class="dx-field-label">{{L('ENTITY')}}</div>
                                <div class="dx-field-value">
                                    <dx-select-box [(value)]="edit.reportObj.entity" [disabled]="!edit.reportObj.bu"
                                        [dataSource]="edit.entities" displayExpr="fullName" valueExpr="c_id"
                                        [searchEnabled]="true" [showClearButton]="true">
                                    </dx-select-box>
                                </div>
                            </div>
                            <div class="dx-field">
                                <div class="dx-field-label">{{L('WASTE')}}</div>
                                <div class="dx-field-value">
                                    <dx-select-box [dataSource]="proxy.wasteType.data" valueExpr="code" displayExpr="code">
                                    </dx-select-box>
                                </div>
                            </div>
                            <div class="dx-field">
                                <div class="dx-field-label">{{L('FROM_DATE')}}</div>
                                <div class="dx-field-value">
                                    <dx-date-box displayFormat="dd.MM.yyyy" [(value)]="edit.reportObj.fromDate">
                                        <dx-validator>
                                            <dxi-validation-rule type="required" message="L('FROM_DATE_REQ')">
                                            </dxi-validation-rule>
                                        </dx-validator>
                                    </dx-date-box>
                                </div>
                            </div>
                            <div class="dx-field">
                                <div class="dx-field-label">{{L('UNTIL_DATE')}}</div>
                                <div class="dx-field-value">
                                    <dx-date-box displayFormat="dd.MM.yyyy" [(value)]="edit.reportObj.thruDate">
                                        <dx-validator>
                                            <dxi-validation-rule type="required" message="L('UNTIL_DATE_REQ')">
                                            </dxi-validation-rule>
                                        </dx-validator>
                                    </dx-date-box>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <dx-validation-summary></dx-validation-summary>
                <div class="dx-fieldset">
                    <div class="row">
                        <div class="col">
                            <dx-button [visible]="false" text="L('GRAPH_GEN_WASTE')"></dx-button>
                            <dx-button [visible]="false" text="L('GRAPH_VAL_VS_ELIM')"></dx-button>
                            <dx-button [visible]="false" text="L('FINAN_GRAPH')"></dx-button>
                        </div>
                        <div class="col">
                        </div>
                    </div>
                </div>
            </dx-validation-group>
        </dxi-item>
        <dxi-item title="L('COSTS')">
            <dx-validation-group>
                <div class="dx-fieldset">
                    <div class="row">
                        <div class="col">
                            <div class="dx-field">
                                <div class="dx-field-label">{{L('ENTITY')}}</div>
                                <div class="dx-field-value">
                                    <dx-select-box [(value)]="edit.reportObj.entity" [dataSource]="edit.entities"
                                        displayExpr="fullName" valueExpr="c_id" [searchEnabled]="true"
                                        [showClearButton]="true">
                                        <dx-validator>
                                            <dxi-validation-rule type="required" message="L('ENTITY_MAN_FIELD')">
                                            </dxi-validation-rule>
                                        </dx-validator>
                                    </dx-select-box>
                                </div>
                            </div>
                            <div class="dx-field">
                                <div class="dx-field-label">{{L('SUPPLIER')}}</div>
                                <div class="dx-field-value">
                                    <dx-select-box [dataSource]="proxy.party.contractor" displayExpr="fullName" valueExpr="c_id"
                                        [searchEnabled]="true" [showClearButton]="true"></dx-select-box>
                                </div>
                            </div>
                            <div class="dx-field">
                                <div class="dx-field-label">{{L('FROM_DATE')}}</div>
                                <div class="dx-field-value">
                                    <dx-date-box displayFormat="dd.MM.yyyy" [(value)]="edit.reportObj.fromDate">
                                        <dx-validator>
                                            <dxi-validation-rule type="required" message="L('FROM_DATE_REQ')">
                                            </dxi-validation-rule>
                                        </dx-validator>
                                    </dx-date-box>
                                </div>
                            </div>
                            <div class="dx-field">
                                <div class="dx-field-label">{{L('UNTIL_DATE')}}</div>
                                <div class="dx-field-value">
                                    <dx-date-box displayFormat="dd.MM.yyyy" [(value)]="edit.reportObj.thruDate">
                                        <dx-validator>
                                            <dxi-validation-rule type="required" message="L('UNTIL_DATE_REQ')">
                                            </dxi-validation-rule>
                                        </dx-validator>
                                    </dx-date-box>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <dx-validation-summary></dx-validation-summary>
                <div class="dx-fieldset">
                    <div class="row">
                        <div class="col">
                            <dx-button [visible]="false" text="L('COSTS_REPORT')"></dx-button>
                        </div>
                        <div class="col">
                        </div>
                    </div>
                </div>
            </dx-validation-group>
        </dxi-item>
        <dxi-item title="L('TAXES')">
            <dx-validation-group>
                <div class="dx-fieldset">
                    <div class="row">
                        <div class="col">
                            <div class="dx-field">
                                <div class="dx-field-label">{{L('ENTITY')}}</div>
                                <div class="dx-field-value">
                                    <dx-select-box [(value)]="edit.reportObj.entity" [dataSource]="edit.entities"
                                        displayExpr="fullName" valueExpr="c_id" [searchEnabled]="true"
                                        [showClearButton]="true">
                                        <dx-validator>
                                            <dxi-validation-rule type="required" message="L('GROUP_REQ')">
                                            </dxi-validation-rule>
                                        </dx-validator>
                                    </dx-select-box>
                                </div>
                            </div>
                            <div class="dx-field">
                                <div class="dx-field-label">{{L('FROM_DATE')}}</div>
                                <div class="dx-field-value">
                                    <dx-date-box displayFormat="dd.MM.yyyy" [(value)]="edit.reportObj.fromDate">
                                        <dx-validator>
                                            <dxi-validation-rule type="required" message="L('FROM_DATE_REQ')">
                                            </dxi-validation-rule>
                                        </dx-validator>
                                    </dx-date-box>
                                </div>
                            </div>
                            <div class="dx-field">
                                <div class="dx-field-label">{{L('UNTIL_DATE')}}</div>
                                <div class="dx-field-value">
                                    <dx-date-box displayFormat="dd.MM.yyyy" [(value)]="edit.reportObj.thruDate">
                                        <dx-validator>
                                            <dxi-validation-rule type="required" message="L('UNTIL_DATE_REQ')">
                                            </dxi-validation-rule>
                                        </dx-validator>
                                    </dx-date-box>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <dx-validation-summary></dx-validation-summary>
                <div class="dx-fieldset">
                    <div class="row">
                        <div class="col">
                            <dx-button [visible]="false" text="L('TAXES_REPORT')"></dx-button>
                        </div>
                        <div class="col">
                        </div>
                    </div>
                </div>

            </dx-validation-group>
        </dxi-item>
        <dxi-item title="L('CONTROLS')">

            <dx-validation-group>
                <div class="dx-fieldset">
                    <div class="row">
                        <div class="col">
                            <div class="dx-field">
                                <div class="dx-field-label">{{L('ENTITY')}}</div>
                                <div class="dx-field-value">
                                    <dx-select-box [(value)]="edit.reportObj.entity" [dataSource]="edit.entities"
                                        displayExpr="fullName" valueExpr="c_id" [searchEnabled]="true"
                                        [showClearButton]="true">
                                        <dx-validator>
                                            <dxi-validation-rule type="required" message="L('GROUP_REQ')">
                                            </dxi-validation-rule>
                                        </dx-validator>
                                    </dx-select-box>
                                </div>
                            </div>
                            <div class="dx-field">
                                <div class="dx-field-label">{{L('FROM_DATE')}}</div>
                                <div class="dx-field-value">
                                    <dx-date-box displayFormat="dd.MM.yyyy" [(value)]="edit.reportObj.fromDate">
                                        <dx-validator>
                                            <dxi-validation-rule type="required" message="L('FROM_DATE_REQ')">
                                            </dxi-validation-rule>
                                        </dx-validator>
                                    </dx-date-box>
                                </div>
                            </div>
                            <div class="dx-field">
                                <div class="dx-field-label">{{L('UNTIL_DATE')}}</div>
                                <div class="dx-field-value">
                                    <dx-date-box displayFormat="dd.MM.yyyy" [(value)]="edit.reportObj.thruDate">
                                        <dx-validator>
                                            <dxi-validation-rule type="required" message="L('UNTIL_DATE_REQ')">
                                            </dxi-validation-rule>
                                        </dx-validator>
                                    </dx-date-box>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <dx-validation-summary></dx-validation-summary>
                <div class="dx-fieldset">
                    <div class="row">
                        <div class="col">
                            <dx-button [visible]="false" text="L('CONTROLS_REPORT')"></dx-button>
                        </div>
                        <div class="col">
                        </div>
                    </div>
                </div>
            </dx-validation-group>
        </dxi-item>
        <dxi-item title="L('VALORIZATION')">
            <dx-validation-group>
                <div class="dx-fieldset">
                    <div class="row">
                        <div class="col">
                            <div class="dx-field">
                                <div class="dx-field-label">{{L('GROUP')}}</div>
                                <div class="dx-field-value">
                                    <dx-select-box [(value)]="edit.reportObj.bu" [dataSource]="edit.dsBu" displayExpr="fullName"
                                        valueExpr="c_id" [searchEnabled]="true" [showClearButton]="true">
                                        <dx-validator>
                                            <dxi-validation-rule type="required" message="L('GROUP_REQ')">
                                            </dxi-validation-rule>
                                        </dx-validator>
                                    </dx-select-box>
                                </div>
                            </div>
                            <div class="dx-field">
                                <div class="dx-field-label">{{L('ENTITY')}}</div>
                                <div class="dx-field-value">
                                    <dx-select-box [(value)]="edit.reportObj.entity" [disabled]="!edit.reportObj.bu"
                                        [dataSource]="edit.entities" displayExpr="fullName" valueExpr="c_id"
                                        [searchEnabled]="true" [showClearButton]="true"></dx-select-box>
                                </div>
                            </div>
                            <div class="dx-field">
                                <div class="dx-field-label">{{L('WASTE')}}</div>
                                <div class="dx-field-value">
                                    <dx-autocomplete [dataSource]="proxy.wasteType.data" valueExpr="code">
                                    </dx-autocomplete>
                                </div>
                            </div>
                            <div class="dx-field">
                                <div class="dx-field-label">{{L('FROM_DATE')}}</div>
                                <div class="dx-field-value">
                                    <dx-date-box displayFormat="dd.MM.yyyy" [(value)]="edit.reportObj.fromDate">
                                        <dx-validator>
                                            <dxi-validation-rule type="required" message="L('FROM_DATE_REQ')">
                                            </dxi-validation-rule>
                                        </dx-validator>
                                    </dx-date-box>
                                </div>
                            </div>
                            <div class="dx-field">
                                <div class="dx-field-label">{{L('UNTIL_DATE')}}</div>
                                <div class="dx-field-value">
                                    <dx-date-box displayFormat="dd.MM.yyyy" [(value)]="edit.reportObj.thruDate">
                                        <dx-validator>
                                            <dxi-validation-rule type="required" message="L('UNTIL_DATE_REQ')">
                                            </dxi-validation-rule>
                                        </dx-validator>
                                    </dx-date-box>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <dx-validation-summary></dx-validation-summary>
                <div class="dx-fieldset">
                    <div class="row">
                        <div class="col">
                            <dx-button [visible]="false" text="L('VALORIZATION_REPORT')"></dx-button>
                        </div>
                        <div class="col">
                        </div>
                    </div>
                </div>
            </dx-validation-group>
        </dxi-item>
        <dxi-item title="L('PERC_VALORIZATION')">

            <dx-validation-group>
                <div class="dx-fieldset">
                    <div class="row">
                        <div class="col">
                            <div class="dx-field">
                                <div class="dx-field-label">{{L('GROUP')}}</div>
                                <div class="dx-field-value">
                                    <dx-select-box [(value)]="edit.reportObj.bu" [dataSource]="edit.dsBu" displayExpr="fullName"
                                        valueExpr="c_id" [searchEnabled]="true" [showClearButton]="true">
                                        <dx-validator>
                                            <dxi-validation-rule type="required" message="L('GROUP_REQ')">
                                            </dxi-validation-rule>
                                        </dx-validator>
                                    </dx-select-box>
                                </div>
                            </div>
                            <div class="dx-field">
                                <div class="dx-field-label">{{L('ENTITY')}}</div>
                                <div class="dx-field-value">
                                    <dx-select-box [(value)]="edit.reportObj.entity" [disabled]="!edit.reportObj.bu"
                                        [dataSource]="edit.entities" displayExpr="fullName" valueExpr="c_id"
                                        [searchEnabled]="true" [showClearButton]="true"></dx-select-box>
                                </div>
                            </div>
                            <div class="dx-field">
                                <div class="dx-field-label">{{L('WASTE')}}</div>
                                <div class="dx-field-value">
                                    <dx-autocomplete [dataSource]="proxy.wasteType.data" valueExpr="code">
                                    </dx-autocomplete>
                                </div>
                            </div>
                            <div class="dx-field">
                                <div class="dx-field-label">{{L('FROM_DATE')}}</div>
                                <div class="dx-field-value">
                                    <dx-date-box displayFormat="dd.MM.yyyy" [(value)]="edit.reportObj.fromDate">
                                        <dx-validator>
                                            <dxi-validation-rule type="required" message="L('FROM_DATE_REQ')">
                                            </dxi-validation-rule>
                                        </dx-validator>
                                    </dx-date-box>
                                </div>
                            </div>
                            <div class="dx-field">
                                <div class="dx-field-label">{{L('UNTIL_DATE')}}</div>
                                <div class="dx-field-value">
                                    <dx-date-box displayFormat="dd.MM.yyyy" [(value)]="edit.reportObj.thruDate">
                                        <dx-validator>
                                            <dxi-validation-rule type="required"
                                                message="L('UNTIL_DATE_REQ')Până la data este câmp obligatoriu!">
                                            </dxi-validation-rule>
                                        </dx-validator>
                                    </dx-date-box>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <dx-validation-summary></dx-validation-summary>
                <div class="dx-fieldset">
                    <div class="row">
                        <div class="col">
                            <dx-button [visible]="false" text="L('VALORIZATION_REPORT')"></dx-button>
                        </div>
                        <div class="col">
                        </div>
                    </div>
                </div>
            </dx-validation-group>
        </dxi-item>
    </dx-tab-panel>
</div>