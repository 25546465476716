import { Component } from '@angular/core';
import { iBisViewComponent } from '@dohu/ibis-common';
import { DataService } from '../../../service/data.service';
import { ProxyService } from '../../../service/proxy.service';
import { CostAnalyzesFilterService } from './cost-analyzes-filter.service';

@Component({
	selector: 'app-cost-analyzes',
	templateUrl: './cost-analyzes.component.html'
})
export class CostAnalyzesComponent extends iBisViewComponent {

	// TODO: We get DataSource only form ds.static.months
	public edit: CostAnalyzesFilterService;
	constructor(public proxy: ProxyService, public ds: DataService) {
		super(proxy.costAnalyzes, proxy.lg, proxy.iui, proxy.activatedRoute);
	}
}
