import notify from 'devextreme/ui/notify';
import { custom } from 'devextreme/ui/dialog';
import { Params } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from '../../../environments/environment';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { DxValidationGroupComponent } from 'devextreme-angular/ui/validation-group';
import { ProxyService } from '../../service/proxy.service';

@Component({
	selector: 'app-register',
	templateUrl: './register.component.html',
	styleUrls: ['./register.component.css']
})
export class RegisterComponent extends iBisBaseComponent implements OnInit {
	@ViewChild('emailValidation') emailValidation: DxValidationGroupComponent;

	public year: number;
	public termCond: string;
	public resetEmail: string;

	public registerData: any;
	public forgotPassword: boolean;
	public isButtonDisable = false;

	constructor(private proxy: ProxyService) {
		super(null, proxy.lg, proxy.iui, proxy.activatedRoute);
		this.year = new Date().getFullYear();
	}

	ngOnInit() {
		this.termCond = environment.termCond;
		this.registerData = { isConfirm: false };
	}

	public validateCIF = (ev: any) => this.proxy.validator.isVATValid(ev.value ?? '');

	onRegister(e: any) {
		const validation = e.validationGroup.validate();
		if (!validation.isValid) {
			notify(this.L('ALL_REQ_FIELDS', null), 'error', 3000);
			return;
		}

		const fields = ['_Companie_', '_CIF_', '_Telefon_'];
		const values = new Array();
		for (let i = 0; i < fields.length; i++) {
			values.push(this.registerData[fields[i]]);
		}
		const obj = {
			registerType: 'QHSE',
			fullName: this.registerData.fullName,
			emailAddress: this.registerData.emailAddress,
			deployId: environment.deployId,
			fields: fields,
			values: values
		};

		const customDialog = custom({
			title: this.L('CONFIRM_REGISTER', null),
			message: this.L('CONFIRMATION_EMAIL', null),
			buttons: [{
				text: 'OK',
				onClick: () => {
					this.proxy.router.navigateByUrl('/', { replaceUrl: true, skipLocationChange: true});
					this.registerData = { isConfirm: false };
					this.isButtonDisable = false;
				}
			}],
			showTitle: true
		});
		this.isButtonDisable = true;
		this.proxy.auth.register(obj).then(() => {
			customDialog.show();
		}, (error: any) => {
			this.lg.showError(error);
			this.isButtonDisable = false;
		});
	}

	onResetPassword(e: any) {
		const validation = e.validationGroup.validate();
		if (!validation.isValid) {
			return;
		}
		this.isButtonDisable = true;
		this.proxy.auth.forgotPassword(this.resetEmail).then((result) => {
			// raspunsul vine prea devreme
			setTimeout(() => {
				this.isButtonDisable = false;
				if (result === 'OK') {
					notify(this.L('CONFIRMATION_EMAIL'), 'success', 10000);
					this.emailValidation.instance.reset();
					setTimeout(() => {
						this.proxy.router.navigateByUrl('/', { replaceUrl: true, skipLocationChange: false});
					}, 3000);
				} else {
					notify(this.L('EMAIL_ADDRESS_IS_INVALID'), 'error', 10000);
				}
			}, 500);
		}, (error: any) => {
			this.lg.showError(error);
			this.isButtonDisable = false;
		});
	}

	onCancelResetPassword (e: any) {
		this.proxy.router.navigateByUrl('/', { replaceUrl: true, skipLocationChange: false})
	}

	protected queryParams(pr: Params): void {
		this.forgotPassword = pr['forgotPassword'] === 'true';
	}
}
