<dx-form #generateForm class="generateForm" [formData]="edit.model" [showValidationSummary]="false"
	[validationGroup]="edit.validation">
	<dxi-item itemType="group" [colCount]="2">
		<dxi-item [colSpan]="2" [label]="{text: L('ENT_WORKC') }" dataField="workCenterId" editorType="dxSelectBox"
			[editorOptions]="{dataSource: proxy.party.ownWorkCenter, valueExpr: 'c_id', displayExpr: 'fullName', searchEnabled: true, onValueChanged: onWorkCenterChanged}">
			<dxi-validation-rule type="required"></dxi-validation-rule>
		</dxi-item>
	
		<dxi-item [label]="{text: L('WASTE_CODE') }" dataField="wasteTypeId" editorType="dxSelectBox" [editorOptions]="{ dataSource: proxy.wasteType.code, valueExpr: 'id', displayExpr: 'code', disabled: !edit.model.workCenterId, 
			onInitialized: onWasteTypeInit, onValueChanged: onWasteTypeChanged, searchEnabled: true }">
			<dxi-validation-rule type="required"></dxi-validation-rule>
		</dxi-item>
		<dxi-item [label]="{text: L('OPERATION_DATE')}" dataField="date" editorType="dxDateBox"
			[editorOptions]="{ displayFormat: 'dd.MM.yyyy', width: '100%'}">
			<dxi-validation-rule type="required"></dxi-validation-rule>
		</dxi-item>
		<dxi-item itemType="group" [colCount]="2" [colSpan]="2">
			<dxi-item [label]="{text: L('QUANTITY') }" dataField="quantity" editorType="dxNumberBox"
				[editorOptions]="{buttons: [{name: 'uom', location: 'after', options: uomHelper.options }]}">
				<dxi-validation-rule type="required"></dxi-validation-rule>
				<dxi-validation-rule type="compare" comparisonType=">" [comparisonTarget]="quantityTarget"
					[message]="L('QUANTITY_GREATER_0')"></dxi-validation-rule>
			</dxi-item>
			<dxi-item [label]="{text: L('PIECE') }" dataField="piece" editorType="dxNumberBox" [editorOptions]="{}"
				[visible]="edit.wasteType == '2'">
				<dxi-validation-rule type="required"></dxi-validation-rule>
			</dxi-item>
		</dxi-item>
	
		<dxi-item itemType="group" [colCount]="2" [colSpan]="2" [visible]="edit.wasteType == '1'">
			<dxi-item [label]="{text: proxy.wasteType.categoryLabel}" [visible]="proxy.wasteType.categoryLabel != ''"
				editorType="dxSelectBox"
				[editorOptions]="{ dataSource: proxy.wasteType.category, valueExpr: 'id', displayExpr: 'description'}">
				<dxi-validation-rule type="required"></dxi-validation-rule>
			</dxi-item>
			<dxi-item [label]="{text: L('MATERIAL') }" [visible]="edit.wasteType == '1'" dataField="materialId"
				editorType="dxSelectBox" [editorOptions]="{ dataSource: proxy.wasteType.material, valueExpr: 'id', 
			  displayExpr: 'description', readOnly: !edit.model.wasteTypeId }">
			</dxi-item>
		</dxi-item>
	
		<dxi-item itemType="group" [colCount]="2" [colSpan]="2" [visible]="edit.wasteType != '2'">
			<dxi-item [label]="{text: L('STORAGE_TYPE')}" dataField="wasteType.storageTypeId" editorType="dxSelectBox"
				[visible]="edit.wasteType != '2'"
				[editorOptions]="{dataSource: proxy.wasteType.storageType, valueExpr: 'id', displayExpr: 'description', placeholder: L('NOT_SETTED'), readOnly: true }">
			</dxi-item>
			<dxi-item [label]="{text: L('EVALUATION')}" dataField="estimated" editorType="dxSelectBox"
				[visible]="edit.wasteType != '2'"
				[editorOptions]="{ dataSource: edit.evaluation, valueExpr: 'id', displayExpr: 'value'}">
			</dxi-item>
		</dxi-item>
		<dxi-item itemType="group" [colCount]="2" [colSpan]="2">
			<dxi-item [label]="{text: L('WASTE_TYPE')}" dataField="wasteType.hazardName" editorType="dxTextBox"
				[editorOptions]="{readOnly: true }">
			</dxi-item>
			<dxi-item [label]="{text: L('SOURCE')}" dataField="source" editorType="dxSelectBox"
				[editorOptions]="{ dataSource: edit.source, valueExpr: 'id', displayExpr: 'value'}">
				<dxi-validation-rule type="required"></dxi-validation-rule>
			</dxi-item>
		</dxi-item>
	
		<dxi-item [label]="{text: L('ELIMINATION_CODE')}" dataField="eliminationId" editorType="dxSelectBox"
			[editorOptions]="{ dataSource: proxy.wasteType.eliminationCode, valueExpr: 'id', displayExpr: 'description', showClearButton: true, searchEnabled: true}">
		</dxi-item>
		<dxi-item [label]="{text: L('CAPITALIZATION_CODE')}" dataField="capitalizationId" editorType="dxSelectBox"
			[editorOptions]="{ dataSource: proxy.wasteType.capitalizationCode, valueExpr: 'id', displayExpr: 'description', showClearButton: true, searchEnabled: true}">
		</dxi-item>
		
		<dxi-item [label]="{text: L('CATEGORY')}" [visible]="edit.wasteType == '2'" dataField="materialId"
			editorType="dxSelectBox" [colCount]="2" [colSpan]="2"
			[editorOptions]="{ dataSource: proxy.wasteType.eeeCategory, valueExpr: 'id', displayExpr: 'description'}">
			<dxi-validation-rule type="required"></dxi-validation-rule>
		</dxi-item>
		<dxi-item [label]="{text: L('WASTE_NAME')}" dataField="wasteType.name" editorType="dxTextBox" [colSpan]="2"
			[editorOptions]="{readOnly: true }">
		</dxi-item>
		<dxi-item [label]="{text: L('OBSERVATIONS')}" dataField="observations" editorType="dxTextArea" [colSpan]="2"
		[editorOptions]="{ }">
	</dxi-item>
	</dxi-item>
</dx-form>