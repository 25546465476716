<dx-data-grid #gridControl class="gridContainer" [dataSource]="edit.data" [remoteOperations]="true"
	[height]="ui.fullHeight" (onCellPrepared)="onCellPrepared($event)" (onCellClick)="onCellClick($event)"
	(onToolbarPreparing)="toolbarPreparing($event, proxy.allowEdit)">

	<dxo-master-detail [enabled]="true" template="details"></dxo-master-detail>
	<dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" [storageKey]='key'>
	</dxo-state-storing>
	<dxo-scrolling [mode]="ui.scrollingMode" [showScrollbar]="ui.showScrollbar" [useNative]="!ui.isWindows">
	</dxo-scrolling>

	<dxi-column type="buttons" fixedPosition="left" [minWidth]="proxy.actionsWidth" [width]="proxy.actionsWidth"
		[fixed]="true" [allowResizing]="false" [showInColumnChooser]="false" [visible]="proxy.showActions">
		<dxi-button [hint]="L('EDIT')" icon="edit" [onClick]="onEditClick" [visible]="proxy.allowEdit">
		</dxi-button>
		<dxi-button [hint]="L('DELETE')" icon="trash" [onClick]="onDeleteClick" [visible]="proxy.allowDelete">
		</dxi-button>
	</dxi-column>

	<dxi-column name='control' headerCellTemplate='controlHeader'>
		<dxi-column [caption]="L('BODY_CONTROL')" dataField="c_authority" dataType="string" [minWidth]="170">
		</dxi-column>
		<dxi-column [caption]="L('CONTROL_TYPE')" dataField="c_typeId" dataType="number" [minWidth]="170">
			<dxo-lookup [dataSource]="edit.type" valueExpr="id" displayExpr="value"></dxo-lookup>
		</dxi-column>
		<dxi-column [caption]="L('DATE')" dataField="c_date" dataType="date" calculateSortValue="c_date"
			[calculateDisplayValue]="iui.formatDate">
		</dxi-column>
		<dxi-column [caption]="L('PLANNED')" dataField="c_planned" dataType="boolean" [minWidth]="110"></dxi-column>
		<dxi-column [caption]="L('CONTROL_REASON')" dataField="c_reason" dataType="string" [minWidth]="170"
			[allowHeaderFiltering]="false"></dxi-column>
	</dxi-column>
	<dxi-column [caption]="L('ENT_WORKC')" dataField="fullName" dataType="string" [minWidth]="170"></dxi-column>
	<dxi-column [caption]="L('CASES')" dataField="c_cases" dataType="string" [minWidth]="170"
		[allowHeaderFiltering]="false"></dxi-column>
	<dxi-column name='sanction' headerCellTemplate='sanctionHeader'>
		<dxi-column [caption]="L('SANCTIONS')" dataField="c_sanction" dataType="boolean" [minWidth]="110"></dxi-column>
		<dxi-column [caption]="L('STATUS_SANCTION')" dataField="c_sanctionStatus" dataType="number" [minWidth]="170">
			<dxo-lookup [dataSource]="edit.sanctionStatus" valueExpr="id" displayExpr="value"></dxo-lookup>
		</dxi-column>
	</dxi-column>
	<dxi-column name='measure' headerCellTemplate='measureHeader'>
		<dxi-column [caption]="L('MEASURES')" dataField="c_action" dataType="boolean" [minWidth]="110"></dxi-column>
		<dxi-column [caption]="L('STATUS_MEASURE')" dataField="c_actionStatus" dataType="number" [minWidth]="170">
			<dxo-lookup [dataSource]="edit.actionStatus" valueExpr="id" displayExpr="value"></dxo-lookup>
		</dxi-column>
	</dxi-column>
	<dxi-column name='cost' headerCellTemplate='costHeader'>
		<dxi-column [caption]="L('GENERATED_COST')" dataField="c_cost" dataType="number" [minWidth]="170"
			[allowHeaderFiltering]="false"></dxi-column>
		<dxi-column [caption]="L('COST_DESCRIPTION')" dataField="c_costDescription" dataType="string" [minWidth]="170"
			[allowHeaderFiltering]="false"></dxi-column>
	</dxi-column>

	<div *dxTemplate="let control of 'details'">
		<dx-tab-panel>
			<dxi-item [title]="L('SANCTIONS')" *ngIf="control.data.c_sanction">
				<app-control-sanction [controlId]="control.data.c_id"></app-control-sanction>
			</dxi-item>
			<dxi-item [title]="L('MEASURES')" *ngIf="control.data.c_action">
				<app-control-measure [controlId]="control.data.c_id"></app-control-measure>
			</dxi-item>
		</dx-tab-panel>
	</div>

	<div *dxTemplate="let data of 'breadcrumb'">
		<ibis-breadcrumb></ibis-breadcrumb>
	</div>
	<div *dxTemplate="let c of 'controlHeader'" class="dx-datagrid-text-content" role="presentation">
		<i class="fas fa-chalkboard-teacher"></i> Control
	</div>
	<div *dxTemplate="let c of 'sanctionHeader'" class="dx-datagrid-text-content" role="presentation">
		<i class="fab fa-flipboard"></i>  Sanctiune
	</div>
	<div *dxTemplate="let c of 'measureHeader'" class="dx-datagrid-text-content" role="presentation">
		<i class="fas fa-align-left"></i> Masura
	</div>
	<div *dxTemplate="let c of 'costHeader'" class="dx-datagrid-text-content" role="presentation">
		<i class="fas fa-coins"></i> Cost
	</div>
</dx-data-grid>

<ibis-edit-popup [edit]="edit" [showAdd]="true">
	<app-control-edit></app-control-edit>
</ibis-edit-popup>