import { Params } from '@angular/router';
import { TransportBase } from '../transport-base';
import { Component, ViewChild } from '@angular/core';
import { ProxyService } from '../../../service/proxy.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';

@Component({
	templateUrl: './transport-result.component.html'
})
export class TransportResultComponent extends TransportBase {
	@ViewChild('gridTransportResult') protected grid: DxDataGridComponent;

	constructor(public proxy: ProxyService) {
		super(proxy, proxy.transport);
	}

	protected queryParams(pr: Params): void {
		super.queryParams(pr);
		this.edit.quantityType = pr['field'];
	}
}
