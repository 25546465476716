<dx-form [colCount]="4" [formData]="edit.model" [validationGroup]="edit.validation"
	(onFieldDataChanged)="onFieldDataChanged($event)" (onInitialized)="onInitDataForm($event)" labelLocation="left">
	<dxi-item [colSpan]="2" [label]="{text: L('NAME')}" dataField="name" editorType="dxTextBox"
		[editorOptions]="{}">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('CODE')}" dataField="code" editorType="dxTextBox" [colSpan]="isWizard ? 2 : 1"
				[editorOptions]="{}">
		<dxi-validation-rule type="stringLength"  [max]="16" [message]="L('CODE_LENGTH')">
		</dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('ACTIVE')}" dataField="isActiv" [visible]="!isWizard" editorType="dxCheckBox" 
		[editorOptions]="{ }"></dxi-item>
	<dxi-item [label]="{text: L('UNIT_TYPE') }" dataField="typeId" editorType="dxSelectBox"
		[visible]="!isWizard" [colSpan]="2"
		[editorOptions]="{ dataSource: edit.type, valueExpr: 'id', displayExpr: 'value', onValueChanged: onTypeValueChanged }">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{ text: L('PARENT_UNIT')}" dataField="parentId" editorType="dxSelectBox" [colSpan]="2"
		[visible]="!isWizard" [editorOptions]="{ dataSource: edit.parent, valueExpr: 'id', displayExpr: 'name',
            onInitialized: onParentInitialized, searchEnabled: true  }">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('FISCAL_CODE')}" dataField="cif" editorType="dxTextBox" [editorOptions]="{}" [colSpan]="2">
	</dxi-item>
	<dxi-item [label]="{text: L('REG_COMERT')}" dataField="registerNo" editorType="dxTextBox" [editorOptions]="{}" [colSpan]="2">
	</dxi-item>
	<dxi-item [label]="{text: L('COUNTY')}" dataField="countyId" editorType="dxSelectBox" [colSpan]="2"
		[editorOptions]="{ dataSource: edit.county, valueExpr: 'id', displayExpr: 'name', onValueChanged: onCountyValueChanged, searchEnabled: true }">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('LOCALITY')}" dataField="localityId" editorType="dxSelectBox" [colSpan]="2" 
		[editorOptions]="{ dataSource: edit.localities, valueExpr: 'id', displayExpr: 'name', onInitialized: onLocalityInitialized, searchEnabled: true}">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('ADDRESS')}" dataField="address" [colSpan]="4" editorType="dxTextBox"
		[editorOptions]="{}">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('ACTIVITIES_TYPE')}" [colSpan]="4">
		<div *dxTemplate="let data of 'template'">
			<dx-drop-down-box #actionDropdown [(value)]="edit.model.selectedActivities" displayExpr="value"
				valueExpr="id" [dataSource]="edit.activityType" placeholder="Selectează...">
				<div *dxTemplate="let actions of 'content'">
					<dx-list [dataSource]="edit.activityType" selectionMode="multiple" [showSelectionControls]="true"
						keyExpr="id" displayExpr="value" [(selectedItemKeys)]="edit.model.selectedActivities">
					</dx-list>
				</div>
				<dx-validator [validationGroup]="edit.validation">
					<dxi-validation-rule type="required"></dxi-validation-rule>
				</dx-validator>
			</dx-drop-down-box>
		</div>
	</dxi-item>
	<dxi-item [label]="{text: L('WASTE_RESPONSABLE')}" dataField="wasteResponsible" editorType="dxTextBox" [colSpan]="2"
		[editorOptions]="{ }"></dxi-item>
	<dxi-item [label]="{text: L('ENVIRONMENT_RESPONSABLE')}" dataField="environmentResponsible" editorType="dxTextBox" [colSpan]="2"
		[editorOptions]="{ }"></dxi-item>
</dx-form>