import { Component, ViewChild } from '@angular/core';
import { iBisViewComponent } from '@dohu/ibis-common';
import { DataService } from '../../../service/data.service';
import { ProxyService } from '../../../service/proxy.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { SafetyPlanningEditService } from './safety-planning-edit.service';

@Component({
	templateUrl: './safety-planning.component.html'
})
export class SafetyPlanningComponent extends iBisViewComponent {
	@ViewChild('gridSafetyPlanning') grid: DxDataGridComponent;

	public edit: SafetyPlanningEditService;
	constructor(public proxy: ProxyService,public ds: DataService) {
		super(proxy.safetyPlanning, proxy.lg, proxy.iui, proxy.activatedRoute);
		this.id = 'p_id';
	}

	toolbarPreparing(ev: any) {
		super.toolbarPreparing(ev, true);
		ev.toolbarOptions.items.unshift({
			widget: 'dxButton',
			options: {
				icon: 'copy',
				hint: this.L('COPY_DATA'),
				onClick: (ev: any) => {
					this.proxy.safetyPlanningCopy.showPopup(null, false);
				}
			},
			location: 'after'
		});
		ev.toolbarOptions.items.unshift({
			widget: 'dxDateBox',
			options: {
				displayFormat: 'MM.yyyy',
				calendarOptions: { minZoomLevel: 'year', maxZoomLevel: 'year' },
				value: new Date(),
				onValueChanged: (e: any) => {
					this.edit.PlannedDate = new Date(e.value);
					this.proxy.safetyPlanningExcept.PlannedDate = new Date(e.value);
				}
			},
			location: 'before'
		});
		ev.toolbarOptions.items.unshift({
			text: this.L('MONTH') + ': ',
			location: 'before'
		});
	}
}
