import { iBisViewComponent } from '@dohu/ibis-common';
import { Component, ViewChild, Input } from '@angular/core';
import { ProxyService } from '../../../../service/proxy.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { TransportApprovalPartyEditService } from './transport-approval-party-edit.service';

@Component({
    selector: 'app-transport-approval-party',
    templateUrl: 'transport-approval-party.component.html'
})
export class TransportApprovalPartyComponent extends iBisViewComponent {

    @Input() approvalId: any;
    @ViewChild('gridTransportApprovalParty') grid: DxDataGridComponent;

    public edit: TransportApprovalPartyEditService;
    constructor(public proxy: ProxyService) {
        super(proxy.transportApprovalParty, proxy.lg, proxy.iui, proxy.activatedRoute);
        this.id = 'ta_id';
        if (!this.key) {
            this.key = 'transportApprovalParty';
        }
    }

    protected prepareNew(): any {
        return { approvalId: this.approvalId };
    }

    public onGridInit(e: any) {
        if (e.component.option('dataSource') == null) {
            e.component.option('dataSource', this.edit.getData(this.approvalId));
        }
    }
}
