import { Injectable } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import { iBisEditService, iBisLanguageService } from '@dohu/ibis-common';

@Injectable({
	providedIn: 'root'
})
export class DepartmentEditService extends iBisEditService {

	public data: CustomStore;
	constructor(private entity: iBisEntityService, lg: iBisLanguageService){
		super(lg);
		this.title = 'DEPARTMENT';
		this.validation = 'departmentValidation';

		this.data = this.getData();
	}
	createDefault() {
		return {};
	}
	getById(id: string, serverUrl?: string): Promise<any> {
		throw new Error('Method not implemented.');
	}
	onRemove(id: string): Promise<void> {
		throw new Error('Method not implemented.');
	}
	onSaveEv(serverUrl?: string): Promise<any> {
		throw new Error('Method not implemented.');
	}
	reset(): void {
		this.model = this.createDefault();
	}

	private getData() {
		return this.entity.store(new EntityQuery('Department').addOrderBy(['name']), false, 'id');
	}
}
