<dx-form [formData]="edit.model" [validationGroup]="edit.validation" [colCount]="2" style="padding-top:10px">
    <dxi-item [label]="{text: L('DATE') }" dataField="monthYear" editorType="dxDateBox" [editorOptions]="{ displayFormat: 'MM.yyyy', width: '100%',
        calendarOptions: {minZoomLevel: 'year', maxZoomLevel: 'year' } }">
    </dxi-item>
    <dxi-item [label]="{ text: L('REPORT_COUNT') }" dataField="planned" editorType="dxNumberBox" [editorOptions]="{ }">
        <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-item>
    <dxi-item [label]="{text: L('ENT_WORKC')}" dataField="partyId" editorType="dxSelectBox"
        [editorOptions]="{ dataSource: proxy.party.ownWorkCenter, valueExpr: 'c_id', displayExpr: 'fullName' }">
    </dxi-item>
	<dxi-item [label]="{text: L('JOB')}" dataField="jobId" editorType="dxSelectBox"
		[editorOptions]="{ dataSource: proxy.auditor.job, valueExpr: 'id', displayExpr: 'description' }">
	</dxi-item>
</dx-form>