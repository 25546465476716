<dx-form [colCount]="2" [formData]="edit.model" [showValidationSummary]="false" [validationGroup]="edit.validation"
	labelLocation="left">

	<dxi-item [label]="{text: L('NO')}" dataField="no" editorType="dxTextBox" [editorOptions]="{}">
	</dxi-item>
	<dxi-item [label]="{text: L('LADING_BILL')}" dataField="ladingBill" editorType="dxTextBox" [editorOptions]="{}">
		<dxi-validation-rule type="stringLength" [max]="16" message="Maxim 16 caractere!"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('ENT_WORKC') }" dataField="workCenterId" [colSpan]="2" editorType="dxSelectBox"
		[editorOptions]="{dataSource: proxy.party.ownWorkCenter, valueExpr: 'c_id', displayExpr: 'fullName', searchEnabled: true, onValueChanged: onWorkCenterChanged,
	  disabled: edit.generalDisabled }">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('CARRIER') }" dataField="transporterId" editorType="dxSelectBox" [colSpan]="2"
		[editorOptions]="{ dataSource: proxy.transporter.name, valueExpr: 'c_id', displayExpr: 'fullName', searchEnabled: true,
			onValueChanged: onTransporterChanged, disabled: edit.generalDisabled }">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('TRANSPORT_TYPE')}" dataField="transportTypeId" editorType="dxSelectBox"
		[editorOptions]="{ dataSource: edit.type, valueExpr: 'id', displayExpr: edit.enumDisplay }">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('TRANSPORT_DATE')}" dataField="sendDate" editorType="dxDateBox"
		[editorOptions]="{ displayFormat: 'dd.MM.yyyy', width: '100%' }">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('REGISTER_NO') }" dataField="carId" editorType="dxSelectBox"
		[editorOptions]="{ dataSource: proxy.transporter.car, valueExpr: 'id', displayExpr: 'registerNo',
			disabled: !edit.model.transporterId, searchEnabled: true, onCustomItemCreating: onCustomItemCar, acceptCustomValue: true, onInitialized: onCarInit,
			onValueChanged: onCarChanged }">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('DRIVER') }" dataField="driverTableId" editorType="dxSelectBox"
		[editorOptions]="{ dataSource: proxy.transporter.driver, valueExpr: 'id', displayExpr: proxy.transporter.displayDriverName, 
			disabled: !edit.model.transporterId, searchEnabled: true, onCustomItemCreating: onCustomItemDriver, acceptCustomValue: true, searchExpr:['firstName','lastName'], onInitialized: onDriverInit,
			onValueChanged: onDriverChanged }">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('ACTIONS')}" [colSpan]="2">
		<div *dxTemplate="let data of 'template'">
			<dx-drop-down-box #actionDropdown [(value)]="edit.model.selectedActivity" displayExpr="value" valueExpr="id"
				[disabled]="edit.generalDisabled" [dataSource]="edit.activity" placeholder="Selecteaz ...">
				<div *dxTemplate="let activity of 'content'">
					<dx-list [dataSource]="edit.activity" selectionMode="multiple" [showSelectionControls]="true"
						keyExpr="id" displayExpr="value" [(selectedItemKeys)]="edit.model.selectedActivity"
						(onSelectionChanged)="onActionListChange($event, actionDropdown)">
					</dx-list>
				</div>
			</dx-drop-down-box>
		</div>
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('RECIPIENT') }" dataField="recipientId" editorType="dxSelectBox" [colSpan]="2"
		[editorOptions]="{ dataSource: edit.recipient, valueExpr: 'c_id', displayExpr: 'fullName', 
			onValueChanged: onDestinationChanged, searchEnabled: true, disabled: edit.generalDisabled || (edit.model.selectedActivity && edit.model.selectedActivity.length === 0)}">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>

	<dxi-item [label]="{text: L('DESTINATION_TYPE')}" dataField="destinationTypeId" editorType="dxSelectBox"
		[visible]="edit.wasteType == '0'"
		[editorOptions]="{ dataSource: edit.destinationType, valueExpr: 'id', displayExpr: edit.enumDisplay }">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [colSpan]="2" [label]="{text: L('CATEGORY')}" [visible]="edit.wasteType == '2'" dataField="materialId"
		editorType="dxSelectBox"
		[editorOptions]="{ dataSource: proxy.wasteType.eeeCategory, valueExpr: 'id', displayExpr: 'description'}">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>

	<dxi-item [label]="{text: L('WASTE_CODE') }" dataField="wasteTypeId" editorType="dxSelectBox" [editorOptions]="{ dataSource: proxy.wasteType.code, 
		valueExpr: 'id', displayExpr: 'code', onInitialized: onWasteTypeInit, onValueChanged: onWasteTypeChanged, searchEnabled: true, 
		disabled: edit.generalDisabled || !edit.model.workCenterId}">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('MATERIAL') }" [visible]="edit.wasteType == '1'" dataField="materialId"
		editorType="dxSelectBox" [editorOptions]="{ dataSource: proxy.wasteType.material, valueExpr: 'id', 
  		displayExpr: 'description', readOnly: !edit.model.wasteTypeId }">
	</dxi-item>

	<dxi-item [label]="{text: L('QUANTITY') }" dataField="quantity" editorType="dxNumberBox" [helpText]="qtyHelpText"
		[editorOptions]="{ buttons: [{name: 'uom', location: 'after', options: uomHelper.options }] }">
		<dxi-validation-rule type="required"></dxi-validation-rule>
		<dxi-validation-rule type="pattern" pattern="^\d+(\.\d{0,6})?$" [message]="L('DECIMAL_VALIDATION')">
		</dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('PIECE') }" dataField="piece" editorType="dxNumberBox"
		[editorOptions]="{ disabled: edit.generalDisabled }" [visible]="edit.wasteType == '2'">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>

	<dxi-item [label]="{text: L('FAT') }" dataField="approvalId" editorType="dxSelectBox" [helpText]="L('FAT_HELP')"
		[editorOptions]="{ dataSource: proxy.transportApproval.code, 
			valueExpr: 'ta_id', displayExpr: 'fullName', disabled: !edit.requiredFAT, showClearButton: true, searchEnabled: true, onInitialized: onFATInit,
			onValueChanged: onFatChanged }">
		<dxi-validation-rule type="custom" [reevaluate]="true" [validationCallback]="validateFAT"
			[message]="L('FAT_IS_RQUIRED')">
		</dxi-validation-rule>
	</dxi-item>

	<dxi-item [label]="{text: L('EVALUATION')}" dataField="estimated" editorType="dxSelectBox"
		[editorOptions]="{ dataSource: proxy.generate.evaluation, valueExpr: 'id', displayExpr: 'value'}">
	</dxi-item>
	<dxi-item [label]="{text: L('WASTE_TYPE')}" dataField="wasteType.hazardName" editorType="dxTextBox"
		[editorOptions]="{readOnly: true }">
	</dxi-item>

	<dxi-item [label]="{text: L('WASTE_NAME')}" dataField="wasteType.name" editorType="dxTextBox" [colSpan]="2"
		[editorOptions]="{ readOnly: true }">
	</dxi-item>
	<dxi-item [label]="{text: L('PACKING_TYPE_NO')}" dataField="packingTypeNo" editorType="dxTextBox" [colSpan]="2"
		[editorOptions]="{ }">
	</dxi-item>
	<dxi-item [label]="{text: L('OBSERVATIONS')}" dataField="observations" editorType="dxTextArea" [colSpan]="2"
		[editorOptions]="{ }">
	</dxi-item>
</dx-form>