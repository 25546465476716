import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AnalizeComponent } from './analize.component';
import { GraphComponent } from './graph/graph.component';
import { DxListModule } from 'devextreme-angular/ui/list';
import { BrowserModule } from '@angular/platform-browser';
import { DxFormModule } from 'devextreme-angular/ui/form';
import { DxPopupModule } from 'devextreme-angular/ui/popup';
import { DxChartModule } from 'devextreme-angular/ui/chart';
import { GenerateModule } from '../generate/generate.module';
import { iBisNavigationModule } from '@dohu/ibis-navigation';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { PartyModule } from '../../common/party/party.module';
import { DxTagBoxModule } from 'devextreme-angular/ui/tag-box';
import { TransportModule } from '../transport/transport.module';
import { DxTextBoxModule } from 'devextreme-angular/ui/text-box';
import { DxDateBoxModule } from 'devextreme-angular/ui/date-box';
import { DxPieChartModule } from 'devextreme-angular/ui/pie-chart';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { DxTreeViewModule } from 'devextreme-angular/ui/tree-view';
import { DxValidatorModule } from 'devextreme-angular/ui/validator';
import { DxSelectBoxModule } from 'devextreme-angular/ui/select-box';
import { DxMultiViewModule } from 'devextreme-angular/ui/multi-view';
import { DxScrollViewModule } from 'devextreme-angular/ui/scroll-view';
import { GenerareTransportComponent } from './generareTransport.component';
import { DxRangeSelectorModule } from 'devextreme-angular/ui/range-selector';
import { TaxAnalyzesComponent } from './tax-analyzes/tax-analyzes.component';
import { KPIAnalyzesComponent } from './kpi-analyzes/kpi-analyzes.component';
import { CostAnalyzesComponent } from './cost-analyzes/cost-analyzes.component';
import { DxValidationGroupModule } from 'devextreme-angular/ui/validation-group';
import { DxValidationSummaryModule } from 'devextreme-angular/ui/validation-summary';
import { IncomeAnalyzesComponent } from './income-analyzes/income-analyzes.component';
import { DashboardBoxesComponent } from './dashboard-boxes/dashboard-boxes.component';
import { ControlAnalyzesComponent } from './control-analyzes/control-analyzes.component';
import { KPIAnalyzesFilterComponent } from './kpi-analyzes/kpi-analyzes-filter.component';
import { TaxAnalyzesFilterComponent } from './tax-analyzes/tax-analyzes-filter.component';
import { CostAnalyzesFilterComponent } from './cost-analyzes/cost-analyzes-filter.component';
import { DashboardAnalyzesComponent } from './dashboard-analyzes/dashboard-analyzes.component';
import { IncomeAnalyzesFilterComponent } from './income-analyzes/income-analyzes-filter.component';
import { ControlAnalyzesFilterComponent } from './control-analyzes/control-analyzes-filter.component';
import { CapitalizationAnalyzesComponent } from './capitalization-analyzes/capitalization-analyzes.component';
import { DashboardAnalyzesFilterComponent } from './dashboard-analyzes/dashboard-analyzes-filter.component';
import { CapitalizationAnalyzesFilterComponent } from './capitalization-analyzes/capitalization-analyzes-filter.component';

@NgModule({
	declarations: [
		GraphComponent,
		AnalizeComponent,
		KPIAnalyzesComponent,
		KPIAnalyzesFilterComponent,
		DashboardAnalyzesComponent,
		TaxAnalyzesComponent,
		TaxAnalyzesFilterComponent,
		CostAnalyzesComponent,
		CostAnalyzesFilterComponent,
		IncomeAnalyzesComponent,
		IncomeAnalyzesFilterComponent,
		DashboardBoxesComponent,
		ControlAnalyzesComponent,
		ControlAnalyzesFilterComponent,
		GenerareTransportComponent,
		DashboardAnalyzesFilterComponent,
		CapitalizationAnalyzesComponent,
		CapitalizationAnalyzesFilterComponent,
	],
	imports: [
		FormsModule,
		PartyModule,
		RouterModule,
		DxListModule,
		DxFormModule,
		BrowserModule,
		DxChartModule,
		DxPopupModule,
		GenerateModule,
		DxButtonModule,
		DxTagBoxModule,
		TransportModule,
		DxDateBoxModule,
		DxTextBoxModule,
		DxTreeViewModule,
		DxDataGridModule,
		DxPieChartModule,
		DxSelectBoxModule,
		DxValidatorModule,
		DxMultiViewModule,
		DxScrollViewModule,
		DxRangeSelectorModule,
		DxValidationGroupModule,
		DxValidationSummaryModule,
		iBisNavigationModule
	],
	exports: [
		GraphComponent,
		AnalizeComponent,
		DashboardAnalyzesComponent,
		TaxAnalyzesComponent,
		KPIAnalyzesComponent,
		CostAnalyzesComponent,
		DashboardBoxesComponent,
		IncomeAnalyzesComponent,
		ControlAnalyzesComponent,
		GenerareTransportComponent,
		CapitalizationAnalyzesComponent
	],
	providers: []
})
export class AnalizeModule { }
