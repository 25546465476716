<dx-form [formData]="edit.model" [showValidationSummary]="false" [validationGroup]="edit.validation" [colCount]="2"
	labelLocation="left">
	<dxi-item [label]="{text: L('WASTE_CODE')}" dataField="wasteTypeId" editorType="dxSelectBox"
		[editorOptions]="{dataSource: proxy.wasteType.allCode, valueExpr: 'id', displayExpr: 'code', onValueChanged: onWasteTypeChange, searchEnabled: true, disabled: edit.model.id !== undefined  }">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('NAME')}" dataField="wasteType.name" editorType="dxTextBox" [editorOptions]="{readOnly: true}"></dxi-item>

	<dxi-item [label]="{text: L('ELIMINATION_CODE')}" dataField="eliminationId" editorType="dxSelectBox"
		[editorOptions]="{dataSource: proxy.wasteType.eliminationCode, valueExpr: 'id', displayExpr: proxy.enumDisplay, searchEnabled: true}">
	</dxi-item>
	<dxi-item [label]="{text: L('CAPITALIZATION_CODE')}" dataField="capitalizationId" editorType="dxSelectBox"
		[editorOptions]="{dataSource: proxy.wasteType.capitalizationCode, valueExpr: 'id', displayExpr: proxy.enumDisplay, searchEnabled: true}">
	</dxi-item>

	<dxi-item [label]="{text: L('WASTE_TYPE')}" dataField="wasteType.hazardName" editorType="dxTextBox"
		[editorOptions]="{ readOnly: true}">
	</dxi-item>
	<dxi-item [label]="{text: L('STORAGE_TYPE')}" dataField="storageTypeId" editorType="dxSelectBox"
		[editorOptions]="{dataSource: proxy.wasteType.storageType, valueExpr: 'id', displayExpr: proxy.enumDisplay, searchEnabled: true}">
	</dxi-item>
	<dxi-item [label]="{text: L('U')}" dataField="uomName" editorType="dxTextBox" [editorOptions]="{readOnly: true}"></dxi-item>
	<dxi-item [label]="{text: L('ALTERNATE_UOM')}" dataField="defaultUom" editorType="dxSelectBox" [visible]="!isWizard"
		[editorOptions]="{dataSource: proxy.wasteType.otherUomCode, valueExpr: 'id', displayExpr: 'value', searchEnabled: true, showClearButton:true}">
	</dxi-item>
	<dxi-item [label]="{text: L('REQUIRE_APPROVAL')}" dataField="requireApproval" editorType="dxCheckBox" [editorOptions]="{}" [visible]="!isWizard">
	</dxi-item>
	<dxi-item [label]="{text: L('ACTIVE')}" dataField="isActiv" editorType="dxCheckBox" [editorOptions]="{}" [visible]="!isWizard">
	</dxi-item>
</dx-form>