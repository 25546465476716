<dx-form [colCount]="2" [formData]="edit.model" [showValidationSummary]="false" [validationGroup]="edit.validation"
	labelLocation="left">
	<dxi-item [label]="{text: L('NAME')}" dataField="name" editorType="dxTextBox" [editorOptions]="{}">
		<dxi-validation-rule type="required"></dxi-validation-rule>
		<dxi-validation-rule type="stringLength"  [max]="256" [message]="L('NAME_LENGTH')">
		</dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('ACTIVE')}" dataField="isActiv" editorType="dxCheckBox" [editorOptions]="{}">
	</dxi-item>
	<dxi-item [label]="{text: L('FISCAL_CODE')}" dataField="cif" editorType="dxTextBox" [editorOptions]="{}"></dxi-item>
	<dxi-item [label]="{text: L('REG_COMERT')}" dataField="registerNo" editorType="dxTextBox" [editorOptions]="{}">
	</dxi-item>
	<dxi-item [label]="{text: L('COUNTY')}" dataField="countyId" editorType="dxSelectBox"
		[editorOptions]="{ dataSource: edit.county, valueExpr: 'id', displayExpr: 'name', onValueChanged: onCountyValueChanged, searchEnabled: true }">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('LOCALITY')}" dataField="localityId" editorType="dxSelectBox" [editorOptions]="{ dataSource: edit.localities, valueExpr: 'id', displayExpr: 'name', 
            onInitialized: onLocalityInitialized, searchEnabled: true }">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [colSpan]="2" [label]="{text: L('ADDRESS')}" dataField="address" editorType="dxTextBox"
		[editorOptions]="{}">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('ACTIVITIES_TYPE')}" [colSpan]='2'>
		<div *dxTemplate="let data of 'template'">
			<dx-drop-down-box #actionDropdown [(value)]="edit.model.selectedActivities" displayExpr="value"
				valueExpr="id" [dataSource]="edit.activityType" placeholder="Selectează...">
				<div *dxTemplate="let actions of 'content'">
					<dx-list [dataSource]="edit.activityType" selectionMode="multiple" [showSelectionControls]="true"
						keyExpr="id" displayExpr="value" [(selectedItemKeys)]="edit.model.selectedActivities">
					</dx-list>
				</div>
				<dx-validator [validationGroup]="edit.validation">
					<dxi-validation-rule type="required"></dxi-validation-rule>
				</dx-validator>
			</dx-drop-down-box>
		</div>
	</dxi-item>
	<dxi-item [label]="{text: L('IS_A_SUBCONTRACTOR')}" dataField="isSubcontractor" editorType="dxCheckBox"
		[editorOptions]="{ onOptionChanged:  onSubcoOptionChanged }"></dxi-item>
	<dxi-item [label]="{text: L('CONTRACTOR')}" dataField="parentId" editorType="dxSelectBox"
		[editorOptions]="{ dataSource: edit.contractor, valueExpr: 'c_id', displayExpr: 'fullName', disabled: !edit.model.isSubcontractor }">
	</dxi-item>
</dx-form>