<dx-form #transferForm [colCount]="2" [formData]="edit.model" [showValidationSummary]="false"
	[validationGroup]="edit.validation" labelLocation="left">
	<dxi-item [label]="{text: L('NO')}" dataField="no" editorType="dxTextBox" [editorOptions]="{}">
	</dxi-item>
	<dxi-item [label]="{text: L('LADING_BILL')}" dataField="ladingBill" editorType="dxTextBox" [editorOptions]="{}">
		<dxi-validation-rule type="stringLength" [max]="16" message="Maxim 16 caractere!"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [colSpan]="2" [label]="{text: L('SOURCE_WS')}" dataField="senderWorkCenterId" editorType="dxSelectBox"
		[editorOptions]="{ dataSource: proxy.party.ownWorkCenter, valueExpr: 'c_id', displayExpr: 'fullName', onValueChanged: onWorkCenterChanged }">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('ACTIONS')}" [colSpan]="2">
		<div *dxTemplate="let data of 'template'">
			<dx-drop-down-box #actionDropdown [(value)]="edit.model.selectedActivity" displayExpr="value" valueExpr="id"
				[dataSource]="edit.activity" placeholder="Selecteaza ...">
				<div *dxTemplate="let activity of 'content'">
					<dx-list [dataSource]="edit.activity" selectionMode="multiple" [showSelectionControls]="true"
						keyExpr="id" displayExpr="value" [(selectedItemKeys)]="edit.model.selectedActivity"
						(onSelectionChanged)="onActionListChange($event, actionDropdown)">
					</dx-list>
				</div>
			</dx-drop-down-box>
		</div>
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [colSpan]="2" [label]="{text: L('DESTINATION')}" dataField="destinationWorkCenterId" editorType="dxSelectBox"
		[editorOptions]="{ dataSource: edit.recipient, valueExpr: 'c_id', displayExpr: 'fullName'}">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('CARRIER') }" dataField="transporterId" editorType="dxSelectBox" [colSpan]="2"
		[editorOptions]="{ dataSource: proxy.transporter.name, valueExpr: 'c_id', displayExpr: 'fullName', searchEnabled: true,
			onValueChanged: onTransporterChanged}">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('TRANSPORT_TYPE')}" dataField="transportTypeId" editorType="dxSelectBox"
		[editorOptions]="{ dataSource: proxy.transport.type, valueExpr: 'id', displayExpr: proxy.transport.enumDisplay }">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [colSpan]="1" [colCount]="2" [label]="{text: L('TRANSFER_DATE')}" dataField="sendDate"
		editorType="dxDateBox" [editorOptions]="{ displayFormat: 'dd.MM.yyyy', width: '100%'}">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('REGISTER_NO') }" dataField="carId" editorType="dxSelectBox"
		[editorOptions]="{ dataSource: proxy.transporter.car, valueExpr: 'id', displayExpr: 'registerNo',
			disabled: !edit.model.transporterId, searchEnabled: true, onCustomItemCreating: onCustomItemCar, acceptCustomValue: true, onInitialized: onCarInit }">
	</dxi-item>
	<dxi-item [label]="{text: L('DRIVER') }" dataField="driverTableId" editorType="dxSelectBox"
		[editorOptions]="{ dataSource: proxy.transporter.driver, valueExpr: 'id', displayExpr: proxy.transporter.displayDriverName, 
			disabled: !edit.model.transporterId, searchEnabled: true, onCustomItemCreating: onCustomItemDriver, acceptCustomValue: true, searchExpr:['firstName','lastName'], onInitialized: onDriverInit }">
	</dxi-item>
	<dxi-item [label]="{text: L('WASTE_CODE')}" dataField="wasteTypeId" editorType="dxSelectBox" [editorOptions]="{ dataSource: proxy.wasteType.code, valueExpr: 'id', displayExpr: 'code', disabled: !edit.model.senderWorkCenterId, 
    onValueChanged: onWasteTypeChanged, searchEnabled: true, onInitialized: onWasteTypeInit }">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>
	<dxi-item itemType="empty" [visible]="edit.wasteType == '2'"></dxi-item>
	<dxi-item [label]="{text: L('QUANTITY') }" dataField="quantity" editorType="dxNumberBox"
		[editorOptions]="{ buttons: [{name: 'uom', location: 'after', options: uomHelper.options }], disabled: !edit.model.wasteTypeId }">
		<dxi-validation-rule type="required"></dxi-validation-rule>
		<dxi-validation-rule type="pattern" pattern="^\d+(\.\d{0,6})?$" message="Sunt permise maxim 6 zecimale.">
		</dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('PIECE') }" dataField="piece" editorType="dxNumberBox"
		[editorOptions]="{ }" [visible]="edit.wasteType == '2'">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('OBSERVATIONS')}" dataField="observations" editorType="dxTextArea" [colSpan]="2"
		[editorOptions]="{ }">
	</dxi-item>
</dx-form>