<dx-data-grid #gridLink [height]="400" (onToolbarPreparing)="toolbarPreparing($event, proxy.allowEdit)"
	(onInitialized)="onGridInit($event)">

	<dxo-scrolling [mode]="ui.scrollingMode" [showScrollbar]="ui.showScrollbar" [useNative]="!ui.isWindows">
	</dxo-scrolling>
	<dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" [storageKey]="key">
	</dxo-state-storing>

	<dxi-column type="buttons" fixedPosition="left" [minWidth]="proxy.actionsWidth" [width]="proxy.actionsWidth" 
		[fixed]="true" [allowResizing]="false" [showInColumnChooser]="false" [visible]="proxy.showActions">
		<dxi-button [hint]="L('EDIT')" icon="edit" [onClick]="onEditClick" [visible]="proxy.allowEdit">
		</dxi-button>
		<dxi-button [hint]="L('DELETE')" icon="trash" [onClick]="onDeleteClick" [visible]="proxy.allowDelete">
		</dxi-button>
	</dxi-column>

	<dxi-column [caption]="L('WASTE_CODE')" dataField="wt_code" dataType="string"> </dxi-column>
	<dxi-column [caption]="L('U')" dataField='uom' dataType='number'>
		<dxo-lookup [dataSource]='proxy.wasteType.uom' valueExpr='id' displayExpr='value'></dxo-lookup>
	</dxi-column>

	<dxi-column [caption]="L('WASTE_DESTINATION')" dataField="p_name" dataType="string"> </dxi-column>
	<dxi-column [caption]="L('OBSERVATIONS')" dataField="wtp_observations" dataType="string">
	</dxi-column>
	<dxi-column [caption]="L('ACTIVE')" dataField="wtp_isActiv" dataType="boolean"></dxi-column>
</dx-data-grid>

<ibis-edit-popup [edit]="edit" [showAdd]="true">
	<app-party-link-edit></app-party-link-edit>
</ibis-edit-popup>