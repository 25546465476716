import { Component } from '@angular/core';
import { iBisViewComponent } from '@dohu/ibis-common';
import { DataService } from '../../../service/data.service';
import { ProxyService } from '../../../service/proxy.service';
import { TaxAnalyzesFilterService } from './tax-analyzes-filter.service';

@Component({
	selector: 'app-tax-analyzes',
	templateUrl: './tax-analyzes.component.html'
})
export class TaxAnalyzesComponent extends iBisViewComponent {

	public edit: TaxAnalyzesFilterService;
	constructor(public proxy: ProxyService, public ds: DataService) {
		super(proxy.taxAnalyzes, proxy.lg, proxy.iui, proxy.activatedRoute);
	}
}
