import { Injectable } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { DataService } from '../../service/data.service';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import { iBisEditService, iBisLanguageService } from '@dohu/ibis-common';

@Injectable({
	providedIn: 'root'
})
export class ControlEditService extends iBisEditService {
	public data: CustomStore;

	public type: { 'id': number; 'value': string }[];
	public actionStatus: { 'id': number; 'value': string }[];
	public sanctionStatus: { 'id': number; 'value': string }[];

	constructor(private entity: iBisEntityService, private ds: DataService, lg: iBisLanguageService) {
		super(lg);
		this.title = 'CONTROL';
		this.validation = 'ControlValidation';

		this.data = this.getData();
		this.type = this.getType();
		this.actionStatus = this.getActionStatus();
		this.sanctionStatus = this.getSanctionStatus();
	}

	createDefault(): any {
		return { planned: false, action: false, sanction: false, wcDisable: true, date: new Date() };
	}

	getById(id: string, serverUrl?: string): Promise<any> {
		return new Promise((resolve, reject) => {
			this.entity.getById('Control', id).then(result => {
				if (!result) {
					reject();
				}
				resolve(result);
			}, error => {
				this.lg.showError(error);
			});
		});
	}

	onRemove(id: string): Promise<void> {
		return this.entity.remove('Control', id);
	}

	onSaveEv(serverUrl?: string): Promise<any> {
		return this.entity.save('Control', this.model.id, this.model);
	}

	reset(): void {
		this.model = this.createDefault();
	}

	private getType(): { 'id': number; 'value': string }[] {
		const list = [{ 'id': 1, 'value': 'INTERNAL' }, { 'id': 2, 'value': 'EXTERNAL' }];
		return this.ds.getEnum('controlType', list);
	}

	private getActionStatus(): { 'id': number; 'value': string }[] {
		const list = [{ 'id': 0, 'value': 'IN_PROGRESS' }, { 'id': 1, 'value': 'ACCOMPLISHED' }];
		return this.ds.getEnum('actionStatus', list);
	}

	private getSanctionStatus(): { 'id': number; 'value': string }[] {
		const list = [{ 'id': 0, 'value': 'RECEIVED' }, { 'id': 1, 'value': 'CHALLENGED' }, { 'id': 2, 'value': 'PAYED' }];
		return this.ds.getEnum('sanctionStatus', list);
	}

	private getData(): CustomStore {
		const q = new EntityQuery('ControlView').addOrderBy(['c.date']);
		return this.entity.store(q, false, 'c_id');
	}
}
