import { Component } from '@angular/core';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { ProxyService } from '../../../service/proxy.service';
import { KpiLocationEditService } from './kpi-location-edit.service';
@Component({
	selector: 'app-kpi-location',
	templateUrl: './kpi-location.component.html'
})
export class KpiLocationComponent extends iBisBaseComponent {

	public edit: KpiLocationEditService;
	constructor(public proxy: ProxyService) {
		super(proxy.kpiLocation, proxy.lg, proxy.iui, proxy.activatedRoute);
	}

}
