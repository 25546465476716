<dx-data-grid #gridCTR [height]="400" (onToolbarPreparing)="toolbarPreparing($event, false)" (onInitialized)="onGridInit($event)">

    <dxo-scrolling [mode]="ui.scrollingMode" [showScrollbar]="ui.showScrollbar" [useNative]="!ui.isWindows">
    </dxo-scrolling>
    <dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" [storageKey]="key">
    </dxo-state-storing>

    <dxi-column [caption]="L('CONTRACT_NO')" dataField="c_no" dataType="string"></dxi-column>
    <dxi-column [caption]="L('INVOICE_START_DATE')" dataField="c_startDate" dataType="date" width="170"
        calculateSortValue="c_startDate" [calculateCellValue]="iui.formatDate"></dxi-column>
    <dxi-column [caption]="L('FINISH_DATE')" dataField="c_endDate" dataType="date" width="170"
        calculateSortValue="c_endDate" [calculateCellValue]="iui.formatDate"></dxi-column>
    <dxi-column [caption]="L('INVOICE_OBJECT')" dataField="c_objective" dataType="string"></dxi-column>
    <dxi-column [caption]="L('VALID')" dataField="c_isValid" dataType="boolean"></dxi-column>
    <dxi-column [caption]="L('CONTRACT_TYPE')" dataField="ev_description" dataType="string"></dxi-column>
    <dxi-column [caption]="L('OBSERVATIONS')" dataField="c_observations" dataType="string"></dxi-column>
</dx-data-grid>