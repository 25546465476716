import { Injectable } from "@angular/core";
import { EntityQuery, iBisEntityService } from "@dohu/ibis-entity";
import { iBisEditService, iBisLanguageService } from "@dohu/ibis-common";

@Injectable({
    providedIn: 'root'
})
export class TransportApprovalPartyEditService extends iBisEditService {

    constructor(private entity: iBisEntityService, lg: iBisLanguageService) {
        super(lg);

        this.title = 'FAT_TRANSPORTER_PARTY';
    }

    createDefault() {
        return {};
    }
    getById(id: string, serverUrl?: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.entity.getById('TransportApprovalParty', id).then((result) => {
                if (result) {
                    resolve(result);
                } else {
                    reject();
                }
            }, error => this.lg.showError(error));
        });
    }
    onRemove(id: string): Promise<void> {
        return this.entity.remove('TransporApprovalParty', id);
    }
    onSaveEv(serverUrl?: string): Promise<any> {
        return this.entity.save('TransportApprovalParty', this.model.id, this.model);
    }
    reset(): void {
        const transporterId = this.model.transporterId;
        const approvalId = this.model.approvalId;
        this.model = { transporterId: transporterId, approvalId: approvalId };
    }

    public getData(approvalId: string) {
        return this.entity.store(new EntityQuery('TransportApprovalPartyView').eq('ta.approvalId', approvalId).addOrderBy(['tr.name']), false, 'ta_id');
    }
}