<ng-container *ngIf='isLoadingVisible; else showLogin'>
	<div class='loading-modal'>
		<img class='rotate logo-loading' src='assets/img/logo.svg'>
	</div>
</ng-container>
<ng-template #showLogin>
	<div class='loginContainer'>
		<div class='login-body'>
			<div class='left-section'>
				<p class='title'>{{L("WASTE_EVIDENCE")}}</p>
				<ibis-login></ibis-login>
			</div>
			<app-login-info></app-login-info>
		</div>
	</div>
	<div class='login-header'>
		<a href='#'><img src='assets/img/logo.svg'></a>
	</div>
	<div class='login-footer'>
		<p *ngIf="ds.allowRegister" class='try-text'> {{L("DO_ACCOUNT")}} <a href='#'
				(click)='goToRegister(false)'>{{L("FREE_APP")}}</a></p>
		<h4>Copyright © {{ year }} <a href='https://www.dohu.ro'>DoHu Consulting</a>{{L("RIGHTS_RESERVED")}}</h4>
	</div>
</ng-template>