<dx-tab-panel>
	<dxi-item [title]="L('REPORT')">
		<dx-scroll-view class="reportEditTabEdit">
			<dx-form [formData]="edit.model" [colCount]="2" [validationGroup]="edit.validation"
				style="padding-top:10px">
				<dxi-item [label]="{ text: L('ENT_WORKC') }" [colSpan]="2" dataField="entityId" editorType="dxSelectBox"
					[editorOptions]="{ dataSource: proxy.party.ownWorkCenter, valueExpr: 'c_id', displayExpr: 'fullName', readOnly: true }">
					<dxi-validation-rule type="required"></dxi-validation-rule>
				</dxi-item>
				<dxi-item [label]="{ text: L('PROFILE') }" dataField="profileId" editorType="dxSelectBox"
					[editorOptions]="{ dataSource: proxy.profile.data, valueExpr: 'id', displayExpr:'name', readOnly: true }">
					<dxi-validation-rule type="required"></dxi-validation-rule>
				</dxi-item>
				<dxi-item [label]="{text: L('DATE') }" dataField="reportDate" editorType="dxDateBox"
					[editorOptions]="{ displayFormat: 'dd.MM.yyyy', width: '100%', onValueChanged: onReportDateChanged }">
					<dxi-validation-rule type="required"></dxi-validation-rule>
				</dxi-item>
				<dxi-item [label]="{ text: L('LOCATION') }" [colSpan]="2" dataField="locationId"
					editorType="dxSelectBox"
					[editorOptions]="{ dataSource: proxy.location.data, valueExpr:'id', displayExpr:'name', disabled: edit.model.entityId == undefined }">
					<dxi-validation-rule type="required"></dxi-validation-rule>
				</dxi-item>

				<dxi-item [label]="{ text: L('LOCATION_DETAILS') }" [colSpan]="2" dataField="locationDetails"
					editorType="dxTextArea" [editorOptions]="{}"></dxi-item>
					<dxi-validation-rule type="stringLength"  [max]="256" [message]="L('NAME_LENGTH')">
					</dxi-validation-rule>
			</dx-form>
		</dx-scroll-view>
	</dxi-item>
	<dxi-item [title]="L('PARTICIPANT_LIST')" [disabled]="edit.model.id == undefined">
		<dx-scroll-view class="reportEditTabEdit">
			<dx-data-grid [dataSource]="edit.participant" [height]="300" (onInitNewRow)="onNewParticipant($event)">
				<dxo-editing mode="row" [allowDeleting]="true" [allowAdding]="true" [allowUpdating]="true">
				</dxo-editing>
				<dxi-column [caption]="L('AUDITOR')" dataField="auditorId" dataType="string">
					<dxo-lookup [dataSource]="proxy.auditor.data" valueExpr="id" displayExpr="displayName">
					</dxo-lookup>
				</dxi-column>
				<dxi-column [caption]="L('LEADER')" dataField="isLeader" dataType="boolean" width="120">
				</dxi-column>
			</dx-data-grid>
		</dx-scroll-view>
	</dxi-item>
	<dxi-item [title]='L("RESULTS")' [disabled]='edit.model.id == undefined'>
		<dx-scroll-view class="reportEditTabEdit">
			<dx-form [formData]="edit.model" [colCount]="4" style="padding-top:10px">
				<dxi-item [label]="{text: L('CLEANING_NOTE') }" dataField="cleanNote" editorType="dxSelectBox"
					[editorOptions]="{ dataSource: edit.rating }">
				</dxi-item>
				<dxi-item [label]="{text: L('POSITIVE_REACTIONS') }" dataField="positivReact" editorType="dxNumberBox"
					[editorOptions]="{ }">
				</dxi-item>
				<dxi-item [label]="{text: L('EMPLOYEES_OBSERVED') }" dataField="observed" editorType="dxNumberBox"
					[editorOptions]="{ }">
				</dxi-item>
				<dxi-item [label]="{text: L('EMPLOYEES_INTERVIEWED') }" dataField="interviewed" editorType="dxNumberBox"
					[editorOptions]="{ }">
				</dxi-item>
				<dxi-item [label]="{ text: L('OBSERVATIONS') }" [colSpan]="4" dataField="observers"
				editorType="dxTextArea" [editorOptions]="{}">
				<dxi-validation-rule type="stringLength"  [max]="256" [message]="L('NAME_LENGTH')">
				</dxi-validation-rule>
			</dxi-item>
			</dx-form>
			<dx-data-grid #resultGrid [dataSource]='edit.reportResult' [height]='390'
				(onInitNewRow)='onNewParticipant($event)' (onCellPrepared)='onCellPrepared($event)'>
				<dxo-master-detail [enabled]='true' template='details'></dxo-master-detail>

				<dxi-column [caption]='L("CATEGORY")' dataField='c_description'></dxi-column>
				<dxi-column [caption]="L('STATE')" dataField='isOk' [calculateDisplayValue]='calculateIsOk'
					dataType='string'>
				</dxi-column>
				<div *dxTemplate="let d of 'details'">
					<dx-data-grid [columnAutoWidth]='true' [remoteOperations]='true' [height]='250'
						(onRowRemoving)="onInconsistentRemove($event)" (onContentReady)='categoryInit($event, d)'
						(onToolbarPreparing)='detailsToolbarPreparing($event, d)'>
						<dxo-editing mode='row' [allowDeleting]='true' [useIcons]='true'>
							<dxo-texts confirmDeleteMessage="">
							</dxo-texts>
						</dxo-editing>

						<dxi-column type="buttons" [width]='60' fixedPosition='left' [fixed]='true'
							[allowResizing]='false' [showInColumnChooser]='false'>
							<dxi-button [hint]='L("EDIT")' icon="edit" [onClick]="onEditInconsistent"></dxi-button>
							<dxi-button name='delete' icon='trash'></dxi-button>
						</dxi-column>

						<dxi-column [caption]="L('REASON')" dataField="reasonId" editorType="string">
							<dxo-lookup [dataSource]="proxy.inconsistent.reason" valueExpr="id"
								displayExpr="description">
							</dxo-lookup>
							<dxi-validation-rule type="required"></dxi-validation-rule>
						</dxi-column>
						<dxi-column [caption]="L('TYPE')" dataField="typeId" dataType="string">
							<dxo-lookup [dataSource]="proxy.inconsistent.type" valueExpr="id" displayExpr="description">
							</dxo-lookup>
						</dxi-column>
						<dxi-column [caption]="L('CONDITION')" dataField="stateId" dataType="string">
							<dxo-lookup [dataSource]="proxy.inconsistent.state" valueExpr="id"
								displayExpr="description">
							</dxo-lookup>
						</dxi-column>
						<dxi-column [caption]="L('DEADLINE')" dataField="deadlineDate" dataType="date"
							calculateSortValue="deadlineDate" [calculateDisplayValue]="iui.formatDate" [minWidth]="120">
						</dxi-column>
						<dxi-column [caption]="L('SOLVING_DATE')" dataField="solvedDate" dataType="date"
							calculateSortValue="solvedDate" [calculateDisplayValue]="iui.formatDate" [minWidth]="120">
						</dxi-column>
						<dxi-column [caption]="L('ASCERTAINMENT')" dataField="observation" dataType="string">
						</dxi-column>
						<dxi-column [caption]="L('RECOMANDATION')" dataField="recomandation" dataType="string">
						</dxi-column>
					</dx-data-grid>
				</div>
			</dx-data-grid>
		</dx-scroll-view>
	</dxi-item>
</dx-tab-panel>