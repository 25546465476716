import { Component, ViewChild } from '@angular/core';
import { iBisViewComponent } from '@dohu/ibis-common';
import { ProxyService } from '../../../service/proxy.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { InconsistentEditService } from './inconsistent-edit.service';

@Component({
	templateUrl: './inconsistent.component.html'
})
export class InconsistentComponent extends iBisViewComponent {
	@ViewChild('gridInconsistent') protected grid: DxDataGridComponent;

	public edit: InconsistentEditService;
	constructor(public proxy: ProxyService) {
		super(proxy.inconsistent, proxy.lg, proxy.iui, proxy.activatedRoute);
		this.id = 'i_id';
		this.key = 'inconsistent';
	}
}
