<dx-form class='generateForm' #generateForm [colCount]="1" [formData]='edit.model' [showValidationSummary]="false"
	[validationGroup]="edit.validation" labelLocation="left">
	<dxi-item [label]="{text: L('ENT_WORKC') }" dataField="workCenterId" [colSpan]='2' editorType="dxSelectBox"
		[editorOptions]="{dataSource: proxy.party.ownWorkCenter, valueExpr: 'c_id', displayExpr: 'fullName', searchEnabled: true, onValueChanged: onWorkCenterChanged}">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>

	<dxi-item [label]="{text: L('WASTE_CODE') }" dataField="wasteTypeId" editorType="dxSelectBox" [editorOptions]="{ dataSource: proxy.wasteType.code, valueExpr: 'id', displayExpr: 'code', disabled: !edit.model.workCenterId, 
	onInitialized: onWasteTypeInit, onValueChanged: onWasteTypeChanged, searchEnabled: true }">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('QUANTITY')}" dataField="quantity" editorType="dxNumberBox"
		[editorOptions]="{buttons: [{name: 'uom', location: 'after', options: uomHelper.options }], disabled: !edit.model.wasteTypeId}">
		<dxi-validation-rule type="required"></dxi-validation-rule>
		<dxi-validation-rule type="compare" comparisonType=">" [comparisonTarget]="quantityTarget"
				[message]="L('QUANTITY_GREATER_0')"></dxi-validation-rule>
		<dxi-validation-rule type='pattern' pattern='^\d+(\.\d{0,6})?$' message='Sunt permise maxim 6 zecimale.'>
		</dxi-validation-rule>
	</dxi-item>

	<dxi-item [label]="{text: L('OPERATION_DATE')}" dataField="date" editorType="dxDateBox"
		[editorOptions]="{ displayFormat: 'dd.MM.yyyy', width: '100%'}">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('WASTE_TYPE')}" dataField="wasteType.hazardName" editorType="dxTextBox"
		[editorOptions]="{readOnly: true }">
	</dxi-item>
	<dxi-item [label]="{text: L('PHYSICAL_STATE')}" dataField="physicalCondition" editorType="dxSelectBox"
		[editorOptions]="{ dataSource: proxy.wasteType.physicalState, valueExpr: 'id', displayExpr: 'value'}">
	</dxi-item>
	<dxi-item itemType='empty'></dxi-item>
	<dxi-item [label]="{text: L('ELIMINATION_CODE')}" dataField="eliminationId" editorType="dxSelectBox"
		[editorOptions]="{ dataSource: proxy.wasteType.eliminationCode, valueExpr: 'id', displayExpr: 'description', showClearButton: true, searchEnabled: true}">
		<dxi-validation-rule type="custom" [reevaluate]='true' [validationCallback]="validateCode">
		</dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('CAPITALIZATION_CODE')}" dataField="capitalizationId" editorType="dxSelectBox"
		[editorOptions]="{ dataSource: proxy.wasteType.capitalizationCode, valueExpr: 'id', displayExpr: 'description', showClearButton: true, searchEnabled: true}">
		<dxi-validation-rule type="custom" [reevaluate]='true' [validationCallback]="validateCode">
		</dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('WASTE_NAME')}" dataField="wasteType.name" editorType="dxTextBox"
		[editorOptions]="{readOnly: true }" [colSpan]='2'>
	</dxi-item>
	<dxi-item [label]="{text: L('OBSERVATIONS')}" dataField="observations" editorType="dxTextArea" [editorOptions]="{}"
		[colSpan]='2'>
	</dxi-item>
</dx-form>