import { Component, ViewChild } from '@angular/core';
import { ProjectEditService } from './project-edit.service';
import { ProxyService } from '../../../service/proxy.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { BreadcrumbViewComponent } from '../../../framework/breadcrumb-view.component';

@Component({
	templateUrl: './project.component.html'
})
export class ProjectComponent extends BreadcrumbViewComponent {
	@ViewChild('gridProject') grid: DxDataGridComponent;

	public edit: ProjectEditService;
	constructor(public proxy: ProxyService) {
		super(proxy.project, proxy.lg, proxy.iui, proxy.activatedRoute);
		this.id = 'p_id';
		this.key = 'project';
	}
}
