import { Injectable } from '@angular/core';
import { iBisEditService, iBisLanguageService } from '@dohu/ibis-common';
import CustomStore from 'devextreme/data/custom_store';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import { environment } from '../../../../environments/environment';
import { iBisAuthService } from '@dohu/ibis-auth';

@Injectable()
export class InconsistentEditService extends iBisEditService {

	public data: CustomStore;
	public type: CustomStore;
	public state: CustomStore;
	public reason: CustomStore;
	public category: CustomStore;
	public auditRecord: CustomStore;

	constructor(private entity: iBisEntityService, private auth: iBisAuthService, lg: iBisLanguageService) {
		super(lg);
		this.title = 'INCONSISTENT';
		this.validation = 'inValidation';

		this.data = this.getData();
		this.type = this.getType();
		this.state = this.getState();
		this.reason = this.getReason();
		this.category = this.getCategory();
		this.auditRecord = this.getAuditRecord();
	}

	createDefault() {
		return {};
	}

	getById(id: string): Promise<any> {
		return new Promise((resolve, reject) => {
			this.entity.getById('Inconsistent', id).then(data => {
				if (data && data.resultId) {
					this.entity.getById('SafetyReportResult', data.resultId, 'id').then(dr => {
						data.reportId = dr.reportId;
						data.categoryId = dr.categoryId;
						resolve(data);
					}, error => {
						this.lg.showError(error);
					});
				} else {
					resolve(data);
				}
			}, error => {
				this.lg.showError(error);
				reject(error);
			});
		});
	}

	onRemove(id: string): Promise<void> {
		return this.entity.remove('Inconsistent', id);
	}

	onSaveEv(): Promise<any> {
		return this.entity.save('Inconsistent', this.model.id, this.model);
	}

	reset(): void {
		this.model = this.createDefault();
	}

	public getInconsistentBy(field: string, id: string): CustomStore {
		const q = new EntityQuery('Inconsistent').eq(field, id).addOrderByDesc(['deadlineDate']);
		return this.entity.store(q, false, 'id');
	}

	private getData(): CustomStore {
		const q = new EntityQuery('InconsistentView').neq('r_refNumber', null).addOrderByDesc(['i.deadlineDate', 'r.reportDate']);
		// TODO - de setat un profil in functie de Auditor/User
		// .eq('r.profileId', this.ds.currentProfileId)

		return this.entity.store(q, true, 'i_id');
	}

	private getAuditRecord(): CustomStore {
		const q = new EntityQuery('AuditRecord').linkEq('configId', 'id', 'AuditRecordConfig', 'appId', this.auth.companyId)
			.addOrderByDesc(['updateTime']);
		return this.entity.store(q, true, 'id', environment.saasServer);
	}

	private getType(): CustomStore {
		const q = new EntityQuery('EnumValue').addOrderBy(['orderIndex', 'code']).
			linkEq('typeId', 'id', 'EnumType', 'code', 'INCONS_TYPE');
		q.fields.push('id', 'code', 'description');
		return this.entity.store(q, false, 'id');
	}

	private getState(): CustomStore {
		const q = new EntityQuery('EnumValue').addOrderBy(['orderIndex', 'code']).
			linkEq('typeId', 'id', 'EnumType', 'code', 'INCONS_STATE');
		q.fields.push('id', 'code', 'description');
		return this.entity.store(q, false, 'id');
	}

	private getReason(): CustomStore {
		const q = new EntityQuery('EnumValue').addOrderBy(['orderIndex', 'code']).
			linkEq('typeId', 'id', 'EnumType', 'code', 'INCONS_REASON');
		q.fields.push('id', 'code', 'description');
		return this.entity.store(q, false, 'id');
	}

	private getCategory(): CustomStore {
		const q = new EntityQuery('EnumValue').addOrderBy(['orderIndex', 'code']).
			linkEq('typeId', 'id', 'EnumType', 'code', 'SAFETY_CATEGORY');
		q.fields.push('id', 'code', 'description');
		return this.entity.store(q, false, 'id');
	}
}
