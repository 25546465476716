<dx-form #fullTransportForm [colCount]="2" [formData]="edit.model" [showValidationSummary]="false"
    [validationGroup]="edit.validation" labelLocation="left" (onInitialized)="onFormInit($event)">

    <dxi-item [label]="{text: L('WORK_CENTER')}" dataField="workCenterId" [colSpan]="2" editorType="dxSelectBox"
        [editorOptions]="{ dataSource: proxy.party.ownWorkCenter, valueExpr: 'c_id', displayExpr: 'fullName', 
                    onValueChanged: onWorkCenterChanged, searchEnabled: true , disabled: edit.generalDisabled}">
        <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-item>

    <dxi-item [label]="{text: L('SENT_DATE')}" dataField="sendDate" editorType="dxDateBox"
        [editorOptions]="{ displayFormat: 'dd.MM.yyyy', width: '100%' }">
    </dxi-item>
    <dxi-item [label]="{text: L('CARRIER')}" dataField="transporterId" editorType="dxSelectBox"
		[editorOptions]="{ dataSource: proxy.transporter.name, valueExpr: 'c_id', displayExpr: 'fullName', searchEnabled: true, 
					onValueChanged: onTransporterChanged, disabled: edit.generalDisabled }">
        <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-item>

    <dxi-item [label]="{text: L('REGISTER_NO')}" dataField="carId" editorType="dxSelectBox"
		[editorOptions]="{ dataSource: proxy.transporter.car, valueExpr: 'id', displayExpr: 'registerNo', 
					disabled: !edit.model.transporterId,  searchEnabled: true, onInitialized: onCarInit }">
        <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-item>
    <dxi-item [label]="{text: L('DRIVER')}" dataField="driverTableId" editorType="dxSelectBox"
		[editorOptions]="{ dataSource: proxy.transporter.driver, valueExpr: 'id', displayExpr: proxy.transporter.displayDriverName,
					disabled: !edit.model.transporterId, searchEnabled: true, searchExpr:['firstName','lastName'], onInitialized: onDriverInit }">
        <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-item>

	<dxi-item [label]="{text: L('RECIPIENT') }" dataField="recipientId" editorType="dxSelectBox" [colSpan]="2"
		[editorOptions]="{ dataSource: proxy.party.otherWorkCenter, valueExpr: 'c_id', displayExpr: 'fullName', 
			onValueChanged: onDestinationChanged, searchEnabled: true, disabled: edit.generalDisabled }">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>

    <dxi-item [label]="{text: L('WASTE_CODE')}" dataField="wasteType.code" editorType="dxTextBox"
        [editorOptions]="{ buttons: [{name: 'Search', options:{icon: 'find', text: 'Selectează', onClick: onOpenStockPopup, stylingMode: 'text' }}], 
          disabled: edit.model.workCenterId == undefined || edit.model.workCenterId == null, onFocusIn: onFocusIn, onInitialized: onWasteTypeInit }">
        <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-item>
    <dxi-item [label]="{text: L('QUANTITY') }" [helpText]="qtyHelpText" dataField="quantity" editorType="dxNumberBox"
		[helpText]="qtyHelpText" [editorOptions]="{ disabled: edit.generalDisabled,
		buttons: [{name: 'uom', location: 'after', options: uomHelper.options }], onFocusIn: onFocusIn }">
        <dxi-validation-rule type="required"></dxi-validation-rule>
        <dxi-validation-rule type="custom" [reevaluate]="true" [validationCallback]="quantityValidation"
            [message]="L('VALUE_HIGHER_THAN_FAT')">
        </dxi-validation-rule>
    </dxi-item>

	<dxi-item [label]="{text: L('FAT') }" dataField="approvalId" editorType="dxSelectBox" [helpText]="L('FAT_HELP')"
		[editorOptions]="{ dataSource: proxy.transportApproval.code, 
			valueExpr: 'ta_id', displayExpr: 'fullName', disabled: !edit.requiredFAT, showClearButton: true, searchEnabled: true, onInitialized: onFATInit,
			onValueChanged: onFatChanged }">
        <dxi-validation-rule type="custom" [reevaluate]="true" [validationCallback]="validateFAT"
            message="Este necesar F.A.T. doar pentru cantitățile mai mari de 1000 kg.">
        </dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{ text: proxy.wasteType.selected.hazard == 0 ? L('FID') : L('FET') }" dataField="fet"
	editorType="dxTextBox" [editorOptions]="{ disabled: !edit.model.wasteTypeId}">
        <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-item>
	<dxi-item [label]="{text: L('ELIMINATION_CODE')}" dataField="eliminationId" editorType="dxSelectBox"
		[editorOptions]="{ dataSource: proxy.wasteType.eliminationCode, valueExpr: 'id', displayExpr: 'description', showClearButton: true, searchEnabled: true}">
	</dxi-item>
	<dxi-item [label]="{text: L('CAPITALIZATION_CODE')}" dataField="capitalizationId" editorType="dxSelectBox"
		[editorOptions]="{ dataSource: proxy.wasteType.capitalizationCode, valueExpr: 'id', displayExpr: 'description', showClearButton: true, searchEnabled: true}">
	</dxi-item>

    <dxi-item [label]="{text: L('ELIMINATION_TYPE')}" [colSpan]="2">
        <div *dxTemplate="let data of 'template'">
			<dx-drop-down-box #actionDropdown [(value)]="edit.model.selectedActivity" displayExpr="value" valueExpr="id"
			[dataSource]="edit.activity" [placeholder]="L('SELECT')">
				<div *dxTemplate="let activity of 'content'">
					<dx-list [dataSource]="edit.activity" selectionMode="multiple" [showSelectionControls]="true"
					  keyExpr="id" displayExpr="value" [(selectedItemKeys)]="edit.model.selectedActivity">
					</dx-list>
				  </div>
            </dx-drop-down-box>
        </div>
        <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-item>

    <dxi-item [label]="{text: L('PHYSICAL_STATE')}" dataField="physicalCondition" editorType="dxSelectBox"
        [editorOptions]="{ dataSource: proxy.wasteType.physicalState, valueExpr: 'id', displayExpr: 'value', searchEnabled: true}">
    </dxi-item>

    <dxi-item [label]="{text: L('DESTINATION')}" dataField="destinationTypeId" editorType="dxSelectBox"
        [visible]="false" [colSpan]="2"
        [editorOptions]="{ dataSource: edit.destinationType, valueExpr: 'id', displayExpr: edit.enumDisplay }">
	</dxi-item>
	
	<dxi-item [label]="{text: L('INVOICE_NO')}" dataField="invoiceNo" editorType="dxTextBox"
		[editorOptions]="{}">
	</dxi-item>
	<dxi-item [label]="{text: L('INVOICE_DATE')}" dataField="invoiceDate" editorType="dxDateBox"
		[editorOptions]="{ width: '100%', displayFormat: 'dd.MM.yyyy'}">
	</dxi-item>

	<dxi-item [label]="{text: L('WASTE_TYPE')}" dataField="wasteType.hazardName" editorType="dxTextBox"
		[editorOptions]="{readOnly: true }">
	</dxi-item>

	<dxi-item [label]="{text: L('WASTE_NAME')}" dataField="wasteType.name" editorType="dxTextBox" [colSpan]="2"
		[editorOptions]="{ readOnly: true }">
	</dxi-item>

    <dxi-item [label]="{text: L('OBSERVATIONS')}" dataField="observations" editorType="dxTextArea" [colSpan]="2"
      [editorOptions]="{ }">
    </dxi-item>
</dx-form>

<app-actual-stock-edit></app-actual-stock-edit>