import { Injectable } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import { iBisEditService, iBisLanguageService } from '@dohu/ibis-common';

@Injectable({
	providedIn: 'root'
})
export class AuditorEditService extends iBisEditService {
	public job: CustomStore;
	public data: CustomStore;

	private auId: string;

	constructor(private entity: iBisEntityService, lg: iBisLanguageService) {
		super(lg);
		this.title = 'AUDITOR';
		this.validation = 'auditorValidation';

		this.data = this.getData();
		this.job = this.getJob();
	}

	public set auditorId(id: string){
		this.auId = id;
	}

	public get auditorId(): string{
		return this.auId;
	}

	createDefault() {
		return {};
	}

	getById(id: string): Promise<any> {
		return this.entity.getById('SafetyAuditor', id).catch(error => {
			this.lg.showError(error);
		});
	}

	onRemove(id: string): Promise<void> {
		return this.entity.remove('SafetyAuditor', id);
	}

	onSaveEv(): Promise<any> {
		return this.entity.save('SafetyAuditor', this.model.id, this.model);
	}

	reset(): void {
		this.model = this.createDefault();
	}

	private getData() {
		return this.entity.store(new EntityQuery('SafetyAuditor').addOrderBy(['displayName']), false, 'id');
	}

	private getJob(): CustomStore {
		const q = new EntityQuery('EnumValue').addOrderBy(['orderIndex', 'code']).
			linkEq('typeId', 'id', 'EnumType', 'code', 'SAFETY_JOB');
		q.fields.push('id', 'code', 'description');
		return this.entity.store(q, false, 'id');
	}

	// private getData(locationId: string) {
	// 	const q = new EntityQuery('SafetyAuditor').addOrderBy(['displayName']);
	// 	if (locationId) {
	// 		q.link('departmentId', 'id', new EntityQuery('Department').linkEq('entityId', 'partyId', 'Location', 'id', locationId));
	// 	}
	// 	return new CustomStore(EntityStore.store(q, false, 'id'));
	// }
}
