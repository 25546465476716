import { Component } from "@angular/core";
import { iBisBaseComponent } from "@dohu/ibis-common";
import { ProxyService } from "../../../../service/proxy.service";

@Component({
    selector: 'app-transport-approval-party-edit',
    templateUrl: './transport-approval-party-edit.component.html'
})
export class TransportApprovalPartyEditComponent extends iBisBaseComponent {
    private cboCar: any;
    private cboDriver: any;
    constructor(public proxy: ProxyService) {
        super(proxy.transportApprovalParty, proxy.lg, proxy.iui, proxy.activatedRoute);

        proxy.transporter.onDataChanged.subscribe((data) => {
            if (data === 'selected' && this.cboCar && this.cboDriver) {
                this.proxy.resetValidation(this.cboCar);
                this.proxy.resetValidation(this.cboDriver);
            }
        });
    }

    public onTransporterChanged = (ev: any) => {
        this.proxy.transporter.selectedId = ev.value;
    };

    public onCarInit = (ev: any) => {
        this.cboCar = ev.component;
    };

    public onDriverInit = (ev: any) => {
        this.cboDriver = ev.component;
    };
}