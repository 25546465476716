<h2>Copiază planificarile de pe o lună pe alta.</h2>
<dx-form [formData]='edit.model' [validationGroup]="edit.validation" [colCount]='1' style='padding-top:10px'>
    <dxi-item [label]='{text: L("FROM_DATE")}' dataField='fromDate' editorType='dxDateBox' [editorOptions]='{ displayFormat: "MM.yyyy", width: "100%",
        calendarOptions: {minZoomLevel: "year", maxZoomLevel: "year" } }'>
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>

    <dxi-item [label]="{text: L('THRU_DATE')}" dataField="thruDate" editorType="dxDateBox" [editorOptions]="{ displayFormat: 'MM.yyyy', width: '100%',
        calendarOptions: {minZoomLevel: 'year', maxZoomLevel: 'year' } }">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>
</dx-form>