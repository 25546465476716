import { Component } from '@angular/core';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { ProxyService } from '../../../service/proxy.service';
import { InconsistentEditService } from './inconsistent-edit.service';

@Component({
	selector: 'app-inconsistent-edit',
	templateUrl: './inconsistent-edit.component.html'
})
export class InconsistentEditComponent extends iBisBaseComponent {

	public edit: InconsistentEditService;
	constructor(public proxy: ProxyService) {
		super(proxy.inconsistent, proxy.lg, proxy.iui, proxy.activatedRoute);
	}
}
