<dx-data-grid class="gridContainer box-shadow" #gridInconsistent [columnAutoWidth]="true" [remoteOperations]="true"
    [height]="ui.fullHeight" (onToolbarPreparing)="toolbarPreparing($event)" [dataSource]="edit.data">

    <dxo-export [enabled]="true" fileName="Inconsistent"></dxo-export>
    <dxo-scrolling [mode]="ui.scrollingMode" [showScrollbar]="ui.showScrollbar" [useNative]="!ui.isWindows">
    </dxo-scrolling>
	<dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" [storageKey]="key">
	</dxo-state-storing>
    <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
    <dxo-master-detail [enabled]="true" template="details"></dxo-master-detail>

    <dxi-column type="buttons" [width]='60' fixedPosition='left' [fixed]='true' [allowResizing]='false'
        [showInColumnChooser]='false'>
        <dxi-button [hint]='L("EDIT")' icon="edit" [onClick]="onEditClick"></dxi-button>
    </dxi-column>

    <dxi-column [caption]="L('NR_REPORT')" dataField="r_refNumber" dataType="number"></dxi-column>
    <dxi-column [caption]="L('DATE')" dataField="r_reportDate" dataType="date" calculateSortValue="r_reportDate"
        [calculateDisplayValue]="iui.formatDate" [minWidth]="120"></dxi-column>
    <dxi-column [caption]="L('CATEGORY')" dataField="c_description" dataType="string"></dxi-column>
    <dxi-column [caption]="L('DEADLINE')" dataField="i_deadlineDate" dataType="date" calculateSortValue="i_deadlineDate"
        [calculateDisplayValue]="iui.formatDate" [minWidth]="120"></dxi-column>
    <dxi-column [caption]="L('SOLVING_DATE')" dataField="i_solvedDate" dataType="date" calculateSortValue="i_solvedDate"
        [calculateDisplayValue]="iui.formatDate" [minWidth]="120"></dxi-column>
    <dxi-column [caption]="L('CONDITION')" dataField="s_description" dataType="string"></dxi-column>
    <dxi-column [caption]="L('LOCATION')" dataField="l_name" dataType="string"></dxi-column>
    <dxi-column [caption]="L('ENTITY')" dataField="e_name" dataType="string"></dxi-column>
    <dxi-column [caption]="L('TYPE')" dataField="t_description" dataType="string"></dxi-column>
    <dxi-column [caption]="L('REASON')" dataField="rs_description" dataType="string"></dxi-column>
    <dxi-column [caption]="L('ASCERTAINMENT')" dataField="i_observation" dataType="string"></dxi-column>
    <dxi-column [caption]="L('RECOMANDATION')" dataField="i_recomandation" dataType="string"></dxi-column>
    <div *dxTemplate="let data of 'details'">
        <ibis-file [entityId]="data.data.i_id" [allowDelete]="proxy.allowDelete" [allowUpload]="proxy.allowEdit"></ibis-file>
    </div>
</dx-data-grid>

<ibis-edit-popup [edit]="edit" [showAdd]="true">
    <app-inconsistent-edit></app-inconsistent-edit>
</ibis-edit-popup>