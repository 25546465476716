import notify from 'devextreme/ui/notify';
import { Injectable } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import { DataService } from '../../service/data.service';
import { PartyEditService } from '../../common/party/party-edit/party-edit.service';
import { EntityQuery, iBisEntityConfig, iBisEntityService } from '@dohu/ibis-entity';
import { iBisEditService, iBisFileService, iBisLanguageService } from '@dohu/ibis-common';

@Injectable({
	providedIn: 'root'
})
export class ReportExtEditService extends iBisEditService {
	public entities: DataSource;
	public reportTypes: CustomStore;
	public workCenters: CustomStore;
	public currentReportCode: String;

	private rt: any;
	private it: any;

	constructor(private entity: iBisEntityService, private file: iBisFileService,
		private party: PartyEditService, private ds: DataService, lg: iBisLanguageService,
		private config: iBisEntityConfig) {
		super(lg);
		this.validation = 'reportValidation';
		this.entities = this.party.getPartyLink([1]);
	}

	public get reportType(): any {
		return this.rt;
	}

	public set reportType(type: any) {
		this.rt = type;
		this.reportTypes = this.ds.getEnumValue(this.rt);
	}

	public get interval(): any {
		return this.it;
	}

	public set interval(interval: any) {
		this.it = interval;
	}

	public getPartyMemberById(id: string): CustomStore {
		const q = new EntityQuery('Party').eq('parentId', id).eq('typeId', 2);
		return this.entity.store(q, false, 'id');
	}

	onClickArchive() {
		const obj = {
			reportType: this.reportType,
			reportCode: this.currentReportCode,
			year: this.model.year,
			semester: this.model.semester,
			month: this.model.month,
			entityId: this.model.entityId,
			workCenterId: this.model.workCenterId,
			interval: this.interval,
			typeId: this.model.typeId
		};
		this.entity.execute('ArchiveReport', obj).then(res => {
			if (res) {
				notify(this.L('REPORT_ARCHIVED', null), 'success', 3000);
			}
		}, error => this.lg.showError(error));
	}

	onGenerateReportClickEv(event: any) {
		const validation = event.validationGroup.validate();
		if (!validation.isValid) {
			notify(this.L('FIELDS_NOT_VALID', null), 'error', 3000);
			return;
		}
		const url: string = this.config.server + 'GenerateReport';
		const obj: any = {
			year: this.model.year,
			month: this.model.month,
			entityId: this.model.entityId,
			workCenterId: this.model.workCenterId,
			reportType: this.reportType,
			reportCode: this.currentReportCode
		};
		if (url) {
			this.file.downloadByPost(url, obj);
		} else {
			notify(this.L('REPORT_NOT_FOUND', null), 'warning', 3000);
		}
	}

	createDefault() {
		return { year: new Date().getFullYear() };
	}
	getById(id: string, serverUrl?: string): Promise<any> {
		throw new Error('Method not implemented.');
	}
	onRemove(id: string): Promise<void> {
		throw new Error('Method not implemented.');
	}
	onSaveEv(serverUrl?: string): Promise<any> {
		throw new Error('Method not implemented.');
	}
	reset(): void {
		this.model = this.createDefault();
	}
}
