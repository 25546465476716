import { NgModule } from '@angular/core';
import { StockModule } from '../stock/stock.module';
import { iBisCommonModule } from '@dohu/ibis-common';
import { BrowserModule } from '@angular/platform-browser';
import { DxFormModule } from 'devextreme-angular/ui/form';
import { TreatmentComponent } from './treatment.component';
import { DxPopupModule } from 'devextreme-angular/ui/popup';
import { TreatmentEditService } from './treatment-edit.service';
import { FrameworkModule } from '../../framework/framework.module';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { TreatmentEditComponent } from './treatment-edit.component';
import { TreatmentItemComponent } from './treatment-item.component';
import { iBisNavigationModule } from '@dohu/ibis-navigation';

@NgModule({
	declarations: [
		TreatmentComponent,
		TreatmentEditComponent,
		TreatmentItemComponent
	],
	imports: [
		StockModule,
		DxFormModule,
		BrowserModule,
		DxPopupModule,
		FrameworkModule,
		DxDataGridModule,
		iBisCommonModule,
		iBisNavigationModule
	],
	exports: [
		TreatmentComponent,
		TreatmentEditComponent,
		TreatmentItemComponent
	],
	providers: [
		TreatmentEditService
	]
})
export class TreatmentModule { }
