<dx-data-grid class="gridContainer" #gridTransportApproval [dataSource]="edit.data"
	[height]="ui.fullHeight" [remoteOperations]="true"
	(onToolbarPreparing)="toolbarPreparing($event, proxy.allowEdit)" (onCellPrepared)="onCellPrepared($event)">

	<dxo-export [enabled]="ui.exportEnabled" fileName="fat"></dxo-export>
	<dxo-scrolling [mode]="ui.scrollingMode" [showScrollbar]="ui.showScrollbar" [useNative]="!ui.isWindows">
	</dxo-scrolling>
	<dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" storageKey="qhseFat"></dxo-state-storing>
	<dxo-master-detail [enabled]="true" template="details"></dxo-master-detail>

	<dxi-column type="buttons" fixedPosition="left" [minWidth]="proxy.actionsWidth" [width]="proxy.actionsWidth"
		[fixed]="true" [allowResizing]="false" [showInColumnChooser]="false" [visible]="proxy.showActions">
		<dxi-button [hint]="L('EDIT')" icon="edit" [onClick]="onEditClick" [visible]="proxy.allowEdit">
		</dxi-button>
		<dxi-button [hint]="L('DELETE')" icon="trash" [onClick]="onDeleteClick" [visible]="proxy.allowDelete">
		</dxi-button>
	</dxi-column>

	<dxi-column name='fat' headerCellTemplate='fatHeader'>
		<dxi-column [caption]="L('FAT_NO')" dataField="ta_no" dataType="string"></dxi-column>
		<dxi-column [caption]="L('DOCUMENT_TYPE')" dataField="ta_type" dataType="number">
			<dxo-lookup [dataSource]='edit.type' valueExpr='id' displayExpr='value'></dxo-lookup>
		</dxi-column>
		<dxi-column [caption]="L('START_DATE')" dataField="ta_startDate" dataType="date"
			[calculateCellValue]="iui.formatDate" calculateSortValue="ta_startDate" width="120"></dxi-column>
		<dxi-column [caption]="L('END_DATE')" dataField="ta_expireDate" dataType="date"
			[calculateCellValue]="iui.formatDate" calculateSortValue="ta_expireDate" width="120"></dxi-column>
	</dxi-column>
	<dxi-column [caption]="L('GENERATOR')" dataField="partyName" dataType="string"></dxi-column>
	<dxi-column [caption]="L('RECIPIENT')" dataField="destinationName" dataType="string"></dxi-column>

	<dxi-column [caption]="L('WASTE_CODE')" dataField="wt_code" dataType="string"></dxi-column>
	<dxi-column name='rtrans' headerCellTemplate='rtransHeader'>
		<dxi-column [caption]="L('APPROVED_TRANS_NO')" dataField="ta_approvedNo" dataType="number"></dxi-column>
		<dxi-column [caption]="L('TRANSPORTS_LEFT')" dataField="noLeft" dataType="number"> </dxi-column>
	</dxi-column>
	<dxi-column name='quantity' headerCellTemplate='qHeader'>
		<dxi-column [caption]="L('APPROVED_QUANTITY')" dataField="ta_approvedQuantity" dataType="number"></dxi-column>
		<dxi-column [caption]="L('QUANTITY_LEFT')" dataField="quantityLeft" dataType="number" minWidth="150">
		</dxi-column>
		<dxi-column [caption]="L('U')" dataField='uom' dataType='number'>
			<dxo-lookup [dataSource]='proxy.wasteType.uom' valueExpr='id' displayExpr='value'></dxo-lookup>
		</dxi-column>
	</dxi-column>

	<div *dxTemplate="let type of 'details'">
		<dx-tab-panel>
			<dxi-item [title]="L('TRANSPORTERS')">
				<app-transport-approval-party [approvalId]="type.data.ta_id"></app-transport-approval-party>
			</dxi-item>
			<dxi-item [title]="L('FILES')">
				<ibis-file [entityId]="type.data.ta_id" [allowDelete]='proxy.allowEdit'></ibis-file>
			</dxi-item>
		</dx-tab-panel>
		
	</div>

	<dxo-summary>
		<dxi-total-item column="ta_approvedNo" summaryType="sum" [displayFormat]="L('TOTAL') + ': {0}'"
			[valueFormat]="{type:'fixedPoint', precision: 2 }"></dxi-total-item>
		<dxi-total-item column="noLeft" summaryType="sum" [displayFormat]="L('TOTAL') + ': {0}'"
			[valueFormat]="{type:'fixedPoint', precision: 0 }">
		</dxi-total-item>
	</dxo-summary>

	<div *dxTemplate="let data of 'breadcrumb'">
		<ibis-breadcrumb></ibis-breadcrumb>
	</div>
	<div *dxTemplate="let c of 'fatHeader'" class="dx-datagrid-text-content" role="presentation">
		<i class="fas fa-file-alt"></i> F.A.T.
	</div>
	<div *dxTemplate="let c of 'rtransHeader'" class="dx-datagrid-text-content" role="presentation">
		<i class="fas fa-truck"></i> Transport
	</div>
	<div *dxTemplate="let c of 'qHeader'" class="dx-datagrid-text-content" role="presentation">
		<i class='fas fa-weight'></i> Cantitate
	</div>
</dx-data-grid>

<ibis-edit-popup [edit]="edit" [showAdd]="true">
	<app-transport-approval-edit></app-transport-approval-edit>
</ibis-edit-popup>