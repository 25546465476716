<dx-data-grid #gridProfile class="gridContainer" [dataSource]="edit.data"
    [height]="ui.fullHeight" [remoteOperations]="true" (onInitNewRow)="onInitNewRow($event)"
    (onToolbarPreparing)="toolbarPreparing($event, false)">

    <dxo-scrolling mode="infinite" [showScrollbar]="ui.showScrollbar" [useNative]="!ui.isWindows">
    </dxo-scrolling>
    <dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" [storageKey]="key">
    </dxo-state-storing>
    <dxo-editing mode="row" [allowAdding]="true" [allowUpdating]="true" [allowDeleting]="true" [useIcons]="true">
    </dxo-editing>
    <dxo-master-detail [enabled]="true" template="details"></dxo-master-detail>

    <dxi-column [caption]="L('NAME')" dataField="name" dataType="string"></dxi-column>
        <dxi-validation-rule type="required"></dxi-validation-rule>
        <dxi-validation-rule type="stringLength"  [max]="80" [message]="L('G_NAME_LENGTH')">
        </dxi-validation-rule>
    <dxi-column [caption]="L('ACTIVE')" dataField="isActiv" dataType="boolean" [width]="100"></dxi-column>
    <div *dxTemplate="let profile of 'details'">
        <app-profile-category [profileId]='profile.data.id'></app-profile-category>
    </div>

</dx-data-grid>