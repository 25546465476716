import { Injectable } from '@angular/core';
import { iBisEntityService } from '@dohu/ibis-entity';
import { UnitEditService } from '../unit/unit-edit.service';
import { UnitWasteEditService } from '../unit/unit-waste-edit.service';
import { iBisEditService, iBisLanguageService } from '@dohu/ibis-common';

@Injectable({
	providedIn: 'root'
})
export class PartyWizardService extends iBisEditService{

	private pg: number;
	private previousPg: number;
	private validations: string[];

	constructor(private entity: iBisEntityService, lg: iBisLanguageService,
		private unit: UnitEditService, private unitWaste: UnitWasteEditService) {
		super(lg);
		this.title = 'PARTY_WIZARD';
		this.validation = null;
		this.pg = 0;
		this.validations = this.getValidation();
	}

	public set page(no: number){
		this.previousPg = this.pg;
		this.pg = no;
		this.onDataChanged.next('nextWizardPage');
	}

	public get page(){
		return this.pg;
	}

	public get previousPage(){
		return this.previousPg;
	}

	createDefault() {
		return {};
	}
	getById(id: string, serverUrl?: string): Promise<any> {
		throw new Error('Method not implemented.');
	}
	onRemove(id: string): Promise<void> {
		throw new Error('Method not implemented.');
	}
	onSaveEv(serverUrl?: string): Promise<any> {
		const obj ={};
		Object.assign(obj, this.model.entity);
		this.model.entity = this.entity.prepareForInsert('Party', obj);
		Object.assign(obj, this.model.workcenter);
		this.model.workcenter = this.entity.prepareForInsert('Party', obj);
		Object.assign(obj, this.model.unitWaste);
		this.model.unitWaste = this.entity.prepareForInsert('WasteTypeParty', obj);
		return this.entity.execute('CreateInitialParty', this.model);
	}
	reset(): void {
		this.model = this.createDefault();
	}

	popupInit(ev: any){
		super.popupInit(ev);
		this.refreshPopupToolbar(ev.component);
	}

	private refreshPopupToolbar(popupInstance: any): void{
		popupInstance.beginUpdate();
		const tbBtns = [{
			location: 'after',
			visible: this.page !== 0,
			options: {
				text: this.L('PREVIOUS_STEP'),
				icon: 'fas fa-arrow-left',
				type: 'default',
				onClick: (e: any) => {
					if(this.page !== 0){
						this.page--;
					}
					this.refreshPopupToolbar(popupInstance);
				}
			},
			toolbar: 'bottom',
			widget: 'dxButton'
		}, {
			location: 'after',
			options: {
				text: this.page !== 4 ? this.L('NEXT_STEP') : this.L('FINISH'),
				icon: this.page !== 4 ? 'fas fa-arrow-right' : 'fas fa-save',
				validationGroup: this.validations[this.page],
				type: 'default',
				onClick: (e: any) => {
					if(this.page !== 4){
						if(!e.validationGroup ||  e.validationGroup?.validate().isValid){
							this.page++;
						}
					} else{
						this.onSaveEv().then((data) => {
							this.hidePopup();
							this.success(this.model);
						}, (e: any) => {
							this.lg.showError(e);
						});
					}
					this.refreshPopupToolbar(popupInstance);
				}
			},
			toolbar: 'bottom',
			widget: 'dxButton'
		}];
		popupInstance.option('toolbarItems', tbBtns);
		popupInstance.endUpdate();
	}

	private getValidation(): string[]{
		return [null, this.unit.validation, this.unit.validation, this.unitWaste.validation, null];
	}
}
