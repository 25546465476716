import * as Bowser from 'bowser';
import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { iBisAuthService } from '@dohu/ibis-auth';
import { iBisGridProperties, iBisUIService } from '@dohu/ibis-common';
import { DataService } from './service/data.service';
import { ProxyService } from './service/proxy.service';
import { environment } from '../environments/environment';
import TextBox from 'devextreme/ui/text_box';
import DropDownBox from 'devextreme/ui/drop_down_box';
import NumberBox from 'devextreme/ui/number_box';
import TagBox from 'devextreme/ui/tag_box';
import DateBox from 'devextreme/ui/date_box';
import SelectBox from 'devextreme/ui/select_box';
import TextArea from 'devextreme/ui/text_area';
import DataGrid from 'devextreme/ui/data_grid';
import Form from 'devextreme/ui/form';
import * as uiC from '../assets/uiCustomize.json';

declare let require: any;

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent {
	public menuOpened = false;
	public version: { lastCommitNumber: number; branch: string; shortSHA: string };
	public app: {link: string; text: string};

	constructor(public ds: DataService, public iui: iBisUIService,
		public proxy: ProxyService, public auth: iBisAuthService, http: HttpClient) {

		this.app = environment.app;
		// auth.appName = 'QHSE';
		this.version = require('../git-version.json');
		TextBox.defaultOptions({
			device: { deviceType: 'desktop' },
			options: { stylingMode: 'underlined' }
		});
		DropDownBox.defaultOptions({
			device: { deviceType: 'desktop' },
			options: { stylingMode: 'underlined' }
		});
		TagBox.defaultOptions({
			device: { deviceType: 'desktop' },
			options: { stylingMode: 'underlined' }
		});
		DateBox.defaultOptions({
			device: { deviceType: 'desktop' },
			options: { stylingMode: 'underlined' }
		});
		SelectBox.defaultOptions({
			device: { deviceType: 'desktop' },
			options: { stylingMode: 'underlined' }
		});
		TextArea.defaultOptions({
			device: { deviceType: 'desktop' },
			options: { stylingMode: 'underlined' }
		});
		NumberBox.defaultOptions({
			device: { deviceType: 'desktop' },
			options: { stylingMode: 'underlined' }
		});
		Form.defaultOptions({
			device: { deviceType: 'desktop' },
			options: { labelMode: 'static' }
		});
		// LoadPanel.defaultOptions({
		// 	device: { deviceType: 'desktop' },
		// 	options: { indicatorSrc: 'assets/img/loading.svg' }
		// });
		// DataGrid.defaultOptions({
		// 	device: { deviceType: 'desktop' },
		// 	options: { loadPanel: {indicatorSrc: 'assets/img/loading.svg' } }
		// });

		const grid = uiC.default.grid as iBisGridProperties;
		const opt =  { showBorders: true, columnResizingMode: 'widget',
			scrolling: {mode: 'virtual', rowRenderingMode: 'standard',
				columnRenderingMode: 'standard', renderAsync: true, useNative: false} };

		for (const vs of ['filterRow', 'filterPanel', 'headerFilter', 'groupPanel', 'searchPanel']) {
			opt[vs] = { visible: grid[vs]};
		}
		for (const vs of ['export', 'columnChooser', 'columnFixing']) {
			opt[vs] = { enabled: grid[vs]};
		}
		for (const vs of ['allowColumnReordering', 'allowColumnResizing', 'columnAutoWidth', 'focusedRowEnabled', 'hoverStateEnabled',
		 	'showColumnHeaders', 'showColumnLines', 'rowAlternationEnabled', 'wordWrapEnabled']) {
			opt[vs] = grid[vs];
		}

		// for (const vs of ['filterRow', 'filterPanel', 'headerFilter', 'groupPanel', 'searchPanel']) {
		// 	opt[vs] = { visible: grid[vs]};
		// }
		// for (const vs of ['export', 'columnChooser', 'columnFixing']) {
		// 	opt[vs] = { enabled: grid[vs]};
		// }
		// for (const vs of ['allowColumnReordering', 'allowColumnResizing', 'columnAutoWidth', 'focusedRowEnabled', 'hoverStateEnabled',
		// 	'showColumnHeaders', 'showColumnLines', 'rowAlternationEnabled', 'wordWrapEnabled']) {
		// 	opt[vs] = grid[vs];
		// }
		DataGrid.defaultOptions({
			device: { deviceType: 'desktop' },
			options: opt
		});

		this.ds.allowRegister = environment.allowRegister;

		const ui = this.iui.config.ui;
		if (ui.useNativ !== null && ui.useNativ !== undefined) {
			ui.isWindows = !ui.useNativ;
		} else {
			if (window.navigator.userAgent && Bowser) {
				const bowser = Bowser.getParser(window.navigator.userAgent);
				const os = Bowser ? bowser.getOS() || {} : {};
				if (os && os.name) {
					ui.isWindows = os.name === 'Windows';
				} else {
					ui.isWindows = false;
				}
			} else {
				ui.isWindows = false;
			}
		}
	}
}
