<dx-tree-list #gridGeneralReport class="gridContainer box-shadow" parentIdExpr="parentId" keyExpr="id"
	[columnAutoWidth]="true" [allowColumnResizing]="true" [allowColumnReordering]="true" [height]="ui.fullHeight"
	[dataSource]="edit.data" (onToolbarPreparing)="toolbarPreparing($event)"
	rootValue="00000000-0000-0000-0000-000000000000">

	<dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" storageKey="qhseGeneralReport">
	</dxo-state-storing>
	<dxo-remote-operations [sorting]="ui.remoteOperationsSorting" [paging]="ui.remoteOperationsPaging"
		[filtering]="ui.remoteOperationsFiltering"></dxo-remote-operations>
	<dxo-scrolling [mode]="ui.scrollingMode" [showScrollbar]="ui.showScrollbar" [useNative]="!ui.isWindows">
	</dxo-scrolling>

	<dxi-column [caption]="L('IN_OUT')" dataField="typeId" dataType="number">
		<dxo-lookup [dataSource]="edit.inOutType" valueExpr="id" displayExpr="value"></dxo-lookup>
	</dxi-column>
	<dxi-column [caption]="L('WORK_CENTER')" dataField="workCenter" dataType="string"></dxi-column>
	<dxi-column [caption]="L('COMPANY')" dataField="company" dataType="string"></dxi-column>
	<dxi-column [caption]="L('DATE')" dataField="date" dataType="date" calculateSortValue="date"
		[calculateDisplayValue]="iui.formatDate"></dxi-column>
	<dxi-column [caption]="L('WASTE_CODE')" dataField="wasteCode" dataType="string"></dxi-column>
	<dxi-column [caption]="L('WASTE_NAME')" dataField="wasteName" dataType="string"></dxi-column>
	<dxi-column [caption]="L('QUANTITY')" dataField="quantity" dataType="number"></dxi-column>
	<dxi-column [caption]="L('U')" dataField="wasteUom" dataType="number">
		<dxo-lookup [dataSource]="proxy.wasteType.uom" valueExpr="id" displayExpr="value"></dxo-lookup>
	</dxi-column>
	<dxi-column [caption]="L('PHYSICAL_STATE')" dataField="physicalCondition" dataType="number">
		<dxo-lookup [dataSource]="proxy.wasteType.physicalState" valueExpr="id" displayExpr="value"></dxo-lookup>
	</dxi-column>
	<dxi-column [caption]="L('WASTE_CODE_FROM')" dataField="fromWasteCode" dataType="string"></dxi-column>
	<dxi-column [caption]="L('ELIMINATION_CODE')" dataField="eliminationCode" dataType="string"></dxi-column>
	<dxi-column [caption]="L('CAPITALIZATION_CODE')" dataField="capitalizationCode" dataType="string">
	</dxi-column>
	<dxi-column [caption]="L('TREATMENT_TYPE')" dataField="treatmentType" dataType="number"
		cellTemplate="actionsTemplate">
		<div *dxTemplate="let data of 'actionsTemplate'">
			{{getActions(data.data.typeId, data.data.treatmentType)}}
		</div>
	</dxi-column>
	<dxi-column [caption]="L('INCINERATE_LINE')" dataField="eliminationDevice" dataType="string">
	</dxi-column>
	<dxi-column [caption]="L('FAT_NO')" dataField="fatNo" dataType="string"></dxi-column>
	<dxi-column [caption]="L('FET')" dataField="fet" dataType="string"></dxi-column>
	<dxi-column [caption]="L('GENERATOR')" dataField="generator" dataType="string"></dxi-column>
	<dxi-column [caption]="L('GENERATOR_CUI')" dataField="cif" dataType="string"></dxi-column>
	<dxi-column [caption]="L('GENERATOR_WORKSTATION')" dataField="generatorWorkCenter" dataType="string">
	</dxi-column>
	<dxi-column [caption]="L('GENERATOR_COUNTY')" dataField="generatorWkCounty" dataType="string">
	</dxi-column>
	<dxi-column [caption]="L('RECIPIENT')" dataField="recipient" dataType="string"></dxi-column>
	<dxi-column [caption]="L('CARRIER')" dataField="transporter" dataType="string"></dxi-column>
	<dxi-column [caption]="L('REGISTER_NO')" dataField="carRegisterNo" dataType="string">
	</dxi-column>
	<dxi-column [caption]="L('DRIVER')" dataField="driverLastName" dataType="string"
		cellTemplate="driverNameTemplate">
		<div *dxTemplate="let data of 'driverNameTemplate'">
			{{data.data.driverLastName}} {{data.data.driverFirstName}}
		</div>
	</dxi-column>
	<dxi-column [caption]="L('INVOICE_DATE')" dataField="invoiceDate" dataType="date" calculateSortValue="date"
		[calculateDisplayValue]="iui.formatDate"></dxi-column>
	<dxi-column [caption]="L('INVOICE_NO')" dataField="invoiceNo" dataType="string"></dxi-column>
	<dxi-column [caption]="L('OBSERVATIONS')" dataField="observations" dataType="string"></dxi-column>
</dx-tree-list>