<app-tax-analyzes-filter></app-tax-analyzes-filter>
<div class="analyzesChart">
    <app-graph-line [txt]="{ title: L('TAXES'), description: edit.result.totalTaxText }" [typeValue]="edit.result.tax"
        [typeOp]="edit.typeOp" [uom]="null" [intervalDate]="edit.result.intervalPeriod"></app-graph-line>
</div>

<div class="analyzesGrid">
    <dx-data-grid [columnAutoWidth]="true" [dataSource]="edit.data" [allowColumnResizing]="true"
        [allowColumnReordering]="true" [showRowLines]="false" height="100%" [remoteOperations]="true"
        (onToolbarPreparing)="toolbarPreparing($event, false)">

        <dxo-scrolling [mode]="ui.scrollingMode" [showScrollbar]="ui.showScrollbar" [useNative]="!ui.isWindows">
        </dxo-scrolling>
        <dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" storageKey="qhseTaxAnalyzes">
        </dxo-state-storing>
        <dxo-export [enabled]="ui.exportEnabled" fileName="taxes"></dxo-export>

        <dxi-column [caption]="L('PAYMENT_DATE')" dataField="t_payDate" dataType="date" width="170"
            calculateSortValue="t_payDate" [calculateCellValue]="iui.formatDate"></dxi-column>
        <dxi-column [caption]="L('WORK_CENTER')" dataField="fullName" dataType="string"></dxi-column>
        <dxi-column [caption]="L('AGENCY')" dataField="a_name" dataType="string"></dxi-column>
        <dxi-column [caption]="L('PAID')" dataField="t_value" dataType="number"></dxi-column>
    </dx-data-grid>
</div>