import { Injectable } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import { iBisLanguageService } from '@dohu/ibis-common';
import { DataService } from '../../../service/data.service';
import { UnitEditService } from './../unit/unit-edit.service';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import { GeographicAreaEditService } from '../../enum/geographic-area/geographic-area-edit.service';

@Injectable()
export class PartyEditService extends UnitEditService {

	public ownUnit: DataSource;
	public ownEntity: DataSource;
	public ownWorkCenter: DataSource;
	public allUnit: DataSource;
	public allDestination: DataSource;
	public allGeneration: DataSource;
	public allTransporter: DataSource;
	public allEntity: DataSource;
	public allWorkCenter: DataSource;
	public otherUnit: DataSource;
	public otherEntity: DataSource;
	public otherWorkCenter: DataSource;
	public otherGeneration: DataSource;
	public otherDestination: DataSource;
	public businessUnit: DataSource;
	public contractor: DataSource;
	public subContractor: DataSource;
	public contractType: string;

	private defaultParty = { bu: null, ent: null, wc: null };

	constructor(entity: iBisEntityService, ds: DataService, geo: GeographicAreaEditService, lg: iBisLanguageService) {
		super(entity, ds, geo, lg);
		this.title = 'PARTNER';
		this.validation = 'partyValidation';

		this.data = this.getData([3]);
		this.ownUnit = this.getPartyLink([1, 2]);
		this.ownEntity = this.getPartyLink([1]);
		this.ownWorkCenter = this.getPartyLink([2]);
		this.allUnit = this.getPartyLink([1, 2, 3, 4]);
		this.allEntity = this.getPartyLink([1, 3]);
		this.allWorkCenter = this.getPartyLink([2, 4]);
		this.allDestination = this.getPartyLink([2, 4], 58);
		this.allGeneration = this.getPartyLink([2, 4], 1);
		this.allTransporter = this.getPartyLink([1, 3], 4);
		this.otherUnit = this.getPartyLink([3, 4]);
		this.otherEntity = this.getPartyLink([3]);
		this.otherWorkCenter = this.getPartyLink([4]);
		this.otherGeneration = this.getPartyLink([4], 1);
		this.otherDestination = this.getPartyLink([4], 58);
		this.businessUnit = this.getPartyLink([0]);
		this.contractor = this.getContractor(false);
		this.subContractor = this.getContractor(true);
	}

	public set types(mask: number) {
		this.data = this.getData([3], mask);
	}

	public getContract(partyId: any): CustomStore {
		const q = new EntityQuery('ContractView').eq('c.partyId', partyId);
		if (this.contractType) {
			q.eq('et.code', this.contractType);
		}
		return this.entity.store(q, false, 'c_id');
	}

	public getContractor(isSubcontractor: boolean): DataSource {
		const q = new EntityQuery('PartyLinkView').eq('c.isActiv', true).eq('c.typeId', 3)
			.linkEq('c.id', 'id', 'Party', 'isSubcontractor', isSubcontractor).addOrderBy(['fullName']);
		q.fields.push('c.id', 'fullName');
		return new DataSource({ store: this.entity.store(q, false, 'c.id'), paginate: true, pageSize: 10 });
	}

	public getPartyLink(types: number[], activity: number = null, exceptId: string = null, isSubcontractor: boolean = null): DataSource {
		const q = new EntityQuery('PartyLinkView').eq('c.isActiv', true).in('c.typeId', types).addOrderBy(['fullName']);
		if (exceptId) {
			q.neq('c_id', exceptId);
		}
		if (activity) {
			q.bit('c.activities', activity);
		}
		q.fields.push('c.id', 'fullName');
		return new DataSource({ store: this.entity.store(q, false, 'c.id'), paginate: false, pageSize: 100 });
	}

	public hasPartyLink(types: number[], activity: number = null, partyId: string = null): Promise<boolean> {
		const q = new EntityQuery('PartyLinkView').eq('c.isActiv', true).eq('c_id', partyId).in('c.typeId', types);
		if (activity) {
			q.bit('c.activities', activity);
		}

		return new Promise<boolean>((resolve, reject) => {
			this.entity.count(q).then((r: number) => {
				if(r > 0){
					resolve(true);
				} else{
					resolve(false);
				}
			}, error => reject(false));
		});
	}

	public getPartyAccess(userId: string): CustomStore {
		const q = new EntityQuery('UserLoginParty').eq('userId', userId).addOrderBy(['id']);
		q.fields.push('partyId');
		return this.entity.store(q);
	}

	public lookUpDataSource(dataSource: DataSource) {
		return dataSource.load();
	}

	createDefault(): any {
		return { isActiv: true, isSubcontractor: false, typeId: 3 };
	}

	public set defaultBusinessUnit(buId: string) {
		this.defaultParty.bu = buId;
	}

	public set defaultEntity(entityId: string) {
		this.defaultParty.ent = entityId;
	}

	public set defaultWorkcenter(workCenterId: string) {
		this.defaultParty.wc = workCenterId;
	}

	public get defaultBusinessUnit() {
		return this.defaultParty.bu;
	}

	public get defaultEntity() {
		return this.defaultParty.ent;
	}

	public get defaultWorkcenter() {
		return this.defaultParty.wc;
	}

	protected getData(typeId: number[], mask: number = null): CustomStore {
		const q = new EntityQuery('PartyView').in('p.typeId', typeId).addOrderBy(['p.name']);
		if (mask) {
			q.bit('p.activities', mask);
		}
		return this.entity.store(q, false, 'p_id');
	}
}
