<dx-data-grid #gridAgency class="gridContainer box-shadow" [dataSource]="edit.data" [height]="ui.fullHeight"
	(onToolbarPreparing)="toolbarPreparing($event, proxy.allowEdit)">

	<dxo-scrolling [mode]="ui.scrollingMode" [showScrollbar]="ui.showScrollbar" [useNative]="!ui.isWindows">
	</dxo-scrolling>
	<dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" [storageKey]='key'>
	</dxo-state-storing>

	<dxi-column type="buttons" fixedPosition="left" [minWidth]="proxy.actionsWidth" [width]="proxy.actionsWidth"
		[fixed]="true" [allowResizing]="false" [showInColumnChooser]="false" [visible]="proxy.showActions">
		<dxi-button [hint]="L('EDIT')" icon="edit" [onClick]="onEditClick" [visible]="proxy.allowEdit">
		</dxi-button>
		<dxi-button [hint]="L('DELETE')" icon="trash" [onClick]="onDeleteClick" [visible]="proxy.allowDelete">
		</dxi-button>
	</dxi-column>

	<dxi-column [caption]="L('NAME')" dataField="a_name" dataType="string"></dxi-column>
	<dxi-column name='adress' headerCellTemplate='adressHeader'>
		<dxi-column [caption]="L('REGION')" dataField="reg_name" dataType="string"></dxi-column>
		<dxi-column [caption]="L('COUNTY')" dataField="co_name" dataType="string"></dxi-column>
		<dxi-column [caption]="L('ADDRESS')" dataField="a_address" dataType="string"></dxi-column>
	</dxi-column>
	<dxi-column name='contact' headerCellTemplate='contactHeader'>
		<dxi-column [caption]="L('PHONE')" dataField="a_phone" dataType="string"></dxi-column>
		<dxi-column [caption]="L('FAX')" dataField="a_fax" dataType="string"></dxi-column>
		<dxi-column [caption]="L('EMAIL_ADDRESS')" dataField="a_email" dataType="string"></dxi-column>
		<dxi-column [caption]="L('WEBSITE')" dataField="a_website" dataType="string"></dxi-column>
	</dxi-column>

	<div *dxTemplate="let c of 'adressHeader'" class="dx-datagrid-text-content" role="presentation">
		<i class="fas fa-map-marked-alt"></i> Adresa
	</div>
	<div *dxTemplate="let c of 'contactHeader'" class="dx-datagrid-text-content" role="presentation">
		<i class="fas fa-address-card"></i> Contact
	</div>
	<div *dxTemplate="let data of 'breadcrumb'">
		<ibis-breadcrumb></ibis-breadcrumb>
	</div>
</dx-data-grid>

<ibis-edit-popup [edit]="edit" [showAdd]="true">
	<app-agency-edit></app-agency-edit>
</ibis-edit-popup>