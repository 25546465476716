import { NgModule } from '@angular/core';
import { iBisCommonModule } from '@dohu/ibis-common';
import { BrowserModule } from '@angular/platform-browser';
import { DxFormModule } from 'devextreme-angular/ui/form';
import { DxPopupModule } from 'devextreme-angular/ui/popup';
import { iBisNavigationModule } from '@dohu/ibis-navigation';
import { FrameworkModule } from '../../framework/framework.module';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { EnumTypeComponent } from './enum-type/enum-type.component';
import { EnumValueComponent } from './enum-type/enum-value.component';
import { EnumTypeEditService } from './enum-type/enum-type-edit.service';
import { EnumTypeEditComponent } from './enum-type/enum-type-edit.component';
import { GeographicAreaComponent } from './geographic-area/geographic-area.component';
import { GeographicAreaEditService } from './geographic-area/geographic-area-edit.service';
import { GeographicAreaEditComponent } from './geographic-area/geographic-area-edit.component';

@NgModule({
	declarations: [
		EnumTypeComponent,
		EnumValueComponent,
		EnumTypeEditComponent,
		GeographicAreaComponent,
		GeographicAreaEditComponent
	],
	imports: [
		DxFormModule,
		BrowserModule,
		DxPopupModule,
		FrameworkModule,
		DxDataGridModule,
		iBisCommonModule,
		iBisNavigationModule
	],
	exports: [
		EnumTypeComponent,
		EnumValueComponent,
		EnumTypeEditComponent,
		GeographicAreaComponent,
		GeographicAreaEditComponent
	],
	providers: [
		EnumTypeEditService,
		GeographicAreaEditService
	]
})
export class EnumModule { }
