import { Component } from '@angular/core';
import { iBisViewComponent } from '@dohu/ibis-common';
import { LocationEditService } from './location-edit.service';
import { ProxyService } from './../../../service/proxy.service';

@Component({
	selector: 'app-location',
	templateUrl: './location.component.html'
})
export class LocationComponent extends iBisViewComponent {

	public edit: LocationEditService;
	constructor(public proxy: ProxyService) {
		super(proxy.location, proxy.lg, proxy.iui, proxy.activatedRoute);
	}
}
