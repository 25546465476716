<div class="globalContainer box-shadow">
	<dx-tab-panel height="calc(100vh - 158px)">
		<dxi-item [title]="L('ENVIRONMENTAL_PERMITS')">
			<dx-data-grid #gridAuth [columnAutoWidth]="true" [allowColumnResizing]="true" [allowColumnReordering]="true"
				[height]="ui.gridTabsHeight" [dataSource]="edit.permits">
				<dxo-scrolling [mode]="ui.scrollingMode" [showScrollbar]="ui.showScrollbar" [useNative]="!ui.isWindows">
				</dxo-scrolling>

				<dxi-column [caption]="L('ENVIRONMENTAL_PERMIT_NUMBER')" dataField="p_no" dataType="string">
				</dxi-column>
				<dxi-column [caption]="L('ENT_WORKC')" dataField="fullName" dataType="string">
				</dxi-column>
				<dxi-column [caption]="L('VALIDITY')" dataField="p_validity" dataType="date" format="dd.MM.yyyy">
				</dxi-column>
				<dxi-column [caption]="L('REVISED_ON_DATE')" dataField="p_revision" dataType="date" format="dd.MM.yyyy">
				</dxi-column>
				<dxi-column [caption]="L('DESCRIPTION')" dataField="p_description" dataType="string">
				</dxi-column>
			</dx-data-grid>
		</dxi-item>
		<dxi-item [title]="L('INTEGRATED_ENV_AUTH')">
			<dx-data-grid #gridIntAuth [columnAutoWidth]="true" [allowColumnResizing]="true"
				[allowColumnReordering]="true" [height]="ui.gridTabsHeight" [dataSource]="edit.permitsInt">
				<dxo-scrolling [mode]="ui.scrollingMode" [showScrollbar]="ui.showScrollbar" [useNative]="!ui.isWindows">
				</dxo-scrolling>

				<dxi-column [caption]="L('ENVIRONMENTAL_PERMIT_NUMBER')" dataField="p_no" dataType="string">
				</dxi-column>
				<dxi-column [caption]="L('ENT_WORKC')" dataField="fullName" dataType="string">
				</dxi-column>
				<dxi-column [caption]="L('VALIDITY')" dataField="p_validity" dataType="date" format="dd.MM.yyyy">
				</dxi-column>
				<dxi-column [caption]="L('REVISED_ON_DATE')" dataField="p_revision" dataType="date" format="dd.MM.yyyy">
				</dxi-column>
				<dxi-column [caption]="L('DESCRIPTION')" dataField="p_descriere" dataType="string">
				</dxi-column>
			</dx-data-grid>
		</dxi-item>
		<dxi-item [title]="L('CONTRACTS')">
			<dx-data-grid #gridaContracts [columnAutoWidth]="true" [allowColumnResizing]="true"
				[allowColumnReordering]="true" [height]="ui.gridTabsHeight" [dataSource]="edit.contracts">
				<dxo-scrolling [mode]="ui.scrollingMode" [showScrollbar]="ui.showScrollbar" [useNative]="!ui.isWindows">
				</dxo-scrolling>

				<dxi-column [caption]="L('CONTRACT_NO')" dataField="c_no" dataType="string"></dxi-column>
				<dxi-column [caption]="L('CONTRACTANT')" dataField="fullName" dataType="string">
				</dxi-column>
				<dxi-column [caption]="L('START_DATE')" dataField="c_startDate" dataType="date" format="dd.MM.yyyy">
				</dxi-column>
				<dxi-column [caption]="L('END_DATE')" dataField="c_endDate" dataType="date" format="dd.MM.yyyy">
				</dxi-column>
				<dxi-column [caption]="L('OBSERVATIONS')" dataField="c_observations" dataType="date"
					format="dd.MM.yyyy">
				</dxi-column>
			</dx-data-grid>
		</dxi-item>
		<dxi-item [title]="L('TRANSPORTS')">
			<dx-data-grid #gridTransports [columnAutoWidth]="true" [allowColumnResizing]="true"
				[allowColumnReordering]="true" [height]="ui.gridTabsHeight" [dataSource]="edit.transports">
				<dxo-scrolling [mode]="ui.scrollingMode" [showScrollbar]="ui.showScrollbar" [useNative]="!ui.isWindows">
				</dxo-scrolling>

				<dxi-column [caption]="L('NO')" dataField="t_no" dataType="string">
				</dxi-column>
				<dxi-column [caption]="L('ENT_WORKC')" dataField="fullName" dataType="string">
				</dxi-column>
				<dxi-column [caption]="L('TRANSPORT_DATE')" dataField="t_sendDate" dataType="date" format="dd.MM.yyyy">
				</dxi-column>
				<dxi-column [caption]="L('CARRIER')" dataField="tr_name" dataType="string"></dxi-column>
				<dxi-column [caption]="L('RECIPIENT')" dataField="rt_name" dataType="string"></dxi-column>
			</dx-data-grid>
		</dxi-item>
		<dxi-item [title]="L('MEASURES')">
			<!-- <dx-data-grid #gridMeasure [columnAutoWidth]="true" [allowColumnResizing]="true"
				[allowColumnReordering]="true" [height]="ui.gridTabsHeight" [dataSource]="controlMeasures">
				<dxo-scrolling [mode]="ui.scrollingMode" [showScrollbar]="ui.showScrollbar" [useNative]="!ui.isWindows">
				</dxo-scrolling>
				<dxo-editing mode="row" [allowUpdating]="proxy.allowEdit"></dxo-editing>

				<dxi-column [caption]="L('MEASURE_DESCRIPTION')" dataField="actionDescription" dataType="string">
					<dxi-validation-rule type="required"></dxi-validation-rule>
				</dxi-column>
				<dxi-column [caption]="L('STATUS')" dataField="actionStatus" dataType="number">
					<dxi-validation-rule type="required"></dxi-validation-rule>
					<dxo-lookup [dataSource]="edit.actionStatus" valueExpr="id" displayExpr="value"></dxo-lookup>
				</dxi-column>
				<dxi-column [caption]="L('MEASURE_TERM')" dataField="termAction" dataType="date" format="dd.MM.yyyy">
					<dxi-validation-rule type="required"></dxi-validation-rule>
				</dxi-column>
				<dxi-column [caption]="L('OBSERVATIONS')" dataField="notifications" dataType="string">
				</dxi-column>
			</dx-data-grid> -->
		</dxi-item>
	</dx-tab-panel>
</div>