import { Injectable } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import { DataService } from '../../service/data.service';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import { iBisEditService, iBisLanguageService } from '@dohu/ibis-common';
import { PartyEditService } from '../party/party-edit/party-edit.service';

@Injectable()
export class ContractEditService extends iBisEditService {
	// TODO: check if we still use role
	// public role: { id: number; value: string; };

	public data: CustomStore;
	public clients: DataSource;
	public type: CustomStore;
	private pt: any;
	private cd: string;

	constructor(private entity: iBisEntityService, private ds: DataService, private party: PartyEditService, lg: iBisLanguageService) {
		super(lg);
		this.title = 'CONTRACT';
		this.validation = 'contractValidation';
	}

	public getData(type: string = null, activities: number[] = null): CustomStore {
		const q = new EntityQuery('ContractView').addOrderByDesc(['c.startDate']);
		if (type !== null) {
			const qq = new EntityQuery('EnumValue').addOrderBy(['orderIndex', 'code']).
				linkEq('typeId', 'id', 'EnumType', 'code', type);
			q.link('c.typeId', 'id', qq);
		}
		// TODO: DON'T COPY THIS CODE FROM PARTY SERVICE, FIND ANOTHER SOLUTION
		if (activities !== null) {
			const qqq = new EntityQuery('PartyLinkView').eq('c.isActiv', true).in('c.typeId', [3, 4]).addOrderBy(['fullName']);
			// // const act = this.party.getActivityTypeKeys(activities);
			// const group = new EntityConditionGroup();
			// for (const item of act) {
			// 	group.like('c.activitiesType', '%' + item + '%');
			// }
			// group.useOr = true;
			// qqq.conditionGroups.groups.push(group);
			// q.link('c.partyId', 'c.id', qqq);
		}
		return this.entity.store(q, false, 'c_id');
	}

	public set code(ctrCode: string) {
		this.cd = ctrCode;
		this.type = this.getType();
	}

	public get partyType(): any {
		return this.pt;
	}

	public set partyType(partyType: any) {
		this.pt = partyType;
		this.data = this.getData(this.cd, this.pt);
		this.clients = this.party.getPartyLink([3, 4], this.pt);
	}

	public get contractTypeKeys() {
		return this.ds.static.contractTypeKeys;
	}

	public get activityTypeKeys() {
		return this.ds.static.activityTypeKeys;
	}

	public getContractParty(contractId: string): CustomStore {
		const q = new EntityQuery('ContractPartyView').eq('cp.contractId', contractId).addOrderBy(['p.name']);
		return this.entity.store(q, false, 'cp_id');
	}

	createDefault(): any {
		if (this && this.ds && this.ds.defaultParty) {
			return {
				isValid: true,
				partyId: this.ds.defaultParty.ent,
				startDate: new Date()
			};
		} else {
			return {
				isValid: true,
				startDate: new Date()
			};
		}
	}

	getById(id: any): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			if (!id) {
				reject();
			}
			this.entity.getById('Contract', id).then((result) => {
				if (!result) {
					reject();
				} else {
					// result.role = this.role.value;
					resolve(result);
				}
			}, error => {
				this.lg.showError(error);
			});
		});
	}

	onRemove(id: string): Promise<void> {
		return this.entity.remove('Contract', id);
	}

	onSaveEv(): Promise<any> {
		// if (this.model.role) {
		// 	this.entity.execute('AddActivity', { id: this.model.partyId, role: this.model.role });
		// }
		return this.entity.save('Contract', this.model.id, this.model);
	}

	reset(): void {
		this.model = this.createDefault();
	}

	private getType(): CustomStore {
		const q = new EntityQuery('EnumValue').addOrderBy(['orderIndex', 'code']).
			linkEq('typeId', 'id', 'EnumType', 'code', this.cd);
		q.fields.push('id', 'code', 'description');
		return this.entity.store(q, false, 'id');
	}
}
