import { Component } from '@angular/core';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { ProjectEditService } from './project-edit.service';
import { ProxyService } from '../../../service/proxy.service';

@Component({
	selector: 'app-project-edit',
	templateUrl: './project-edit.component.html'
})
export class ProjectEditComponent extends iBisBaseComponent {
	public edit: ProjectEditService;

	constructor(public proxy: ProxyService) {
		super(proxy.project, proxy.lg, proxy.iui, proxy.activatedRoute);
	}

	dateComparison = () => this.edit.model.startDate;
}
