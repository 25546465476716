<dx-data-grid #gridTransportHistory class="gridContainer" [height]="ui.fullHeight" [dataSource]="edit.data" [remoteOperations]="true" 
	(onCellPrepared)="onTransportHistoryCellPreparedEv($event)" (onToolbarPreparing)="toolbarPreparing($event, false)">

    <dxo-export [enabled]="ui.exportEnabled" fileName="transporturi_finalizare"></dxo-export>
    <dxo-scrolling [mode]="ui.scrollingMode" [showScrollbar]="ui.showScrollbar" [useNative]="!ui.isWindows">
    </dxo-scrolling>
    <dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" [storageKey]="'qhseTransportHistory' +
        edit.wasteType">
    </dxo-state-storing>
    <dxo-master-detail [enabled]="true" template="details"></dxo-master-detail>

    <dxi-column type="buttons" [width]="120" fixedPosition="left" [fixed]="true" [allowResizing]="false"
        [showInColumnChooser]="false">
        <dxi-button [hint]="L('NOTICE')" icon="doc" [onClick]="onNoticeClick"> </dxi-button>
        <dxi-button [hint]="L('ANNEX')" icon="fieldchooser" [onClick]="onAnnexClick"></dxi-button>
        <dxi-button [hint]="L('EDIT')" icon="edit" [onClick]="onEditClick" [visible]="proxy.allowEdit">
        </dxi-button>
        <dxi-button [hint]="L('DELETE')" icon="trash" [onClick]="onDeleteClick" [visible]="proxy.allowDelete">
        </dxi-button>
    </dxi-column>

    <dxi-column name='sender' headerCellTemplate='senderHeader'>
        <dxi-column [caption]="L('NO')" dataField="t_no" dataType="string">
        </dxi-column>
        <dxi-column [caption]="L('LADING_BILL')" dataField="t_ladingBill" dataType="string"></dxi-column>
        <dxi-column [caption]="L('ENT_WORKC')" dataField="fullName" dataType="string"></dxi-column>
    </dxi-column>
    <dxi-column name='transporter' headerCellTemplate='transHeader'>
        <dxi-column [caption]="L('TRANSPORT_DATE')" dataField="t_sendDate" dataType="datetime"
            calculateSortValue="t_sendDate" [calculateDisplayValue]="iui.formatDate"></dxi-column>
        <dxi-column [caption]="L('REGISTER_NO')" dataField="car_registerNo" dataType="string"></dxi-column>
        <dxi-column [caption]="L('CARRIER')" dataField="tr_name" dataType="string"></dxi-column>
    </dxi-column>

    <dxi-column [caption]="L('RECIPIENT')" dataField="rt_name" dataType="string"></dxi-column>
    <dxi-column name='waste' headerCellTemplate='wasteHeader'>
        <dxi-column [caption]="L('WASTE_CODE')" dataField="wt_code" dataType="string">
        </dxi-column>
        <dxi-column [caption]="L('CATEGORY')" dataField="m_description" dataType="string"></dxi-column>
        <dxi-column [caption]="L('U')" dataField="uom" dataType="number">
            <dxo-lookup [dataSource]="proxy.wasteType.uom" valueExpr="id" displayExpr="value"></dxo-lookup>
        </dxi-column>
        <dxi-column [caption]="L('QUANTITY')" dataField="t_quantity" dataType="number"></dxi-column>
        <dxi-column [caption]="L('PIECE')" dataField="t_piece" dataType="number" [visible]="edit.wasteType == '2'">
        </dxi-column>
        <dxi-column [caption]="L('ESTIMATED')" dataField="t_estimated" dataType="string">
            <dxo-lookup [dataSource]="proxy.generate.evaluation" valueExpr="id" displayExpr="value"> </dxo-lookup>
        </dxi-column>
    </dxi-column>
    <dxi-column name='administration' headerCellTemplate='aHeader'>
        <dxi-column [caption]="L('VALORIZED_QUANTITY')" dataField="t_valuedQuantity" dataType="number"></dxi-column>
        <dxi-column [caption]="L('ELIMINATED_QUANTITY')" dataField="t_eliminatedQuantity" dataType="number">
        </dxi-column>
        <dxi-column [caption]="L('QUANTITY_RECYCLED')" dataField="t_recycledQuantity" dataType="number"></dxi-column>
        <dxi-column [caption]="L('EXPORTED_QUANTITY')" dataField="t_exportedQuantity" dataType="number"></dxi-column>
        <dxi-column [caption]="L('REUSED_QUANTITY')" dataField="t_reusedQuantity" dataType="number"></dxi-column>

        <dxi-column [caption]="L('TEMP_STO_DATE')" dataField="t_tempStockDate" dataType="datetime"
            calculateSortValue="t_tempStockDate" [calculateDisplayValue]="iui.formatDate"></dxi-column>
        <dxi-column [caption]="L('TRANS_COST')" dataField="t_shippingCost" dataType="number"></dxi-column>
        <dxi-column [caption]="L('DATE_RECEPTION')" dataField="t_receptionDate" dataType="datetime"
            calculateSortValue="t_receptionDate" [calculateDisplayValue]="iui.formatDate"></dxi-column>
        <dxi-column [caption]="L('FINISH_DATE')" dataField="t_finishDate" dataType="datetime"
            calculateSortValue="t_finishDate" [calculateDisplayValue]="iui.formatDate"></dxi-column>
    </dxi-column>
	<dxi-column [caption]="L('OBSERVATIONS')" dataField="t_observations" dataType="string"></dxi-column>
    <dxi-column name='audit' headerCellTemplate='auditHeader'>
        <dxi-column [caption]="L('MADE_BY')" dataField="ti_createdBy" dataType="string"></dxi-column>
        <dxi-column [caption]="L('MODIFIED_BY')" dataField="ti_modifiedBy" dataType="string"></dxi-column>
        <dxi-column [caption]="L('CREATION_DATE')" dataField="ti_created" dataType="datetime"
            calculateSortValue="ti_created" [calculateDisplayValue]="iui.formatDateTime"></dxi-column>
        <dxi-column [caption]="L('LAST_MODIFIED')" dataField="ti_modified" dataType="datetime"
            calculateSortValue="ti_modified" [calculateDisplayValue]="iui.formatDateTime"></dxi-column>
    </dxi-column>
    <div *dxTemplate="let data of 'details'">
        <ibis-file [entityId]="data.data.t_id" [allowDelete]="proxy.allowDelete" [allowUpload]="proxy.allowEdit"></ibis-file>
    </div>
    <div *dxTemplate="let data of 'breadcrumb'">
        <ibis-breadcrumb></ibis-breadcrumb>
    </div>

    <div *dxTemplate="let c of 'senderHeader'" class="dx-datagrid-text-content" role="presentation">
        <i class="fas fa-file"></i> Expeditor
    </div>
    <div *dxTemplate="let c of 'transHeader'" class="dx-datagrid-text-content" role="presentation">
        <i class="fas fa-truck-moving"></i> Transportator
    </div>
    <div *dxTemplate="let c of 'wasteHeader'" class="dx-datagrid-text-content" role="presentation">
        <i class='fas fa-recycle'></i> Deseu
    </div>
    <div *dxTemplate="let c of 'auditHeader'" class="dx-datagrid-text-content" role="presentation">
        <i class='far fa-calendar-alt'></i> Audit
    </div>
    <div *dxTemplate="let c of 'aHeader'" class="dx-datagrid-text-content" role="presentation">
        <i class="fas fa-dumpster"></i>Administrare deseu
    </div>
</dx-data-grid>

<ibis-edit-popup [edit]="edit" [showAdd]="false">
    <app-transport-finish></app-transport-finish>
</ibis-edit-popup>