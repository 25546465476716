import { Component, ViewChild } from '@angular/core';
import { InvoiceEditService } from './invoice-edit.service';
import { iBisViewComponent } from '@dohu/ibis-common';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { ProxyService } from '../../service/proxy.service';

@Component({
	templateUrl: 'invoice.component.html'
})
export class InvoiceComponent extends iBisViewComponent {
	@ViewChild('gridInvoices') grid: DxDataGridComponent;
	public edit: InvoiceEditService;

	constructor(public proxy: ProxyService) {
		super(proxy.invoice, proxy.lg, proxy.iui, proxy.activatedRoute);
		this.id = 'i_id';
		this.key = 'invoice';
	}
}

