<div class="flex-row">
	<div class="filter-form">
		<dx-form [formData]="edit.model">
			<dxi-item itemType="group" [colSpan]="1" [colCount]="4">
				<dxi-item [label]="{text: L('WORK_CENTER')}" [colSpan]="2">
					<dx-tag-box [dataSource]="proxy.party.ownWorkCenter" displayExpr="fullName" valueExpr="c_id"
						[multiline]="false" [searchEnabled]="true" [showSelectionControls]="true"
						placeholder="Selectează un punct de lucru..." selectAllMode="allPages"
						(onValueChanged)="onValueChanged($event)" [(value)]="edit.model.workCenter">
					</dx-tag-box>
				</dxi-item>

				<dxi-item [label]="{text: L('WASTE')}" [colSpan]="2">
					<dx-tag-box #wasteCodeTagBox [dataSource]="edit.wasteTypeByWorkCenter" displayExpr="wt_code"
						valueExpr="wtp_wasteTypeId" [multiline]="false" [searchEnabled]="true"
						[showSelectionControls]="true" placeholder="Selectează un cod de deșeu..."
						selectAllMode="allPages" [(value)]="edit.model.wasteType">
					</dx-tag-box>
				</dxi-item>
			</dxi-item>
			<dxi-item itemType="group" [colSpan]="1" [colCount]="5">
				<dxi-item [label]="{text: L('REPORT_TYPE')}" dataField="reportType" editorType="dxSelectBox"
					[editorOptions]="{ dataSource: edit.type, valueExpr: 'id', displayExpr: 'text', onValueChanged: onRaportTypeChanged }">
				</dxi-item>
				<dxi-item [label]="{text: L('INTERVAL')}" dataField="interval" editorType="dxSelectBox"
					[editorOptions]="{ dataSource: edit.interval, valueExpr: 'id', displayExpr: 'text', readOnly: edit.model.reportType == 1 }">
				</dxi-item>
				<dxi-item [label]="{text: L('FROM_DATE')}" dataField="fromDate" editorType="dxDateBox" [editorOptions]="{displayFormat: 'yyyy', width: 'auto', 
						calendarOptions: {minZoomLevel: 'decade', maxZoomLevel: 'decade' }}">
				</dxi-item>
				<dxi-item [label]="{text: L('UNTIL_DATE')}" dataField="thruDate" editorType="dxDateBox"
					[visible]="edit.model.reportType == 0"
					[editorOptions]="{displayFormat: 'yyyy', width: 'auto', calendarOptions: {minZoomLevel: 'decade', maxZoomLevel: 'decade' }}">
				</dxi-item>
				<dxi-item itemType="empty" [visible]="edit.model.reportType == 1"></dxi-item>
				<dxi-item [label]="{text: ''}" editorType="dxButton"
					[editorOptions]="{text:'Filtrează', onClick: edit.filterData}">
				</dxi-item>
			</dxi-item>
		</dx-form>
	</div>
</div>