import notify from 'devextreme/ui/notify';
import { Injectable } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { iBisEditService, iBisLanguageService } from '@dohu/ibis-common';
import { EntityQuery, EntityConditionGroup, iBisEntityService } from '@dohu/ibis-entity';

@Injectable({
	providedIn: 'root'
})
export class MonthlyStockEditService extends iBisEditService {
	public data: CustomStore;

	constructor(private entity: iBisEntityService, lg: iBisLanguageService) {
		super(lg);
		this.title = 'MONTHLY';
		this.validation = 'monthlyValidation';

		this.data = this.getData();
	}

	createDefault(): any {
		return { id: undefined };
	}
	getById(id: any, serverUrl?: string): Promise<any> {
		return new Promise((resolve, reject) => {
			this.entity.getById('MonthlyStock', id).then(result => {
				if (!result) {
					reject();
				} else {
					resolve(result);
				}
			}, error => this.lg.showError(error));
		});
	}

	onRemove(id: string): Promise<void> {
		return this.entity.remove('GenerateAdjustment', id);
	}
	onSaveEv(): Promise<any> {
		const obj = {
			year: this.model.year,
			month: this.model.month,
			adjustment: this.model.adjustment,
			wasteTypeId: this.model.wasteTypeId,
			workCenterId: this.model.workCenterId
		};

		return new Promise((resolve, reject) => {
			this.checkForAdjustment(obj).then((result: any) => {
				if (result && !this.model.id) {
					notify(this.L('REQUEST_EXIST', null), 'error', 3000);
					reject();
				} else {
					resolve(this.entity.save('GenerateAdjustment', this.model.id, obj));
				}
			}, error => this.lg.showError(error));
		});
	}
	reset(): void {
		this.model = this.createDefault();
	}

	private checkForAdjustment(obj: any) {
		return this.entity.single(new EntityQuery('GenerateAdjustment')
			.eq('month', obj.month).eq('year', obj.year).eq('wasteTypeId', obj.wasteTypeId));
	}

	private getData(): CustomStore {
		const q = new EntityQuery('MonthlyStock');
		const group = new EntityConditionGroup();
		group.gt('generated', 0).gt('valued', 0).gt('taken', 0).gt('inProgress', 0).
			gt('transfer', 0).gt('eliminated', 0).gt('adjustment', 0).gt('collected', 0);
		group.useOr = true;
		q.conditionGroups.groups.push(group);
		return this.entity.store(q.addOrderByDesc(['year', 'month']), false, 'id');
	}
}
