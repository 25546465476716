import { Injectable } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { DataService } from '../../../service/data.service';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import { iBisEditService, iBisLanguageService } from '@dohu/ibis-common';

@Injectable({
	providedIn: 'root'
})
export class ControlSanctionEditService extends iBisEditService {

	public status: { 'id': number; 'value': string }[];
	constructor(private entity: iBisEntityService, private ds: DataService, lg: iBisLanguageService) {
		super(lg);
		this.title = 'SANCTIONS';
		this.validation = 'SanctionValidation';

		this.status = this.getStatus();
	}

	public getData(controlId: any): CustomStore {
		return this.entity.store(new EntityQuery('ControlSanction').eq('controlId', controlId), false, 'id');
	}

	createDefault(): any {
		return {};
	}

	getById(id: string): Promise<any> {
		return new Promise((resolve, reject) => {
			this.entity.getById('ControlSanction', id).then(result => {
				if (!result) {
					reject();
				}
				resolve(result);
			}, error => {
				this.lg.showError(error);
			});
		});
	}

	onRemove(id: string): Promise<void> {
		return this.entity.remove('ControlSanction', id);
	}

	onSaveEv(serverUrl?: string): Promise<any> {
		return this.entity.save('ControlSanction', this.model.id, this.model);
	}

	reset(): void {
		this.model = this.createDefault();
	}

	private getStatus(): { 'id': number; 'value': string }[] {
		const list = [{ 'id': 0, 'value': 'RECEIVED' }, { 'id': 1, 'value': 'CHALLENGED' }, { 'id': 2, 'value': 'PAYED' }];
		return this.ds.getEnum('sanctionStatus', list);
	}
}
