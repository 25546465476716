import { TaxEditService } from './tax-edit.service';
import { Component, ViewChild } from '@angular/core';
import { ProxyService } from '../../../service/proxy.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { BreadcrumbViewComponent } from '../../../framework/breadcrumb-view.component';

@Component({
	templateUrl: 'tax.component.html'
})
export class TaxComponent extends BreadcrumbViewComponent {
	@ViewChild('gridTax') protected grid: DxDataGridComponent;

	public edit: TaxEditService;
	constructor(public proxy: ProxyService) {
		super(proxy.tax, proxy.lg, proxy.iui, proxy.activatedRoute);
		this.id = 't_id';
		this.key = 'tax';
	}
}
