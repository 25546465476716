<dx-data-grid class="gridContainer box-shadow" #gridScale [columnAutoWidth]="true" [allowColumnResizing]="true"
  [allowColumnReordering]="true" [dataSource]="edit.scale" [height]="ui.fullHeight" [remoteOperations]="true"
  (onToolbarPreparing)="toolbarPreparing($event, false)">

  <dxo-export [enabled]="ui.exportEnabled" fileName="scale"></dxo-export>
  <dxo-scrolling [mode]="ui.scrollingMode" [showScrollbar]="ui.showScrollbar" [useNative]="!ui.isWindows">
  </dxo-scrolling>
  <dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" storageKey="qhseScale"></dxo-state-storing>
  <dxo-column-fixing [enabled]="true"></dxo-column-fixing>

  <dxi-column type="buttons" [width]="120" fixedPosition="left" [fixed]="true" [allowResizing]="false"
    [showInColumnChooser]="false">
    <dxi-button [hint]="L('EDIT')" icon="edit" [onClick]="onEditTransportClick" [visible]="ds.auth.tabAccess.edit">
    </dxi-button>
    <dxi-button template="landingTemplate"></dxi-button>
    <dxi-button template="anexTemplate"></dxi-button>
  </dxi-column>

  <div *dxTemplate="let cell of 'landingTemplate'">
    <a *ngIf="cell.data.transportId " class="dx-link dx-icon-car" (click)="onNoticeClick(cell)" [title]="L('NOTICE')"></a>
  </div>
  <div *dxTemplate="let cell of 'anexTemplate'">
    <a *ngIf="cell.data.transportId && cell.data.hazard" style="color: red" class="dx-link dx-icon-doc"
      (click)="onRaportTransport(cell, 2)" [title]="L('ANNEX2')"></a>
    <a *ngIf="cell.data.transportId && !cell.data.hazard" style="color: green" class="dx-link dx-icon-doc"
      (click)="onRaportTransport(cell, 3)" [title]="L('ANNEX3')"></a>
  </div>

  <dxi-column [caption]="L('SCALE')" dataField="name" dataType="string"></dxi-column>
  <dxi-column [caption]="L('DATE')" dataField="scaleDate" dataType="date" minWidth="100" calculateSortValue="t_date"
    [calculateDisplayValue]="iui.formatDate">
  </dxi-column>
  <dxi-column [caption]="L('CARRIER')" dataField="transporterName" dataType="string"></dxi-column>
  <dxi-column [caption]="L('EVENTS')" dataField="succession" dataType="string"></dxi-column>
  <dxi-column [caption]="L('REGISTER_NO')" dataField="carNumber" dataType="string"></dxi-column>
  <dxi-column [caption]="L('DRIVER')" dataField="driverName" dataType="string"></dxi-column>
  <dxi-column [caption]="L('WASTE_CODE')" dataField="wasteCode" dataType="string"></dxi-column>
  <dxi-column [caption]="L('WASTE_TYPE')" dataField="hazard" dataType="string">
    <dxo-lookup [dataSource]="ds.static.hazard" valueExpr="id" displayExpr="value"></dxo-lookup>
  </dxi-column>
</dx-data-grid>