import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { iBisCommonModule } from '@dohu/ibis-common';
import { iBisNavigationModule } from '@dohu/ibis-navigation';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { DxDropDownBoxModule } from 'devextreme-angular/ui/drop-down-box';
import { DxFormModule } from 'devextreme-angular/ui/form';
import { DxListModule } from 'devextreme-angular/ui/list';
import { DxMultiViewModule } from 'devextreme-angular/ui/multi-view';
import { DxPopupModule } from 'devextreme-angular/ui/popup';
import { DxScrollViewModule } from 'devextreme-angular/ui/scroll-view';
import { DxTabPanelModule } from 'devextreme-angular/ui/tab-panel';
import { DxValidatorModule } from 'devextreme-angular/ui/validator';
import { FrameworkModule } from '../../framework/framework.module';
import { AgencyEditComponent } from './agency/agency-edit.component';
import { AgencyEditService } from './agency/agency-edit.service';
import { AgencyComponent } from './agency/agency.component';
import { PartyEditComponent } from './party-edit/party-edit.component';
import { PartyEditService } from './party-edit/party-edit.service';
import { PartyContractComponent } from './party-link/party-contract.component';
import { PartyLinkEditComponent } from './party-link/party-link-edit.component';
import { PartyLinkEditService } from './party-link/party-link-edit.service';
import { PartyLinkComponent } from './party-link/party-link.component';
import { PartyWizardComponent } from './party-wizard/party-wizard.component';
import { PartyWizardService } from './party-wizard/party-wizard.service';
import { PartyWorkCenterEditComponent } from './party-work-center/party-work-center-edit.component';
import { PartyWorkCenterEditService } from './party-work-center/party-work-center-edit.service';
import { PartyWorkCenterComponent } from './party-work-center/party-work-center.component';
import { PartyComponent } from './party.component';
import { PermitEditComponent } from './permit/permit-edit.component';
import { PermitEditService } from './permit/permit-edit.service';
import { PermitComponent } from './permit/permit.component';
import { CarComponent } from './transporter/car.component';
import { DriverComponent } from './transporter/driver.component';
import { TransporterEditComponent } from './transporter/transporter-edit.component';
import { TransporterEditService } from './transporter/transporter-edit.service';
import { TransporterComponent } from './transporter/transporter.component';
import { UnitEditComponent } from './unit/unit-edit.component';
import { UnitEditService } from './unit/unit-edit.service';
import { UnitKpiComponent } from './unit/unit-kpi.component';
import { UnitWasteEditComponent } from './unit/unit-waste-edit.component';
import { UnitWasteEditService } from './unit/unit-waste-edit.service';
import { UnitWasteComponent } from './unit/unit-waste.component';
import { UnitComponent } from './unit/unit.component';

@NgModule({
	declarations: [
		CarComponent,
		UnitComponent,
		PartyComponent,
		DriverComponent,
		AgencyComponent,
		PermitComponent,
		UnitKpiComponent,
		UnitEditComponent,
		UnitWasteComponent,
		PartyEditComponent,
		PartyLinkComponent,
		AgencyEditComponent,
		PermitEditComponent,
		TransporterComponent,
		PartyWizardComponent,
		UnitWasteEditComponent,
		PartyContractComponent,
		PartyLinkEditComponent,
		TransporterEditComponent,
		PartyWorkCenterComponent,
		PartyWorkCenterEditComponent
	],
	imports: [
		DxFormModule,
		DxListModule,
		BrowserModule,
		DxPopupModule,
		DxButtonModule,
		FrameworkModule,
		DxTabPanelModule,
		DxDataGridModule,
		iBisCommonModule,
		iBisNavigationModule,
		DxValidatorModule,
		DxMultiViewModule,
		DxScrollViewModule,
		DxDropDownBoxModule
	],
	exports: [
		CarComponent,
		UnitComponent,
		PartyComponent,
		AgencyComponent,
		DriverComponent,
		PermitComponent,
		UnitKpiComponent,
		UnitEditComponent,
		UnitWasteComponent,
		PartyLinkComponent,
		PartyEditComponent,
		AgencyEditComponent,
		PermitEditComponent,
		TransporterComponent,
		PartyWizardComponent,
		PartyContractComponent,
		PartyLinkEditComponent,
		UnitWasteEditComponent,
		TransporterEditComponent,
		PartyWorkCenterComponent,
		PartyWorkCenterEditComponent
	],
	providers: [
		UnitEditService,
		PartyEditService,
		AgencyEditService,
		PermitEditService,
		PartyWizardService,
		UnitWasteEditService,
		PartyLinkEditService,
		TransporterEditService,
		PartyWorkCenterEditService
	]
})
export class PartyModule { }
