<dx-data-grid class="gridContainer box-shadow" #gridSafetyReport [columnAutoWidth]="true" [height]="ui.fullHeight"
    (onToolbarPreparing)="toolbarPreparing($event)" (onCellPrepared)='onCellPrepared($event)' [dataSource]="edit.data" [remoteOperations]="true">

    <dxo-export [enabled]="true" fileName="Audit_report"></dxo-export>
    <dxo-scrolling [mode]="ui.scrollingMode" [showScrollbar]="ui.showScrollbar" [useNative]="!ui.isWindows">
    </dxo-scrolling>
    <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
    <dxo-master-detail [enabled]="true" template="details"></dxo-master-detail>

    <dxi-column type="buttons" fixedPosition='left' [minWidth]="ui.actionWidth" [width]="ui.actionWidth"
        [fixed]='true' [allowResizing]='false' [showInColumnChooser]='false'>
        <dxi-button [hint]='L("EDIT")' icon="edit" [onClick]="onEditClick"></dxi-button>
    </dxi-column>

    <dxi-column [caption]="L('NR_REPORT')" dataField="r_refNumber" dataType="number"></dxi-column>
    <dxi-column [caption]="L('DATE')" dataField="r_reportDate" dataType="datetime" calculateSortValue="r_reportDate"
        [calculateDisplayValue]="iui.formatDate" [minWidth]="100"></dxi-column>
    <dxi-column [caption]="L('PROFILE')" dataField="pr_name" dataType="string"></dxi-column>
    <dxi-column [caption]="L('ENT_WORKC')" dataField="fullName" dataType="string"></dxi-column>
    <dxi-column [caption]="L('COORDINATOR')" dataField="a_displayName" dataType="string"></dxi-column>
    <dxi-column [caption]="L('LOCATION')" dataField="l_name" dataType="string"></dxi-column>
    <dxi-column [caption]="L('STATE')" dataField='isOk' [calculateDisplayValue]='calculateIsOk' dataType='string' [width]="50">
    </dxi-column>
    <div *dxTemplate="let data of 'details'">
        <ibis-file [entityId]="data.data.r_id" [allowDelete]="proxy.allowDelete" [allowUpload]="proxy.allowEdit"></ibis-file>
    </div>
</dx-data-grid>

<ibis-edit-popup [edit]="edit" [showAdd]="true">
    <app-safety-report-edit></app-safety-report-edit>
</ibis-edit-popup>

<ibis-edit-popup [edit]="proxy.safetyReportWizard" [height]="500" [showAdd]="false">
    <app-safety-report-wizard></app-safety-report-wizard>
</ibis-edit-popup>
<ibis-edit-popup [title]="proxy.inconsistent.title" [edit]="proxy.inconsistent" [showAdd]="false">
    <app-inconsistent-edit></app-inconsistent-edit>
</ibis-edit-popup>