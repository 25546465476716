<dx-form #transportApprovalForm [formData]="edit.model" [showValidationSummary]="false" labelLocation="left"
	validationGroup="transportApprovalValidation" [colCount]="2">
	<dxi-item [label]="{text: L('GENERATOR')}" dataField="partyId" editorType="dxSelectBox" [colSpan]="2"
		[editorOptions]="{ dataSource: proxy.party.allGeneration, valueExpr: 'c_id', displayExpr: 'fullName', searchEnabled: true, onValueChanged: onWorkCenterChanged,
		 disabled: edit.used }">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('RECIPIENT')}" dataField="destinationId" editorType="dxSelectBox" [colSpan]="2"
		[editorOptions]="{ dataSource: proxy.party.allDestination, valueExpr: 'c_id', displayExpr: 'fullName', searchEnabled: true, 
		disabled: edit.used  }">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('WASTE_CODE')}" dataField="wasteTypeId" editorType="dxSelectBox" [colSpan]="2"
		[editorOptions]="{ dataSource: proxy.wasteType.hazardCode, valueExpr: 'id', displayExpr: 'code', disabled: edit.used, onInitialized: onWasteTypeInit, 
		onValueChanged: onWasteTypeChanged, searchEnabled: true}">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('NO')}" dataField="no" editorType="dxTextBox"
		[editorOptions]="{disabled: !edit.model.wasteTypeId, readOnly: edit.model.id != undefined}">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('DOCUMENT_TYPE')}" dataField="type" editorType="dxSelectBox"
		[editorOptions]="{dataSource: edit.type, valueExpr: 'id', displayExpr: 'value' }">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('APPROVED_QUANTITY')}" dataField="approvedQuantity" editorType="dxNumberBox"
		[helpText]="edit.model.quantityUsed > 0 ? (edit.model.quantityUsed + ' - ' + L('QUANTITY_USED')) : ''"
		[editorOptions]="{disabled: !edit.model.wasteTypeId, min: '0',
		buttons: [{name: 'uom', location: 'after', options: uomHelper.options }]}">
		<dxi-validation-rule type="required"></dxi-validation-rule>
		<dxi-validation-rule type="range" [min]='edit.model.quantityUsed'
			[message]="L('MIN_VALUE') + ' ' + edit.model.quantityUsed"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('APPROVED_TRANS_NO')}" dataField="approvedNo" editorType="dxNumberBox"
		[helpText]="edit.model.noUsed > 0 ? (edit.model.noUsed + ' - ' + L('TRANSPORT_USED')) : ''"
		[editorOptions]="{disabled: !edit.model.wasteTypeId, min: '1'}">
		<dxi-validation-rule type="required"></dxi-validation-rule>
		<dxi-validation-rule type="range" [min]='edit.model.noUsed'
			[message]="L('MIN_VALUE') + ' ' + edit.model.noUsed"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('START_DATE')}" dataField="startDate" editorType="dxDateBox" [editorOptions]="{ width: '100%', disabled: !edit.model.wasteTypeId,
    onValueChanged: onStartChange }">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('END_DATE')}" dataField="expireDate" editorType="dxDateBox"
		[editorOptions]="{ width: '100%', disabled: !edit.model.wasteTypeId }">
		<dxi-validation-rule type="required"></dxi-validation-rule>
		<dxi-validation-rule type="custom" [message]="L('END_DATE_TWO_YEARS_MAX')" [reevaluate]="true"
			[validationCallback]="endDateValidation">
		</dxi-validation-rule>
	</dxi-item>
</dx-form>