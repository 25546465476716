import { Component, ViewChild } from '@angular/core';
import { AgencyEditService } from './agency-edit.service';
import { ProxyService } from '../../../service/proxy.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { BreadcrumbViewComponent } from '../../../framework/breadcrumb-view.component';

@Component({
	templateUrl: 'agency.component.html'
})
export class AgencyComponent extends BreadcrumbViewComponent {
	@ViewChild('gridAgency') grid: DxDataGridComponent;

	public edit: AgencyEditService;
	constructor(public proxy: ProxyService) {
		super(proxy.agency, proxy.lg, proxy.iui, proxy.activatedRoute);
		this.id = 'a_id';
		this.key = 'agency';
	}
}
