import { iBisViewComponent } from '@dohu/ibis-common';
import { Component, Input, ViewChild } from '@angular/core';
import { ProxyService } from '../../../service/proxy.service';
import { PartyLinkEditService } from './party-link-edit.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';

@Component({
	selector: 'app-party-link',
	templateUrl: 'party-link.component.html'
})
export class PartyLinkComponent extends iBisViewComponent {
	@Input() partyId: any;
	@ViewChild('gridLink') grid: DxDataGridComponent;

	public edit: PartyLinkEditService;
	constructor(public proxy: ProxyService) {
		super(proxy.partyLink, proxy.lg, proxy.iui, proxy.activatedRoute);
		this.id = 'wtp_id';
		this.key = 'partyLink';
	}
	onGridInit(e: any) {
		if (e.component.option('dataSource') == null) {
			e.component.option('dataSource', this.edit.getLink(this.partyId));
		}
	}

	protected prepareNew(): any {
		const result = this.edit.createDefault();
		result.senderId = this.partyId;
		result.workCenterId = null;
		return result;
	}
}
