import { Params } from '@angular/router';
import { Component, ViewChild } from '@angular/core';
import { ProxyService } from '../../service/proxy.service';
import { TransferEditService } from './transfer-edit.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { BreadcrumbViewComponent } from '../../framework/breadcrumb-view.component';
import notify from 'devextreme/ui/notify';

@Component({
	selector: 'app-transfer',
	templateUrl: './transfer.component.html'
})
export class TransferComponent extends BreadcrumbViewComponent {
	@ViewChild('gridTransfer') grid: DxDataGridComponent;
	public onNoticeClick: any;
	public onAnnexClick: any;

	public edit: TransferEditService;
	constructor(public proxy: ProxyService) {
		super(proxy.transfer, proxy.lg, proxy.iui, proxy.activatedRoute);
		this.id = 't_id';
		this.key = 'transfer';

		this.onNoticeClick = this.onNotice.bind(this);
		this.onAnnexClick = this.onAnnex.bind(this);
	}

	public actionsWidth(): number {
		return (this.proxy.allowedActions + 2) * (this.iui.config.ui.actionWidth ?? 35);
	}

	protected queryParams(pr: Params): void {
		super.queryParams(pr);
		this.edit.wasteType = pr['wasteType'];
		this.edit.actions = pr['transportActions'];
	}

	protected onEdit(e: any) {
		this.proxy.wasteType.typeId = this.edit.wasteType;
		super.onEdit(e);
	}

	protected prepareNew(): any {
		this.proxy.wasteType.typeId = this.edit.wasteType;
		return super.prepareNew();
	}

	protected onAnnex(e: any) {
		this.proxy.transport.downloadAnnexBeta(e.row.data.t_id, e.row.data.wt_hazard);
	}

	protected onNotice(e: any) {
		if (e.row.data.t_ladingBill) {
			this.proxy.transport.downloadNoticeBeta(e.row.data.t_id);
		} else {
			notify(this.L('NO_LADINGBILL_FOR_DOWNLOAD_NOTE'), 'info', 3000);
		}
	}
}
