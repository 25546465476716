<dx-form [formData]="edit.model" [showValidationSummary]="false" labelLocation="left" [colCount]="2"
	[validationGroup]="edit.validation">
	<dxi-item [label]="{ text: L('FULL_NAME')}" dataField="p_name" editorType="dxTextBox" [editorOptions]="{ }">
		<dxi-validation-rule type="required"></dxi-validation-rule>
		<dxi-validation-rule type="stringLength"  [max]="256" [message]="L('NAME_LENGTH')">
		</dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{ text: L('FISCAL_CODE')}" editorType="dxTextBox" [editorOptions]="{ }">
	</dxi-item>
	<dxi-item [label]="{ text: L('COUNTY')}" editorType="dxTextBox" [editorOptions]="{ }">
	</dxi-item>
	<dxi-item [label]="{ text: L('LOCALITY')}" dataField="loc_name" editorType="dxTextBox" [editorOptions]="{ }">
	</dxi-item>
	<dxi-item [label]="{ text: L('ADDRESS')}" [colSpan]="2" dataField="p_address" editorType="dxTextBox"
		[editorOptions]="{ }">
	</dxi-item>
	<dxi-item [label]="{ text: L('AIM_NO')}" dataField="aim_no" editorType="dxTextBox" [editorOptions]="{ }">
	</dxi-item>
	<dxi-item [label]="{ text: L('EXPIRATION_DATE') + ' AIM' }" dataField="aim_validity" editorType="dxDateBox"
		[editorOptions]="{ width:'100%' }">
	</dxi-item>
	<dxi-item [label]="{ text: L('TRANSPORT_LICENSE')}" dataField="" editorType="dxTextBox" [editorOptions]="{ }">
	</dxi-item>
	<dxi-item [label]="{ text: L('EXPIRATION_DATE')}" dataField="" editorType="dxDateBox"
		[editorOptions]="{ width:'100%' }">
	</dxi-item>
</dx-form>