import { Params } from '@angular/router';
import { Component, ViewChild } from '@angular/core';
import { ProxyService } from '../../service/proxy.service';
import { ContractEditService } from './contract-edit.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { BreadcrumbViewComponent } from '../../framework/breadcrumb-view.component';

@Component({
	templateUrl: './contract.component.html'
})
export class ContractComponent extends BreadcrumbViewComponent {
	@ViewChild('gridContractType') grid: DxDataGridComponent;

	public edit: ContractEditService;
	constructor(public proxy: ProxyService) {
		super(proxy.contract, proxy.lg, proxy.iui, proxy.activatedRoute);
		this.id = 'c_id';
		this.key = 'contract';
	}

	onEditContractTypeClickEv(e: any) {
		e.event.preventDefault();
		// this.edit.role = this.edit.partyType ? null : this.edit.activityTypeKeys[this.edit.partyType];
		this.edit.showPopup(e.row.data, true).then(
			() => this.refresh(),
			(error) => this.lg.serverError(error)
		);
	}

	protected queryParams(params: Params): void {
		super.queryParams(params);
		this.edit.code = params['ctrCode'];
		this.edit.partyType = params['partyType'];
	}
}
