import { Injectable } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { DataService } from '../../service/data.service';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import { iBisEditService, iBisLanguageService } from '@dohu/ibis-common';

@Injectable({
	providedIn: 'root'
})
export class GeneralReportEditService extends iBisEditService {
	public data: CustomStore;
	public inOutType: { id: number; value: string }[];
	constructor(private entity: iBisEntityService, private ds: DataService, lg: iBisLanguageService) {
		super(lg);
		this.data = this.getData();

		this.inOutType = this.getInOutType();
	}
	createDefault(): any {
		return {};
	}
	getById(id: string, serverUrl?: string): Promise<any> {
		throw new Error('Method not implemented.');
	}
	onRemove(id: string): Promise<void> {
		throw new Error('Method not implemented.');
	}
	onSaveEv(serverUrl?: string): Promise<any> {
		throw new Error('Method not implemented.');
	}
	reset(): void {
		this.model = this.createDefault();
	}

	private getData(): CustomStore {
		const q = new EntityQuery('MirrorStock').addOrderBy(['date']);
		return this.entity.store(q, false, 'id');
	}

	private getInOutType(): { id: number; value: string }[] {
		const list = [
			{ 'id': 0, 'value': 'COLLECT' },
			{ 'id': 1, 'value': 'GENERATE' },
			{ 'id': 2, 'value': 'IN_TREATMENT_SORT' },
			{ 'id': 3, 'value': 'INCINERATE' },
			{ 'id': 4, 'value': 'DELIVERY_TO_CLIENTS' },
			{ 'id': 5, 'value': 'OUT_TREATMENT_SORT' }];
		return this.ds.getEnum('inOutType', list);
	}
}
