import { Params } from '@angular/router';
import { Component, ViewChild } from '@angular/core';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { ProxyService } from '../../service/proxy.service';
import { DxFormComponent } from 'devextreme-angular/ui/form';
import { ReportExtEditService } from './report-ext-edit.service';

@Component({
	templateUrl: 'report-ext.component.html'
})
export class ReportExtComponent extends iBisBaseComponent {
	@ViewChild('reportExt') reportExt: DxFormComponent;
	public edit: ReportExtEditService;
	reportObj: any;

	constructor(public proxy: ProxyService) {
		super(proxy.reportExt, proxy.lg, proxy.iui, proxy.activatedRoute);
	}

	onReportItemChangedEv = (ev: any) => {
		this.edit.currentReportCode = ev.selectedItem.code;
	};

	onEntityValueChangedEv = (ev: any) => {
		this.edit.model.workCenterId = null;
		this.edit.workCenters = this.edit.getPartyMemberById(ev.value);
	};

	protected queryParams(pr: Params): void {
		super.queryParams(pr);

		this.edit.model.typeId = null;
		this.edit.interval = pr['interval'];
		this.edit.reportType = pr['reportType'];
	}
}
