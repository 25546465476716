import { Component, Input } from '@angular/core';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { ProxyService } from '../../../service/proxy.service';
import { UnitWasteEditService } from './unit-waste-edit.service';

@Component({
	selector: 'app-unit-waste-edit',
	templateUrl: './unit-waste-edit.component.html'
})

export class UnitWasteEditComponent extends iBisBaseComponent {
	@Input() isWizard = false;
	public edit: UnitWasteEditService;

	constructor(public proxy: ProxyService) {
		super(proxy.unitWaste, proxy.lg, proxy.iui, proxy.activatedRoute);
		proxy.wasteType.onDataChanged.subscribe((data) => {
			if (data === 'selected') {
				this.edit.model.wasteType = this.proxy.wasteType.selected;
				this.edit.model.uomName = this.proxy.wasteType.selectedUomName;
			}
		});
	}

	onWasteTypeChange = (ev: any) => {
		this.proxy.wasteType.selectedId = ev.value;
	};
}
