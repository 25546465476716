import { Component, ViewChild } from '@angular/core';
import { ProxyService } from '../../../service/proxy.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { GeographicAreaEditService } from './geographic-area-edit.service';
import { BreadcrumbViewComponent } from '../../../framework/breadcrumb-view.component';

@Component({
	templateUrl: 'geographic-area.component.html'
})
export class GeographicAreaComponent extends BreadcrumbViewComponent {
	@ViewChild('gridGa') grid: DxDataGridComponent;

	public edit: GeographicAreaEditService;
	constructor(public proxy: ProxyService) {
		super(proxy.geo, proxy.lg, proxy.iui, proxy.activatedRoute);
		this.key = 'geographicArea';
	}
}
