import { Injectable } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import { iBisEditService, iBisLanguageService } from '@dohu/ibis-common';

@Injectable()
export class SafetyReportEditService extends iBisEditService {
	public rating: number[];
	public data: CustomStore;
	public participant: CustomStore;
	public reportResult: CustomStore;

	constructor(private entity: iBisEntityService, lg: iBisLanguageService) {
		super(lg);
		this.validation = 'srValidation';

		this.data = this.getData();
		this.rating = this.getRating();
	}

	createDefault() {
		return {};
	}

	getById(id: string): Promise<any> {
		return new Promise((resolve, reject) => {
			this.entity.getById('SafetyReport', id).then((rr) => {
				this.title = this.lg.format('EDIT_SAFETY_REPORT', [rr.refNumber]);
				this.reportResult = this.getResult(rr.id);
				this.participant = this.getReportParticipant(rr.id);
				if (rr.locationId) {
					this.entity.getById('Location', rr.locationId).then(data => {
						rr.entityId = data.partyId;
						resolve(rr);
					}, error => {
						this.lg.showError(error);
					});
				} else {
					resolve(rr);
				}
			}, error => {
				this.lg.showError(error);
				reject(error);
			});
		});
	}

	onRemove(id: string): Promise<void> {
		return this.entity.remove('SafetyReport', id);
	}

	onSaveEv(): Promise<any> {
		return this.entity.save('SafetyReport', this.model.id, this.model);
	}

	reset(): void {
		this.model = this.createDefault();
	}

	private getData(): CustomStore {
		const q = new EntityQuery('SafetyReportView').addOrderByDesc(['r.refNumber']);
		return this.entity.store(q, false, 'r_id');
	}

	private getReportParticipant(reportId: string): CustomStore {
		const q = new EntityQuery('SafetyReportParticipant').eq('reportId', reportId);
		return this.entity.store(q, false, 'id');
	}

	private getResult(reportId: string) {
		const q = new EntityQuery('SafetyReportResultView').addOrderBy(['srr.position']);
		if (reportId) {
			q.eq('srr.reportId', reportId);
		}
		return this.entity.store(q, false, 'srr_id');
	}

	private getRating(): number[] {
		return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
	}
}
