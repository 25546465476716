import { Component, ViewChild } from '@angular/core';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { ProxyService } from '../../service/proxy.service';
import { DxFormComponent } from 'devextreme-angular/ui/form';
import { GenerateEditService } from './generate-edit.service';
import { UOMHelper } from '../waste-type/waste-type-edit.service';

@Component({
	selector: 'app-generate-edit',
	templateUrl: 'generate-edit.component.html'
})
export class GenerateEditComponent extends iBisBaseComponent {
	@ViewChild('generateForm') generateForm: DxFormComponent;
	public edit: GenerateEditService;
	public uomHelper: UOMHelper;
	private cboWasteType: any;

	constructor(public proxy: ProxyService) {
		super(proxy.generate, proxy.lg, proxy.iui, proxy.activatedRoute);
		this.uomHelper = new UOMHelper(this.proxy.wasteType);

		proxy.wasteType.onDataChanged.subscribe((data) => {
			if (data === 'code' && this.cboWasteType) {
				this.proxy.resetValidation(this.cboWasteType);
			}
			if (data === 'selected') {
				this.edit.model.wasteType = this.proxy.wasteType.selected;
				if(this.edit.model.uom) {
					this.proxy.wasteType.selected.uom = this.edit.model.uom;
					this.uomHelper.update(this.proxy.wasteType.selectedUomName);
				}
				if (!this.edit.model.id) {
					this.edit.model.capitalizationId = this.edit.model.wasteType.capitalizationId;
					this.edit.model.eliminationId = this.edit.model.wasteType.eliminationId;
				}
			}
		});
	}

	onWorkCenterChanged = (ev: any) => {
		this.proxy.wasteType.workCenterId = ev.value;
	};

	onWasteTypeChanged = (ev: any) => {
		this.proxy.wasteType.selectedId = ev.value;
	};

	onWasteTypeInit = (ev: any) => {
		this.cboWasteType = ev.component;
	};

	quantityTarget = () => 0;

	validateCode = (op: any) => {
		const other = op.formItem.dataField === 'eliminationId' ?
			this.edit.model.capitalizationId : this.edit.model.eliminationId;
		return op.value || other;
	};
}
