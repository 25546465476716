<dx-data-grid class="gridContainer box-shadow" #gridTreatmentIn [columnAutoWidth]="true" [allowColumnResizing]="true"
    [allowColumnReordering]="true" [height]="ui.fullHeight" [dataSource]="edit.dataIn"
    (onToolbarPreparing)="toolbarPreparing($event, false)">

    <dxo-export [enabled]="ui.exportEnabled" fileName="treatment"></dxo-export>
    <dxo-scrolling mode="infinite" [showScrollbar]="ui.showScrollbar" [useNative]="!ui.isWindows">
    </dxo-scrolling>
	<dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" [storageKey]='key'>
	</dxo-state-storing>

    <dxi-column [caption]="L('CREATION_DATE')" dataField="t_date" dataType="date" [calculateCellValue]="iui.formatDate"
        calculateSortValue="t_date" width="170"></dxi-column>
    <dxi-column [caption]="L('WORK_CENTER')" dataField="wc_name" dataType="string"></dxi-column>
    <dxi-column [caption]="L('ELIMINATION_TYPE')" dataField="t_typeId" dataType="number">
        <dxo-lookup [dataSource]="proxy.treatment.type" valueExpr="id" displayExpr="value"></dxo-lookup>
    </dxi-column>
    <dxi-column [caption]="L('WASTE_CODE')" dataField="wti_code" dataType="string"></dxi-column>
    <dxi-column [caption]="L('WASTE_TYPE')" dataField="wti_hazard" dataType="number">
        <dxo-lookup [dataSource]="proxy.wasteType.hazard" valueExpr="id" displayExpr="value"></dxo-lookup>
    </dxi-column>
    <dxi-column [caption]="L('WASTE_NAME')" dataField="wti_name" dataType="string"></dxi-column>
    <dxi-column [caption]="L('U')" dataField="uom" dataType="number">
        <dxo-lookup [dataSource]="proxy.wasteType.uom" valueExpr="id" displayExpr="value"></dxo-lookup>
    </dxi-column>
    <dxi-column [caption]="L('PHYSICAL_STATE')" dataField="i_physicalCondition" dataType="number">
        <dxo-lookup [dataSource]="proxy.wasteType.physicalState" valueExpr="id" displayExpr="value"></dxo-lookup>
    </dxi-column>
    <dxi-column [caption]="L('QUANTITY')" dataField="i_quantity" dataType="number"></dxi-column>
    <dxi-column [caption]="L('WASTE_CODE_FROM')" dataField="wt_code" dataType="string"></dxi-column>
    <dxi-column [caption]="L('OBSERVATIONS')" dataField="t_observations" dataType="string"></dxi-column>
</dx-data-grid>