import { Component } from '@angular/core';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { ProxyService } from '../../../../service/proxy.service';
import { SafetyPlanningCopyService } from './safety-planning-copy.service';

@Component({
	selector: 'app-safety-planning-copy',
	templateUrl: './safety-planning-copy.component.html'
})
export class SafetyPlanningCopyComponent extends iBisBaseComponent {
	public edit: SafetyPlanningCopyService;
	constructor(public proxy: ProxyService) {
		super(proxy.safetyPlanningCopy, proxy.lg, proxy.iui, proxy.activatedRoute);
	}
}
