<dx-data-grid #gridUnit class="gridContainer" [dataSource]="edit.data" [height]="ui.fullHeight"
	[remoteOperations]="true" (onCellPrepared)="onCellPrepared($event)" (onCellClick)="onCellClick($event)"
	(onRowExpanding)="onRowExpanding($event)" (onRowPrepared)="onRowPrepared($event)"
	(onToolbarPreparing)='toolbarPreparing($event, proxy.allowEdit)'>

	<dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" [storageKey]='key'>
	</dxo-state-storing>

	<dxo-scrolling [mode]="ui.scrollingMode" [showScrollbar]="ui.showScrollbar" [useNative]="!ui.isWindows">
	</dxo-scrolling>
	<dxo-master-detail [enabled]="true" template="details"></dxo-master-detail>

	<dxi-column type="buttons" fixedPosition="left" [minWidth]="ui.actionWidth" [width]="ui.actionWidth" [fixed]="true"
		[allowResizing]="false" [showInColumnChooser]="false">
		<dxi-button [hint]="L('EDIT')" icon="edit" [onClick]="onEditClick" [visible]="proxy.allowEdit">
		</dxi-button>
	</dxi-column>

	<dxi-column name='unit' headerCellTemplate='unitHeader'>
		<dxi-column [caption]="L('NAME')" dataField="p_name" dataType="string"></dxi-column>
		<dxi-column [caption]="L('CODE')" dataField="p_code" dataType="string"></dxi-column>
		<dxi-column [caption]="L('FISCAL_CODE')" dataField="p_cif" dataType="string"></dxi-column>
		<dxi-column [caption]="L('REG_COMERT')" dataField="p_registerNo" dataType="string"></dxi-column>
		<dxi-column [caption]="L('UNIT_TYPE')" dataField="p_typeId" dataType="number">
			<dxo-lookup [dataSource]="edit.type" valueExpr="id" displayExpr="value"></dxo-lookup>
		</dxi-column>
		<dxi-column [caption]="L('PARENT_UNIT')" dataField="parent_name" dataType="string">
		</dxi-column>
	</dxi-column>
	<dxi-column name='adress' headerCellTemplate='adressHeader'>
		<dxi-column [caption]="L('COUNTY')" dataField="co_name" dataType="string"></dxi-column>
		<dxi-column [caption]="L('LOCALITY')" dataField="loc_name" dataType="string"></dxi-column>
		<dxi-column [caption]="L('ADDRESS')" dataField="p_address" dataType="string"></dxi-column>
	</dxi-column>
	<dxi-column [caption]="L('ACTIVE')" dataField="p_isActiv" dataType="boolean"></dxi-column>
	<dxi-column name='responsable' headerCellTemplate='resHeader'>
		<dxi-column [caption]="L('WASTE_RESPONSABLE')" dataField="p_wasteResponsible" dataType="string"></dxi-column>
		<dxi-column [caption]="L('ENVIRONMENT_RESPONSABLE')" dataField="p_environmentResponsible" dataType="string">
		</dxi-column>
	</dxi-column>

	<div *dxTemplate="let data of 'breadcrumb'">
		<ibis-breadcrumb></ibis-breadcrumb>
	</div>
	<div *dxTemplate="let pw of 'details'">
		<dx-tab-panel height="500">
			<dxi-item [title]="L('WASTE_TYPE')">
				<app-unit-waste [workCenterId]="pw.data.p_id"></app-unit-waste>
			</dxi-item>
			<!-- <dxi-item [title]="L('KPI_TARGET')">
				<app-unit-kpi [partyId]="pw.data.p_id"></app-unit-kpi>
			</dxi-item> -->
		</dx-tab-panel>
	</div>
	<div *dxTemplate="let c of 'adressHeader'" class="dx-datagrid-text-content" role="presentation">
		<i class="fas fa-map-marked-alt"></i> Adresa
	</div>
	<div *dxTemplate="let c of 'unitHeader'" class="dx-datagrid-text-content" role="presentation">
		<i class="fas fa-industry"></i> Unitate
	</div>
	<div *dxTemplate="let c of 'resHeader'" class="dx-datagrid-text-content" role="presentation">
		<i class="fas fa-user-alt"></i> Responsabil
	</div>
</dx-data-grid>

<!-- Edit Unit -->
<ibis-edit-popup [edit]="edit" [showAdd]="true">
	<app-unit-edit></app-unit-edit>
</ibis-edit-popup>