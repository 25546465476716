import { Component } from '@angular/core';
import { iBisViewComponent } from '@dohu/ibis-common';
import { ProxyService } from '../../../service/proxy.service';
import { KPIAnalyzesFilterService } from './kpi-analyzes-filter.service';

@Component({
	selector: 'app-kpi-analyzes',
	templateUrl: './kpi-analyzes.component.html'
})
export class KPIAnalyzesComponent extends iBisViewComponent {

	public edit: KPIAnalyzesFilterService;
	constructor(public proxy: ProxyService) {
		super(proxy.kpiAnalyzes, proxy.lg, proxy.iui, proxy.activatedRoute);
	}
}
