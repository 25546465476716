<app-dashboard-analyzes-filter></app-dashboard-analyzes-filter>
<div class="flex-row">
  <div class="charts">
    <div class="generareChart">
      <app-graph-line [txt]="{ title: L('GENERATED'), description: edit.result.totalGenerateText }"
        [typeValue]="edit.result.generate" [typeOp]="edit.typeOp.gen" [uom]="edit.model.wasteUom"
        [intervalDate]="edit.result.intervalPeriod"></app-graph-line>
    </div>
    <div class="transportChart">
      <app-graph-line [txt]="{ title: L('TRANSPORTED'), description: edit.result.totalTransportText }"
        [typeValue]="edit.result.transport" [typeOp]="edit.typeOp.tr" [uom]="edit.model.wasteUom"
        [intervalDate]="edit.result.intervalPeriod"></app-graph-line>
    </div>
  </div>
  <div class="charts">
    <div class="elimChart">
      <app-graph-line [txt]="{ title: L('REMOVED'), description: edit.result.totalEliminateText}"
        [typeValue]="edit.result.eliminate" [typeOp]="edit.typeOp.el" [uom]="edit.model.wasteUom"
        [intervalDate]="edit.result.intervalPeriod"></app-graph-line>
    </div>
    <div class="valorifChart">
      <app-graph-line [txt]="{ title: L('VALORIFIED'), description: edit.result.totalCapitalizationText }"
        [typeValue]="edit.result.capitalization" [typeOp]="edit.typeOp.vl" [uom]="edit.model.wasteUom"
        [intervalDate]="edit.result.intervalPeriod"></app-graph-line>
    </div>
  </div>
</div>