import { Component, ViewChild } from '@angular/core';
import { ProxyService } from '../../service/proxy.service';
import { ControlEditService } from './control-edit.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { BreadcrumbViewComponent } from '../../framework/breadcrumb-view.component';

@Component({
	templateUrl: 'control.component.html'
})
export class ControlComponent extends BreadcrumbViewComponent {
	@ViewChild('gridControl') grid: DxDataGridComponent;

	public edit: ControlEditService;
	constructor(public proxy: ProxyService) {
		super(proxy.control, proxy.lg, proxy.iui, proxy.activatedRoute);
		this.id = 'c_id';
		this.key = 'control';
	}

	onCellPrepared(e: any) {
		if (e.rowType === 'data' && e.column.command === 'expand' &&
			e.data.c_sanction === false && e.data.c_action === false) {
			e.cellElement.children[0].classList.remove('dx-datagrid-group-closed');
		}
	}

	onCellClick(e: any) {
		if (e.rowType === 'data' && e.cellElement.classList[0] === 'dx-command-expand' &&
			e.data.c_sanction === false && e.data.c_action === false) {
			e.component.collapseAll(-1);
		}
	}
}
