import { Component, ViewChild } from '@angular/core';
import { ProxyService } from '../../../service/proxy.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { MonthlyStockEditService } from './monthly-stock-edit.service';
import { MonthlyStockRecheckService } from './monthly-stock-recheck.service';
import { BreadcrumbViewComponent } from '../../../framework/breadcrumb-view.component';

@Component({
	templateUrl: 'monthly-stock.component.html'
})
export class MonthlyStockComponent extends BreadcrumbViewComponent {
	@ViewChild('gridMonthly') grid: DxDataGridComponent;
	public edit: MonthlyStockEditService;

	constructor(public proxy: ProxyService, public editR: MonthlyStockRecheckService) {
		super(proxy.monthlyStock, proxy.lg, proxy.iui, proxy.activatedRoute);
		this.key = 'monthlyStock';
	}

	// protected onEdit(e: any): void {
	// 	e.event.preventDefault();
	// 	// this.grid.instance.beginCustomLoading('');
	// 	// this.edit.showPopup({ id: e.row.data.id }, true).then(() => {
	// 	// 	this.refresh();
	// 	// }, error => this.proxy.lg.showError(error));
	// }

	onRowPrepared(rowInfo: any) {
		if (rowInfo.rowType === 'data' && (rowInfo.data.previousStock < 0 || rowInfo.data.stock + rowInfo.data.previousStock < 0)) {
			rowInfo.rowElement.className = rowInfo.rowElement.className + ' stockAlert';
		}
	}

	calculateTotalStock(rowData) {
		return (parseFloat(rowData.stock) + parseFloat(rowData.previousStock)).toFixed(3);
	}

	toolbarPreparing(event: any, edit: boolean) {
		super.toolbarPreparing(event, edit);
		event.toolbarOptions.items.unshift({
			widget: 'dxButton',
			options: {
				icon: 'redo',
				hint: this.L('UPDATE', null),
				onClick: (e: any) => {
					e.event.preventDefault();
					this.grid.instance.beginCustomLoading('');
					this.editR.showPopup(null).then(() => {
						this.refresh();
					}, error => this.proxy.lg.showError(error));
				}
			},
			location: 'after'
		});
	}
}
