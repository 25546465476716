import { Component } from '@angular/core';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { ProxyService } from '../../../service/proxy.service';
import { CostAnalyzesFilterService } from './cost-analyzes-filter.service';

@Component({
	selector: 'app-cost-analyzes-filter',
	templateUrl: './cost-analyzes-filter.component.html'
})
export class CostAnalyzesFilterComponent extends iBisBaseComponent {

	public edit: CostAnalyzesFilterService;
	constructor(public proxy: ProxyService) {
		super(proxy.costAnalyzes, proxy.lg, proxy.iui, proxy.activatedRoute);
	}
}
