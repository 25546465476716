<dx-form [colCount]="1" [formData]="edit.model" [showValidationSummary]="false" [validationGroup]="edit.validation"
	labelLocation="left">
	<dxi-item [label]="{text: L('ENT_WORKC')}" dataField="workCenterId"
		editorType="dxSelectBox"
		[editorOptions]="{ dataSource: proxy.party.ownWorkCenter, valueExpr: 'c_id', displayExpr: 'fullName', onValueChanged: onWorkCenterChanged, searchEnabled: true,
			readOnly: !edit.model.id || edit.model.id !== undefined }">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>

	<dxi-item [label]="{text: L('WASTE_CODE')}" dataField="wasteTypeId"
		editorType="dxSelectBox"
		[editorOptions]="{ dataSource: proxy.wasteType.code, valueExpr: 'id', displayExpr: 'code', onValueChanged: onWasteTypeChanged, onInitialized: onWasteTypeInit, searchEnabled: true, disabled: !edit.model.workCenterId,
	readOnly: !edit.model.id || edit.model.id !== undefined }">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>
	
	<dxi-item itemType="group" [colSpan]="2" [colCount]="2">
		<dxi-item [label]="{text: L('WASTE_TYPE')}" dataField="wasteType.hazardName" editorType="dxTextBox"
			[editorOptions]="{readOnly: true }"></dxi-item>
		<dxi-item [label]="{text: L('ADJUSTED_GENERATE')}" dataField="adjustment" editorType="dxNumberBox"
			[editorOptions]="{showClearButton: true}">
			<dxi-validation-rule type="range" [min]="0" [ignoreEmptyValue]="true"
				message="Nu se pot introduce valori negative"></dxi-validation-rule>
			<dxi-validation-rule type="pattern" pattern="^\d+(\.\d{0,6})?$" [message]="L('NOT_NEG_VALUES')">
			</dxi-validation-rule>
		</dxi-item>
		<dxi-item [label]="{text: L('YEAR')}" dataField="year" editorType="dxNumberBox"
			[editorOptions]="{ readOnly: edit.model.id !== undefined, min: year.min, max: year.current }">
			<dxi-validation-rule type="required"></dxi-validation-rule>
		</dxi-item>
		<dxi-item [label]="{text: L('MONTH')}" dataField="month" editorType="dxSelectBox"
			[editorOptions]="{ dataSource: proxy.archive.months, valueExpr: 'id', displayExpr: 'value', disabled: edit.model.id !== undefined}">
		</dxi-item>
	</dxi-item>
	<dxi-item [label]="{text: L('WASTE_NAME')}" dataField="wasteType.name" editorType="dxTextBox"
		[editorOptions]="{readOnly: true }"></dxi-item>
</dx-form>