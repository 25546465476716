import { Injectable } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { DataService } from '../../../service/data.service';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import { PartyEditService } from '../party-edit/party-edit.service';
import { iBisEditService, iBisLanguageService } from '@dohu/ibis-common';

@Injectable()
export class PermitEditService extends iBisEditService {

	public data: CustomStore;
	public category: CustomStore;
	public type: { 'id': number; 'value': string }[];

	constructor(private entity: iBisEntityService, partyEdit: PartyEditService, private ds: DataService, lg: iBisLanguageService) {
		super(lg);
		this.title = 'PERMIT';
		this.validation = 'permitValidation';

		this.data = this.getData();
		this.type = this.getType();
		this.category = this.ds.getEnumValue('PERMIT');
	}

	public getData(entityId: any = null): CustomStore {
		const q = new EntityQuery('PermitView').addOrderBy(['p.no']);
		if (entityId) {
			q.eq('p.entityId', entityId);
		}
		return this.entity.store(q, false, 'p_id');
	}

	createDefault(): any {
		return { isValid: true };
	}

	getById(id: any): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			this.entity.getById('PermitView', id, 'p_id').then((result) => {
				if (!result) {
					reject();
				} else {
					const data = {
						id: result.p_id,
						no: result.p_no,
						typeId: result.p_typeId,
						validity: result.p_validity,
						revision: result.p_revision,
						isValid: result.p_isValid,
						entityId: result.p_entityId,
						categoryId: result.p_categoryId,
						description: result.p_description
					};
					resolve(data);
				}
			}, error => {
				this.lg.showError(error);
			});
		});
	}

	onRemove(id: string): Promise<void> {
		return this.entity.remove('Permit', id);
	}

	onSaveEv(): Promise<void> {
		return this.entity.save('Permit', this.model.id, this.model);
	}

	reset(): any {
		this.model = this.createDefault();
	}

	private getType(): { 'id': number; 'value': string }[] {
		// TODO move to static
		//	const list = [{ 'id': 0, 'value': 'NOTICE' }, { 'id': 1, 'value': 'AGREEMENT' }, { 'id': 2, 'value': 'ENVIRONMENTAL_PERMIT' },
		//		{ 'id': 3, 'value': 'ENVIRONMENTAL_INTEGRAL_PERMIT' }, { 'id': 4, 'value': 'TRANSPORT_LICENSE' }];
		const list = [{ 'id': 2, 'value': 'ENVIRONMENTAL_PERMIT' }, { 'id': 4, 'value': 'TRANSPORT_LICENSE' }];
		return this.ds.getEnum('permitType', list);
	}
}
