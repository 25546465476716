<dx-data-grid class="gridContainer" #gridTreatmentOut [remoteOperations]="true" [height]="ui.fullHeight"
	[dataSource]="edit.data" (onToolbarPreparing)="toolbarPreparing($event, proxy.allowEdit)">

	<dxo-scrolling [mode]="ui.scrollingMode" [showScrollbar]="ui.showScrollbar" [useNative]="!ui.isWindows">
	</dxo-scrolling>
	<dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" storageKey="qhseTreatmentOut">
	</dxo-state-storing>

	<dxi-column type="buttons" fixedPosition="left" [minWidth]="ui.actionWidth" [width]="ui.actionWidth" [fixed]="true"
		[allowResizing]="false" [showInColumnChooser]="false" [visible]="proxy.allowEdit">
		<dxi-button [hint]="L('EDIT')" icon="edit" [onClick]="onEditClick"></dxi-button>
		<dxi-button [hint]="L('DELETE')" icon="trash" [onClick]="onDeleteTreatmentClick"></dxi-button>
	</dxi-column>

	<dxi-column [caption]="L('WORK_CENTER')" dataField="wc_name" dataType="string"></dxi-column>
	<dxi-column name='treatment' headerCellTemplate='treatHeader'>
		<dxi-column [caption]="L('TREATMENT_DATE')" dataField="t_date" dataType="date"
			[calculateCellValue]="iui.formatDate" calculateSortValue="t_date" width="170"></dxi-column>
		<dxi-column [caption]="L('ELIMINATION_TYPE')" dataField="t_typeId" dataType="number">
			<dxo-lookup [dataSource]="edit.type" valueExpr="id" displayExpr="value"></dxo-lookup>
		</dxi-column>
	</dxi-column>
	<dxi-column name='waste' headerCellTemplate='wasteHeader'>
		<dxi-column [caption]="L('WASTE_CODE')" dataField="wt_code" dataType="string"></dxi-column>
		<dxi-column [caption]="L('PHYSICAL_STATE')" dataField="t_physicalCondition" dataType="number">
			<dxo-lookup [dataSource]="proxy.wasteType.physicalState" valueExpr="id" displayExpr="value"></dxo-lookup>
		</dxi-column>
		<dxi-column [caption]="L('WASTE_NAME')" dataField="wt_name" dataType="string"></dxi-column>
		<dxi-column [caption]="L('WASTE_TYPE')" dataField="wt_hazard" dataType="number">
			<dxo-lookup [dataSource]="proxy.wasteType.hazard" valueExpr="id" displayExpr="value"></dxo-lookup>
		</dxi-column>
	</dxi-column>
	<dxi-column name='quantity' headerCellTemplate='qHeader'>
		<dxi-column [caption]="L('QUANTITY')" dataField="t_quantity" dataType="number"></dxi-column>
		<dxi-column [caption]="L('U')" dataField="uom" dataType="number">
			<dxo-lookup [dataSource]="proxy.wasteType.uom" valueExpr="id" displayExpr="value"></dxo-lookup>
		</dxi-column>
	</dxi-column>
	<dxi-column [caption]="L('OBSERVATIONS')" dataField="t_observations" dataType="string"></dxi-column>

	<dxo-summary>
		<dxi-total-item column="t_quantity" summaryType="sum" displayFormat="Total: {0}"
			[valueFormat]="{type:'fixedPoint', precision: 6 }"></dxi-total-item>
	</dxo-summary>

	<div *dxTemplate="let data of 'breadcrumb'">
		<ibis-breadcrumb></ibis-breadcrumb>
	</div>
	<div *dxTemplate="let c of 'wasteHeader'" class="dx-datagrid-text-content" role="presentation">
		<i class='fas fa-recycle'></i> Deseu
	</div>
	<div *dxTemplate="let c of 'qHeader'" class="dx-datagrid-text-content" role="presentation">
		<i class='fas fa-weight'></i> Cantitate
	</div>
	<div *dxTemplate="let c of 'treatHeader'" class="dx-datagrid-text-content" role="presentation">
		<i class="fas fa-vials"></i> Tratare
	</div>
</dx-data-grid>

<ibis-edit-popup [edit]="edit" [showAdd]="true">
	<app-treatment-edit></app-treatment-edit>
</ibis-edit-popup>