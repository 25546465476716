import { NgModule } from '@angular/core';
import { GenerateComponent } from './generate.component';
import { DxFormModule } from 'devextreme-angular/ui/form';
import { BrowserModule } from '@angular/platform-browser';
import { DxPopupModule } from 'devextreme-angular/ui/popup';
import { iBisNavigationModule } from '@dohu/ibis-navigation';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { GenerateEditService } from './generate-edit.service';
import { DxTextBoxModule } from 'devextreme-angular/ui/text-box';
import { GenerateEditComponent } from './generate-edit.component';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { FrameworkModule } from '../../framework/framework.module';
import { DxValidatorModule } from 'devextreme-angular/ui/validator';
import { DxSelectBoxModule } from 'devextreme-angular/ui/select-box';

@NgModule({
	declarations: [
		GenerateComponent,
		GenerateEditComponent
	],
	imports: [
		DxFormModule,
		BrowserModule,
		DxPopupModule,
		DxButtonModule,
		DxTextBoxModule,
		FrameworkModule,
		DxDataGridModule,
		DxValidatorModule,
		DxSelectBoxModule,
		iBisNavigationModule
	],
	exports: [
		GenerateComponent,
		GenerateEditComponent
	],
	providers: [
		GenerateEditService
	]
})
export class GenerateModule { }
