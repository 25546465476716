import { Injectable } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { iBisLanguageService } from '@dohu/ibis-common';
import { PartyBaseService } from '../party-base.service';
import { DataService } from '../../../service/data.service';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import { GeographicAreaEditService } from '../../enum/geographic-area/geographic-area-edit.service';

@Injectable()
export class UnitEditService extends PartyBaseService {
	public data: CustomStore;
	public treeList: CustomStore;
	public kpiUnitType: CustomStore;

	public parent: CustomStore;
	public type: { 'id': number; 'value': string }[];
	public activityType: { 'id': number; 'value': string }[];


	constructor(protected entity: iBisEntityService, protected ds: DataService,
		protected geo: GeographicAreaEditService, lg: iBisLanguageService) {
		super(entity, geo, lg);
		this.title = 'UNIT';
		this.validation = 'unitValidation';

		this.data = this.getData([1, 2]);
		this.treeList = this.getTreeList();
		this.kpiUnitType = this.getKpiUnitType();

		this.parent = this.getParent(0);
		this.type = this.getType();
		this.activityType = this.getActivityType();
	}

	public set typeId(id: number) {
		this.parent = this.getParent(id);
		this.onDataChanged.next('parent');
	}
	createDefault(): any {
		return { isActiv: true, isSubcontractor: false, activities: 1, selectedActivities: [1] };
	}

	getById(id: string): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			this.entity.getById('Party', id).then((obj) => {
				if (obj === null) {
					reject();
				} else {
					if (obj.activities) {
						obj.selectedActivities = this.ds.splitMask(obj.activities, this.activityType);
					}
					this.typeId = obj.typeId;
					if (obj.localityId) {
						this.entity.getFieldsById('GeographicArea', obj.localityId, ['parentId']).then((c) => {
							obj.countyId = c.parentId;
							this.countyId = c.parentId;
							resolve(obj);
						});
					} else {
						resolve(obj);
					}
				}
			}, error => {
				this.lg.showError(error);
				reject(error);
			});
		});
	}

	onRemove(id: string): Promise<any> {
		return this.entity.remove('Party', id);
	}

	onSaveEv(): Promise<any> {
		if (!this.model.isSubcontractor) {
			this.model.isSubcontractor = false;
		}
		if (this.model.selectedActivities && this.model.selectedActivities.length) {
			this.model.activities = this.ds.createMask(this.model.selectedActivities);
		}
		if (!this.model.cif) {
			return this.entity.save('Party', this.model.id, this.model);
		}

		// TODO: Check CIF VALIDATION
		return new Promise((resolve, reject) => {
			let cifValue = this.model.cif;
			cifValue = cifValue.replaceAll(' ', '');
			cifValue = cifValue.toLowerCase();
			cifValue = cifValue.replace('ro', '');
			this.entity.save('Party', this.model.id, this.model).then((result) => {
				resolve(result);
			});
			// 	this.entity.load(new EntityQuery('Party').like('cif', '%' + cifValue)).then((data) => {
			// 		if (this.model.id) { // ON UPDATE
			// 			if (data.length > 0) {
			// 				let update = true;
			// 				data.forEach(p => {
			// 					if (p.id != this.model.id && p.id != this.model.parentId && ((p.parentId != null && this.model.parentId != null && 
			// 						p.parentId != this.model.parentId) || ((p.parentId == null && this.model.id == null) || 
			// 						(p.parentId != null && this.model.id == null) || ((p.parentId == null && this.model.id != null))))) {
			// 						update = false;
			// 					}
			// 				});
			// 				if (update) {
			// 					this.entity.save('Party', this.model.id, this.model).then((result) => {
			// 						resolve(result);
			// 					});
			// 				} else {
			// 					reject(this.L('CIF_DUPLICATED'));
			// 				}
			// 			} else {
			// 				this.entity.save('Party', this.model.id, this.model).then((result) => {
			// 					resolve(result);
			// 				});
			// 			}
			// 		}
			// 		else { // ON INSERT
			// 			if (this.model.typeId == 2 || this.model.typeId == 4) // WORKCENTER
			// 			{
			// 				let insert = true;
			// 				if (data.length > 0) {
			// 					data.forEach(p => {
			// 						if (p.id != this.model.parentId && p.parentId != this.model.parentId) {
			// 							insert = false;
			// 						}
			// 					});
			// 					if (insert) {
			// 						this.entity.save('Party', this.model.id, this.model).then((result) => {
			// 							resolve(result);
			// 						});
			// 					} else {
			// 						reject(this.L('CIF_DUPLICATED'));
			// 					}
			// 				}
			// 				else {
			// 					this.entity.save('Party', this.model.id, this.model).then((result) => {
			// 						resolve(result);
			// 					});
			// 				}
			// 			} else // ENTITY
			// 			{
			// 				if (data.length > 0) {
			// 					reject(this.L('CIF_DUPLICATED'));
			// 				}
			// 				else {
			// 					this.entity.save('Party', this.model.id, this.model).then((result) => {
			// 						resolve(result);
			// 					});
			// 				}
			// 			}
			// 		}
			// 	});
		});
	}


	public cifValidation(param: any): Promise<any> {
		return new Promise((resolve) => {
			let cifValue = param.value;
			cifValue = cifValue.replaceAll(' ', '');
			cifValue = cifValue.toLowerCase();
			cifValue = cifValue.replace('ro', '');
			this.entity.load(new EntityQuery('Party').like('cif', '%' + cifValue + '%')).then((data) => {
				resolve(data.length > 0);
			});
		});
	}
	reset(): void {
		this.model = this.createDefault();
	}

	public getPartyKpi(partyId: any): CustomStore {
		return this.entity.store(new EntityQuery('PartyKpi').eq('partyId', partyId).addOrderByDesc(['fromDate']));
	}

	protected getData(typeId: number[]): CustomStore {
		return this.entity.store(new EntityQuery('PartyView').in('p.typeId', typeId).addOrderBy(['p.typeId', 'p.name']), false, 'p_id');
	}

	protected getTreeList(): CustomStore {
		const q = new EntityQuery('Party').in('typeId', [1, 2]).addOrderBy(['typeId', 'name']);
		q.fields.push('id', 'typeId', 'parentId', 'name');
		return this.entity.store(q, false, 'id', null, (item) => {
			if (item.typeId === 1) {
				item.parentId = '00000000-0000-0000-0000-000000000000';
			}
		});
	}

	protected getParent(typeId: number): CustomStore {
		const q = new EntityQuery('Party').eq('isActiv', true).eq('typeId', typeId - 1).addOrderBy(['name']);
		q.fields.push('id', 'name');
		return this.entity.store(q);
	}

	private getActivityType(): { 'id': number; 'value': string }[] {
		const list = [{ 'id': 1, 'value': 'GENERATE' }, { 'id': 2, 'value': 'COLLECT' }, { 'id': 4, 'value': 'TRANSPORT' },
		{ 'id': 8, 'value': 'VALORIZATION' }, { 'id': 16, 'value': 'ELIMINATION' }, { 'id': 32, 'value': 'TEMP_STORAGE' },
		{ 'id': 64, 'value': 'TREATMENT' }];
		return this.ds.getEnum('activityType', list);
	}

	private getType(): { 'id': number; 'value': string }[] {
		const list = [{ 'id': 1, 'value': 'ENTITY' }, { 'id': 2, 'value': 'WORK_CENTER' }];
		return this.ds.getEnum('unitType', list);
	}

	private getKpiUnitType(): CustomStore {
		const q = new EntityQuery('EnumValue').addOrderBy(['orderIndex', 'code']).
			linkEq('typeId', 'id', 'EnumType', 'code', 'UNIT_KPI_TYPE');
		q.fields.push('id', 'code', 'description');
		return this.entity.store(q, false, 'id');
	}
}
