import { Injectable } from '@angular/core';
import { iBisLanguageService } from '@dohu/ibis-common';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import { UnitWasteEditService } from '../unit/unit-waste-edit.service';
import { WasteTypeEditService } from '../../../waste/waste-type/waste-type-edit.service';

@Injectable()
export class PartyLinkEditService extends UnitWasteEditService {

	constructor(protected entity: iBisEntityService, wtp: WasteTypeEditService, lg: iBisLanguageService) {
		super(entity, wtp, lg);
		this.title = 'WASTECODE_GENERATED';
		this.validation = 'lkValidation';
	}

	public getLink(partyId: any) {
		return this.entity.store(new EntityQuery('WasteTypePartyView').eq('wtp.senderId', partyId)
			.addOrderBy(['wt.code']), false, 'wtp_id');
	}

	createDefault(): any {
		return { isActiv: true };
	}
}
