import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxChartModule } from 'devextreme-angular/ui/chart';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { DxDateBoxModule } from 'devextreme-angular/ui/date-box';
import { DxFormModule } from 'devextreme-angular/ui/form';
import { DxiSeriesModule } from 'devextreme-angular/ui/nested';
import { DxPivotGridModule } from 'devextreme-angular/ui/pivot-grid';
import { DxPopupModule } from 'devextreme-angular/ui/popup';
import { DxScrollViewModule } from 'devextreme-angular/ui/scroll-view';
import { DxSelectBoxModule } from 'devextreme-angular/ui/select-box';
import { DxTabPanelModule } from 'devextreme-angular/ui/tab-panel';
import { DxTextBoxModule } from 'devextreme-angular/ui/text-box';
import { DxValidatorModule } from 'devextreme-angular/ui/validator';
import { DxValidationGroupModule } from 'devextreme-angular/ui/validation-group';

import { FrameworkModule } from '../../framework/framework.module';
import { iBisCommonModule } from '@dohu/ibis-common';
import { InconsistentComponent } from './inconsistent/inconsistent.component';
import { InconsistentEditComponent } from './inconsistent/inconsistent-edit.component';
import { InconsistentEditService } from './inconsistent/inconsistent-edit.service';
import { SafetyPlanningComponent } from './safety-planning/safety-planning.component';
import { SafetyPlanningCopyComponent } from './safety-planning/safety-planning-copy/safety-planning-copy.component';
import { SafetyPlanningCopyService } from './safety-planning/safety-planning-copy/safety-planning-copy.service';
import { SafetyPlanningEditComponent } from './safety-planning/safety-planning-edit.component';
import { SafetyPlanningEditService } from './safety-planning/safety-planning-edit.service';
import { SafetyPlanningExceptComponent } from './safety-planning/safety-planning-except/safety-planning-except.component';
import { SafetyPlanningExceptEditComponent } from './safety-planning/safety-planning-except/safety-planning-except-edit.component';
import { SafetyPlanningExceptEditService } from './safety-planning/safety-planning-except/safety-planning-except.service';
import { SafetyReportComponent } from './safety-report/safety-report.component';
import { SafetyReportEditComponent } from './safety-report/safety-report-edit.component';
import { SafetyReportEditService } from './safety-report/safety-report-edit.service';
import { SafetyReportWizardComponent } from './safety-report/safety-report-wizard.component';
import { SafetyReportWizardService } from './safety-report/safety-report-wizard.service';

@NgModule({
	declarations: [
		InconsistentComponent,
		SafetyReportComponent,
		SafetyPlanningComponent,
		SafetyReportEditComponent,
		InconsistentEditComponent,
		SafetyPlanningEditComponent,
		SafetyReportWizardComponent,
		SafetyPlanningCopyComponent,
		SafetyPlanningExceptComponent,
		SafetyPlanningExceptEditComponent
	],
	imports: [
		DxFormModule,
		BrowserModule,
		DxChartModule,
		DxPopupModule,
		DxButtonModule,
		DxDateBoxModule,
		DxTextBoxModule,
		DxiSeriesModule,
		FrameworkModule,
		DxTabPanelModule,
		DxDataGridModule,
		DxPivotGridModule,
		DxValidatorModule,
		DxSelectBoxModule,
		DxSelectBoxModule,
		DxScrollViewModule,
		DxValidationGroupModule,
		iBisCommonModule
	],
	exports: [
		InconsistentComponent,
		SafetyReportComponent,
		SafetyReportEditComponent
	],
	providers: [
		InconsistentEditService,
		SafetyReportEditService,
		SafetyPlanningCopyService,
		SafetyPlanningEditService,
		SafetyReportWizardService,
		SafetyPlanningExceptEditService
	]
})
export class SafetyAuditModule { }
