import notify from 'devextreme/ui/notify';
import { Injectable } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { DataService } from '../../../service/data.service';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import { iBisAuthService, iBisSecureEditService } from '@dohu/ibis-auth';
import { iBisLanguageService, iBisServerConfig } from '@dohu/ibis-common';
import { WasteTypeEditService } from '../../waste-type/waste-type-edit.service';

@Injectable({
	providedIn: 'root'
})
export class CapitalizationAnalyzesFilterService extends iBisSecureEditService {

	public result: Result;
	public data: CustomStore;
	public uomData: any = [{ id: 1, text: this.L('TONES') }, { id: 2, text: this.L('MC') }, { id: 3, text: this.L('KG') }];
	public interval: any = [{ id: 1, text: this.L('DAILY') }, { id: 2, text: this.L('MONTHLY') }];
	public typeOp: any;
	constructor(auth: iBisAuthService, entity: iBisEntityService, config: iBisServerConfig, lg: iBisLanguageService,
		public ds: DataService, public wtp: WasteTypeEditService) {
		super(auth, entity, config, lg);

		this.result = new Result();
		this.setTypeOp();
	}

	createDefault() {
		return { workCenter: [], interval: 1, wasteUom: 1, date: new Date(), lastYear: false };
	}
	getById(id: string, serverUrl?: string): Promise<any> {
		throw new Error('Method not implemented.');
	}
	onRemove(id: string): Promise<void> {
		throw new Error('Method not implemented.');
	}
	onSaveEv(serverUrl?: string): Promise<any> {
		throw new Error('Method not implemented.');
	}
	reset(): void {
		this.model = this.createDefault();
	}

	public filterData = (): void => {
		if (this.model.wasteType && this.model.wasteType.length < 1) {
			notify('Selectați coduri de deșeu pentru analiză.', 'error', 3000);
			return;
		}
		if (this.model.workCenter && this.model.workCenter.length < 1) {
			notify('Selectați punct de lucru pentru analiză.', 'error', 3000);
			return;
		}
		if (this.model.date == null) {
			notify('Selectați data pentru analiză.', 'error', 3000);
			return;
		}
		if (this.model.uom == null) {
			notify('Selectați unitate de măsură pentru analiză.', 'error', 3000);
			return;
		}
		this.model.wasteUom = this.model.uom;
		const obj: any = {};
		sessionStorage.setItem('capitalization-analyzes', JSON.stringify(this.model));
		Object.assign(obj, this.model);
		let d = new Date(obj.date);
		d = new Date(d.setDate(1));
		obj.fromDate = this.entity.toDateFilter(new Date(d));
		this.model.fromDate = new Date(d);
		if (obj.interval === 1) {
			obj.thruDate = this.entity.toDateFilter(new Date(d.getFullYear(), d.getMonth() + 1, 0));
			this.model.thruDate = new Date(d.getFullYear(), d.getMonth() + 1, 0);
		} else {
			obj.thruDate = this.entity.toDateFilter(new Date(d.getFullYear() + 1, 0, 0));
			this.model.thruDate = new Date(d.getFullYear() + 1, 0, 0);
		}
		this.entity.execute('GetCapitalizationAnalyzes', obj).then((result) => {
			this.initDashboard(result);
			this.setTypeOp();
			this.data = this.getCapsByDate();
		}, error => {
			this.ds.lg.showError(error);
		});
	};

	public refreshWasteTypeData(): Promise<Array<WasteTypeItem>> {
		const q = new EntityQuery('WasteTypePartyView').addOrderBy(['wt.code']);
		if (this.model.workCenter && this.model.workCenter.length > 0) {
			q.in('wtp.workCenterId', this.model.workCenter ?? [null]);
		} else {
			q.eq('wtp.workCenterId', null);
		}
		q.fields.push('wtp.wasteTypeId', 'wt.code', 'wt.uom');
		q.distinct = true;
		return new Promise<any>((resolve, reject) => {
			this.entity.load(q).then((result: any) => {
				var items = new Array<WasteTypeItem>();
				result.forEach(item => {
					items.push(new WasteTypeItem(item.wtp_wasteTypeId as string, item.wt_uom as number, `${item.wt_code} [${this.wtp.getUomName(item.wt_uom)}]`));
				});
				WasteTypeItem.uom = undefined;
				resolve(items);
			}, error => {
				reject(error);
			});
		});
	}

	initDashboard(data: any) {
		this.result.capitalization = this.getValues(data.data, data.lastData || null, 'cap');
		this.result.totalCapitalizationText = this.totalQuantityTxt(this.result.capitalization, 'cap');
		this.result.intervalPeriod = 'Interval: ' + this.formatDate(this.model.fromDate) + ' - '
			+ this.formatDate(this.model.thruDate);
	}

	protected load(): void {
	}

	private setTypeOp() {
		if (this.model.lastYear) {
			this.typeOp = [{ value: 'cap', name: this.L('VALORIZATION') }, { value: 'lastcap', name: this.L('LAST_VALORIFIED') }];
		} else {
			this.typeOp = [{ value: 'cap', name: this.L('VALORIZATION') }];
		}
	}

	private getCapsByDate() {
		let fromDate = new Date(this.model.fromDate);
		let thruDate = new Date(this.model.thruDate);
		thruDate = new Date(thruDate.setDate(thruDate.getDate() + 1));
		if (this.model.lastYear) {
			fromDate = new Date(fromDate.setFullYear(fromDate.getFullYear() - 1));
		}
		const q = new EntityQuery('TransportView').in('t_workCenterId', this.model.workCenter).in('t_wasteTypeId', this.model.wasteType)
			.neq('t_capitalizationId', null).addOrderBy(['t.no']);
		q.gte('t.sendDate', this.entity.toDateTimeFilter(this.convertToDate(fromDate)));
		q.lte('t.sendDate', this.entity.toDateTimeFilter(this.convertToDate(thruDate)));
		q.fields.push('t.id', 't.sendDate', 't.quantity', 't.valuedQuantity', 'wt.code', 'fullName');
		return this.entity.store(q, false, 't_id');
	}

	private totalQuantityTxt(ds: any, key: string) {
		const uom = (this.model.wasteUom === 1 ? this.L('TONES', null) : (this.model.wasteUom === 2 ?
			this.L('MC', null) : this.L('KG', null)));
		let text = this.L('TOTAL_QUANTITY', null) + ' ' + this.getSum(ds, key) + ' ' + uom;
		if (this.model.lastYear) {
			text += '\n' + this.L('LAST_YEAR_TOTAL_QUANTITY', null) + ' ' + this.getSum(ds, 'last' + key) + ' ' + uom;
		}
		return text;
	}

	private getValues(actualData: any[], lastData: any, key: string) {
		if (!lastData) {
			return actualData;
		}
		lastData.forEach(element => {
			actualData[element.day - 1]['last' + key] = element[key];
		});
		return actualData;
	}

	private getSum(arr: any[], field: string) {
		let total = 0;
		for (const item of arr) {
			total += item[field];
		}
		return total.toFixed(2);
	}

	private convertToDate(date: any) {
		const d = new Date(date);
		d.setHours(0, 0, 0, 0);
		return d;
	}

	private formatDate(date: any) {
		const d = new Date(this.convertToDate(date));
		return this.entity.toD2(d.getDate()) + '.' + this.entity.toD2((d.getMonth() + 1)) + '.' + d.getFullYear();
	}
}

export class Result {
	public capitalization: any[];
	public totalCapitalizationText: string;
	public intervalPeriod: string;
	constructor() {
		this.capitalization = [];
		this.totalCapitalizationText = '';
		this.intervalPeriod = '';
	};
}
export class WasteTypeItem {
	public static uom: number;

	id: string;
	uom: number;
	text?: string;
	disabled: boolean = false;

	constructor(id: string, uom: number, text: string) {
		this.id = id;
		this.uom = uom;
		this.text = text;
	}
}
