<dx-data-grid #gridCar [height]="400" (onToolbarPreparing)="toolbarPreparing($event, false)" 
    (onInitialized)="onGridInit($event)" (onInitNewRow)='onInitRow($event)'>

    <dxo-scrolling [mode]="ui.scrollingMode" [showScrollbar]="ui.showScrollbar" [useNative]="!ui.isWindows">
    </dxo-scrolling>
    <dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" [storageKey]='key'>
    </dxo-state-storing>

    <dxo-editing [allowUpdating]="proxy.allowEdit" [allowDeleting]="proxy.allowDelete" [allowAdding]="proxy.allowEdit">
    </dxo-editing>
    <dxi-column type="buttons" fixedPosition="left" [minWidth]="proxy.actionsWidth" [width]="proxy.actionsWidth" 
        [fixed]="true" [allowResizing]="false" [showInColumnChooser]="false">
        <dxi-button [hint]="L('EDIT')" name="edit"></dxi-button>
        <dxi-button [hint]="L('DELETE')" name="delete"></dxi-button>
    </dxi-column>

    <dxi-column [caption]="L('REGISTER_NO')" dataField="registerNo" dataType="string">
        <dxi-validation-rule type="required" message="L('REGISTERNO_IS_REQUIRED')">
        </dxi-validation-rule>
        <dxi-validation-rule type="stringLength"  [max]="256" [message]="L('NAME_LENGTH')">
		</dxi-validation-rule>
    </dxi-column>
    <dxi-column [caption]="L('VALIDITY')" dataField="validUntil" dataType="date" calculateSortValue="validUntil"
        [calculateCellValue]="iui.formatDate">
    </dxi-column>
    <dxi-column [caption]="L('OBSERVATIONS')" dataField="observations" dataType="string">
    </dxi-column>
</dx-data-grid>