import { Component, ViewChild } from '@angular/core';
import { iBisViewComponent } from '@dohu/ibis-common';
import { ProxyService } from '../../../service/proxy.service';
import { DepartmentEditService } from './department-edit.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';

@Component({
	selector: 'app-department',
	templateUrl: './department.component.html'
})
export class DepartmentComponent extends iBisViewComponent {
	@ViewChild('departmentGrid') protected grid: DxDataGridComponent;

	public edit: DepartmentEditService;
	constructor(public proxy: ProxyService) {
		super(proxy.department, proxy.lg, proxy.iui, proxy.activatedRoute);
		this.key = 'department';
	}

	onInitNewRow(e: any) {
		e.data.isActiv = true;
	}
}
