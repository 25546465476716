<dx-form [colCount]="2" [formData]="edit.model" [showValidationSummary]="false" [validationGroup]="edit.validation"
	labelLocation="left">
	<dxi-item [label]="{text: L('BODY_CONTROL')}" dataField="authority" editorType="dxTextBox" [editorOptions]="{ }">
		<dxi-validation-rule type="required"></dxi-validation-rule>
		<dxi-validation-rule type="stringLength"  [max]="256" [message]="L('NAME_LENGTH')">
		</dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('DATE')}" dataField="date" editorType="dxDateBox"
		[editorOptions]="{displayFormat: 'dd.MM.yyyy', width: '100%'}">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [colSpan]="2" [label]="{text: L('ENT_WORKC')}" dataField="partyId" editorType="dxSelectBox"
		[editorOptions]="{dataSource: proxy.party.ownUnit, valueExpr: 'c_id', displayExpr: 'fullName' }">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('TYPE')}" dataField="typeId" editorType="dxSelectBox"
		[editorOptions]="{ dataSource: edit.type, valueExpr: 'id', displayExpr: 'value'}">
	</dxi-item>
	<dxi-item [label]="{text: L('PLANNED')}" dataField="planned" editorType="dxCheckBox" [editorOptions]="{ }">
	</dxi-item>
	<dxi-item [label]="{text: L('REASON')}" dataField="reason" editorType="dxTextBox" [editorOptions]="{ }">
	</dxi-item>
	<dxi-item [label]="{text: L('CASES')}" dataField="cases" editorType="dxTextBox" [editorOptions]="{ }">
	</dxi-item>
	<dxi-item [label]="{text: L('SANCTIONS')}" dataField="sanction" editorType="dxCheckBox" [editorOptions]="{ }">
	</dxi-item>
	<dxi-item [label]="{text: L('MEASURES')}" dataField="action" editorType="dxCheckBox" [editorOptions]="{ }">
	</dxi-item>
	<dxi-item [label]="{text: L('GENERATED_COST')}" dataField="cost" editorType="dxNumberBox" [editorOptions]="{ }">
	</dxi-item>
	<dxi-item [label]="{text: L('COST_DESCRIPTION')}" dataField="costDescription" editorType="dxTextBox"
		[editorOptions]="{ }"></dxi-item>
</dx-form>