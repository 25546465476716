/* eslint-disable no-bitwise */
import { Component } from '@angular/core';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { ProxyService } from '../../../service/proxy.service';
import { TransportFinishService } from './transport-finish.service';

@Component({
	selector: 'app-transport-finish',
	templateUrl: 'transport-finish.component.html'
})
export class TransportFinishComponent extends iBisBaseComponent {
	public edit: TransportFinishService;
	formInstance: any;

	disableElimination: boolean;
	disableCapitalization: boolean;

	constructor(public proxy: ProxyService) {
		super(proxy.transportFinish, proxy.lg, proxy.iui, proxy.activatedRoute);
	}

	onFormInit(event: { component: any }) {
		this.formInstance = event.component;
	}

	checkValue(val: number) {
		if (val || val === 0) {
			return true;
		}
		return false;
	}

	initDisableCondition() {
		this.disableCapitalization = (this.edit.model.actions & 8) === 8 && this.edit.model.actions < 24;
		this.disableElimination = (this.edit.model.actions & 16) === 16 && this.edit.model.actions < 24;
	}

	setDisable(e: { dataField: string }) {
		if (e.dataField === 'eliminatedQuantity') {
			this.initDisableCondition();
		}
	}

	eQuantityValidation = (options: { value: number }) => {
		let vQuantity: number;
		switch (this.edit.wasteType) {
			case '0':
				vQuantity = this.formInstance.getEditor('valuedQuantity') ?
					this.formInstance.getEditor('valuedQuantity').option('value') : null;
				if (vQuantity === 0 && options.value === 0) {
					return false;
				}
				if (vQuantity === 0 || options.value === 0) {
					return true;
				}
				return vQuantity || options.value;
			case '1':
				vQuantity = this.formInstance.getEditor('valuedQuantity') ?
					this.formInstance.getEditor('valuedQuantity').option('value') : null;
				const elimQuantity = this.formInstance.getEditor('eliminatedQuantity') ?
					this.formInstance.getEditor('eliminatedQuantity').option('value') : null;
				const expoQuantity = this.formInstance.getEditor('exportedQuantity') ?
					this.formInstance.getEditor('exportedQuantity').option('value') : null;
				const recyQuantity = this.formInstance.getEditor('recycledQuantity') ?
					this.formInstance.getEditor('recycledQuantity').option('value') : null;
				if (vQuantity === 0 && elimQuantity === 0 && expoQuantity === 0 && recyQuantity === 0 && options.value === 0) {
					return false;
				}
				if (vQuantity === 0 || elimQuantity === 0 || expoQuantity === 0 || recyQuantity === 0 || options.value === 0) {
					return true;
				}
				return vQuantity || elimQuantity || expoQuantity || recyQuantity || options.value;
		}

	};

	eCodeValidation = (options: { value: any }) => {
		let vQuantity: number;
		let eQuantity: number;
		let condition: boolean;
		switch (this.edit.wasteType) {
			case '0':
				eQuantity = this.formInstance.getEditor('eliminatedQuantity') ?
					this.formInstance.getEditor('eliminatedQuantity').option('value') : null;
				vQuantity = this.formInstance.getEditor('valuedQuantity') ?
					this.formInstance.getEditor('valuedQuantity').option('value') : null;
				condition = (this.checkValue(eQuantity) && !vQuantity) || (this.checkValue(eQuantity) && this.checkValue(vQuantity));
				if (condition) {
					if (eQuantity === 0) {
						return true;
					}
					return options.value;
				} else {
					return true;
				}
			case '1':
				eQuantity = this.formInstance.getEditor('eliminatedQuantity') ?
					this.formInstance.getEditor('eliminatedQuantity').option('value') : null;
				vQuantity = this.formInstance.getEditor('valuedQuantity') ?
					this.formInstance.getEditor('valuedQuantity').option('value') : null;
				const recyQuantity = this.formInstance.getEditor('recycledQuantity') ?
					this.formInstance.getEditor('recycledQuantity').option('value') : null;
				condition = (this.checkValue(eQuantity) && !vQuantity && !recyQuantity) ||
					(this.checkValue(eQuantity) && this.checkValue(vQuantity) && !recyQuantity) ||
					(this.checkValue(eQuantity) && this.checkValue(recyQuantity) && !vQuantity) ||
					(this.checkValue(eQuantity) && this.checkValue(recyQuantity) && this.checkValue(vQuantity));
				if (condition) {
					if (eQuantity === 0) {
						return true;
					}
					return options.value;
				} else {
					return true;
				}
		}
	};

	vQuantityValidation = (options: any) => {
		switch (this.edit.wasteType) {
			case '0':
				const eQuantity = this.formInstance.getEditor('eliminatedQuantity') ?
					this.formInstance.getEditor('eliminatedQuantity').option('value') : null;
				if (eQuantity === 0 && options.value === 0) {
					return false;
				}
				if (eQuantity === 0 || options.value === 0) {
					return true;
				}
				return eQuantity || options.value;
			case '1':
				const expQuantity = this.formInstance.getEditor('exportedQuantity') ?
					this.formInstance.getEditor('exportedQuantity').option('value') : null;
				const recQuantity = this.formInstance.getEditor('recycledQuantity') ?
					this.formInstance.getEditor('recycledQuantity').option('value') : null;
				const elimQuantity = this.formInstance.getEditor('eliminatedQuantity') ?
					this.formInstance.getEditor('eliminatedQuantity').option('value') : null;
				if (expQuantity === 0 && recQuantity === 0 && elimQuantity === 0 && options.value === 0) {
					return false;
				}
				if (expQuantity > 0 || recQuantity > 0 || elimQuantity > 0 || options.value > 0) {
					return true;
				}
				return expQuantity || recQuantity || elimQuantity || options.value;
			case '2':
				const reuQuantity = this.formInstance.getEditor('reusedQuantity') ?
					this.formInstance.getEditor('reusedQuantity').option('value') : null;
				const reccQuantity = this.formInstance.getEditor('recycledQuantity') ?
					this.formInstance.getEditor('recycledQuantity').option('value') : null;
				if (reuQuantity === 0 && reccQuantity === 0 && options.value === 0) {
					return false;
				}
				if (reuQuantity > 0 || reccQuantity > 0 || options.value > 0) {
					return true;
				}
				return reuQuantity || reccQuantity || options.value;
		}
	};

	vCodeValidation = (options: { value: any }) => {
		let vQuantity: number;
		let condition: boolean;
		switch (this.edit.wasteType) {
			case '0':
				vQuantity = this.formInstance.getEditor('valuedQuantity') ?
					this.formInstance.getEditor('valuedQuantity').option('value') : null;
				const eQuantity = this.formInstance.getEditor('eliminatedQuantity') ?
					this.formInstance.getEditor('eliminatedQuantity').option('value') : null;
				condition = (this.checkValue(vQuantity) && !eQuantity) || (this.checkValue(eQuantity) && this.checkValue(vQuantity));
				if (condition) {
					if (vQuantity === 0) {
						return true;
					}
					return options.value;
				} else {
					return true;
				}
			case '1':
				vQuantity = this.formInstance.getEditor('valuedQuantity') ?
					this.formInstance.getEditor('valuedQuantity').option('value') : null;
				const recQuantity = this.formInstance.getEditor('recycledQuantity') ?
					this.formInstance.getEditor('recycledQuantity').option('value') : null;
				const elimQuantity = this.formInstance.getEditor('eliminatedQuantity') ?
					this.formInstance.getEditor('eliminatedQuantity').option('value') : null;
				condition = (this.checkValue(vQuantity) && !recQuantity && !elimQuantity) ||
					(this.checkValue(recQuantity) && this.checkValue(vQuantity) && !elimQuantity) ||
					(this.checkValue(recQuantity) && this.checkValue(elimQuantity) && !vQuantity) ||
					(this.checkValue(recQuantity) && this.checkValue(elimQuantity) && this.checkValue(vQuantity));
				if (condition) {
					if (vQuantity === 0) {
						return true;
					}
					return options.value;
				} else {
					return true;
				}
			case '2':
				vQuantity = this.formInstance.getEditor('valuedQuantity') ?
					this.formInstance.getEditor('valuedQuantity').option('value') : null;
				const rQuantity = this.formInstance.getEditor('recycledQuantity') ?
					this.formInstance.getEditor('recycledQuantity').option('value') : null;
				condition = (this.checkValue(vQuantity) && !rQuantity) || (this.checkValue(rQuantity) && this.checkValue(vQuantity));
				if (condition) {
					if (vQuantity === 0) {
						return true;
					}
					return options.value;
				} else {
					return true;
				}
		}
	};

	recQuantityValidation = (options: { value: number }) => {
		let vQuantity: number;
		switch (this.edit.wasteType) {
			case '1':
				vQuantity = this.formInstance.getEditor('valuedQuantity') ?
					this.formInstance.getEditor('valuedQuantity').option('value') : null;
				const expQuantity = this.formInstance.getEditor('exportedQuantity') ?
					this.formInstance.getEditor('exportedQuantity').option('value') : null;
				const eQuantity = this.formInstance.getEditor('eliminatedQuantity') ?
					this.formInstance.getEditor('eliminatedQuantity').option('value') : null;
				if (vQuantity === 0 && expQuantity === 0 && eQuantity === 0 && options.value === 0) {
					return false;
				}
				if (vQuantity > 0 || expQuantity > 0 || eQuantity > 0 || options.value > 0) {
					return true;
				}
				return vQuantity || expQuantity || eQuantity || options.value;
			case '2':
				vQuantity = this.formInstance.getEditor('valuedQuantity') ?
					this.formInstance.getEditor('valuedQuantity').option('value') : null;
				const reuQuantity = this.formInstance.getEditor('reusedQuantity') ?
					this.formInstance.getEditor('reusedQuantity').option('value') : null;
				if (vQuantity === 0 && reuQuantity === 0 && options.value === 0) {
					return false;
				}
				if (vQuantity > 0 || reuQuantity > 0 || options.value > 0) {
					return true;
				}
				return vQuantity || reuQuantity || options.value;
		}
	};

	recCodeValidation = (options: { value: any }) => {
		const recQuantity = this.formInstance.getEditor('recycledQuantity') ?
			this.formInstance.getEditor('recycledQuantity').option('value') : null;
		const vQuantity = this.formInstance.getEditor('valuedQuantity') ?
			this.formInstance.getEditor('valuedQuantity').option('value') : null;
		const eQuantity = this.formInstance.getEditor('eliminatedQuantity') ?
			this.formInstance.getEditor('eliminatedQuantity').option('value') : null;
		const condition = (this.checkValue(recQuantity) && !vQuantity && !eQuantity) ||
			(this.checkValue(recQuantity) && this.checkValue(vQuantity) && !eQuantity) ||
			(this.checkValue(recQuantity) && this.checkValue(eQuantity) && !vQuantity) ||
			(this.checkValue(recQuantity) && this.checkValue(vQuantity) && this.checkValue(eQuantity));
		if (condition) {
			if (recQuantity === 0) {
				return true;
			}
			return options.value;
		} else {
			return true;
		}
	};

	expQuantityValidation = (options: { value: number }) => {
		const vQuantity = this.formInstance.getEditor('valuedQuantity') ?
			this.formInstance.getEditor('valuedQuantity').option('value') : null;
		const recQuantity = this.formInstance.getEditor('recycledQuantity') ?
			this.formInstance.getEditor('recycledQuantity').option('value') : null;
		const eQuantity = this.formInstance.getEditor('eliminatedQuantity') ?
			this.formInstance.getEditor('eliminatedQuantity').option('value') : null;
		if (vQuantity === 0 && recQuantity === 0 && eQuantity === 0 && options.value === 0) {
			return false;
		}
		if (vQuantity > 0 || recQuantity > 0 || eQuantity > 0 || options.value > 0) {
			return true;
		}
		return vQuantity || recQuantity || eQuantity || options.value;
	};

	reuQuantityValidation = (options: { value: number }) => {
		const vQuantity = this.formInstance.getEditor('valuedQuantity') ?
			this.formInstance.getEditor('valuedQuantity').option('value') : null;
		const recQuantity = this.formInstance.getEditor('recycledQuantity') ?
			this.formInstance.getEditor('recycledQuantity').option('value') : null;
		const eQuantity = this.formInstance.getEditor('eliminatedQuantity') ?
			this.formInstance.getEditor('eliminatedQuantity').option('value') : null;
		if (vQuantity === 0 && recQuantity === 0 && eQuantity === 0 && options.value === 0) {
			return false;
		}
		if (vQuantity > 0 || recQuantity > 0 || eQuantity > 0 || options.value > 0) {
			return true;
		}
		return vQuantity || recQuantity || eQuantity || options.value;
	};

	receptionDateValidation = (options: { value: Date }) => {
		const tsDate = this.formInstance.getEditor('tempStockDate') ?
			this.formInstance.getEditor('tempStockDate').option('value') : null;
		return tsDate || options.value;
	};

	tempStockDateValidation = (options: { value: Date }) => {
		const rDate = this.formInstance.getEditor('receptionDate') ?
			this.formInstance.getEditor('receptionDate').option('value') : null;
		return rDate || options.value;
	};
}
