import { Injectable } from '@angular/core';
import { iBisEntityService } from '@dohu/ibis-entity';
import { iBisEditService, iBisLanguageService } from '@dohu/ibis-common';

@Injectable({
	providedIn: 'root'
})
export class SafetyPlanningCopyService extends iBisEditService {
	constructor(public entity: iBisEntityService, lg: iBisLanguageService) {
		super(lg);
		this.title = 'COPY_DATA';
		this.validation = 'safetyPlanningCopyValidation';
	}
	createDefault() {
		return {};
	}
	getById(id: string, serverUrl?: string): Promise<any> {
		throw new Error('Method not implemented.');
	}
	onRemove(id: string): Promise<void> {
		throw new Error('Method not implemented.');
	}
	onSaveEv(serverUrl?: string): Promise<any> {
		return new Promise<any>((resolve,reject) =>{
			this.entity.execute('CloneSafetyPlanning', {
				fmonth: new Date(this.model.fromDate).getMonth() + 1,
				fyear: new Date(this.model.fromDate).getFullYear(),
				tmonth: new Date(this.model.thruDate).getMonth() + 1,
				tyear: new Date(this.model.thruDate).getFullYear()
			}).then(() => resolve(null)).catch(err => reject(err));
		});
	}
	reset(): void {
		this.model = this.createDefault();
	}
}
