<dx-data-grid class="gridContainer box-shadow" #gridAuditor [columnAutoWidth]="true" [height]="ui.fullHeight"
    (onToolbarPreparing)="toolbarPreparing($event, proxy.allowEdit)" [remoteOperations]="true" [dataSource]="edit.data">

    <dxo-scrolling mode="infinite" [showScrollbar]="ui.showScrollbar" [useNative]="!ui.isWindows">
    </dxo-scrolling>
	<dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" [storageKey]="key">
	</dxo-state-storing>
    <dxo-master-detail [enabled]="true" template="details"></dxo-master-detail>

    <dxi-column type="buttons" [width]="60" fixedPosition="right" [fixed]="true" [allowResizing]="false"
        [showInColumnChooser]="false">
        <dxi-button [hint]="L('EDIT')" icon="edit" [onClick]="onEditClick" [visible]="proxy.allowEdit"></dxi-button>
        <dxi-button [hint]="L('DELETE')" icon="tarsh" [onClick]="onDeleteClick" [visible]="proxy.allowDelete">
        </dxi-button>
    </dxi-column>

    <dxi-column [caption]="L('MARK')" dataField="mark" dataType="number"></dxi-column>
    <dxi-column [caption]="L('NAME')" dataField="displayName" dataType="string"></dxi-column>
    <dxi-column [caption]="L('USER')" dataField="userId" dataType="string">
        <dxo-lookup [dataSource]="proxy.user.data" valueExpr="u_id" displayExpr="u_fullName"></dxo-lookup>
    </dxi-column>
    <dxi-column [caption]="L('JOB')" dataField="jobId" dataType="string">
        <dxo-lookup [dataSource]="edit.job" valueExpr="id" displayExpr="description"></dxo-lookup>
    </dxi-column>
    <dxi-column [caption]="L('DEPARTMENT')" dataField="departmentId" dataType="string">
        <dxo-lookup [dataSource]="proxy.department.data" valueExpr="id" displayExpr="name"></dxo-lookup>
    </dxi-column>
    <dxi-column [caption]="L('START_DATE')" dataField="startDate" dataType="date" calculateSortValue="startDate"
        [calculateDisplayValue]="iui.formatDate" [width]="120"></dxi-column>
    <dxi-column [caption]="L('FINISH_DATE')" dataField="endDate" dataType="date" calculateSortValue="endDate"
        [calculateDisplayValue]="iui.formatDate" [width]="120"></dxi-column>

    <div *dxTemplate="let auditor of 'details'">
        <dx-tab-panel>
            <dxi-item itemTemplate="tabActivity" [title]="L('PERMISSION')">
                <div *dxTemplate="let tab of 'tabActivity'">
                    <app-permission [auditorId]='auditor.data.id'></app-permission>
                </div>
            </dxi-item>
        </dx-tab-panel>
    </div>

</dx-data-grid>

<ibis-edit-popup [edit]="edit" [showAdd]="true">
    <app-auditor-edit></app-auditor-edit>
</ibis-edit-popup>