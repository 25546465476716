import { Injectable } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { DataService } from '../../../service/data.service';
import { TransportEditService } from './../transport-edit.service';
import { iBisFileService, iBisLanguageService, iBisServerConfig } from '@dohu/ibis-common';
import { WasteTypeEditService } from '../../waste-type/waste-type-edit.service';
import { PartyEditService } from '../../../common/party/party-edit/party-edit.service';
import { EntityConditionGroup, EntityQuery, iBisEntityConfig, iBisEntityService } from '@dohu/ibis-entity';
import { iBisAuthService } from '@dohu/ibis-auth';

@Injectable()
export class TransportFinishService extends TransportEditService {
	constructor(auth: iBisAuthService,protected entity: iBisEntityService, public ds: DataService, wtp: WasteTypeEditService,
		party: PartyEditService, lg: iBisLanguageService, config: iBisServerConfig, protected entityConfig: iBisEntityConfig, protected file: iBisFileService) {
		super(auth, entity, ds, wtp, party, lg, entityConfig, config, file);
		this.title = 'TRANSPORT_COMPLETE';
		this.validation = 'finishValidation';
	}

	public getById(id: string): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			super.getById(id).then(obj => {
				this.entity.getById('TransportView', id, 't.id').then(result => {
					if (result) {
						const d = new Date(result.t_sendDate);
						const info = {
							noDate: (result.t_no ? result.t_no : '-') + ' / ' +
								d.getDate() + '.' + (d.getMonth() + 1) + '.' + d.getFullYear(),
							carTransporter: (result.car_registerNo ? result.car_registerNo : '-') + ' / ' + result.tr_name,
							fullName: result.fullName,
							recipient: result.rt_name,
							code: result.wt_code
						};
						obj.info = info;
					}
					resolve(obj);
				}, error => reject());
			}, error => reject());
		});
	}

	public get fromatQuantity(): string{
		return '#,##0.### ' + this.wtp.getUomName(this.model.uom);
	}

	public onRemove(id: string): Promise<any> {
		this.model = {
			capitalizationId: null,
			eliminatedQuantity: null,
			eliminationId: null,
			exportedQuantity: null,
			finishDate: null,
			recycledQuantity: null,
			recycleId: null,
			reusedQuantity: null,
			receptionDate: null,
			tempStockDate: null,
			treatmentTypeId: null,
			valuedQuantity: null
		};
		return this.entity.save('Transport', id, this.model);
	}

	protected getData(): CustomStore {
		const q = new EntityQuery('TransportView').addOrderByDesc(['t.sendDate']);
		if (this.tempStock) {
			q.eq('t_finishDate', null);
			const group = new EntityConditionGroup();
			group.neq('t_receptionDate', null).neq('t_tempStockDate', null);
			group.useOr = true;
			q.conditionGroups.groups.push(group);
		} else {
			q.neq('t_finishDate', null);
		}
		if (this.wasteType != null && this.wasteType !== '') {
			q.link('t.wasteTypeId', 'id', new EntityQuery('WasteType').eq('typeId', this.wasteType));
		}
		return this.entity.store(q, false, 't_id');
	}
}
