<app-control-analyzes-filter></app-control-analyzes-filter>
<div class="analyzesChart">
  <app-graph-line [txt]="{ title: L('CONTROLS'), description: edit.result.totalControlText }"
    [typeValue]="edit.result.control" [typeOp]="edit.typeOp" [uom]="null"
    [intervalDate]="edit.result.intervalPeriod"></app-graph-line>
</div>

<div class="analyzesGrid">
  <dx-data-grid height="100%" [dataSource]="edit.data" [columnAutoWidth]="true" [allowColumnResizing]="true"
    [allowColumnReordering]="true" [remoteOperations]="true" (onToolbarPreparing)="toolbarPreparing($event, false)">

    <dxo-scrolling [mode]="ui.scrollingMode" [showScrollbar]="ui.showScrollbar" [useNative]="!ui.isWindows">
    </dxo-scrolling>
    <dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" storageKey="qhseControlAnalyzes">
    </dxo-state-storing>
    <dxo-export [enabled]="ui.exportEnabled" fileName="controls"></dxo-export>

    <dxi-column [caption]="L('DATE')" dataField="c_date" dataType="date" width="170" calculateSortValue="c_date"
      [calculateCellValue]="iui.formatDate"></dxi-column>
    <dxi-column [caption]="L('BODY_CONTROL')" dataField="c_authority" dataType="string"></dxi-column>
    <dxi-column [caption]="L('ENT_WORKC')" dataField="fullName" dataType="string"></dxi-column>
    <dxi-column [caption]="L('PAID')" dataField="c_cost" dataType="number"></dxi-column>
  </dx-data-grid>
</div>