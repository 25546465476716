<div class="flex-row">
	<div class="filter-form">
		<dx-form [formData]="edit.model">
			<dxi-item itemType="group" [colSpan]="1" [colCount]="6">
				<dxi-item [label]="{text: L('WORK_CENTER')}" [colSpan]="6">
					<dx-tag-box [dataSource]="proxy.party.ownWorkCenter" displayExpr="fullName" valueExpr="c_id"
						[multiline]="false" [searchEnabled]="true" [showSelectionControls]="true"
						placeholder="Selectează un punct de lucru..." selectAllMode="allPages"
						[(value)]="edit.model.workCenter">
					</dx-tag-box>
				</dxi-item>
			</dxi-item>
			<dxi-item itemType="group" [colSpan]="1" [colCount]="4">
				<dxi-item [label]="{text: L('INTERVAL')}" dataField="interval" editorType="dxSelectBox" [colSpan]="1"
					[editorOptions]="{ dataSource: edit.interval, valueExpr: 'id', displayExpr: 'text'}">
				</dxi-item>
				<dxi-item [label]="{text: L('DATE')}" dataField="date" editorType="dxDateBox" [editorOptions]="{displayFormat: edit.model.interval == 1 ? 'MM.yyyy' : 'yyyy', 
				width: 'auto', calendarOptions: {minZoomLevel: edit.model.interval == 1 ? 'year' : 'decade', maxZoomLevel: edit.model.interval == 1 ? 'year': 'decade' }}">
				</dxi-item>
				<dxi-item [label]="{text: L('COMPARE_LAST_YEAR'), location: 'left'}" dataField="lastYear" editorType="dxCheckBox">
				</dxi-item>
				<dxi-item [label]="{text: ''}" editorType="dxButton"
					[editorOptions]="{text:'Filtrează', onClick: edit.filterData}">
				</dxi-item>
			</dxi-item>
		</dx-form>
	</div>
</div>