import { Component } from '@angular/core';
import { ProxyService } from '../../service/proxy.service';
import { WasteTypeEditService } from './waste-type-edit.service';
import { BreadcrumbViewComponent } from '../../framework/breadcrumb-view.component';

@Component({
	templateUrl: 'waste-file.component.html'
})
export class WasteFileComponent extends BreadcrumbViewComponent {
	public edit: WasteTypeEditService;

	constructor(public proxy: ProxyService) {
		super(proxy.wasteType, proxy.lg, proxy.iui, proxy.activatedRoute);
	}
}
