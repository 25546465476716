<dx-data-grid #permitGrid class="gridContainer" [dataSource]="edit.data"
	[height]="height" [remoteOperations]="true" (onToolbarPreparing)="toolbarPreparing($event, proxy.allowEdit)"
	(onInitialized)="onGridInit($event)">

	<dxo-scrolling [mode]="ui.scrollingMode" showScrollbar="ui.showScrollbar" [useNative]="!ui.isWindows">
	</dxo-scrolling>
	<dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" [storageKey]="key"></dxo-state-storing>
	<dxo-master-detail [enabled]="true" template="details"></dxo-master-detail>

	<dxi-column type="buttons" fixedPosition="left" [minWidth]="proxy.actionsWidth" [width]="proxy.actionsWidth"
		[fixed]="true" [allowResizing]="false" [visible]="proxy.showActions" [showInColumnChooser]="false">
		<dxi-button [hint]="L('EDIT')" icon="edit" [onClick]="onEditClick" [visible]="proxy.allowEdit">
		</dxi-button>
		<dxi-button [hint]="L('DELETE')" icon="trash" [onClick]="onDeleteClick" [visible]="proxy.allowDelete">
		</dxi-button>
	</dxi-column>

	<dxi-column name='document' headerCellTemplate='docHeader'>
		<dxi-column [caption]="L('NO')" dataField="p_no" dataType="string" [minWidth]="170"
			[allowHeaderFiltering]="false">
		</dxi-column>
		<dxi-column [caption]="L('DOCUMENT_TYPE')" dataField="p_categoryId" dataType="string" [minWidth]="280"
			[allowHeaderFiltering]="false">
			<dxo-lookup [dataSource]="edit.category" valueExpr="id" displayExpr="description"></dxo-lookup>
		</dxi-column>
		<dxi-column [caption]="L('DESCRIPTION')" dataField="p_description" dataType="string" [minWidth]="170"
			[allowHeaderFiltering]="false"></dxi-column>
	</dxi-column>
	<dxi-column [caption]="L('ENT_WORKC')" dataField="fullName" dataType="string" [visible]='!isSubtab' [minWidth]="170"
		[allowHeaderFiltering]="false">
	</dxi-column>
	<dxi-column name='valability' headerCellTemplate='vHeader'>
		<dxi-column [caption]="L('VALIDITY')" dataField="p_validity" [minWidth]="170" dataType="date"
			calculateSortValue="p_validity" [calculateDisplayValue]="iui.formatDate"></dxi-column>
		<dxi-column [caption]="L('REVISED_ON_DATE')" dataField="p_revision" [minWidth]="170" dataType="date"
			calculateSortValue="p_revision" [calculateDisplayValue]="iui.formatDate"></dxi-column>
		<dxi-column [caption]="L('VALID')" dataField="p_isValid" dataType="boolean" [minWidth]="110"
			[allowHeaderFiltering]="false">
		</dxi-column>
	</dxi-column>

	<div *dxTemplate="let data of 'breadcrumb'">
		<ibis-breadcrumb></ibis-breadcrumb>
	</div>
	<div *dxTemplate="let data of 'details'">
		<ibis-file [entityId]="data.data.p_id" [allowDelete]="proxy.allowDelete" [allowUpload]="proxy.allowEdit"></ibis-file>
	</div>
	<div *dxTemplate="let c of 'docHeader'" class="dx-datagrid-text-content" role="presentation">
		<i class="fas fa-project-diagram"></i> Document
	</div>
	<div *dxTemplate="let c of 'vHeader'" class="dx-datagrid-text-content" role="presentation">
		<i class="fas fa-calendar-week"></i> Valabilitate
	</div>
</dx-data-grid>

<ibis-edit-popup [edit]="edit" [showAdd]="true">
	<app-permit-edit></app-permit-edit>
</ibis-edit-popup>