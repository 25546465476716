import { Component } from '@angular/core';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { ProxyService } from '../../service/proxy.service';
import { ControlEditService } from './control-edit.service';

@Component({
	selector: 'app-control-edit',
	templateUrl: 'control-edit.component.html'
})

export class ControlEditComponent extends iBisBaseComponent {
	public edit: ControlEditService;

	constructor(public proxy: ProxyService) {
		super(proxy.control, proxy.lg, proxy.iui, proxy.activatedRoute);
	}
}
