import { Component } from '@angular/core';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { ProxyService } from '../../../service/proxy.service';
import { MonthlyStockRecheckService } from './monthly-stock-recheck.service';

@Component({
	selector: 'app-monthly-stock-recheck',
	templateUrl: './monthly-stock-recheck.component.html'
})
export class MonthlyStockRecheckComponent extends iBisBaseComponent {
	public edit: MonthlyStockRecheckService;
	public year: { min: number; current: number };

	constructor(public proxy: ProxyService) {
		super(proxy.monthlyStockRecheck, proxy.lg, proxy.iui, proxy.activatedRoute);
		this.year = { min: new Date().getFullYear() - 15, current: new Date().getFullYear() };
	}
}
