import { iBisViewComponent } from '@dohu/ibis-common';
import { Component, Input, ViewChild } from '@angular/core';
import { ProxyService } from '../../../service/proxy.service';
import { TransporterEditService } from './transporter-edit.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';

@Component({
	selector: 'app-car',
	templateUrl: 'car.component.html'
})
export class CarComponent extends iBisViewComponent {
	@Input() partyId: any;
	@ViewChild('gridCar') grid: DxDataGridComponent;

	public edit: TransporterEditService;
	constructor(public proxy: ProxyService) {
		super(proxy.transporter, proxy.lg, proxy.iui, proxy.activatedRoute);
		this.key = 'car';
	}

	onInitRow(ev: any) {
		ev.data.transporterId = this.partyId;
	}

	onGridInit(e: any) {
		if (e.component.option('dataSource') == null) {
			e.component.option('dataSource', this.edit.getCar(this.partyId));
		}
	}
}
