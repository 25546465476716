import { Routes } from '@angular/router';
import { UserComponent } from './account/user/user.component';
import { PartyComponent } from './common/party/party.component';
import { LoginComponent } from './account/login/login.component';
import { TaxComponent } from './common/control/tax/tax.component';
import { UnitComponent } from './common/party/unit/unit.component';
import { AnalizeComponent } from './waste/analize/analize.component';
import { CollectComponent } from './waste/collect/collect.component';
import { ControlComponent } from './common/control/control.component';
import { AlertComponent } from './waste/report/alert/alert.component';
import { InvoiceComponent } from './common/invoice/invoice.component';
import { GenerateComponent } from './waste/generate/generate.component';
import { ReportExtComponent } from './waste/report/report-ext.component';
import { ReportIntComponent } from './waste/report/report-int.component';
import { TransferComponent } from './waste/transfer/transfer.component';
import { AgencyComponent } from './common/party/agency/agency.component';
import { ContractComponent } from './common/contract/contract.component';
import { ScaleComponent } from './waste/transport/scale/scale.component';
import { PermitComponent } from './common/party/permit/permit.component';
import { RegisterComponent } from './account/register/register.component';
import { TreatmentComponent } from './waste/treatment/treatment.component';
import { TransportComponent } from './waste/transport/transport.component';
import { ArchiveComponent } from './waste/report/archive/archive.component';
import { AuditorComponent } from './safety/admin/auditor/auditor.component';
import { ProfileComponent } from './safety/admin/profile/profile.component';
import { WasteTypeComponent } from './waste/waste-type/waste-type.component';
import { WasteFileComponent } from './waste/waste-type/waste-file.component';
import { IncinerateComponent } from './waste/incinerate/incinerate.component';
import { LocationComponent } from './safety/admin/location/location.component';
import { ProjectComponent } from './common/contract/project/project.component';
import { EnumTypeComponent } from './common/enum/enum-type/enum-type.component';
import { GeneralReportComponent } from './waste/report/general-report.component';
import { DashboardAnalyzesComponent } from './waste/analize/dashboard-analyzes/dashboard-analyzes.component';
import { TreatmentItemComponent } from './waste/treatment/treatment-item.component';
import { DepartmentComponent } from './safety/admin/department/department.component';
import { ActualStockComponent } from './waste/stock/actual-stock/actual-stock.component';
import { MirrorStockComponent } from './waste/stock/mirror-stock/mirror-stock.component';
import { KpiAuditorComponent } from './safety/analize/kpi-auditor/kpi-auditor.component';
import { TaxAnalyzesComponent } from './waste/analize/tax-analyzes/tax-analyzes.component';
import { InconsistentComponent } from './safety/audit/inconsistent/inconsistent.component';
import { KPIAnalyzesComponent } from './waste/analize/kpi-analyzes/kpi-analyzes.component';
import { KpiLocationComponent } from './safety/analize/kpi-location/kpi-location.component';
import { InvoiceItemComponent } from './common/invoice/invoice-item/invoice-item.component';
import { MonthlyStockComponent } from './waste/stock/monthly-stock/monthly-stock.component';
import { SafetyReportComponent } from './safety/audit/safety-report/safety-report.component';
import { CostAnalyzesComponent } from './waste/analize/cost-analyzes/cost-analyzes.component';
import { EcoFireCollectComponent } from '../clients/ecofire/collect/collect.component.ecofire';
import { GeographicAreaComponent } from './common/enum/geographic-area/geographic-area.component';
import { EcoFireGenerateComponent } from '../clients/ecofire/generate/generate.component.ecofire';
import { SafetyPlanningComponent } from './safety/audit/safety-planning/safety-planning.component';
import { TransportFullComponent } from './waste/transport/transport-full/transport-full.component';
import { IncomeAnalyzesComponent } from './waste/analize/income-analyzes/income-analyzes.component';
import { ControlAnalyzesComponent } from './waste/analize/control-analyzes/control-analyzes.component';
import { TransportResultComponent } from './waste/transport/transport-result/transport-result.component';
import { TransportHistoryComponent } from './waste/transport/transport-history/transport-history.component';
import { TransportApprovalComponent } from './waste/transport/transport-approval/transport-approval.component';
import { CapitalizationAnalyzesComponent } from './waste/analize/capitalization-analyzes/capitalization-analyzes.component';
import { iBisAuthService, iBisConfirmInviteComponent, iBisResetPasswordComponent, iBisAuditComponent, iBisChangePasswordComponent } from '@dohu/ibis-auth';

export const routes: Routes = [
	{ path: '', component: LoginComponent, data: [{ showRegister: true, textBoxStyling: 'underlined' }] },
	{
		path: 'analize', component: AnalizeComponent, canActivate: [iBisAuthService],
		children: [
			{ path: 'capitalization', component: CapitalizationAnalyzesComponent, canActivate: [iBisAuthService] },
			{ path: 'kpi', component: KPIAnalyzesComponent, canActivate: [iBisAuthService] },
			{ path: 'cost', component: CostAnalyzesComponent, canActivate: [iBisAuthService] },
			{ path: 'control', component: ControlAnalyzesComponent, canActivate: [iBisAuthService] },
			{ path: 'dashboard', component: DashboardAnalyzesComponent, canActivate: [iBisAuthService] },
			{ path: 'reveneu', component: IncomeAnalyzesComponent, canActivate: [iBisAuthService] },
			{ path: 'tax', component: TaxAnalyzesComponent, canActivate: [iBisAuthService] }
		]
	},
	{
		path: 'waste',
		children: [
			{ path: 'actualStock', component: ActualStockComponent, canActivate: [iBisAuthService] },
			{ path: 'transportResult', component: TransportResultComponent, canActivate: [iBisAuthService] },
			{ path: 'fullTransport', component: TransportFullComponent, canActivate: [iBisAuthService] },
			{ path: 'generate', component: GenerateComponent, canActivate: [iBisAuthService] },
			{ path: 'ecofire-generate', component: EcoFireGenerateComponent, canActivate: [iBisAuthService] },
			{ path: 'historyTransport', component: TransportHistoryComponent, canActivate: [iBisAuthService] },
			{ path: 'mirrorStock', component: MirrorStockComponent, canActivate: [iBisAuthService] },
			{ path: 'scale', component: ScaleComponent, canActivate: [iBisAuthService] },
			{ path: 'transfer', component: TransferComponent, canActivate: [iBisAuthService] },
			{ path: 'transport', component: TransportComponent, canActivate: [iBisAuthService] },
			{ path: 'transport-temp', component: TransportHistoryComponent, canActivate: [iBisAuthService] },
		]
	},
	{
		path: 'document',
		children: [
			{ path: 'archive', component: ArchiveComponent, canActivate: [iBisAuthService] },
			{ path: 'client', component: ContractComponent, canActivate: [iBisAuthService] },
			{ path: 'contract', component: ContractComponent, canActivate: [iBisAuthService] },
			{ path: 'control', component: ControlComponent, canActivate: [iBisAuthService] },
			{ path: 'fat', component: TransportApprovalComponent, canActivate: [iBisAuthService] },
			{ path: 'invoice', component: InvoiceComponent, canActivate: [iBisAuthService] },
			{ path: 'invoice-item', component: InvoiceItemComponent, canActivate: [iBisAuthService] },
			{ path: 'permit', component: PermitComponent, canActivate: [iBisAuthService] },
			{ path: 'project', component: ProjectComponent, canActivate: [iBisAuthService] },
			{ path: 'supplier', component: ContractComponent, canActivate: [iBisAuthService] },
			{ path: 'tax', component: TaxComponent, canActivate: [iBisAuthService] },
			{ path: 'wastefile', component: WasteFileComponent, canActivate: [iBisAuthService] }
		]
	},
	{
		path: 'processing',
		children: [
			{ path: 'collect', component: CollectComponent, canActivate: [iBisAuthService] },
			{ path: 'ecofire-collect', component: EcoFireCollectComponent, canActivate: [iBisAuthService] },
			{ path: 'incinerate', component: IncinerateComponent, canActivate: [iBisAuthService] },
			{ path: 'treatmentOut', component: TreatmentComponent, canActivate: [iBisAuthService] },
			{ path: 'treatmentIn', component: TreatmentItemComponent, canActivate: [iBisAuthService] },
		]
	},
	{
		path: 'report',
		children: [
			{ path: 'monthly', component: MonthlyStockComponent, canActivate: [iBisAuthService] },
			{
				path: 'reportExt',
				children: [
					{ path: 'batery', component: ReportExtComponent, canActivate: [iBisAuthService] },
					{ path: 'electrics', component: ReportExtComponent, canActivate: [iBisAuthService] },
					{ path: 'generic', component: ReportExtComponent, canActivate: [iBisAuthService] },
					{ path: 'oils', component: ReportExtComponent, canActivate: [iBisAuthService] },
					{ path: 'packing', component: ReportExtComponent, canActivate: [iBisAuthService] },
					{ path: 'vehicles', component: ReportExtComponent, canActivate: [iBisAuthService] }
					// { path: 'extractive', component: ReportExtComponent },
					// { path: 'pcbpct', component: ReportExtComponent },
					// {  path: 'mud', component: ReportExtComponent }
				]
			},
			{ path: 'reportGen', component: GeneralReportComponent },
			{ path: 'reportInt', component: ReportIntComponent }
		]
	},
	{
		path: 'admin',
		children: [
			{ path: 'agency', component: AgencyComponent, canActivate: [iBisAuthService] },
			{ path: 'audit', component: iBisAuditComponent, canActivate: [iBisAuthService] },
			{ path: 'geographicareas', component: GeographicAreaComponent, canActivate: [iBisAuthService] },
			{ path: 'nomenclatures', component: EnumTypeComponent, canActivate: [iBisAuthService] },
			{ path: 'party', component: PartyComponent, canActivate: [iBisAuthService] },
			{ path: 'units', component: UnitComponent, canActivate: [iBisAuthService] },
			{ path: 'users', component: UserComponent, canActivate: [iBisAuthService] },
			{ path: 'wastetype', component: WasteTypeComponent, canActivate: [iBisAuthService] }
		]
	},
	{
		path: 'safety',
		children: [
			{
				path: 'admin',
				children: [
					{ path: 'location', component: LocationComponent, canActivate: [iBisAuthService] },
					{ path: 'auditor', component: AuditorComponent, canActivate: [iBisAuthService] },
					{ path: 'department', component: DepartmentComponent, canActivate: [iBisAuthService] },
					{ path: 'profile', component: ProfileComponent, canActivate: [iBisAuthService] }
				]
			},
			{
				path: 'analize',
				children: [
					{ path: 'dashboard', component: DashboardAnalyzesComponent, canActivate: [iBisAuthService] },
					{ path: 'kpi-auditor', component: KpiAuditorComponent, canActivate: [iBisAuthService] },
					{ path: 'kpi-location', component: KpiLocationComponent, canActivate: [iBisAuthService] }
				]
			},
			{
				path: 'audit',
				children: [
					{ path: 'inconsistent', component: InconsistentComponent, canActivate: [iBisAuthService] },
					{ path: 'planning', component: SafetyPlanningComponent, canActivate: [iBisAuthService] },
					{ path: 'report', component: SafetyReportComponent, canActivate: [iBisAuthService] }
				]
			},
		]
	},
	{ path: 'account', component: iBisChangePasswordComponent },
	{ path: 'alert', component: AlertComponent },
	{
		path: 'confirmInvite',
		component: iBisConfirmInviteComponent,
		data: [{ returnUrl: '/' }]
	},
	{
		path: 'login', component: LoginComponent, data:
			[{ showRegister: true, textBoxStyling: 'underlined' }]
	},
	{ path: 'register', component: RegisterComponent },
	{
		path: 'resetPassword',
		component: iBisResetPasswordComponent,
		data: [{ title: 'Setează parola', isReset: true, returnUrl: '/' }]
	}
];
