import { ActivatedRoute } from '@angular/router';
import { iBisEditService, iBisLanguageService, iBisUIService, iBisViewComponent } from '@dohu/ibis-common';

export abstract class BreadcrumbViewComponent extends iBisViewComponent {

	constructor(edit: iBisEditService, lg: iBisLanguageService, iui: iBisUIService, activatedRoute: ActivatedRoute) {
		super(edit, lg, iui, activatedRoute);
	}

	public toolbarPreparing(event: any, showAdd?: boolean, customStore?: boolean): void {
		super.toolbarPreparing(event, showAdd, customStore);
		event.toolbarOptions.items.unshift({
			template: 'breadcrumb',
			location: 'before'
		});
	}
}
