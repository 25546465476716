import notify from 'devextreme/ui/notify';
import { iBisFileService } from '@dohu/ibis-common';
import { Component, ViewChild } from '@angular/core';
import { ProxyService } from '../../service/proxy.service';
import { CollectEditService } from './collect-edit.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { BreadcrumbViewComponent } from '../../framework/breadcrumb-view.component';

@Component({
	selector: 'app-collect',
	templateUrl: './collect.component.html'
})
export class CollectComponent extends BreadcrumbViewComponent {
	@ViewChild('gridCollect') grid: DxDataGridComponent;
	public edit: CollectEditService;

	constructor(public proxy: ProxyService) {
		super(proxy.collect, proxy.lg, proxy.iui, proxy.activatedRoute);
		this.id = 'c_id';
		this.key = 'collect';
	}

	toolbarPreparing(event) {
		super.toolbarPreparing(event, this.proxy.allowEdit);
		if (this.proxy.allowEdit === true) {
			event.toolbarOptions.items.unshift({
				widget: 'dxButton',
				location: 'after',
				options: {
					text: 'Import',
					onClick: (ev: any) => {
						this.edit.uploadCollect().then(() =>{
							this.refresh();
						});
					}
				}
			});
		}
	}
}
