<dx-data-grid #gridTransportApprovalParty [height]="300" (onInitialized)="onGridInit($event)"
    (onToolbarPreparing)="toolbarPreparing($event, true)">
    <dxo-scrolling [mode]="ui.scrollingMode" [showScrollbar]="ui.showScrollbar" [useNative]="!ui.isWindows">
    </dxo-scrolling>
    <dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" [storageKey]='key'>
    </dxo-state-storing>

    <dxo-editing mode="row" [allowAdding]="proxy.allowEdit" [allowDeleting]="proxy.allowDelete"
        [allowUpdating]="proxy.allowEdit"></dxo-editing>

    <dxi-column type="buttons" fixedPosition="left" [minWidth]="ui.actionWidth" [width]="ui.actionWidth" [fixed]="true"
        [allowResizing]="false" [showInColumnChooser]="false" [visible]="proxy.showActions">
        <dxi-button [hint]="L('EDIT')" icon="edit" [onClick]="onEditClick" [visible]="proxy.allowEdit">
        </dxi-button>
    </dxi-column>
    <dxi-column name='transporter' headerCellTemplate='transHeader'>
        <dxi-column [caption]="L('TRANSPORTER')" dataField="tr_name" dataType="string"></dxi-column>
        <dxi-column [caption]="L('CAR')" dataField="car_registerNo" dataType="string"></dxi-column>
        <dxi-column [caption]="L('DRIVER')" dataField='driverName' dataType='string'></dxi-column>
    </dxi-column>

    <div *dxTemplate="let c of 'transHeader'" class="dx-datagrid-text-content" role="presentation">
        <i class="fas fa-truck-moving"></i> Transportator
    </div>
</dx-data-grid>

<ibis-edit-popup [edit]="edit" [showAdd]="true">
	<app-transport-approval-party-edit></app-transport-approval-party-edit>
</ibis-edit-popup>