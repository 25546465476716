<dx-data-grid class="gridContainer" #gridArchive [remoteOperations]="true" [dataSource]="edit.data"
	(onToolbarPreparing)="toolbarPreparing($event)" [height]="ui.fullHeight">

	<dxo-scrolling [mode]="ui.scrollingMode" [showScrollbar]="ui.showScrollbar" [useNative]="!ui.isWindows">
	</dxo-scrolling>
	<dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" storageKey="qhseArchive">
	</dxo-state-storing>

	<dxi-column [caption]="L('REPORT_TYPE')" dataField="e_description" dataType="string"></dxi-column>
	<dxi-column [caption]="L('ENT_WORKC')" dataField="fullName" dataType="string"></dxi-column>
	<dxi-column [caption]="L('REPORT_DATE')" [calculateCellValue]="getReportPeriod"></dxi-column>
	<dxi-column [caption]="L('DESCRIPTION')" dataField="a_comments" dataType="string">
	</dxi-column>
	<dxi-column type="buttons" fixedPosition='left' width='120' [fixed]='true' [allowResizing]='true'
		[showInColumnChooser]='false'>
		<dxi-button [hint]='L("DOWNLOAD")' icon="download" [onClick]="onDownloadFileClick"></dxi-button>
		<dxi-button [hint]='L("UPLOAD")' icon="upload" [onClick]="onUploadClickEv"></dxi-button>
		<dxi-button name='edit' [onClick]="onEditClick" [visible]="proxy.allowEdit"></dxi-button>
		<dxi-button name='delete' [onClick]="onDeleteClick" [visible]="proxy.allowDelete"></dxi-button>
	</dxi-column>
	<div *dxTemplate="let data of 'breadcrumb'">
		<ibis-breadcrumb></ibis-breadcrumb>
	</div>
</dx-data-grid>

<ibis-edit-popup [edit]="edit" [showAdd]="true">
	<app-archive-edit></app-archive-edit>
</ibis-edit-popup>