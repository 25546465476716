import { Injectable } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import { iBisEditService, iBisLanguageService } from '@dohu/ibis-common';

@Injectable({
	providedIn: 'root'
})
export class ProjectEditService extends iBisEditService {

	public data: CustomStore;

	constructor(private entity: iBisEntityService, lg: iBisLanguageService) {
		super(lg);
		this.title = 'PROJECT';
		this.validation = 'projectValidation';

		this.data = this.getData();
	}

	createDefault(): any {
		return { isValid: true };
	}

	getById(id: string): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			this.entity.getById('Project', id).then((result) => {
				if (!result) {
					reject();
				} else {
					resolve(result);
				}
			}, error => {
				this.lg.showError(error);
			});
		});
	}

	onRemove(id: string): Promise<void> {
		return this.entity.remove('Project', id);
	}

	onSaveEv(): Promise<any> {
		return this.entity.save('Project', this.model.id, this.model);
	}

	reset(): void {
		this.model = this.createDefault();
	}

	private getData(): CustomStore {
		return this.entity.store(new EntityQuery('ProjectView').addOrderByDesc(['p.startDate']), false, 'p_id');
	}
}
