<dx-data-grid #gridDriver [height]='400' (onToolbarPreparing)="toolbarPreparing($event, false)"
	(onInitialized)="onGridInit($event)" (onInitNewRow)='onInitRow($event)'>

	<dxo-scrolling [mode]="ui.scrollingMode" [showScrollbar]="ui.showScrollbar" [useNative]="!ui.isWindows">
	</dxo-scrolling>
	<dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" [storageKey]='key'>
	</dxo-state-storing>
	<dxo-editing [allowUpdating]="proxy.allowEdit" [allowDeleting]="proxy.allowDelete" [allowAdding]="proxy.allowEdit">
	</dxo-editing>

	<dxi-column type="buttons" fixedPosition="left" [minWidth]="proxy.actionsWidth" [width]="proxy.actionsWidth" 
		[fixed]="true" [allowResizing]="false" [showInColumnChooser]="false" [visible]="proxy.showActions">
		<dxi-button [hint]="L('EDIT')" name="edit" [visible]="proxy.allowEdit"></dxi-button>
		<dxi-button [hint]="L('DELETE')" name="delete" [visible]="proxy.allowDelete"></dxi-button>
	</dxi-column>

	<dxi-column [caption]="L('LASTNAME')" dataField="lastName" dataType="string">
		<dxi-validation-rule type="required" message="L('LASTNAME_REQUIRED')"></dxi-validation-rule>
		<dxi-validation-rule type="stringLength"  [max]="256" [message]="L('NAME_LENGTH')">
		</dxi-validation-rule>
	</dxi-column>
	<dxi-column [caption]="L('FIRSTNAME')" dataField="firstName" dataType="string">
		<dxi-validation-rule type="required" message="L('NAME_IS_REQUIRED')"></dxi-validation-rule>
		<dxi-validation-rule type="stringLength"  [max]="256" [message]="L('NAME_LENGTH')">
		</dxi-validation-rule>
	</dxi-column>
	<dxi-column [caption]="L('OBSERVATIONS')" dataField="observations" dataType="string">
	</dxi-column>
	<dxi-column [caption]="L('VALADITY_ADR')" dataField="validUntil" dataType="date" calculateSortValue="validUntil"
		[calculateCellValue]="iui.formatDate"></dxi-column>

</dx-data-grid>