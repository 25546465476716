import { Component } from '@angular/core';
import { iBisViewComponent } from '@dohu/ibis-common';
import { DataService } from '../../../service/data.service';
import { ProxyService } from '../../../service/proxy.service';
import { CapitalizationAnalyzesFilterService } from './capitalization-alanyzes-filter.service';

@Component({
	selector: 'app-capitalization-analyzes',
	templateUrl: './capitalization-analyzes.component.html'
})
export class CapitalizationAnalyzesComponent extends iBisViewComponent {

	public edit: CapitalizationAnalyzesFilterService;
	constructor(public proxy: ProxyService, public ds: DataService) {
		super(proxy.capitalizationAnalyzes, proxy.lg, proxy.iui, proxy.activatedRoute);
	}

	displayMonth = (ev: any) => this.L(ev.value);

	tvPercent(row: any) {
		return (row.t_valuedQuantity / row.t_quantity * 100).toFixed(2) + '%';
	}
}
