<dx-data-grid #gridPermission [columnAutoWidth]="true" [height]="350" (onInitNewRow)="onInitNewRow($event)"
    (onInitialized)="onGridInit($event)" [remoteOperations]="true" (onToolbarPreparing)="toolbarPreparing($event, false)">

    <dxo-editing mode="row" [allowAdding]="true" [allowUpdating]="true" [allowDeleting]="true" [useIcons]="true">
    </dxo-editing> 
	<dxo-scrolling [mode]="ui.scrollingMode" [showScrollbar]="ui.showScrollbar" [useNative]="!ui.isWindows">
	</dxo-scrolling>
	<dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" [storageKey]="key">
	</dxo-state-storing>

    <dxi-column [caption]="L('NAME')" dataField="activityId" dataType="string">
        <dxo-lookup [dataSource]="edit.activity" valueExpr="id" displayExpr="description">
        </dxo-lookup>
    </dxi-column>
    <dxi-column [caption]="L('ACTIVE')" dataField="hasPermission" dataType="boolean" [width]="100">
    </dxi-column>

</dx-data-grid>