<app-kpi-analyzes-filter></app-kpi-analyzes-filter>

<dx-chart id="chart" [dataSource]="edit.data">
	<dxo-title [text]="edit.reportType.title">
	</dxo-title>
	<dxi-value-axis *ngFor="let ax of edit.reportType.axis" [name]="ax.name" [position]="ax.position"
		[showZero]="ax.showZero" [tickInterval]="ax.tickInterval" [autoBreaksEnabled]="ax.autoBreaksEnabled"
		[breakStyle]="ax.breakStyle">
		<dxo-grid [visible]="ax.visible"></dxo-grid>
		<dxo-title [text]="ax.title"></dxo-title>
	</dxi-value-axis>
	<dxi-series *ngFor="let rt of edit.reportType.series" [argumentField]="rt.argumentField" [name]="rt.name"
		[valueField]="rt.valueField" [type]="rt.type" [color]="rt.color" [axis]="rt.axisName">
	</dxi-series>
	<dxo-export [enabled]="true"></dxo-export>

	<dxo-common-series-settings *ngFor="let rt of edit.reportType.series" [argumentField]="rt.argumentField"
		[type]='rt.type'>
		<dxo-label [visible]='true' position='inside'>
			<dxo-font color="white" [size]='11'></dxo-font>
		</dxo-label>
	</dxo-common-series-settings>

	<dxo-legend verticalAlignment="bottom" horizontalAlignment="center" itemTextPosition="bottom">
	</dxo-legend>
</dx-chart>