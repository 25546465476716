<div class="ibis-content-block" style='background-color: #f7f7f7;'>
  <ibis-breadcrumb></ibis-breadcrumb>
</div>
<div class="header-box">
  <div class="squares">
    <!-- <div class="num-box green">
      <h2 class="first-st">{{statistic.tgt.toFixed(2)}} t</h2>
      <h2 class="second-st">{{statistic.tgm.toFixed(2)}} mc</h2>
      <h4 style="margin-bottom:0px">Total generare</h4>
    </div>
    <div class="num-box green">
      <h2 class="first-st">{{statistic.trt.toFixed(2)}} t</h2>
      <h2 class="second-st">{{statistic.trm.toFixed(2)}} mc</h2>
      <h4 style="margin-bottom:0px">Total transportate</h4>
    </div> -->
    <div class="num-box purple">
      <h1>{{edit.statistic.trUnf}}</h1>
      <h4>{{L("UNFINISHED_SHIP")}}</h4>
    </div>
    <div class="num-box blue">
      <h1>{{edit.statistic.wtNo}}</h1>
      <h4>{{L("ACTIVE_WASTE_CODES")}}</h4>
    </div>
    <div class="num-box red">
      <h1>{{edit.statistic.wkNo}}</h1>
      <h4>{{L("ACTIVE_WORKSTATIONS")}}</h4>
    </div>
    <div class="num-box green">
      <h1>{{edit.statistic.trNo}}</h1>
      <h4>{{L("ACTIVE_CARRIERS")}}</h4>
    </div>
    <div class="num-box blue">
      <h1>{{edit.statistic.avNo}}</h1>
      <h4>{{L("NO_ACTIVE_NOTICES")}}</h4>
    </div>
    <div class="num-box purple">
      <h1>{{edit.statistic.masNo}}</h1>
      <h4 style="margin-bottom:0px">{{L("NO_MEASURES")}}</h4>
      <h5 style="margin:0">{{L("RECEIVED_CONTROLS")}}</h5>
    </div>
  </div>
  <!-- <div class="header-item">
    <dx-button icon="car" class="wizard-button" text="Deschide wizard" (onClick)="isWizardVisible = true;"></dx-button>
  </div> -->
</div>
<hr>