export const environment = {
	production: false,
	defaultUrlServer: 'https://api-test.sistemis.ro/qhse/',
	deployId: '5a138590-0ae5-4ab2-94f3-159c6734bda5',
	saasServer: 'https://api-test.sistemis.ro/saas/',
	authServer: 'https://api-test.sistemis.ro/auth/',
	filePath: 'https://api-test.sistemis.ro/qhse_file/',
	termCond: 'https://www.evidentadeseuri.ro/termeni-si-conditii/',
	manualUrl: 'https://www.evidentadeseuri.ro/manual-de-utilizare/',
	allowRegister: true,
	app: {link: 'https://www.evidentadeseuri.ro/', text: 'EvidentaDeseuri.ro' }
};

