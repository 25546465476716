import { Component } from '@angular/core';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { ProxyService } from '../../service/proxy.service';
import { InvoiceEditService } from './invoice-edit.service';

@Component({
	selector: 'app-invoice-edit',
	templateUrl: 'invoice-edit.component.html'
})
export class InvoiceEditComponent extends iBisBaseComponent {
	public edit: InvoiceEditService;

	constructor(public proxy: ProxyService) {
		super(proxy.invoice, proxy.lg, proxy.iui, proxy.activatedRoute);
	}
}
