import { Injectable } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { DataService } from '../../../service/data.service';
import { iBisEditService, iBisLanguageService } from '@dohu/ibis-common';
import { WasteTypeEditService } from '../../waste-type/waste-type-edit.service';
import { EntityQuery, iBisEntityService, SimpleFunctionType } from '@dohu/ibis-entity';

@Injectable({
	providedIn: 'root'
})
export class TransportApprovalEditService extends iBisEditService {

	public data: CustomStore;
	public code: CustomStore;
	public type: { 'id': number; 'value': string }[];
	public selected: { quantityLeft: number; noLeft: number; uom: number };
	// TODO: collectNoFAT && transportNoFAT are calculated in KGs AND the only cases calculated are on
	// transport or collected with quantity in KG or T.
	// we must use wasteType factor to convert quantity UOM into WasteType BASE UOM and after that TO
	// FAT selected UOM
	// LINE: 118 Method: private noFATQuantity
	public collectNoFAT: number;
	public transportNoFAT: number;

	private genId: string;
	private destId: string;
	private drvId: string;
	private crId: string;
	private transId: string;
	private wtId: string;

	constructor(private entity: iBisEntityService, private wtp: WasteTypeEditService,
		private ds: DataService, lg: iBisLanguageService) {
		super(lg);
		this.title = 'FAT';
		this.validation = 'transportApprovalValidation';

		this.data = this.getData();
		this.type = this.getType();
		this.resetSelected();
	}

	public set generatorId(id: string) {
		this.genId = id;
		this.setId();
		this.noFATQuantity();
	}
	public set destinationId(id: string) {
		this.destId = id;
		this.setId();
	}
	public set transporterId(id: string) {
		this.transId = id;
		this.setId();
	}
	public set wasteTypeId(id: string) {
		this.wtId = id;
		this.setId();
		this.noFATQuantity();
	}
	public set driverId(id: string) {
		this.drvId = id;
		this.setId();
	}
	public set carId(id: string) {
		this.crId = id;
		this.setId();
	}
	public set selectedId(id: any) {
		if (!id) {
			this.resetSelected();
			this.onDataChanged.next('selected');
			return;
		}

		this.entity.getFieldsById('TransportApprovalView', id, ['quantityLeft', 'noLeft', 'uom'], 'ta_id').then(result => {
			if (result) {
				this.selected.noLeft = result.noLeft;
				this.selected.quantityLeft = result.quantityLeft;
				this.selected.uom = result.uom;
				this.onDataChanged.next('selected');
			} else {
				this.resetSelected();
				this.onDataChanged.next('selected');
			}
		});
	}

	public get used(): Boolean {
		return !this.ds.isAdmin && this.model.id &&
			(this.model.quantityLeft !== this.model.approvedQuantity || this.model.noLeft !== this.model.approvedNo);
	}

	createDefault(): any {
		return { isValid: true, type: 1, approvedNo: 1, approvedQuantity: 1, quantityUsed: 0, noUsed: 0, startDate: new Date() };
	}

	getById(id: any): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			this.entity.getById('TransportApproval', id).then((result) => {
				if (result) {
					this.entity.getFieldsById('TransportApprovalView', id, ['noLeft', 'quantityLeft'], 'ta_id').then(qs => {
						result.quantityLeft = qs.quantityLeft;
						result.noLeft = qs.noLeft;
						result.quantityUsed = result.approvedQuantity - result.quantityLeft;
						result.noUsed = result.approvedNo - result.noLeft;
						resolve(result);
					}, error => this.lg.showError(error));
				} else {
					reject();
				}
			}, error => this.lg.showError(error));
		});
	}

	onRemove(id: string): Promise<void> {
		return this.entity.remove('TransportApproval', id);
	}

	onSaveEv(): Promise<any> {
		this.model.uom = this.wtp.selected.uom;
		return this.entity.save('TransportApproval', this.model.id, this.model);
	}

	reset(): void {
		this.model = this.createDefault();
	}

	private noFATQuantity(): void {
		if (!this.wtId || !this.genId) {
			this.collectNoFAT = null;
			this.transportNoFAT = null;
			return;
		}
		const q = new EntityQuery('Collecting').eq('wasteTypeId', this.wtId).eq('approvalId', null)
			.eq('partyWorkCenterId', this.genId)
			.gte('date', new Date(new Date().getFullYear(), 0, 1)).lte('date', new Date(new Date().getFullYear(), 11, 31));
		q.fields.push('uom');
		this.entity.function(q, [{ field: 'quantity', ft: SimpleFunctionType.Sum }]).then(res => {
			let sum = 0;
			res.forEach(el => {
				sum += el.f1 === 1 ? el.f2 * 1000 : el.f2;
			});
			this.collectNoFAT = sum;
		});
		const qq = new EntityQuery('Transport').eq('wasteTypeId', this.wtId).eq('approvalId', null)
			.eq('workCenterId', this.genId)
			.gte('sendDate', new Date(new Date().getFullYear(), 0, 1)).lte('sendDate', new Date(new Date().getFullYear(), 11, 31));
		qq.fields.push('uom');
		this.entity.function(qq, [{ field: 'quantity', ft: SimpleFunctionType.Sum }]).then(res => {
			let sum = 0;
			res.forEach(el => {
				sum += el.f1 === 1 ? el.f2 * 1000 : el.f2;
			});
			this.transportNoFAT = sum;
		});
	}

	private resetSelected(): void {
		this.selected = { quantityLeft: null, noLeft: null, uom: null };
	}

	private getData(): CustomStore {
		const q = new EntityQuery('TransportApprovalView').addOrderByDesc(['ta.startDate']);
		return this.entity.store(q, false, 'ta_id', null, (item) => {
			item.fullName = item.ta_no + ' - ' + ' [' + item.quantityLeft +
				(item.uom === 1 ? ' t ' : item.uom === 2 ? ' mc ' : ' kg ') + ' / ' + item.noLeft + ']';
		});
	}

	private getType(): { 'id': number; 'value': string }[] {
		const list = [{ id: 1, value: 'ANNEX1' }];
		return this.ds.getEnum('fatType', list);
	}

	private getCodes(): CustomStore {
		if (!this.genId || !this.destId || !this.transId || !this.wtId) {
			return null;
		}
		// TODO: fix for the future
		// .eq('driverId', this.drvId);
		const today = new Date();
		today.setHours(0, 0, 0, 0);
		const taQ = new EntityQuery('TransportApproval').
			eq('wasteTypeId', this.wtId).eq('partyId', this.genId).eq('destinationId', this.destId).gt('expireDate', today);
		const tapQ = new EntityQuery('TransportApprovalParty').eq('transporterId', this.transId).eq('carId', this.crId);
		const q = new EntityQuery('TransportApprovalView', false, true).gt('quantityLeft', 0).gt('noLeft', 0).
			link('ta.id', 'id', taQ).addOrderByDesc(['ta.startDate']).
			link('ta.id', 'approvalId', tapQ);
		q.fields.push('ta.id', 'ta.no', 'quantityLeft', 'noLeft', 'ta.approvedQuantity', 't.quantity', 'c.quantity', 'ta.approvedNo',
			't.no', 'c.no', 'uom');
		return this.entity.store(q, false, 'ta_id', null, (item) => {
			item.fullName = item.ta_no + ' - ' + ' [' + item.quantityLeft +
				(item.uom === 1 ? ' t ' : item.uom === 2 ? ' mc ' : ' kg ') + ' / ' + item.noLeft + ']';
		});
	}

	private setId() {
		this.code = this.getCodes();
		this.onDataChanged.next('code');
	}
}
