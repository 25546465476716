<dx-data-grid #gridEnumValue [height]="300" (onInitialized)="onGridInit($event)"
	(onRowInserting)="onRowInserting($event)" (onToolbarPreparing)="toolbarPreparing($event, false)">

	<dxo-scrolling [mode]="ui.scrollingMode" [showScrollbar]="ui.showScrollbar" [useNative]="!ui.isWindows">
	</dxo-scrolling>
	<dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" [storageKey]='key'>
	</dxo-state-storing>

	<dxo-editing mode="row" [allowAdding]="proxy.allowEdit" [allowDeleting]="proxy.allowDelete" [allowUpdating]="proxy.allowEdit"></dxo-editing>

	<dxi-column type="buttons" fixedPosition="left"  [minWidth]="proxy.actionsWidth" [width]="proxy.actionsWidth"
		[fixed]="true" [allowResizing]="false" [showInColumnChooser]="false" [visible]="proxy.showActions">
		<dxi-button [hint]="L('EDIT')" icon="edit" name="edit" [visible]="proxy.allowEdit">
		</dxi-button>
		<dxi-button [hint]="L('DELETE')" icon="trash" name="delete" [visible]="proxy.allowDelete">
		</dxi-button>
	</dxi-column>

	<dxi-column [caption]="L('INDEX')" dataField="orderIndex" dataType="number"></dxi-column>
	<dxi-column [caption]="L('CODE')" dataField="code" dataType="string"></dxi-column>
	<dxi-column [caption]="L('DESCRIPTION')" dataField="description" dataType="string" [allowHeaderFiltering]="false">
	</dxi-column>
</dx-data-grid>