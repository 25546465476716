import notify from 'devextreme/ui/notify';
import { Injectable } from '@angular/core';
import { DataService } from '../../../service/data.service';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import { iBisAuthService, iBisSecureEditService } from '@dohu/ibis-auth';
import { iBisLanguageService, iBisServerConfig } from '@dohu/ibis-common';

@Injectable({
	providedIn: 'root'
})
export class CostAnalyzesFilterService extends iBisSecureEditService {

	public result: Result;
	public data: any[];
	public interval: any = [{ id: 1, text: 'DAILY' }, { id: 2, text: 'MONTHLY' }];
	constructor(auth: iBisAuthService, entity: iBisEntityService, config: iBisServerConfig, lg: iBisLanguageService,
		public ds: DataService) {
		super(auth, entity, config, lg);

		this.result = new Result();
	}

	createDefault() {
		return { workCenter: [], interval: 1, date: new Date() };
	}
	getById(id: string, serverUrl?: string): Promise<any> {
		throw new Error('Method not implemented.');
	}
	onRemove(id: string): Promise<void> {
		throw new Error('Method not implemented.');
	}
	onSaveEv(serverUrl?: string): Promise<any> {
		throw new Error('Method not implemented.');
	}
	reset(): void {
		this.model = this.createDefault();
	}

	public filterData = (): void => {
		if (this.model.wasteType && this.model.wasteType.length < 1) {
			notify('Selectați coduri de deșeu pentru analiză.', 'error', 3000);
			return;
		}
		if (this.model.workCenter && this.model.workCenter.length < 1) {
			notify('Selectați punct de lucru pentru analiză.', 'error', 3000);
			return;
		}
		this.initCost();
	};

	initCost() {
		this.getCostByDate().then((res: any) => {
			const graphData = [];
			const gridData = [];

			res.forEach((element: any) => {
				const dd = res.filter(x => new Date(x.date).getMonth() + 1 === new Date(element.date).getMonth() + 1);
				const itExists = gridData.find(x => x.month === new Date(element.date).getMonth() + 1);
				if (dd && dd.length === 1) {
					gridData.push({
						date: element.date,
						month: new Date(element.date).getMonth() + 1,
						year: new Date(element.date).getFullYear(),
						amount: element.amount,
						entityId: element.entityId,
						supplierId: element.supplierId
					});
				} else if (dd.length > 1 && !itExists) {
					gridData.push({
						date: element.date,
						month: new Date(element.date).getMonth() + 1,
						year: new Date(element.date).getFullYear(),
						amount: this.getSum(dd, 'amount'),
						entityId: element.entityId,
						supplierId: element.supplierId
					});
				}
				graphData.push({ day: element.date, amount: element.amount });
			});
			this.data = gridData;
			this.result.cost = this.getValues(graphData, 'amount');
			this.result.totalCostText = this.totalValueTxt(this.result.cost, 'amount');
		});
	}

	protected load(): void {
	}

	private getCostByDate() {
		// TODO: get date by interval
		const fromDate = new Date(this.model.data);
		const thruDate = new Date(this.model.date);
		const q = new EntityQuery('Invoice').in('entityId', this.model.workCenter).eq('isSales', true).addOrderByDesc(['date']);
		q.gte('date', this.entity.toDateTimeFilter(this.convertToDate(fromDate)));
		q.lte('date', this.entity.toDateTimeFilter(this.convertToDate(thruDate)));
		q.fields.push('id', 'entityId', 'amount', 'supplierId', 'date');
		return this.entity.store(q, false, 't_id').load().catch(error => {
			this.ds.lg.showError(error);
		});
	}

	private getValues(data: any, key: string): any[] {
		const arr = [];
		data = data || [];
		const diffTime = Math.abs(new Date(this.model.thruDate).getTime() - new Date(this.model.fromDate).getTime());
		const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
		for (let j = 0; j < diffDays; j++) {
			const fd = new Date(this.model.fromDate);
			fd.setHours(0, 0, 0, 0);
			const currentFD = this.formatDate(new Date(fd.setDate(fd.getDate() + j)));
			const dd = data.filter((x: { day: string | number | Date }) => this.formatDate(new Date(x.day)) === currentFD);
			const obj = { day: dd.length ? this.formatDate(dd[0].day) : currentFD };
			obj[key] = dd.length ? this.getSum(dd, key) : 0;
			arr.push(obj);
		}
		return arr;
	}

	private totalValueTxt(ds: any, key: string) {
		return this.L('TOTAL_VALUE', null) + ' ' + this.getSum(ds, key) + ' ' + this.L('CURRENCY', null);
	}

	private getSum(arr: any[], field: string) {
		let total = 0;
		for (const item of arr) {
			total += item[field];
		}
		return total;
	}

	private convertToDate(date: any) {
		const d = new Date(date);
		d.setHours(0, 0, 0, 0);
		return d;
	}

	private formatDate(date: any) {
		const d = new Date(this.convertToDate(date));
		return this.entity.toD2(d.getDate()) + '.' + (d.getMonth() + 1) + '.' + d.getFullYear();
	}
}

export class Result {
	public cost: any[];
	public totalCostText: string;
	public intervalPeriod: string;
	constructor() {
		this.cost = [];
		this.totalCostText = '';
		this.intervalPeriod = '';
	};
}
