<app-income-analyzes-filter></app-income-analyzes-filter>
<div class="analyzesChart">
    <app-graph-line [txt]="{ title: L('INCOME'), description: edit.result.totalIncomeText }"
        [typeValue]="edit.result.income" [typeOp]="[{ value: 'amount', name: L('COSTS') }]" [uom]="null"
        [intervalDate]="edit.result.intervalPeriod"></app-graph-line>
</div>

<div class="analyzesGrid">
    <dx-data-grid height="100%" [dataSource]="edit.data" [columnAutoWidth]="true" [allowColumnResizing]="true"
        [allowColumnReordering]="true" [remoteOperations]="true" (onToolbarPreparing)="toolbarPreparing($event, false)">

        <dxo-scrolling [mode]="ui.scrollingMode" [showScrollbar]="ui.showScrollbar" [useNative]="!ui.isWindows">
        </dxo-scrolling>
        <dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" storageKey="qhseIncomeAnalyzes">
        </dxo-state-storing>
        <dxo-export [enabled]="ui.exportEnabled" fileName="incomes"></dxo-export>

        <dxi-column [caption]="L('YEAR1')" dataField="year" dataType="string"></dxi-column>
        <dxi-column [caption]="L('MONTH')" dataField="month" dataType="number">
            <dxo-lookup [dataSource]="ds.static.months" valueExpr="id" displayExpr="name"></dxo-lookup>
        </dxi-column>
        <dxi-column [caption]="L('ENTITY')" dataField="entityId" dataType="string">
            <dxo-lookup [dataSource]="proxy.party.ownEntity" valueExpr="c_id" displayExpr="fullName"></dxo-lookup>
        </dxi-column>
        <dxi-column [caption]="L('SUPPLIER')" dataField="supplierId" dataType="string">
            <dxo-lookup [dataSource]="proxy.party.subContractor" valueExpr="c_id" displayExpr="fullName"></dxo-lookup>
        </dxi-column>
        <dxi-column [caption]="L('AMOUNT')" dataField="amount" dataType="string"></dxi-column>
    </dx-data-grid>
</div>