import { Component } from '@angular/core';
import { iBisViewComponent } from '@dohu/ibis-common';
import { DataService } from '../../../service/data.service';
import { ProxyService } from '../../../service/proxy.service';
import { ControlAnalyzesFilterService } from './control-alanyzes-filter.service';

@Component({
	selector: 'app-control-analyzes',
	templateUrl: './control-analyzes.component.html'
})
export class ControlAnalyzesComponent extends iBisViewComponent {

	public edit: ControlAnalyzesFilterService;
	constructor(public proxy: ProxyService, public ds: DataService) {
		super(proxy.controlAnalyzes, proxy.lg, proxy.iui, proxy.activatedRoute);
	}
}
