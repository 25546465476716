<dx-tree-list #gridWasteType class="gridContainer" [height]="ui.fullHeight" [dataSource]="edit.data"
	(onToolbarPreparing)="toolbarPreparing($event, proxy.allowEdit)" hasItemsExpr='hasItems' rootValue="null"
	[remoteOperations]="true" [showBorders]="true">

	<dxo-export [enabled]="ui.exportEnabled" fileName="wastetypes"></dxo-export>
	<dxo-scrolling [mode]="ui.scrollingMode" [showScrollbar]="ui.showScrollbar" [useNative]="!ui.isWindows">
	</dxo-scrolling>

	<dxi-column type="buttons" fixedPosition="left"  [minWidth]="proxy.actionsWidth" [width]="proxy.actionsWidth" 
		[fixed]="true" [allowResizing]="false" [showInColumnChooser]="false" [visible]="proxy.showActions">
		<dxi-button [hint]="L('EDIT')" icon="edit" [onClick]="onEditClick" [visible]="proxy.allowEdit">
		</dxi-button>
		<dxi-button [hint]="L('DELETE')" icon="trash" [onClick]="onDeleteClick" [visible]="proxy.allowDelete">
		</dxi-button>
	</dxi-column>
	<dxi-column [caption]="L('CODE')" dataField="code" dataType="string" width="200">
	</dxi-column>
	<dxi-column [caption]="L('NAME')" dataField="name" dataType="string">
	</dxi-column>
	<div *dxTemplate="let data of 'breadcrumb'">
		<ibis-breadcrumb></ibis-breadcrumb>
	</div>
</dx-tree-list>

<ibis-edit-popup [edit]="edit">
	<app-waste-type-edit></app-waste-type-edit>
</ibis-edit-popup>