<dx-data-grid class="gridContainer box-shadow" #gridInvoiceItems [dataSource]="edit.dsInvoiceItems"
	[columnAutoWidth]="true" [allowColumnResizing]="true" [remoteOperations]="true" [allowColumnReordering]="true"
	[hoverStateEnabled]="true" [height]="ui.fullHeight" (onRowExpanding)="onRowExpanding($event)"
	(onToolbarPreparing)="toolbarPreparing($event)">

	<dxo-scrolling [mode]="ui.scrollingMode" [showScrollbar]="ui.showScrollbar" [useNative]="!ui.isWindows">
	</dxo-scrolling>
	<dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" [storageKey]="key">
	</dxo-state-storing>
	<dxo-master-detail [enabled]="true" template="details"></dxo-master-detail>

	<dxi-column [caption]="L('ENTITY')" dataField="ent_name" dataType="string"></dxi-column>
	<dxi-column [caption]="L('SUPPLIER')" dataField="sup_name" dataType="string"></dxi-column>
	<dxi-column [caption]="L('INVOICE_NO')" dataField="i_no" dataType="number"></dxi-column>
	<dxi-column [caption]="L('DATE')" dataField="i_date" dataType="date" calculateSortValue="date"
		[calculateDisplayValue]="iui.formatDate">
	</dxi-column>
	<dxi-column [caption]="L('CODE')" dataField="ii_partNumber" dataType="number"></dxi-column>
	<dxi-column [caption]="L('PRODUCT_NAME')" dataField="ii_productName" dataType="string"></dxi-column>
	<dxi-column [caption]="L('QUANTITY')" dataField="ii_quantity" dataType="number"></dxi-column>
	<dxi-column [caption]="L('U')" dataField="ii_uom" dataType="number">
		<dxo-lookup [dataSource]="proxy.wasteType.uom" valueExpr="id" displayExpr="value"></dxo-lookup>
	</dxi-column>
	<dxi-column [caption]="L('PRICE')" dataField="ii_unitCost" dataType="number"></dxi-column>
	<dxi-column type="buttons" fixedPosition="left" [minWidth]="proxy.actionsWidth" [width]="proxy.actionsWidth" 
		[fixed]="true" [allowResizing]="false" [showInColumnChooser]="false" [visible]="proxy.showActions">
		<dxi-button [hint]="L('EDIT')" icon="edit" [onClick]="onEditClick" [visible]="proxy.allowEdit">
		</dxi-button>
		<dxi-button [hint]="L('DELETE')" icon="trash" [onClick]="onDeleteClick" [visible]="proxy.allowDelete">
		</dxi-button>
		<dxi-button [hint]="L('GENERATE')" icon="redo" [onClick]="onGenerateClick"></dxi-button>
	</dxi-column>

	<div *dxTemplate="let data of 'details'">
		<dx-data-grid #gridItemWaste [dataSource]="edit.dsItemWaste" [height]="300" [allowColumnResizing]="true"
			[allowColumnReordering]="true" [rowAlternationEnabled]="ui.rowAlternationEnabled" [remoteOperations]="true"
			(onToolbarPreparing)="toolbarPreparing($event, false)" (onRowInserting)="onRowInserting($event, data.data)">

			<dxo-scrolling [mode]="ui.scrollingMode" [showScrollbar]="ui.showScrollbar" [useNative]="!ui.isWindows">
			</dxo-scrolling>
			<dxo-editing mode="row" [allowUpdating]="proxy.allowEdit" [allowAdding]="data.data.generationId == null"
				[allowDeleting]="data.data.generationId == null">
			</dxo-editing>

			<dxi-column [caption]="L('WASTE_CODE')" dataField="wasteTypeId" dataType="string">
				<dxo-lookup [dataSource]="edit.dsWasteType" valueExpr="id" displayExpr="code"></dxo-lookup>
			</dxi-column>
			<dxi-column [caption]="L('NAME')" dataField="wasteTypeId" dataType="string" [allowEditing]="false">
				<dxo-lookup [dataSource]="edit.dsWasteType" valueExpr="id" displayExpr="name"></dxo-lookup>
			</dxi-column>
			<dxi-column [caption]="L('QUANTITY')" dataField="quantity" dataType="number">
			</dxi-column>
			<dxi-column [caption]="L('U')" dataField="uom" dataType="number" [allowHeaderFiltering]="false">
				<dxo-lookup [dataSource]="proxy.wsteType.uom" valueExpr="id" displayExpr="value"></dxo-lookup>
			</dxi-column>
			<dxi-column [caption]="L('GENERATE')" dataField="generationId" dataType="number" [allowEditing]="false">
			</dxi-column>
		</dx-data-grid>
	</div>
</dx-data-grid>

<ibis-edit-popup [edit]="edit" [showAdd]="true">
	<app-invoice-item-edit></app-invoice-item-edit>
</ibis-edit-popup>