import notify from 'devextreme/ui/notify';
import { confirm } from 'devextreme/ui/dialog';
import { Component, ViewChild } from '@angular/core';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { ProxyService } from '../../../service/proxy.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { SafetyReportEditService } from './safety-report-edit.service';
@Component({
	selector: 'app-safety-report-edit',
	templateUrl: './safety-report-edit.component.html',
	styleUrls: ['safety-report-edit.component.css'],
})
export class SafetyReportEditComponent extends iBisBaseComponent {

	@ViewChild('resultGrid') resultGrid: DxDataGridComponent;
	public edit: SafetyReportEditService;
	constructor(public proxy: ProxyService) {
		super(proxy.safetyReport, proxy.lg, proxy.iui, proxy.activatedRoute);
		this.setCurrentAuditor();
	}

	setCurrentAuditor() {
		if (sessionStorage.getItem('currentAuditorId')) {
			this.proxy.auditor.auditorId = sessionStorage.getItem('currentAuditorId');
			return;
		}
		this.proxy.entity.getById('SafetyAuditor', this.proxy.auth.user.id).then(data => {
			if (!data) {
				// TODO Redirect || add auditor || don't show the page for user unnauthorized
			} else {
				sessionStorage.setItem('currentAuditorId', data.id);
				this.proxy.auditor.auditorId = data.id;
			}
		});
	}

	calculateIsOk = (rowInfo: any) => rowInfo ? (rowInfo.i_total ?
		((rowInfo.ir_total ? rowInfo.ir_total : 0) + '/' + rowInfo.i_total) : '-') : '';

	onCellPrepared(rowInfo: any) {
		if (rowInfo.rowType === 'data' && rowInfo.column.dataField === 'isOk' && rowInfo.data.i_total) {
			if (rowInfo.data.ir_total === null || rowInfo.data.ir_total === 0) {
				rowInfo.cellElement.className = rowInfo.cellElement.className + ' result-red';
			} else if (rowInfo.data.i_total === rowInfo.data.ir_total) {
				rowInfo.cellElement.className = rowInfo.cellElement.className + ' result-green';
			} else {
				rowInfo.cellElement.className = rowInfo.cellElement.className + ' result-yellow';
			}
		}
	}

	onEditInconsistent = (e: any) => {
		this.proxy.inconsistent.showPopup(e.row.data.id, true).catch(error => {
			this.proxy.lg.showError(error);
		}).finally(() => {
			e.component.refresh();
			this.resultGrid.instance.refresh();
		});
	};

	onNewParticipant(e: any) {
		e.data.reportId = this.edit.model.id;
		e.data.isLeader = false;
	}

	categoryInit(e: any, d: any) {
		if (e.component.option('dataSource') == null) {
			e.component.option('dataSource', this.proxy.inconsistent.getInconsistentBy('resultId', d.data.srr_id));
		}
	}

	onReportDateChanged = (ev: any) => {
		if (ev.event && ev.previousValue) {
			confirm('Are you sure ?', 'Confirm change date').then(dr => {
				if (!dr) {
					this.edit.model.reportDate = new Date(ev.previousValue);
					ev.cancel = true;
				}
			}, error => {
				this.proxy.lg.showError(error);
			});
		}
	};

	onInconsistentRemove(ev: any) {
		ev.cancel = true;
		this.proxy.inconsistent.remove(ev.data.id).then(() => {
			ev.component.refresh();
			this.resultGrid.instance.refresh();
		});
	}

	detailsToolbarPreparing(ev: any, rowData: any) {
		this.iui.toolbarPreparing(ev, 'safetyReportDetails');
		ev.toolbarOptions.items.unshift({
			widget: 'dxButton',
			options: {
				hint: this.L('ADD_INCONSISTENT', null),
				icon: 'plus',
				onClick: (e: any) => {
					const obj = {
						reportId: this.edit.model.id,
						// TODO check resultId's purpose
						resultId: rowData.data.srr_id,
						categoryId: rowData.data.srr_categoryId,
						auditorId: this.proxy.auditor.auditorId
					};
					this.proxy.inconsistent.showPopup(obj, false).catch((error) => {
						notify(this.L('ERROR_ADD_INCONSISTENT'));
					}).finally(() => {
						ev.component.refresh();
						this.resultGrid.instance.refresh();
					});
				}
			},
			location: 'after'
		});
	}
}
