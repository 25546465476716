import { Component, OnInit } from '@angular/core';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { ProxyService } from '../../../service/proxy.service';
import { DashboardAnalyzesFilterService } from './dashboard-analyzes-filter.service';

@Component({
	templateUrl: 'dashboard-analyzes.component.html'
})
export class DashboardAnalyzesComponent extends iBisBaseComponent implements OnInit {
	isWizardVisible: boolean;
	isInitAppVisible: boolean;

	initOptions: any;
	public edit: DashboardAnalyzesFilterService;
	constructor(public proxy: ProxyService) {
		super(proxy.dashboardAnalyzes, proxy.lg, proxy.iui, proxy.activatedRoute);
	}

	ngOnInit() {
		this.isWizardVisible = false;
		this.isInitAppVisible = false;

		if (!this.proxy.auth.isAuth) {
			return;
		}
	}
}
