import { UnitEditService } from './unit-edit.service';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { ProxyService } from '../../../service/proxy.service';
import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'app-unit-edit',
	templateUrl: 'unit-edit.component.html'
})
export class UnitEditComponent extends iBisBaseComponent {
	@Input() isWizard: boolean;
	@Output() initForm = new EventEmitter<any>();

	public edit: UnitEditService;
	private cboParent: any;
	private cboLocality: any;

	constructor(public proxy: ProxyService) {
		super(proxy.unit, proxy.lg, proxy.iui, proxy.activatedRoute);

		proxy.unit.onDataChanged.subscribe((data) => {
			if (data === 'locality' && this.cboLocality) {
				this.proxy.resetValidation(this.cboLocality);
			}
			if (data === 'parent' && this.cboParent) {
				this.proxy.resetValidation(this.cboParent);
			}
		});
	}

	onInitDataForm(e: any) {
		this.initForm.emit(e.component);
	}

	onParentInitialized = (ev: any) => {
		this.cboParent = ev.component;
	};

	onTypeValueChanged = (ev: any) => {
		this.edit.typeId = ev.value;
	};

	onLocalityInitialized = (ev: any) => {
		this.cboLocality = ev.component;
	};

	onCountyValueChanged = (ev: any) => {
		if(ev.event){
			this.edit.countyId = ev.value;
		}
	};

	onFieldDataChanged(item: any) {
		if (item.dataField === 'typeId') {
			const editor = item.component.getEditor('parentId');
			if (item.value === 0) {
				editor.option('disabled', true);
				editor.option('value', null);
				return;
			} else {
				if (editor) {
					editor.option('disabled', false);
				}
			}
		}
	}
}
