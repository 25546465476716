import { Component } from '@angular/core';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { ProxyService } from '../../../service/proxy.service';
import { PartyLinkEditService } from './party-link-edit.service';

@Component({
	selector: 'app-party-link-edit',
	templateUrl: 'party-link-edit.component.html'
})
export class PartyLinkEditComponent extends iBisBaseComponent {
	public edit: PartyLinkEditService;

	constructor(public proxy: ProxyService) {
		super(proxy.partyLink, proxy.lg, proxy.iui, proxy.activatedRoute);

		proxy.wasteType.onDataChanged.subscribe((data) => {
			if (data === 'selected') {
				this.edit.model.wasteType = this.proxy.wasteType.selected;
				this.edit.model.uomName = this.proxy.wasteType.selectedUomName;
			}
		});
	}

	onWasteTypeChanged = (ev: any) => {
		this.proxy.wasteType.selectedId = ev.value;
	};
}
