<dx-popup showCloseButton="true" [minWidth]="ui.minWidthPopup" maxWidth="90vw" width="85vw" maxHeight="90vh"
    height="auto" contentTemplate="popupTemplateSecond" title="Selectare deșeuri din stoc"
    (onInitialized)="edit.popupInit($event)" (onShown)="onStockShown($event)">

    <div *dxTemplate="let data of 'popupTemplateSecond'">
        <div style="display: flex; justify-content: space-between">
            <div style="display:flex">
                <h3 style="margin: 10px;">Cod deșeu:</h3>
                <dx-select-box style="margin: 0 10px;" [dataSource]="dsWasteType" valueExpr="id" displayExpr="code"
                    [searchEnabled]="true" [showClearButton]="true" [(value)]="edit.model.wasteTypeId"
                    [readOnly]="edit && edit.model && edit.model.edit == true" [readOnly]="edit.model.eliminationId"
                    (onValueChanged)="loadStockDataSource($event)" [width]="200" [height]="32"
                    [elementAttr]="{ class: 'inline'}">
                </dx-select-box>
            </div>
            <h3 style="margin: 10px">Total: {{edit.model.totalQuantity}} {{edit.model.uom || ''}}</h3>
        </div>

        <dx-data-grid style="margin-top: 5px" #gridActualStock keyExpr="id" [dataSource]="edit.dsStock"
            [columnAutoWidth]="true" [allowColumnReordering]="true" height="50vh"
            [remoteOperations]="true" (onRowUpdating)="onStockUpdating($event)"
            (onSelectionChanged)="onSelectionChange($event)" (onEditingStart)="onEditingStart($event)">

            <dxo-editing mode="cell" [allowUpdating]="true"></dxo-editing>
            <dxo-selection mode="multiple" showCheckBoxesMode="always"></dxo-selection>

            <dxi-column [caption]="L('CREATION_DATE')" dataField="date" dataType="date" [allowEditing]="false">
            </dxi-column>
            <dxi-column [caption]="L('ACTUAL_QUANTITY')" dataField="remain" dataType="number" [allowEditing]="false"
                [allowHeaderFiltering]="false">
            </dxi-column>
            <dxi-column [caption]="L('SELECTED_QUANTITY')" dataField="quantity" dataType="number"
                [allowHeaderFiltering]="false"></dxi-column>
            <dxi-column [caption]="L('GENERATOR')" dataField="company" dataType="string" [allowEditing]="false">
            </dxi-column>
            <dxi-column [caption]="L('WORK_CENTER')" dataField="workCenter" dataType="string" [allowEditing]="false"
                [allowHeaderFiltering]="false">
            </dxi-column>
            <dxi-column [caption]="L('OBSERVATIONS')" dataField="observations" dataType="string" [allowEditing]="false"
                [allowHeaderFiltering]="false">
            </dxi-column>
        </dx-data-grid>
    </div>

    <dxi-toolbar-item toolbar="bottom" location="after" widget="dxButton"
        [options]="{ text: L('SAVE') , icon:'save', type: 'normal', onClick: onSave}">
    </dxi-toolbar-item>
</dx-popup>