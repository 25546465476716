import { Injectable } from '@angular/core';
import { iBisEntityService } from '@dohu/ibis-entity';
import { iBisEditService, iBisLanguageService } from '@dohu/ibis-common';

@Injectable({
	providedIn: 'root'
})
export class MonthlyStockRecheckService extends iBisEditService {

	constructor(private entity: iBisEntityService, lg: iBisLanguageService) {
		super(lg);
		this.title = 'RECHECK';
		this.validation = 'recheckValidation';
	}

	createDefault(): any {
		return {};
	}
	getById(id: string, serverUrl?: string): Promise<any> {
		throw new Error('Method not implemented.');
	}
	onRemove(id: string): Promise<void> {
		throw new Error('Method not implemented.');
	}
	onSaveEv(serverUrl?: string): Promise<any> {
		return this.entity.execute('RecheckMonthlyStock', { month: this.model.month, year: this.model.year });
	}
	reset(): void {
		this.model = this.createDefault();
	}
}
