import { Injectable } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import { iBisEditService, iBisLanguageService } from '@dohu/ibis-common';

@Injectable({
	providedIn: 'root'
})
export class InvoiceItemEditService extends iBisEditService {

	public dsInvoices: CustomStore;
	public dsWasteType: CustomStore;
	public dsItemWaste: CustomStore;
	public dsInvoiceItems: CustomStore;

	constructor(private entity: iBisEntityService, lg: iBisLanguageService) {
		super(lg);
		this.title = 'INVOICE_ITEM';
		this.validation = 'InvoiceItemValidation';

		this.dsInvoices = this.getInvoice();
		this.dsInvoiceItems = this.getInvoiceItems();
		this.dsWasteType = this.getWasteTypeChildren();
	}

	getItemWaste(itemId: any): CustomStore {
		const q = new EntityQuery('InvoiceItemWaste').eq('invoiceItemId', itemId);
		return this.entity.store(q, false, 'id');
	}

	createDefault() {
		return {};
	}

	getById(id: string): Promise<any> {
		return new Promise((resolve, reject) => {
			this.entity.getById('InvoiceItem', id).then(result => {
				if (!result) {
					reject();
				}
				resolve(result);
			}, error => {
				this.lg.showError(error);
			});
		});
	}

	onRemove(id: string): Promise<void> {
		return this.entity.remove('InvoiceItem', id);
	}

	onSaveEv(serverUrl?: string): Promise<any> {
		return this.entity.save('InvoiceItem', this.model.id, this.model);
	}

	reset(): void {
		this.model = this.createDefault();
	}

	private getInvoiceItems(): CustomStore {
		const q = new EntityQuery('InvoiceItemView').addOrderByDesc(['i.date']);
		return this.entity.store(q, false, 'ii_id');
	}

	private getWasteTypeChildren() {
		const q = new EntityQuery('WasteType').neq('parentId', null).neq('parentId', '00000000-0000-0000-0000-000000000000');
		return this.entity.store(q, false, 'id');
	}

	private getInvoice(): CustomStore {
		const q = new EntityQuery('Invoice').addOrderByDesc(['date']);
		return this.entity.store(q, false, 'id');
	}
}
