import { Component } from '@angular/core';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { ProxyService } from '../../../service/proxy.service';
import { EnumTypeEditService } from './enum-type-edit.service';

@Component({
	selector: 'app-enum-type-edit',
	templateUrl: 'enum-type-edit.component.html'
})
export class EnumTypeEditComponent extends iBisBaseComponent {
	public edit: EnumTypeEditService;

	constructor(proxy: ProxyService) {
		super(proxy.enumType, proxy.lg, proxy.iui, proxy.activatedRoute);
	}
}
