<dx-pivot-grid class="gridContainer box-shadow" #AuditPivotGrid [allowSortingBySummary]="true" [allowFiltering]="true"
    [showBorders]="true" [showColumnGrandTotals]="false" [showRowGrandTotals]="false" [allowSorting]="true"
    [showRowTotals]="false" [showColumnTotals]="false" [width]='ui.fullWidth' [height]='ui.fullHeight' rowHeaderLayout='tree'
    rowHeaderLayout='standard' [allowExpandAll]='true' [dataSource]="edit.data">
	
    <dxo-field-chooser [enabled]="true" [height]="400"></dxo-field-chooser>
    <dxo-scrolling mode="standard" [useNative]='true'></dxo-scrolling>
    <dxo-export [enabled]='true' fileName='Auditor'></dxo-export>
    <dxo-field-panel [showDataFields]="true" [showRowFields]="true" [showColumnFields]="true" [showFilterFields]="true"
        [allowFieldDragging]="true" [visible]="true"> </dxo-field-panel>
		
    <dxo-state-storing [enabled]='true' type="localStorage" storageKey="AuditPivot">
    </dxo-state-storing>
</dx-pivot-grid>