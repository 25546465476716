import { Injectable } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { DataService } from '../../service/data.service';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import { iBisEditService, iBisLanguageService } from '@dohu/ibis-common';
import { TransportEditService } from '../transport/transport-edit.service';
import { WasteTypeEditService } from '../waste-type/waste-type-edit.service';

@Injectable()
export class GenerateEditService extends iBisEditService {
	public data: CustomStore;

	public source: { id: number; value: string }[];
	public evaluation: { id: boolean; value: string }[];

	private wt: any;

	constructor(private entity: iBisEntityService, private ds: DataService, public wtp: WasteTypeEditService,
		public ts: TransportEditService, lg: iBisLanguageService) {
		super(lg);
		this.title = 'ENTRY';
		this.validation = 'gdValidation';

		this.data = this.getData();
		this.evaluation = [{ 'id': false, 'value': lg.format('WEIGHT') }, { 'id': true, 'value': lg.format('ESTIMATED') }];
		this.source = this.getSource();
	}

	public hidePopup() {
		super.hidePopup();
		this.wtp.typeId = null;
	}

	public set transportActions(tsA: number) {
		this.ts.actions = tsA;
	}

	public get wasteType(): any {
		return this.wt;
	}

	public set wasteType(type: any) {
		if (this.wt === type) {
			return;
		}
		this.wt = type;
		this.data = this.getData();
	}

	createDefault(): any {
		// TODO: default values from config
		const result: any = { source: 1, estimated: false, date: new Date(), quantity: undefined };
		// TODO: LUCA set default workcenter if any
		return result;
	}

	getById(id: string): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			this.entity.getById('Generate', id).then(data => {
				if (data) {
					resolve(data);
				}
				reject();
			});
		});
	}

	onRemove(id: string): Promise<void> {
		return this.entity.remove('Generate', id);
	}

	onSaveEv(): Promise<any> {
		this.model.uom = this.wtp.selected.uom;
		return this.entity.save('Generate', this.model.id, this.model);
	}

	reset(): void {
		this.model = this.createDefault();
	}

	private getData(): CustomStore {
		const q = new EntityQuery('GenerateView').addOrderByDesc(['g.date']);
		if (this.wt != null && this.wt !== '') {
			q.link('g.wasteTypeId', 'id', new EntityQuery('WasteType').eq('typeId', this.wt));
		}
		return this.entity.store(q, false, 'g_id');
	}

	private getSource(): { id: number; value: string }[] {
		const list = [
			{ 'id': 1, 'value': 'GENERATED' },
			{ 'id': 2, 'value': 'NATIONAL_TAKEN' },
			{ 'id': 3, 'value': 'EU_TAKEN' },
			{ 'id': 4, 'value': 'OUTSIDE_EU_TAKEN' }];
		return this.ds.getEnum('source', list);
	}
}
