import { Injectable } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { iBisEditService, iBisLanguageService } from '@dohu/ibis-common';
import { PartyEditService } from '../../common/party/party-edit/party-edit.service';

@Injectable({
	providedIn: 'root'
})
export class ReportIntEditService extends iBisEditService {
	public reportObj: any;
	public currentYear: number;

	public dsBu: DataSource;
	public entities: DataSource;

	constructor(private party: PartyEditService, lg: iBisLanguageService) {
		super(lg);

		this.currentYear = new Date().getFullYear();
		this.dsBu = this.party.getPartyLink([0]);
		this.entities = this.party.getPartyLink([1]);
		this.reportObj = {
			bu: null, entity: null,
			workCenter: null,
			sem: null,
			fromDate: new Date().setDate(1),
			thruDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
		};
	}

	createDefault(): any {
		return {};
	}
	getById(id: string, serverUrl?: string): Promise<any> {
		throw new Error('Method not implemented.');
	}
	onRemove(id: string): Promise<void> {
		throw new Error('Method not implemented.');
	}
	onSaveEv(serverUrl?: string): Promise<any> {
		throw new Error('Method not implemented.');
	}
	reset(): void {
		this.model = this.createDefault();
	}
}
