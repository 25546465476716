import { Params } from '@angular/router';
import { Component, ViewChild } from '@angular/core';
import { ProxyService } from '../../service/proxy.service';
import { GenerateEditService } from './generate-edit.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { BreadcrumbViewComponent } from '../../framework/breadcrumb-view.component';

@Component({
	templateUrl: 'generate.component.html',
})
export class GenerateComponent extends BreadcrumbViewComponent {
	@ViewChild('gridGenerate') grid: DxDataGridComponent;
	public edit: GenerateEditService;

	constructor(public proxy: ProxyService) {
		super(proxy.generate, proxy.lg, proxy.iui, proxy.activatedRoute);
		this.id = 'g_id';
		if (!this.key) {
			this.key = 'generate';
		}
	}

	public onTransportClick = (event: any) => {
		this.proxy.wasteType.typeId = this.edit.wasteType;
		const obj = {
			workCenterId: event.row.data.g_workCenterId,
			wasteTypeId: event.row.data.g_wasteTypeId,
			quantity: event.row.data.g_quantity,
			uom: event.row.data.uom,
			materialId: event.row.data.g_materialId,
			sendDate: new Date()
		};
		this.proxy.transport.showPopup(obj);
	};

	public onTransferClick = (event: any) => {
		const obj = {
			senderWorkCenterId: event.row.data.g_workCenterId,
			wasteTypeId: event.row.data.g_wasteTypeId,
			quantity: event.row.data.g_quantity,
			uom: event.row.data.uom,
			sendDate: new Date()
		};
		this.proxy.transfer.showPopup(obj);
	};
	protected onEdit(e: any) {
		this.proxy.wasteType.typeId = this.edit.wasteType;
		super.onEdit(e);
	}

	protected prepareNew(): any{
		this.proxy.wasteType.typeId = this.edit.wasteType;
		return super.prepareNew();
	}

	protected queryParams(pr: Params): void {
		super.queryParams(pr);
		this.edit.wasteType = pr['wasteType'];
		this.edit.transportActions = pr['transportActions'];
	}

}
