<dx-data-grid  #gridContractType class="gridContainer" [height]="ui.fullHeight"
	[remoteOperations]="true" [dataSource]="edit.data" (onToolbarPreparing)="toolbarPreparing($event, proxy.allowEdit)">

	<dxo-scrolling [mode]="ui.scrollingMode" [showScrollbar]="ui.showScrollbar" [useNative]="!ui.isWindows">
	</dxo-scrolling>
	<dxo-master-detail [enabled]="true" template="details"></dxo-master-detail>
	<dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" storageKey="qhseContract">
	</dxo-state-storing>

	<dxi-column type="buttons" fixedPosition="left" [minWidth]="ui.actionWidth" [width]="ui.actionWidth" [fixed]="true"
		[allowResizing]="false" [showInColumnChooser]="false" [visible]="proxy.allowEdit">
		<dxi-button icon="edit" [onClick]="onEditClick" [visible]="proxy.allowEdit"></dxi-button>
	</dxi-column>

	<dxi-column name='contract' headerCellTemplate='cHeader'>
		<dxi-column [caption]="L('NO')" dataField="c_no" dataType="string" [minWidth]='170'></dxi-column>
		<dxi-column [caption]="L('TYPE')" dataField="c_typeId" dataType="string" [minWidth]='170'>
			<dxo-lookup [dataSource]="edit.type" displayExpr="description" valueExpr="id"></dxo-lookup>
		</dxi-column>
		<dxi-column [caption]="L('OBJECTIVE')" dataField="c_objective" dataType="string" [minWidth]='170'></dxi-column>
	</dxi-column>
	<dxi-column [caption]="L('CONTRACTOR')" dataField="ownerFullName" dataType="string" [minWidth]='170'>
	</dxi-column>
	<dxi-column [caption]="L('CONTRACTANT')" dataField="fullName" dataType="string" [minWidth]='170'>
	</dxi-column>
	<dxi-column name='valability' headerCellTemplate='vHeader'>
	<dxi-column [caption]="L('INVOICE_START_DATE')" dataField="c_startDate" dataType="date" width="170"
		calculateSortValue="c_startDate" [calculateCellValue]="iui.formatDate"></dxi-column>
	<dxi-column [caption]="L('INVOICE_END_DATE')" dataField="c_endDate" dataType="date" width="170"
		calculateSortValue="c_endDate" [calculateCellValue]="iui.formatDate"></dxi-column>
	<dxi-column [caption]="L('VALID')" dataField="c_isValid" dataType="boolean" [minWidth]='110'>
	</dxi-column>
</dxi-column>
	<dxi-column [caption]="L('OBSERVATIONS')" dataField="c_observations" dataType="string" [minWidth]='170'>
	</dxi-column>

	<div *dxTemplate="let contract of 'details'">
		<dx-tab-panel height="400">
			<dxi-item [title]="L('DOCUMENTS')">
				<app-contract-annex [contractId]="contract.data.c_id"></app-contract-annex>
			</dxi-item>
		</dx-tab-panel>
	</div>

	<div *dxTemplate="let data of 'breadcrumb'">
		<ibis-breadcrumb></ibis-breadcrumb>
	</div>
	<div *dxTemplate="let c of 'cHeader'" class="dx-datagrid-text-content" role="presentation">
		<i class="fas fa-file-signature"></i> Contract
	</div>
	<div *dxTemplate="let c of 'vHeader'" class="dx-datagrid-text-content" role="presentation">
		<i class="fas fa-calendar-week"></i> Valabilitate
	</div>
</dx-data-grid>