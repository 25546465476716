import { Component } from '@angular/core';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { ProxyService } from '../../../service/proxy.service';
import { ControlMeasureEditService } from './control-measure-edit.service';

@Component({
	selector: 'app-control-measure-edit',
	templateUrl: 'control-measure-edit.component.html'
})
export class ControlMeasureEditComponent extends iBisBaseComponent {
	public edit: ControlMeasureEditService;

	constructor(public proxy: ProxyService) {
		super(proxy.controlMeasure, proxy.lg, proxy.iui, proxy.activatedRoute);
	}
}
