import { NgModule } from '@angular/core';
import { iBisCommonModule } from '@dohu/ibis-common';
import { BrowserModule } from '@angular/platform-browser';
import { DxFormModule } from 'devextreme-angular/ui/form';
import { WasteTypeComponent } from './waste-type.component';
import { WasteFileComponent } from './waste-file.component';
import { DxPopupModule } from 'devextreme-angular/ui/popup';
import { WasteTypeEditService } from './waste-type-edit.service';
import { FrameworkModule } from '../../framework/framework.module';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { DxTreeListModule } from 'devextreme-angular/ui/tree-list';
import { WasteTypeEditComponent } from './waste-type-edit.component';
import { iBisNavigationModule } from '@dohu/ibis-navigation';

@NgModule({
	declarations: [
		WasteTypeComponent,
		WasteFileComponent,
		WasteTypeEditComponent
	],
	imports: [
		DxFormModule,
		BrowserModule,
		DxPopupModule,
		FrameworkModule,
		DxTreeListModule,
		DxDataGridModule,
		iBisCommonModule,
		iBisNavigationModule
	],
	exports: [
		WasteTypeComponent,
		WasteFileComponent,
		WasteTypeEditComponent
	],
	providers: [
		WasteTypeEditService
	]
})
export class WasteTypeModule { }
