import { Component } from '@angular/core';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { ProxyService } from '../../service/proxy.service';
import { ReportIntEditService } from './report-int-edit.service';

@Component({
	templateUrl: 'report-int.component.html'
})
export class ReportIntComponent extends iBisBaseComponent {
	public edit: ReportIntEditService;

	constructor(public proxy: ProxyService) {
		super(proxy.reportInt, proxy.lg, proxy.iui, proxy.activatedRoute);
	}
}
