import { Injectable } from '@angular/core';
import { iBisEntityService } from '@dohu/ibis-entity';
import { DataService } from '../../../service/data.service';
import { iBisSecureEditService, iBisAuthService } from '@dohu/ibis-auth';
import { iBisServerConfig, iBisLanguageService } from '@dohu/ibis-common';


@Injectable({
	providedIn: 'root'
})
export class DashboardBoxesService extends iBisSecureEditService {

	public statistic: { trUnf: number; wtNo: number; wkNo: number; trNo: number; avNo: number; masNo: number };

	constructor(auth: iBisAuthService, entity: iBisEntityService, config: iBisServerConfig, lg: iBisLanguageService,
		public ds: DataService) {
		super(auth, entity, config, lg);

	}
	createDefault() {
		return {};
	}
	getById(id: string, serverUrl?: string): Promise<any> {
		throw new Error('Method not implemented.');
	}
	onRemove(id: string): Promise<void> {
		throw new Error('Method not implemented.');
	}
	onSaveEv(serverUrl?: string): Promise<any> {
		throw new Error('Method not implemented.');
	}
	reset(): void {
		this.model = this.createDefault();
	}

	protected load(): void {
		this.initStatistics();
	}

	private initStatistics() {
		this.statistic = { trUnf: 0, wtNo: 0, wkNo: 0, trNo: 0, avNo: 0, masNo: 0 };
		this.entity.execute('GlobalStatistic', {}).then(data => {
			this.statistic = data;
		}, error => {
			this.ds.lg.showError(error);
		});
	}
}
