<dx-form [colCount]="2" [formData]="edit.model" [showValidationSummary]="false" [validationGroup]="edit.validation"
	labelLocation="left">
	<dxi-item [label]="{text: L('CODE')}" dataField="code" editorType="dxTextBox" [editorOptions]="{}">
		<dxi-validation-rule type="required" [validationCallback]="generalValidation"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('PARENT')}" dataField="parentId" editorType="dxSelectBox"
		[editorOptions]="{ dataSource: edit.parent, valueExpr: 'id', displayExpr: 'code', showClearButton: true }">
	</dxi-item>
	<dxi-item [label]="{text: L('NAME')}" [colSpan]="2" dataField="name" editorType="dxTextBox" [editorOptions]="{}">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('HAZARD')}" dataField="hazard" editorType="dxSelectBox"
		[editorOptions]="{ dataSource: edit.hazard, valueExpr: 'id', displayExpr: 'value', disabled: isParent}">
		<dxi-validation-rule type="custom" [validationCallback]="generalValidation"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('PHYSICAL_STATE')}" dataField="physicalCondition" editorType="dxSelectBox"
		[editorOptions]="{ dataSource: edit.physicalState, valueExpr: 'id', displayExpr: 'value', disabled: isParent }">
		<dxi-validation-rule type="custom" [validationCallback]="generalValidation"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('U')}" dataField="uom" editorType="dxSelectBox"
		[editorOptions]="{ dataSource: edit.uom, valueExpr: 'id', displayExpr: 'value', disabled: isParent}">
		<dxi-validation-rule type="custom" [validationCallback]="generalValidation"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('TYPE')}" dataField="typeId" editorType="dxSelectBox"
		[editorOptions]="{ dataSource: edit.type, valueExpr: 'id', displayExpr: 'value', disabled: isParent}">
		<dxi-validation-rule type="custom" [validationCallback]="generalValidation"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('MATERIAL_TYPE')}" dataField="materialId" editorType="dxSelectBox"
		[editorOptions]="{ dataSource: edit.materialType, valueExpr: 'id', displayExpr: 'code', disabled: isParent, showClearButton: true}">
	</dxi-item>
	<dxi-item [label]="{text: L('CATEGORY_TYPE')}" dataField="categoryId" editorType="dxSelectBox"
		[editorOptions]="{ dataSource: edit.categoryType, valueExpr: 'id', displayExpr: 'code', disabled: isParent, showClearButton: true}">
	</dxi-item>
	<dxi-item [label]="{visible: false}" [colSpan]='2' [visible]='!isParent'>
		<div *dxTemplate="let data of 'template'">
			<dx-data-grid [dataSource]="edit.otherUom" height="300" (onToolbarPreparing)="onToolbarPreparing($event)"
				(onRowRemoved)="onUomRemoving($event)">
				<dxo-editing mode="cell" [allowUpdating]="true" [allowDeleting]="true" [allowAdding]="true">
				</dxo-editing>
				<dxi-column [caption]="L('U')" dataField='uom' dataType='number'>
					<dxo-lookup [dataSource]='edit.uom' valueExpr='id' displayExpr='value'></dxo-lookup>
					<dxi-validation-rule type="required"></dxi-validation-rule>
					<dxi-validation-rule type="custom" [validationCallback]="alternateValidation"
						[message]="L('UOM_ALREADY_ASSIGNED')"></dxi-validation-rule>
				</dxi-column>
				<dxi-column [caption]="L('FACTOR')" dataField="factor" dataType="number">
					<dxi-validation-rule type="required"></dxi-validation-rule>
				</dxi-column>
			</dx-data-grid>
		</div>
	</dxi-item>
</dx-form>