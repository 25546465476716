<div class="registerContainer">

  <div class="register-body">
    <h2 style="text-align: center; color: #46241f">{{L('WASTE_EVIDENCE')}}</h2>
    <br>
    <div *ngIf="!forgotPassword" class="create-acc-body">
      <h3>{{L('CREATE_ACC')}}</h3>
      <dx-form [(formData)]="registerData" validationGroup="registerValidation">
        <dxi-item [label]="{ visible: false}" dataField="fullName" editorType="dxTextBox"
          [editorOptions]="{ placeholder: L('FULL_NAME')}">
          <dxi-validation-rule type="required" [message]="L('NAME_IS_REQUIRED')"></dxi-validation-rule>
        </dxi-item>
        <dxi-item [label]="{ visible: false}" dataField="_Companie_" editorType="dxTextBox"
          [editorOptions]="{ placeholder: L('COMPANY_NAME')}">
          <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-item>
        <dxi-item [label]="{ visible: false}" dataField="_CIF_" editorType="dxTextBox"
          [editorOptions]="{ placeholder: L('FISCAL_CODE')}">
          <dxi-validation-rule type="custom" [reevaluate]="true" [validationCallback]="validateCIF">
          </dxi-validation-rule>
        </dxi-item>
        <dxi-item [label]="{ visible: false}" dataField="emailAddress" editorType="dxTextBox"
          [editorOptions]="{ placeholder: L('EMAIL_ADDRESS')}">
          <dxi-validation-rule type="email" [message]="L('EMAIL_ADDRESS_IS_INVALID')"></dxi-validation-rule>
          <dxi-validation-rule type="required" [message]="L('EMAIL_ADDRESS_IS_MANDATORY')"></dxi-validation-rule>
        </dxi-item>
        <dxi-item [label]="{ visible: false}" dataField="_Telefon_" editorType="dxTextBox"
          [editorOptions]="{ placeholder: L('PHONE')}">
          <dxi-validation-rule type="required" [message]="L('PHONE_NUM_REQ')"></dxi-validation-rule>
          <dxi-validation-rule type="pattern" pattern="^(\+4|)?(07[0-8]{1}[0-9]{1}|02[0-9]{2}|03[0-9]{2}){1}?(\s|\.|\-)?([0-9]{3}(\s|\.|\-|)){2}$">
          </dxi-validation-rule>
        </dxi-item>
        <!-- <dxi-item [label]="{visible: false}" dataField="isConfirm" editorType="dxCheckBox" [editorOptions]="{ text: ""}"></dxi-item> -->
        <dxi-item>
          <dx-check-box [(value)]="registerData.isConfirm"></dx-check-box>
          <p style="font-size: 14px; display: inline;margin-left: 5px;">{{L('AGREE_WITH')}}
            <a href="{{termCond}}" target="_blank">{{L('TERMS_COND')}}</a>
          </p>
        </dxi-item>
      </dx-form>
      <div style="margin: 20px auto; width: 170px;">
        <dx-button class="register-btn" [text]="L('REGISTER')" (onClick)="onRegister($event)"
          validationGroup="registerValidation" [disabled]="!registerData.isConfirm || isButtonDisable"></dx-button>
      </div>
    </div>
    <div *ngIf="forgotPassword" class="forgot-pass-body">
      <h3>{{L('RESET_PASS')}}</h3>
      <dx-validation-group>
        <dx-text-box [placeholder]="L('EMAIL_ADDRESS')" [(value)]="resetEmail">
          <dx-validator #emailValidation>
            <dxi-validation-rule type="required" [message]="L('EMAIL_ADDRESS_IS_MANDATORY')"></dxi-validation-rule>
            <dxi-validation-rule type="email" [message]="L('EMAIL_ADDRESS_IS_INVALID')"></dxi-validation-rule>
          </dx-validator>
        </dx-text-box>
        <br>
        <dx-validation-summary></dx-validation-summary>
        <div style="text-align: center">
          <dx-button [text]="L('SEND')" type="default" (onClick)="onResetPassword($event)"
            [disabled]="isButtonDisable"></dx-button>
          <span style="font-size: 0.8em;margin: 10px">{{L('OR')}}</span>
          <dx-button [text]="L('CANCEL')" type="default" (onClick)="onCancelResetPassword($event)"
          ></dx-button>
        </div>
      </dx-validation-group>
    </div>
  </div>
    <div class="register-header">
    <h1>
      <a href="#"><img src="assets/img/logo.svg"></a>
    </h1>
  </div>
  <div class="register-footer">
    <h4>Copyright © {{ year }} <a href="https://www.dohu.ro">DoHu Consulting</a>{{L('RIGHTS_RESERVED')}}</h4>
  </div>
</div>
<!-- Event snippet for formular ED conversion page -->
<script>
  gtag("event", "conversion", { "send_to": "AW-728412303/U7o0CMTTq7EBEI_hqtsC" });
</script>