
import { Component } from '@angular/core';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { ProxyService } from '../../service/proxy.service';
import { TransportEditService } from './transport-edit.service';
import { UOMHelper } from '../waste-type/waste-type-edit.service';

@Component({
	selector: 'app-transport-edit',
	templateUrl: 'transport-edit.component.html'
})
export class TransportEditComponent extends iBisBaseComponent {
	public edit: TransportEditService;
	public uomHelper: UOMHelper;
	public noFatQtyCollected: string;

	private cboFAT: any;
	private cboCar: any;
	private cboDriver: any;
	private cboWasteType: any;
	constructor(public proxy: ProxyService) {
		super(proxy.transport, proxy.lg, proxy.iui, proxy.activatedRoute);
		this.uomHelper = new UOMHelper(this.proxy.wasteType);

		proxy.wasteType.onDataChanged.subscribe((data) => {
			if (data === 'code' && this.cboWasteType) {
				this.proxy.resetValidation(this.cboWasteType);
			}
			if (data === 'selected') {
				this.edit.model.wasteType = this.proxy.wasteType.selected;
				if (this.edit.model.uom) {
					this.proxy.wasteType.selected.uom = this.edit.model.uom;
					this.uomHelper.update(this.proxy.wasteType.selectedUomName);
				}
			}
		});
		proxy.transporter.onDataChanged.subscribe((data) => {
			if (data === 'selected' && this.cboCar && this.cboDriver) {
				this.proxy.resetValidation(this.cboCar);
				this.proxy.resetValidation(this.cboDriver);
			}
		});
		proxy.transportApproval.onDataChanged.subscribe((data) => {
			if (data === 'code' && this.cboFAT) {
				this.proxy.resetValidation(this.cboFAT);
			}
			if (data === 'selected') {
				this.edit.model.fat = this.proxy.transportApproval.selected;
			}
		});
	}

	onFATInit = (ev: any) => {
		this.cboFAT = ev.component;
	};

	onCarInit = (ev: any) => {
		this.cboCar = ev.component;
	};

	onDriverInit = (ev: any) => {
		this.cboDriver = ev.component;
	};

	onWasteTypeInit = (ev: any) => {
		this.cboWasteType = ev.component;
	};

	onFatChanged = (ev: any) => {
		this.proxy.transportApproval.selectedId = ev.value;
	};

	onDestinationChanged = (ev: any) => {
		this.proxy.transportApproval.destinationId = ev.value;
	};

	onTransporterChanged = (ev: any) => {
		this.proxy.transporter.selectedId = ev.value;
		this.proxy.transportApproval.transporterId = ev.value;
	};

	onWorkCenterChanged = (ev: any) => {
		this.proxy.wasteType.workCenterId = ev.value;
		this.proxy.transportApproval.generatorId = ev.value;
	};

	onWasteTypeChanged = (ev: any) => {
		this.proxy.wasteType.selectedId = ev.value;
		this.proxy.transportApproval.wasteTypeId = ev.value;
	};

	onCarChanged = (ev: any) => {
		this.proxy.transportApproval.carId = ev.value;
	};

	onDriverChanged = (ev: any) => {
		this.proxy.transportApproval.driverId = ev.value;
	};

	public get qtyHelpText(): string {
		return this.proxy.transportApproval.transportNoFAT ? this.L('FAT_QUANTITY_HELP', [
			this.proxy.transportApproval.transportNoFAT.toString(),
			this.proxy.wasteType.selectedUomName
		]) : '';
	}

	onActionListChange(e: any, dropdown: any) {
		this.edit.setRecipient(this.edit.model.selectedActivity);
	}

	validateFAT = (_options: any) => {
		if (this.edit.model.wasteType.hazard === 1 && this.proxy.wasteType.selected.requireApproval && !this.edit.model.approvalId) {
			return false;
		} else {
			// TODO: more checks in the future.
			return true;
		}
		// if (this.edit.model.wasteType.hazard === 1 && this.edit.model.quantity >= (this.proxy.wasteType.selected.uom === 1 ? 1 : 1000)
		// 	&& !this.edit.model.approvalId) {
		// 	return false;
		// } else {
		// 	const totalQuantity = this.proxy.transportApproval.transportNoFAT +
		// 		this.proxy.transportApproval.collectNoFAT +
		// 		(this.proxy.wasteType.selected.uom === 1 ? this.edit.model.quantity * 1000 : this.edit.model.quantity);
		// 	if (totalQuantity >= 1000 && this.edit.model.wasteType.hazard === 1 && !this.edit.model.approvalId) {
		// 		return false;
		// 	} else {
		// 		// TODO: PORNO, this is a case only for Tones AND Kg, need something automatic for more uoms
		// 		const quantity = this.edit.model.uom === 1 ? this.edit.model.quantity : this.edit.model.quantity * 1000;
		// 		const fatQuantityLeft = this.proxy.transportApproval.selected.uom === 1 ?
		// 			this.proxy.transportApproval.selected.quantityLeft : this.proxy.transportApproval.selected.quantityLeft * 1000;
		// 		if (quantity > fatQuantityLeft) {
		// 			return false;
		// 		} else {
		// 			return true;
		// 		}
		// 	}
		// }
	};

	onCustomItemDriver = (ev: any) => {
		this.edit.model.driverTableId = this.proxy.transporter.onCustomItemDriver(ev, this.edit.model.transporterId);
	};

	onCustomItemCar = (ev: any) => {
		this.edit.model.carId = this.proxy.transporter.onCustomItemCar(ev, this.edit.model.transporterId);
	};
}
