import { iBisBaseComponent } from '@dohu/ibis-common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ProxyService } from '../../../service/proxy.service';
import { DxTagBoxComponent } from 'devextreme-angular/ui/tag-box';
import { KPIAnalyzesFilterService } from './kpi-analyzes-filter.service';

@Component({
	selector: 'app-kpi-analyzes-filter',
	templateUrl: './kpi-analyzes-filter.component.html'
})
export class KPIAnalyzesFilterComponent extends iBisBaseComponent implements OnInit {

	@ViewChild('wasteCodeTagBox') tagBox: DxTagBoxComponent;
	public edit: KPIAnalyzesFilterService;
	constructor(public proxy: ProxyService) {
		super(proxy.kpiAnalyzes, proxy.lg, proxy.iui, proxy.activatedRoute);
	}

	ngOnInit(): void {
		if (sessionStorage.getItem('kpi-analyzes')) {
			this.edit.model = JSON.parse(sessionStorage.getItem('kpi-analyzes'));
			this.edit.filterData();
		}
	}

	public onRaportTypeChanged = (ev: any) => {
		if (ev.value === 1) {
			this.edit.model.thruDate = null;
			this.edit.model.interval = 0;
		}
	};

	public onValueChanged(ev: any) {
		if (ev.event) {
			this.edit.model.wasteType = [];
			this.edit.refreshWasteTypeData();
		}
	}
}
