/* eslint-disable no-bitwise */
import { TransportBase } from '../transport-base';
import { Component, ViewChild } from '@angular/core';
import { ProxyService } from '../../../service/proxy.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { TransportFullEditService } from './transport-full-edit.service';

@Component({
	selector: 'app-transport-full',
	templateUrl: './transport-full.component.html'
})
export class TransportFullComponent extends TransportBase {
	@ViewChild('gridFullTransport') grid: DxDataGridComponent;

	public edit: TransportFullEditService;
	constructor(public proxy: ProxyService) {
		super(proxy, proxy.transportFull);

		this.id = 't_id';
	}

	displayActivity(val: number) {
		const arr = this.proxy.transport.activity;
		if (!arr) {
			return '';
		}
		const result = [];
		for (const i of arr) {
			if ((val & i.id) === i.id) {
				result.push(i.value);
			}
		}
		return result.join(', ');
	}
}
