<dx-form #transportApprovalForm [formData]="edit.model" [showValidationSummary]="false" labelLocation="left"
    validationGroup="transportApprovalValidation" [colCount]="1">
    <dxi-item [label]="{text: L('CARRIER')}" dataField="transporterId" editorType="dxSelectBox" [editorOptions]="{ dataSource: proxy.party.allTransporter, valueExpr: 'c_id', displayExpr: 'fullName', searchEnabled: true,
    onValueChanged: onTransporterChanged }">
        <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-item>
    <dxi-item [label]="{text: L('REGISTER_NO') }" dataField="carId" editorType="dxSelectBox" [editorOptions]="{ dataSource: proxy.transporter.carNo, valueExpr: 'id', displayExpr: 'registerNo', disabled: !edit.model.transporterId, 
    searchEnabled: true, onInitialized: onCarInit }">
    </dxi-item>
    <dxi-item [label]="{text: L('DRIVER') }" dataField="driverId" editorType="dxSelectBox" [editorOptions]="{ dataSource: proxy.transporter.driverName, valueExpr: 'id', displayExpr: proxy.transporter.displayDriverName, disabled: !edit.model.transporterId, 
    searchEnabled: true, onInitialized: onDriverInit }">
    </dxi-item>
</dx-form>