import { Injectable } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import { iBisEditService, iBisLanguageService } from '@dohu/ibis-common';
import { WasteTypeEditService } from '../../../waste/waste-type/waste-type-edit.service';

@Injectable()
export class UnitWasteEditService extends iBisEditService {
	public type: DataSource;

	protected wcId: any;
	constructor(protected entity: iBisEntityService, private wtp: WasteTypeEditService, lg: iBisLanguageService) {
		super(lg);
		this.validation = 'partyWasteValidation';
		this.title = 'ASSOC_WASTE_CODE';
	}

	public showPopup(data: any, byId?: boolean, serverUrl?: string): Promise<any>{
		this.wtp.workCenterId = null;
		return super.showPopup(data, byId, serverUrl);
	}

	public set workCenterId(id: any) {
		this.wcId = id;
	}

	public getData(): CustomStore {
		return this.entity.store(new EntityQuery('WasteTypePartyView').eq('wtp_workCenterId', this.wcId).eq('wtp_senderId', null).
			addOrderBy(['wt.code']), false, 'wtp_id');
	}

	createDefault(): any {
		return { isActiv: true, workCenterId: this.wcId, requireApproval: true };
	}

	getById(id: string): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			this.entity.getById('WasteTypeParty', id).then((obj) => {
				if (obj) {
					resolve(obj);
				} else {
					reject();
				}
			}, error => reject(error));
		});
	}

	onRemove(id: string): Promise<void> {
		return new Promise<void>((resolve, reject) => reject());
	}

	onSaveEv(): Promise<any> {
		const q = new EntityQuery('WasteTypeParty').eq('wasteTypeId', this.model.wasteTypeId).
			eq('workCenterId', this.model.workCenterId).eq('senderId', this.model.senderId).neq('id', this.model.id);
		return new Promise<any>((resolve, reject) => {
			this.entity.count(q).then(count => {
				if (count > 0) {
					reject('CODE_ALREADY_ASSIGNED');
				} else {
					this.entity.save('WasteTypeParty', this.model.id, this.model).then(response => {
						resolve(response);
					}, error => reject(error));
				}
			}, error => reject(error));
		});
	}

	reset(): void {
		this.model = this.createDefault();
	}
}
