<dx-data-grid class="gridContainer box-shadow" #gridCollect [dataSource]="edit.data" [height]="ui.fullHeight"
	[remoteOperations]="true" (onToolbarPreparing)="toolbarPreparing($event)">

	<dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" [storageKey]="key">
	</dxo-state-storing>
	<dxo-scrolling mode="infinite" [showScrollbar]="ui.showScrollbar" [useNative]="!ui.isWindows">
	</dxo-scrolling>

	<dxi-column type="buttons" fixedPosition="left" [minWidth]="proxy.actionsWidth" [width]="proxy.actionsWidth"
		[fixed]="true" [allowResizing]="false" [showInColumnChooser]="false" [visible]="proxy.showActions">
		<dxi-button [hint]="L('EDIT')" icon="edit" [onClick]="onEditClick" [visible]="proxy.allowEdit">
		</dxi-button>
		<dxi-button [hint]="L('DELETE')" icon="trash" [onClick]="onDeleteClick" [visible]="proxy.allowDelete">
		</dxi-button>
	</dxi-column>

	<dxi-column name='colector' headerCellTemplate='colectorHeader'>
		<dxi-column [caption]="L('CREATION_DATE')" dataField="c_date" dataType="date"
			[calculateDisplayValue]="iui.formatDate" calculateSortValue="c_date" minWidth="120"></dxi-column>
		<dxi-column [caption]="L('FET_FID')" dataField="c_fet" dataType="string"></dxi-column>
		<dxi-column [caption]="L('COLLECT_WORKSTATION')" dataField="wc_name" dataType="string"></dxi-column>
	</dxi-column>
	<dxi-column name='waste' headerCellTemplate='wasteHeader'>
		<dxi-column [caption]="L('WASTE_CODE')" dataField="wt_code" dataType="string"></dxi-column>
		<dxi-column [caption]="L('FAT')" dataField="ta_no" dataType="string"></dxi-column>
		<dxi-column [caption]="L('WASTE_TYPE')" dataField="wt_hazard" dataType="string">
			<dxo-lookup [dataSource]="proxy.wasteType.hazard" valueExpr="id" displayExpr="value"></dxo-lookup>
		</dxi-column>
		<dxi-column [caption]="L('WASTE_NAME')" dataField="wt_name" dataType="string"></dxi-column>
		<dxi-column [caption]="L('PHYSICAL_STATE')" dataField="c_physicalCondition" dataType="number">
			<dxo-lookup [dataSource]="proxy.wasteType.physicalState" valueExpr="id" displayExpr="value"></dxo-lookup>
		</dxi-column>
	</dxi-column>
	<dxi-column name='generator' headerCellTemplate='generatorHeader'>
		<dxi-column [caption]="L('GENERATOR')" dataField="generatorName" dataType="string"></dxi-column>
		<dxi-column [caption]="L('GENERATOR_CUI')" dataField="pwc_cif" dataType="string"></dxi-column>
		<dxi-column [caption]="L('GENERATOR_LOCATION')" dataField="generatorLocation" dataType="string"></dxi-column>
	</dxi-column>
	<dxi-column name='transporter' headerCellTemplate='transHeader'>
		<dxi-column [caption]="L('CARRIER')" dataField="tr_name" dataType="string"></dxi-column>
		<dxi-column [caption]="L('REGISTER_NO')" dataField="car_registerNo" dataType="string"></dxi-column>
		<dxi-column [caption]="L('DRIVER')" dataField="driverName" dataType="string"></dxi-column>
	</dxi-column>
	<dxi-column name='quantity' headerCellTemplate='qHeader'>
		<dxi-column [caption]="L('U')" dataField="uom" dataType="number">
			<dxo-lookup [dataSource]="proxy.wasteType.uom" valueExpr="id" displayExpr="value"></dxo-lookup>
		</dxi-column>
		<dxi-column [caption]="L('QUANTITY')" dataField="c_quantity" dataType="number"></dxi-column>
		<dxi-column [caption]="L('ELIMINATION_CODE')" dataField="e_code" dataType="string"></dxi-column>
		<dxi-column [caption]="L('ELIMINATED')" dataField="es_quantity" dataType="number"></dxi-column>
		<dxi-column [caption]="L('CAPITALIZATION_CODE')" dataField="ca_code" dataType="string"></dxi-column>
		<dxi-column [caption]="L('TREATED')" dataField="ts_quantity" dataType="number"></dxi-column>
	</dxi-column>
	<dxi-column name='invoice' headerCellTemplate='invoiceHeader'>
		<dxi-column [caption]="L('INVOICE_DATE')" dataField="c_invoiceDate" dataType="date"
			[calculateCellValue]="iui.formatDate" calculateSortValue="c_invoiceDate" minWidth="150"></dxi-column>
		<dxi-column [caption]="L('INVOICE_NO')" dataField="c_invoiceNo" dataType="string"></dxi-column>
	</dxi-column>
	<dxi-column [caption]="L('OBSERVATIONS')" dataField="c_observations" dataType="string"></dxi-column>

	<div *dxTemplate="let data of 'breadcrumb'">
		<ibis-breadcrumb></ibis-breadcrumb>
	</div>
	<div *dxTemplate="let c of 'transHeader'" class="dx-datagrid-text-content" role="presentation">
		<i class="fas fa-truck-moving"></i> Transportator
	</div>
	<div *dxTemplate="let c of 'wasteHeader'" class="dx-datagrid-text-content" role="presentation">
		<i class='fas fa-recycle'></i> Deseu
	</div>
	<div *dxTemplate="let c of 'qHeader'" class="dx-datagrid-text-content" role="presentation">
		<i class='fas fa-weight'></i> Cantitate
	</div>
	<div *dxTemplate="let c of 'invoiceHeader'" class="dx-datagrid-text-content" role="presentation">
		<i class="fas fa-file-invoice"></i> Factura
	</div>
	<div *dxTemplate="let c of 'generatorHeader'" class="dx-datagrid-text-content" role="presentation">
		<i class="fas fa-industry"></i> Generator
	</div>
	<div *dxTemplate="let c of 'colectorHeader'" class="dx-datagrid-text-content" role="presentation">
		<i class="fas fa-building"></i> Colector
	</div>

</dx-data-grid>

<ibis-edit-popup [edit]="edit" [showAdd]="true">
	<app-collect-edit></app-collect-edit>
</ibis-edit-popup>