import { Component } from '@angular/core';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { ProxyService } from '../../../service/proxy.service';
import { UOMHelper } from '../../waste-type/waste-type-edit.service';
import { TransportApprovalEditService } from './transport-approval-edit.service';

@Component({
	selector: 'app-transport-approval-edit',
	templateUrl: './transport-approval-edit.component.html'
})
export class TransportApprovalEditComponent extends iBisBaseComponent {
	public edit: TransportApprovalEditService;
	public uomHelper: UOMHelper;

	private cboWasteType: any;

	constructor(public proxy: ProxyService) {
		super(proxy.transportApproval, proxy.lg, proxy.iui, proxy.activatedRoute);
		this.uomHelper = new UOMHelper(this.proxy.wasteType);
		proxy.wasteType.onDataChanged.subscribe((data) => {
			if (data === 'code' && this.cboWasteType) {
				this.proxy.resetValidation(this.cboWasteType);
			}
			if (data === 'selected') {
				this.edit.model.wasteType = this.proxy.wasteType.selected;
				if(this.edit.model.uom) {
					this.proxy.wasteType.selected.uom = this.edit.model.uom;
					this.uomHelper.update(this.proxy.wasteType.selectedUomName);
				}
			}
		});
	}

	onWasteTypeInit = (ev: any) => {
		this.cboWasteType = ev.component;
	};

	onWorkCenterChanged = (ev: any) => {
		this.proxy.wasteType.workCenterId = ev.value;
	};

	onWasteTypeChanged = (ev: any) => {
		this.proxy.wasteType.selectedId = ev.value;
	};

	onStartChange = (ev: any) => {
		if (!this.edit.model.id && ev.value) {
			const time = new Date(ev.value).setFullYear(new Date(ev.value).getFullYear() + 2);
			this.edit.model.expireDate = new Date(time);
		}
	};

	endDateValidation = (options) => {
		const startDate = this.setDateTo(new Date(this.edit.model.startDate));
		const thruDate = this.setDateTo(new Date(options.value));
		if (thruDate < startDate) {
			return false;
		}
		const maxDate = new Date(startDate.getFullYear() + 2, startDate.getMonth(), startDate.getDate());
		if (thruDate > maxDate) {
			return false;
		}
		return true;
	};

	private setDateTo(date: Date): Date {
		date.setHours(0);
		date.setMinutes(0);
		date.setSeconds(0);
		date.setMilliseconds(0);
		return date;
	}
}
