import { Component, Input } from '@angular/core';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { ProxyService } from '../../service/proxy.service';
import { WasteTypeEditService } from './waste-type-edit.service';

@Component({
	selector: 'app-waste-type-edit',
	templateUrl: 'waste-type-edit.component.html'
})

export class WasteTypeEditComponent extends iBisBaseComponent {
	@Input() wastetypeData: any;
	public edit: WasteTypeEditService;

	constructor(public proxy: ProxyService) {
		super(proxy.wasteType, proxy.lg, proxy.iui, proxy.activatedRoute);
	}

	public generalValidation = (options: any): Boolean => {
		const code = this.edit.model.code;
		return code && (code.endsWith('00') || options.value !== null);
	};

	public alternateValidation = (options: any): Boolean => this.edit.otherUom.findIndex(x => x.uom === options.value) === -1;

	public get isParent(): boolean {
		return !this.edit.model.code || this.edit.model.code.endsWith('00');
	}

	public onToolbarPreparing(ev: any) {
		ev.toolbarOptions.items.unshift({
			location: 'before',
			text: this.L('ALTERNATE_UOM')
		});
	}

	public onUomRemoving(ev: any) {
		if (ev.data.id) {
			this.edit.removeUom(ev.data.id);
		}
	}
}
