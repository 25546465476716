<dx-data-grid #gridEnumType class="gridContainer" [dataSource]="edit.data" [height]="ui.fullHeight"
	(onToolbarPreparing)="toolbarPreparing($event, proxy.allowEdit)" (onRowExpanding)="onRowExpanding($event)">

	<dxo-scrolling [mode]="ui.scrollingMode" [showScrollbar]="ui.showScrollbar" [useNative]="!ui.isWindows">
	</dxo-scrolling>
	<dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" [storageKey]='key'>
	</dxo-state-storing>

	<dxo-master-detail [enabled]="true" template="details"></dxo-master-detail>

	<dxi-column type="buttons" fixedPosition="left" [minWidth]="proxy.actionsWidth" [width]="proxy.actionsWidth" 
		[fixed]="true" [allowResizing]="false" [showInColumnChooser]="false" [visible]="proxy.showActions">
		<dxi-button [hint]="L('EDIT')" icon="edit" [onClick]="onEditClick" [visible]="proxy.allowEdit">
		</dxi-button>
		<dxi-button [hint]="L('DELETE')" icon="trash" [onClick]="onDeleteClick" [visible]="proxy.allowDelete">
		</dxi-button>
	</dxi-column>

	<dxi-column [caption]="L('CODE')" dataField="code" dataType="string"></dxi-column>
	<dxi-column [caption]="L('DESCRIPTION')" dataField="description" dataType="string">
	</dxi-column>

	<div *dxTemplate="let data of 'breadcrumb'">
		<ibis-breadcrumb></ibis-breadcrumb>
	</div>
	<div *dxTemplate="let type of 'details'">
		<app-enum-value [typeId]="type.data.id"></app-enum-value>
	</div>
</dx-data-grid>

<ibis-edit-popup [edit]="edit" [showAdd]="true">
	<app-enum-type-edit></app-enum-type-edit>
</ibis-edit-popup>