import { Component, ViewChild } from '@angular/core';
import { iBisViewComponent } from '@dohu/ibis-common';
import { ProfileEditService } from './profile-edit.service';
import { ProxyService } from '../../../service/proxy.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';

@Component({
	selector: 'app-profile',
	templateUrl: './profile.component.html'
})
export class ProfileComponent extends iBisViewComponent {
	@ViewChild('gridProfile') protected grid: DxDataGridComponent;

	public edit: ProfileEditService;
	constructor(public proxy: ProxyService) {
		super(proxy.profile, proxy.lg, proxy.iui, proxy.activatedRoute);
	}

	onInitNewRow(e: any) {
		e.data.isActiv = true;
	}
}
