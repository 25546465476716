import { NgModule } from '@angular/core';
import { SafetyKpiModule } from './analize/safety-kpi.module';
import { SafetyAdminModule } from './admin/safety-admin.module';
import { SafetyAuditModule } from './audit/safety-audit.module';

@NgModule({
	declarations: [
	],
	imports: [
		SafetyKpiModule,
		SafetyAdminModule,
		SafetyAuditModule,
	],
	exports: [
	],
	providers: [
	]
})
export class SafetyModule { }
