import { iBisViewComponent } from '@dohu/ibis-common';
import { Component, Input, ViewChild } from '@angular/core';
import { ProxyService } from '../../../service/proxy.service';
import { PartyEditService } from '../party-edit/party-edit.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';

@Component({
	selector: 'app-party-contract',
	templateUrl: 'party-contract.component.html'
})
export class PartyContractComponent extends iBisViewComponent {
	@Input() partyId: any;
	@ViewChild('gridCTR') grid: DxDataGridComponent;

	public edit: PartyEditService;
	constructor(public proxy: ProxyService) {
		super(proxy.party, proxy.lg, proxy.iui, proxy.activatedRoute);
		this.id = 'c_id';
		this.key = 'partyContract';
	}

	onGridInit(e: any) {
		if (e.component.option('dataSource') == null) {
			e.component.option('dataSource', this.edit.getContract(this.partyId));
		}
	}
}
