<dx-form [formData]="edit.model" [validationGroup]="edit.validation" [colCount]="2" style="padding-top:10px">
    <dxi-item [label]="{text: L('DATE') }" dataField="monthYear" editorType="dxDateBox" [editorOptions]="{ displayFormat: 'MM.yyyy', width: '100%',
        calendarOptions: {minZoomLevel: 'year', maxZoomLevel: 'year' } }">
    </dxi-item>
    <dxi-item [label]="{text: L('AUDITOR')}" dataField="auditorId" editorType="dxSelectBox"
        [editorOptions]="{ dataSource: proxy.auditor.data, valueExpr: 'id', displayExpr: 'displayName' }">
    </dxi-item>
    <dxi-item [label]="{ text: L('REASON') }" dataField="reason" editorType="dxTextBox"
        [editorOptions]="{ }">
        <dxi-validation-rule type="required"></dxi-validation-rule>
        <dxi-validation-rule type="stringLength"  [max]="256" [message]="L('NAME_LENGTH')">
		</dxi-validation-rule>
    </dxi-item>
    <dxi-item [label]="{ text: L('PLANNED') }" dataField="planned" editorType="dxNumberBox" [editorOptions]="{ }">
        <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-item>
</dx-form>