<dx-form [colCount]="2" [formData]="edit.model" [showValidationSummary]="false" [validationGroup]="edit.validation"
	labelLocation="left">
	<dxi-item [label]="{text: L('NO')}" dataField="no" editorType="dxTextBox" [editorOptions]="{ }">
		<dxi-validation-rule type="required"></dxi-validation-rule>
		<dxi-validation-rule type="stringLength"  [max]="256" [message]="L('NAME_LENGTH')">
		</dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('VALID')}" dataField="isValid" editorType="dxCheckBox" [editorOptions]="{ }">
	</dxi-item>

	<dxi-item [label]="{text: L('DOCUMENT_TYPE')}" dataField="categoryId" [colSpan]="2" editorType="dxSelectBox"
		[editorOptions]="{ dataSource: edit.category, valueExpr: 'id', displayExpr: 'description' }">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('ENT_WORKC')}" dataField="entityId" editorType="dxSelectBox" [colSpan]="2"
		[editorOptions]="{dataSource: proxy.party.allUnit, valueExpr: 'c_id', displayExpr: 'fullName', searchEnabled: true }">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>

	<dxi-item [label]="{text: L('VALIDITY')}" dataField="validity" editorType="dxDateBox"
		[editorOptions]="{ format: 'dd.MM.yyyy', width: 'auto' }">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('REVISED_ON_DATE')}" dataField="revision" editorType="dxDateBox"
		[editorOptions]="{ format: 'dd.MM.yyyy', width: 'auto' }">
	</dxi-item>

	<dxi-item [colSpan]='2' [label]="{text: L('DESCRIPTION')}" dataField="description" editorType="dxTextBox"
		[editorOptions]="{ }">
	</dxi-item>
</dx-form>