import { Component } from '@angular/core';
import { ProxyService } from '../../../app/service/proxy.service';
import { GenerateEditComponent } from '../../../app/waste/generate/generate-edit.component';

@Component({
	selector: 'app-ecofire-generate-edit',
	templateUrl: './generate-edit.component.ecofire.html'
})
export class EcoFireGenerateEditComponent extends GenerateEditComponent {

	constructor(public proxy: ProxyService) {
		super(proxy);
	}
}
