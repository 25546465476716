import { Component } from '@angular/core';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { AuditorEditService } from './auditor-edit.service';
import { ProxyService } from '../../../service/proxy.service';

@Component({
	selector: 'app-auditor-edit',
	templateUrl: './auditor-edit.component.html'
})
export class AuditorEditComponent extends iBisBaseComponent {

	public edit: AuditorEditService;
	constructor(public proxy: ProxyService) {
		super(proxy.auditor, proxy.lg, proxy.iui, proxy.activatedRoute);
	}
}
