<dx-form [colCount]="4" [formData]="edit.model" [showValidationSummary]="false" [validationGroup]="edit.validation"
    labelLocation="left">
    <dxi-item [label]="{text: L('NO')}" dataField="no" editorType="dxTextBox" [colSpan]="2" [editorOptions]="{ }">
    </dxi-item>
    <dxi-item [label]="{text: L('DATE')}" dataField="sendDate" editorType="dxDateBox" [colSpan]="2"
        [editorOptions]="{ displayFormat: 'dd.MM.yyyy', width: '100%' }">
        <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-item>
    <dxi-item [label]="{text: L('VALUE')}" dataField="value" editorType="dxNumberBox" [colSpan]="2" [editorOptions]="{ }">
        <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-item>
    <dxi-item [label]="{text: L('PAYMENT_DATE')}" dataField="payDate" editorType="dxDateBox" [colSpan]="2"
        [editorOptions]="{displayFormat: 'dd.MM.yyyy', width: '100%' }">
    </dxi-item>
    <dxi-item [label]="{text: L('ENT_WORKC')}" dataField="workCenterId" editorType="dxSelectBox" [colSpan]="4"
        [editorOptions]="{ dataSource: proxy.party.ownUnit, valueExpr: 'c_id', displayExpr: 'fullName',  onValueChanged: onWorkCenterChanged, searchEnabled: true }">
        <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-item>
    <dxi-item [label]="{text: L('AGENCY')}" dataField="agencyId" editorType="dxSelectBox"  [colSpan]="4" [editorOptions]="{
        dataSource: proxy.agency.name, valueExpr: 'a_id', displayExpr: 'a_name', searchEnabled: true }">
    <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-item>
    <dxi-item [label]="{text: L('TRANSPORT')}" dataField="transportId" editorType="dxSelectBox" [colSpan]="3"
        [editorOptions]="{ dataSource: edit.finishedTransport, valueExpr: 'id', displayExpr: customTrDisplayExpr, showClearButton: true, searchEnabled: true}">
    </dxi-item>
    <dxi-item [label]="{text: L('TRANSPORT_INCLUDED')}" dataField="costIncluded" editorType="dxCheckBox" [colSpan]="1"
        [editorOptions]="{ }">
    </dxi-item>
    <dxi-item [colSpan]="4" [label]="{text: L('OBSERVATIONS')}" dataField="observation" editorType="dxTextArea"
        [editorOptions]="{ }">
    </dxi-item>
</dx-form>