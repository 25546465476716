import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { DxPopupModule } from 'devextreme-angular/ui/popup';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { DxScrollViewModule } from 'devextreme-angular/ui/scroll-view';
import { SingleCardComponent } from './single-card/single-card.component';

@NgModule({
	declarations: [
		SingleCardComponent
	],
	imports: [
		BrowserModule,
		DxPopupModule,
		DxButtonModule,
		DxDataGridModule,
		DxScrollViewModule
	],
	exports: [
		SingleCardComponent
	]
})
export class FrameworkModule { }
