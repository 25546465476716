import { Injectable } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import { iBisEditService, iBisLanguageService } from '@dohu/ibis-common';

@Injectable({
	providedIn: 'root'
})
export class ProfileEditService extends iBisEditService {

	public data: CustomStore;
	public dataActive: CustomStore;
	constructor(private entity: iBisEntityService, lg: iBisLanguageService){
		super(lg);
		this.title = 'Profile';
		this.validation = 'profileValidation';

		this.data = this.getData();
		this.dataActive = this.getData(true);
	}
	createDefault() {
		return {};
	}
	getById(id: string, serverUrl?: string): Promise<any> {
		throw new Error('Method not implemented.');
	}
	onRemove(id: string): Promise<void> {
		throw new Error('Method not implemented.');
	}
	onSaveEv(serverUrl?: string): Promise<any> {
		throw new Error('Method not implemented.');
	}
	reset(): void {
		this.model = this.createDefault();
	}

	private getData(isActiv = false) {
		const q = new EntityQuery('Profile').addOrderBy(['name']);
		if (isActiv) {
			q.eq('isActiv', true);
		}
		return this.entity.store(q, false, 'id');
	}
}
