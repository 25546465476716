import { Injectable } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { iBisEntityService } from '@dohu/ibis-entity';
import CustomStore from 'devextreme/data/custom_store';
import { iBisEditService, iBisLanguageService } from '@dohu/ibis-common';
import { GeographicAreaEditService } from '../enum/geographic-area/geographic-area-edit.service';

@Injectable()
export abstract class PartyBaseService extends iBisEditService {

	public localities: DataSource;
	public counties: DataSource;

	constructor(protected entity: iBisEntityService, protected geo: GeographicAreaEditService, lg: iBisLanguageService) {
		super(lg);
		this.localities = this.getLocality();
		this.counties = this.getCounty();
	}

	public get county(): CustomStore {
		return this.geo.county;
	}

	public set countyId(id: any) {
		this.localities = this.getLocality(id);
		this.onDataChanged.next('locality');
	}

	public set zoneId(id: any){
		this.counties = this.getCounty(id);
		this.onDataChanged.next('county');
	}

	public get zone(): CustomStore {
		return this.geo.zone;
	}

	protected getLocality(countyId: any = null): DataSource {
		return new DataSource({ store: this.geo.getGeographic(1, countyId), paginate: true, pageSize: 50 });
	}

	protected getCounty(zoneId: any = null): DataSource {
		return new DataSource({store: this.geo.getGeographic(2, zoneId), paginate: true, pageSize: 15});
	}
}
