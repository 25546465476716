<dx-data-grid class="gridContainer box-shadow" [dataSource]="edit.data" [columnAutoWidth]="true"
    [height]="ui.fullHeight" [remoteOperations]="true" (onToolbarPreparing)="toolbarPreparing($event, false)">

    <dxo-scrolling mode="infinite" [showScrollbar]="ui.showScrollbar" [useNative]="!ui.isWindows">
    </dxo-scrolling>
    <dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" [storageKey]="key">
    </dxo-state-storing>
    <dxo-editing mode="row" [allowAdding]="true" [allowUpdating]="true" [allowDeleting]="true" [useIcons]="true">
    </dxo-editing>

    <dxi-column [caption]="L('ENT_WORKC')" dataField="partyId" dataType="string">
        <dxo-lookup [dataSource]="proxy.party.ownWorkCenter.store()" valueExpr="c_id" displayExpr="fullName">
        </dxo-lookup>
    </dxi-column>
    <dxi-column [caption]="L('NAME')" dataField="name" dataType="string"></dxi-column>
        <dxi-validation-rule type="required"></dxi-validation-rule>
		<dxi-validation-rule type="stringLength"  [max]="80" [message]="L('G_NAME_LENGTH')">
		</dxi-validation-rule>
    <dxi-column [caption]="L('START_DATE')" dataField="startDate" dataType="date"></dxi-column>
        <dxi-validation-rule type="required"></dxi-validation-rule>
    <dxi-column [caption]="L('END_DATE')" dataField="endDate" dataType="date">
    </dxi-column>

</dx-data-grid>