import { Params } from '@angular/router';
import { Component, ViewChild } from '@angular/core';
import { ProxyService } from '../../service/proxy.service';
import { IncinerateEditService } from './incinerate-edit.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { BreadcrumbViewComponent } from '../../framework/breadcrumb-view.component';

@Component({
	selector: 'app-incinerate',
	templateUrl: './incinerate.component.html'
})
export class IncinerateComponent extends BreadcrumbViewComponent {
	@ViewChild('gridIncinerate') grid: DxDataGridComponent;
	public edit: IncinerateEditService;

	constructor(public proxy: ProxyService) {
		super(proxy.incinerate, proxy.lg, proxy.iui, proxy.activatedRoute);
		this.id = 'e_id';
	}

	protected queryParams(pr: Params): void {
		super.queryParams(pr);
		this.edit.type = pr['type'];
		this.edit.actualStock = pr['actualStock'] === 'true';
		this.key = pr['key'];
	}
}
