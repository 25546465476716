<dx-data-grid #gridProject class="gridContainer" [height]="ui.fullHeight" [dataSource]="edit.data"
	[remoteOperations]="true" (onToolbarPreparing)="toolbarPreparing($event, proxy.allowEdit)">

	<dxo-scrolling [mode]="ui.scrollingMode" [showScrollbar]="ui.showScrollbar" [useNative]="!ui.isWindows">
	</dxo-scrolling>
	<dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" [storageKey]="key">
	</dxo-state-storing>

	<dxi-column type="buttons" fixedPosition="left" [minWidth]="proxy.actionsWidth" [width]="proxy.actionsWidth"
		[fixed]="true" [allowResizing]="false" [showInColumnChooser]="false" [visible]="proxy.showActions">
		<dxi-button [hint]="L('EDIT')" icon="edit" [onClick]="onEditClick" [visible]="proxy.allowEdit"></dxi-button>
		<dxi-button [hint]="L('DELETE')" icon="trash" [onClick]="onDeleteClick" [visible]="proxy.allowDelete">
		</dxi-button>
	</dxi-column>

	<dxi-column name='project' headerCellTemplate='projectHeader'>
		<dxi-column [caption]="L('NO')" dataField="p_no" dataType="string" [allowHeaderFiltering]='false'
			[minWidth]='170'>
		</dxi-column>
		<dxi-column [caption]="L('NAME')" dataField="p_name" dataType="string" [allowHeaderFiltering]='false'
			[minWidth]='170'></dxi-column>
		<dxi-column [caption]="L('OBJECTIVE')" dataField="p_objective" dataType="string" [allowHeaderFiltering]='false'
			[minWidth]='170'></dxi-column>
	</dxi-column>
	<dxi-column name='valability' headerCellTemplate='vHeader'>
		<dxi-column [caption]="L('INVOICE_START_DATE')" dataField="p_startDate" dataType="date" width="170"
			calculateSortValue="p_startDate" [calculateCellValue]="iui.formatDate"></dxi-column>
		<dxi-column [caption]="L('INVOICE_END_DATE')" dataField="p_endDate" dataType="date" width="170"
			calculateSortValue="p_endDate" [calculateCellValue]="iui.formatDate"></dxi-column>
		<dxi-column [caption]="L('VALID')" dataField="p_isValid" dataType="boolean" [minWidth]='110' [width]='110'>
		</dxi-column>
	</dxi-column>
	<dxi-column [caption]="L('CONTRACTOR')" dataField="from" dataType="string" [minWidth]='170'></dxi-column>
	<dxi-column [caption]="L('CONTRACTANT')" dataField="to" dataType="string" [minWidth]='170'></dxi-column>

	<div *dxTemplate="let data of 'breadcrumb'">
		<ibis-breadcrumb></ibis-breadcrumb>
	</div>
	<div *dxTemplate="let c of 'projectHeader'" class="dx-datagrid-text-content" role="presentation">
		<i class="fas fa-project-diagram"></i> Proiect
	</div>
	<div *dxTemplate="let c of 'vHeader'" class="dx-datagrid-text-content" role="presentation">
		<i class="fas fa-calendar-week"></i> Valabilitate
	</div>

</dx-data-grid>

<ibis-edit-popup [edit]="edit" [showAdd]="true">
	<app-project-edit></app-project-edit>
</ibis-edit-popup>