<dx-data-grid #gridWastefile class="gridContainer" [remoteOperations]="true" [dataSource]="edit.party"
	(onToolbarPreparing)="toolbarPreparing($event, false)">
	<dxo-export [enabled]="ui.exportEnabled" fileName="wastefile"></dxo-export>
	<dxo-scrolling [mode]="ui.scrollingMode" [showScrollbar]="ui.showScrollbar" [useNative]="!ui.isWindows">
	</dxo-scrolling>

	<dxo-master-detail [enabled]="true" template="details"></dxo-master-detail>

	<dxi-column [caption]="L('COMPANY')" dataField="p_name" dataType="string" [allowHeaderFiltering]="false">
	</dxi-column>
	<dxi-column [caption]="L('WASTE_CODE')" dataField="wt_code" dataType="string"> </dxi-column>
	<dxi-column [caption]="L('WASTE_DESCRIPTION')" dataField="wt_name" dataType="string" [allowHeaderFiltering]="false">
	</dxi-column>

	<div *dxTemplate="let data of 'breadcrumb'">
		<ibis-breadcrumb></ibis-breadcrumb>
	</div>
	<div *dxTemplate="let data of 'details'">
		<ibis-file [entityId]="data.data.wtp_id" [allowDelete]="proxy.allowEdit" [allowUpload]="proxy.allowEdit"></ibis-file>
	</div>
</dx-data-grid>