import { Component, ViewChild } from '@angular/core';
import { iBisViewComponent } from '@dohu/ibis-common';
import { ProxyService } from '../../../service/proxy.service';
import { InvoiceItemEditService } from './invoice-item-edit.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';

@Component({
	templateUrl: './invoice-item.component.html'
})
export class InvoiceItemComponent extends iBisViewComponent {
	@ViewChild('gridInvoiceItems') grid: DxDataGridComponent;
	public edit: InvoiceItemEditService;

	public invoiceItemId: any;
	public onGenerateClick: any;
	constructor(public proxy: ProxyService) {
		super(proxy.invoice, proxy.lg, proxy.iui, proxy.activatedRoute);
		this.id = 'ii_id';
		this.key = 'invoiceItem';
		this.onGenerateClick = this.onGenerateClickEv.bind(this);
	}

	onRowExpanding(e: any) {
		e.component.collapseAll(-1);
		this.invoiceItemId = e.key;
		this.edit.dsItemWaste = this.edit.getItemWaste(this.invoiceItemId);
	}

	onRowInserting(event: any) {
		event.data.invoiceItemId = this.invoiceItemId;
	}

	onGenerateClickEv(e: any) {
		this.proxy.entity.execute('InvoiceItemGeneration', { id: e.row.data.ii_id }).then(res => {
			if (res === 'OK') {
				this.refresh();
			}
		}, error => {
			this.lg.showError(error);
		});
	}
}
