import { Component } from '@angular/core';
import { iBisAuthService } from '@dohu/ibis-auth';
import { DataService } from '../../service/data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { iBisNavigationService } from '@dohu/ibis-navigation';
import { iBisBaseComponent, iBisUIService } from '@dohu/ibis-common';

@Component({
	selector: 'app-login',
	templateUrl: 'login.component.html',
	styleUrls: ['./login.component.css']
})
export class LoginComponent extends iBisBaseComponent {
	year: number;
	userLogin: any;
	isLoadingVisible = true;

	constructor(public auth: iBisAuthService, public ds: DataService, public menu: iBisNavigationService, iui: iBisUIService,
		public router: Router, activeRoute: ActivatedRoute) {
		super(null, ds.lg, iui, activeRoute);
		this.userLogin = {};
		this.auth.onAuthReset.subscribe(() => {
			this.isLoadingVisible = false;
		});
		this.isLoadingVisible = this.auth.user?.token !== undefined;

		// if (this.router.url === '/login' || this.router.url === '/') {
		// 	// this.ds.isLoginModalVisible = false;
		// 	if (this.auth.isAuth) {
		// 		this.menu.redirectToTheFirstRoute();
		// 	}
		// }
		this.year = new Date().getFullYear();
	}

	goToRegister(fp: boolean) {
		this.router.navigate(['/register'], { queryParams: { forgotPassword: fp } });
		this.ds.isLoginModalVisible = false;
		return false;
	}
}
