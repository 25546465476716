import notify from 'devextreme/ui/notify';
import { Component, ViewChild } from '@angular/core';
import { ArchiveEditService } from './archive-edit.service';
import { ProxyService } from '../../../service/proxy.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { iBisFileService } from '@dohu/ibis-common';
import { BreadcrumbViewComponent } from '../../../framework/breadcrumb-view.component';

@Component({
	templateUrl: 'archive.component.html'
})
export class ArchiveComponent extends BreadcrumbViewComponent {
	@ViewChild('gridArchive') grid: DxDataGridComponent;
	public edit: ArchiveEditService;
	constructor(public proxy: ProxyService, private file: iBisFileService) {
		super(proxy.archive, proxy.lg, proxy.iui, proxy.activatedRoute);
		this.id = 'a_id';
		this.key = 'archive';
	}

	onDownloadFileClick = (e: any) => {
		this.file.download(e.row.data.a_id, true).then(
			() => {
				notify(this.L('FILE_DOWNLOADED', null), 'success', 2000);
			},
			() => {
				notify(this.L('DOWNLOAD_ERROR', null), 'error', 3000);
			});
	};

	getReportPeriod = (rowData: any) => {
		let period = '';
		switch (rowData.a_interval) {
			case 1:
				period += this.edit.months.find((x: any) => x.id === rowData.a_month).value;
				period += ' ' + rowData.a_year;
				break;
			case 2:
				period += this.edit.semesters.find((x: any) => x.id === rowData.a_semester).value;
				period += ' - ' + rowData.a_year;
				break;
			case 3:
				period += rowData.a_year;
				break;
		}
		return period;
	};

	onUploadClickEv = (ev: any) => {
		this.createUploadEvent(ev.row.data, this.grid);
	};

	createUploadEvent(entity: any, gridComponent: any) {
		this.file.upload(entity.a_id).then(
			() => {
				gridComponent.instance.refresh();
				notify(this.L('FILE_UPLOADED', null), 'success', 2000);
			},
			() => {
				notify(this.L('UPLOAD_ERROR', null), 'error', 3000);
			}
		);
	}
}
