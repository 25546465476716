import { Guid } from 'guid-typescript';
import { Injectable } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { WasteTypeEditService } from '../waste-type/waste-type-edit.service';
import { EntityQuery, iBisEntityConfig, iBisEntityService } from '@dohu/ibis-entity';
import { iBisEditService, iBisFileService, iBisLanguageService } from '@dohu/ibis-common';

@Injectable({
	providedIn: 'root'
})
export class CollectEditService extends iBisEditService {
	public data: CustomStore;

	constructor(public entity: iBisEntityService, private wtp: WasteTypeEditService, lg: iBisLanguageService,
		protected config: iBisEntityConfig, protected file: iBisFileService) {
		super(lg);
		this.title = 'COLLECT';
		this.validation = 'collectingValidate';

		this.data = this.getData();
	}

	public get requiredFAT(): boolean {
		return !this.model.id && this.model.partyWorkCenterId && this.model.wasteTypeId
			&& this.model.transporterId && this.model.wasteType?.hazard === 1;
	}

	createDefault(): any {
		return { date: new Date() };
	}

	getById(id: any): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			this.entity.getById('Collecting', id).then((obj) => {
				if (obj) {
					// TODO @Luca: Check if we need a new service for Silcotub Client
					// we check if exists carId because Silcotub doesn't register transporter
					if (obj.carId) {
						this.entity.getFieldsById('Car', obj.carId, ['transporterId']).then(car => {
							if (car) {
								obj.transporterId = car.transporterId;
							}
							resolve(obj);
						}, error => this.lg.showError(error));
					} else {
						resolve(obj);
					}
				} else {
					reject();
				}
			}, error => this.lg.showError(error));
		});
	}

	onRemove(id: string): Promise<void> {
		return this.entity.remove('Collecting', id);
	}

	onSaveEv(): Promise<any> {
		const q = new EntityQuery('Collecting').eq('fet', this.model.fet).neq('fet', null).neq('id', this.model.id);
		return new Promise<any>((resolve, reject) => {
			this.entity.count(q).then(count => {
				if (count > 0) {
					reject(this.lg.format('ALREADY_EXIST',
						[this.wtp.selected.hazard === 0 ? this.lg.format('FID') : this.lg.format('FET')]));
				} else {
					this.model.uom = this.wtp.selected.uom;
					this.entity.save('Collecting', this.model.id, this.model).then((response) => {
						resolve(response);
					}, error => reject(error));
				}
			}, error => reject(error));
		});
	}

	reset(): void {
		this.model = this.createDefault();
	}

	public uploadCollect() {
		return new Promise((resolve, reject) => {
			const fileId = Guid.create().toString();
			this.file.upload(fileId, 'Import_collect').then(() => {
				resolve(this.file.downloadByUrl(this.config.server + 'UploadCollect/' + fileId));
			}, (err) => {
				reject();
			});
		});
	}

	private getData(): CustomStore {
		const q = new EntityQuery('CollectingView').addOrderByDesc(['c.date']);
		return this.entity.store(q, false, 'c_id');
	}
}
