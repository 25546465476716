import { Component } from '@angular/core';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { AlertEditService } from './alert-edit.service';
import { ProxyService } from '../../../service/proxy.service';

@Component({
	templateUrl: 'alert.component.html'
})
export class AlertComponent extends iBisBaseComponent {
	public edit: AlertEditService;

	constructor(public proxy: ProxyService) {
		super(proxy.alert, proxy.lg, proxy.iui, proxy.activatedRoute);
	}
}
