import notify from 'devextreme/ui/notify';
import { Injectable } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import { iBisEditService, iBisLanguageService } from '@dohu/ibis-common';
import { iBisAuthService } from '@dohu/ibis-auth';

@Injectable({
	providedIn: 'root'
})
export class KpiAuditorEditService extends iBisEditService {
	public data: PivotGridDataSource;
	constructor(private entity: iBisEntityService, lg: iBisLanguageService, auth: iBisAuthService) {
		super(lg);

		if (auth.isAuth) {
			this.getData();
		}
		auth.onAuthFinished.subscribe(() => {
			this.getData();
		});
	}

	createDefault() {
		return {};
	}
	getById(id: string, serverUrl?: string): Promise<any> {
		throw new Error('Method not implemented.');
	}
	onRemove(id: string): Promise<void> {
		throw new Error('Method not implemented.');
	}
	onSaveEv(serverUrl?: string): Promise<any> {
		throw new Error('Method not implemented.');
	}
	reset(): void {
		this.model = this.createDefault();
	}

	private getData() {
		const q = new EntityQuery('KPISafetyAuditorView').addOrderBy(['entityName']);
		this.getAuditPivot(this.entity.store(q, false, 'id')).then((result: PivotGridDataSource) => {
			this.data = result;
		}, error => {
			notify('Error while getting Audit data', 'error', 3000);
		});
	}

	private getAuditPivot(ds: CustomStore) {
		return new Promise((resolve, reject) => {
			ds.load().then((data: any) => {
				const reprep = [];
				if (data) {
					for (const k of data) {
						reprep.push({
							year: k.year,
							noEmpty: k.noEmpty,
							noReport: k.noReport,
							observed: k.observed,
							posReact: k.posReact,
							entityName: k.entityName,
							departName: k.departName,
							auditorName: k.auditorName,
							interviewed: k.interviewed,
						});
					}
				}
				resolve(new PivotGridDataSource({
					fields: [{
						caption: this.L('ENTITY'),
						dataField: 'entityName',
						expanded: true,
						area: 'row'
					}, {
						caption: this.L('DEPARTMENT'),
						dataField: 'departName',
						expanded: true,
						area: 'row'
					}, {
						caption: this.L('AUDITOR'),
						dataField: 'auditorName',
						expanded: true,
						area: 'row'
					}, {
						caption: this.L('YEAR'),
						dataField: 'year',
						dataType: 'number',
						expanded: true,
						area: 'column'
					}, {
						caption: this.L('REPORT_COUNT'),
						dataField: 'noReport',
						dataType: 'number',
						expanded: true,
						area: 'data',
						summaryType: 'sum'
					}, {
						caption: this.L('OBSERVED'),
						dataField: 'observed',
						dataType: 'number',
						expanded: true,
						area: 'data',
						summaryType: 'sum'
					}, {
						caption: this.L('INTERVIEWED'),
						dataField: 'interviewed',
						dataType: 'number',
						expanded: true,
						area: 'data',
						summaryType: 'sum'
					}, {
						caption: this.L('POSITIVE_REACT'),
						dataField: 'posReact',
						dataType: 'number',
						expanded: true,
						area: 'data',
						summaryType: 'sum'
					}],
					store: reprep
				}));
			});
		});
	}
}
