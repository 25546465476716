import notify from 'devextreme/ui/notify';
import { Component, ViewChild } from '@angular/core';
import { DataService } from '../../../service/data.service';
import { ProxyService } from '../../../service/proxy.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { TransportApprovalEditService } from './transport-approval-edit.service';
import { BreadcrumbViewComponent } from '../../../framework/breadcrumb-view.component';

@Component({
	templateUrl: './transport-approval.component.html'
})
export class TransportApprovalComponent extends BreadcrumbViewComponent {
	@ViewChild('gridTransportApproval') protected grid: DxDataGridComponent;
	public edit: TransportApprovalEditService;

	constructor(public proxy: ProxyService, public ds: DataService) {
		super(proxy.transportApproval, proxy.lg, proxy.iui, proxy.activatedRoute);
		this.id = 'ta_id';
		this.key = 'transportApproval';
	}

	onCellPrepared(cellInfo: any) {
		const dataField = cellInfo.column.dataField;
		if (cellInfo.rowType === 'data' && (dataField === 'noLeft' || dataField === 'quantityLeft' || dataField === 'ta_expireDate')) {
			if(dataField === 'ta_expireDate'){
				const expireDate = new Date(cellInfo.data['ta_expireDate']);
				const today =  new Date();
				if(today > expireDate){
					cellInfo.cellElement.className = cellInfo.cellElement.className + ' error';
				} else if(Math.ceil(Math.abs(expireDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24)) <= 30){
					cellInfo.cellElement.className = cellInfo.cellElement.className + ' warning';
				}
			} else {
				const compField = dataField === 'noLeft' ? 'ta_approvedNo' : 'ta_approvedQuantity';
				if (cellInfo.data[compField] <= 0) {
					cellInfo.cellElement.className = cellInfo.cellElement.className + ' error';
				} else if (cellInfo.data[compField] === cellInfo.data[dataField]) {
					cellInfo.cellElement.className = cellInfo.cellElement.className + ' info';
				} else {
					cellInfo.cellElement.className = cellInfo.cellElement.className + ' warning';
				}
			}
			return;
		}
	}

	protected onDelete(e: any): void {
		if (e.row.data && (e.row.data.noLeft !== e.row.data.ta_approvedNo || e.row.data.quantityLeft !== e.row.data.ta_approvedQuantity)) {
			notify(this.L('ERROR_DELETE_FAT_USED'), 'error', 3000);
			return;
		}
		super.onDelete(e);
	}
}
