import { NgModule } from '@angular/core';
import { iBisCommonModule } from '@dohu/ibis-common';
import { ContractComponent } from './contract.component';
import { BrowserModule } from '@angular/platform-browser';
import { DxFormModule } from 'devextreme-angular/ui/form';
import { DxPopupModule } from 'devextreme-angular/ui/popup';
import { iBisNavigationModule } from '@dohu/ibis-navigation';
import { ContractEditService } from './contract-edit.service';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { ProjectComponent } from './project/project.component';
import { ContractEditComponent } from './contract-edit.component';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { DxTabPanelModule } from 'devextreme-angular/ui/tab-panel';
import { FrameworkModule } from '../../framework/framework.module';
import { ProjectEditService } from './project/project-edit.service';
import { ProjectEditComponent } from './project/project-edit.component';
import { ContractAnnexComponent } from './contract-annex/contract-annex.component';
import { ContractAnnexEditComponent } from './contract-annex/contract-annex-edit.component';

@NgModule({
	declarations: [
		ProjectComponent,
		ContractComponent,
		ProjectEditComponent,
		ContractEditComponent,
		ContractAnnexComponent,
		ContractAnnexEditComponent
	],
	imports: [
		DxFormModule,
		BrowserModule,
		DxPopupModule,
		DxButtonModule,
		FrameworkModule,
		DxTabPanelModule,
		DxDataGridModule,
		iBisCommonModule,
		iBisNavigationModule
	],
	exports: [
		ProjectComponent,
		ContractComponent,
		ProjectEditComponent,
		ContractEditComponent
	],
	providers: [
		ProjectEditService,
		ContractEditService
	]
})
export class ContractModule { }
