import { Injectable } from '@angular/core';
import { iBisLanguageService } from '@dohu/ibis-common';
import { DataService } from '../../../service/data.service';
import { TransportEditService } from '../transport-edit.service';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import { WasteTypeEditService } from '../../waste-type/waste-type-edit.service';
import { iBisAuthService } from '@dohu/ibis-auth';

@Injectable({
	providedIn: 'root'
})
export class TransportFullEditService extends TransportEditService {

	constructor(auth: iBisAuthService,public entity: iBisEntityService, public ds: DataService, public wtp: WasteTypeEditService, lg: iBisLanguageService) {
		super(auth, entity, ds, wtp, null, lg, null, null, null);
	}

	getById(id: string): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			this.entity.getById('Transport', id).then((obj) => {
				if (obj === null) {
					reject();
				} else {
					this.dsTransportSource = [];
					obj.selectedActivity = this.setTransportDataSource(obj.activities);
					this.wtp.workCenterId = obj.workCenterId;
					this.wtp.selectedId = obj.wasteTypeId;
					resolve(obj);
				}
			}, error => this.ds.lg.showError(error));
		});
	}

	onSaveEv(): Promise<any> {
		const q = new EntityQuery('Transport').eq('fet', this.model.fet).neq('id', this.model.id);
		return new Promise<any>((resolve, reject) => {
			this.entity.count(q).then(count => {
				if (count > 0) {
					reject(this.lg.format('ALREADY_EXIST',
						[this.wtp.selected.hazard === 0 ? this.lg.format('FID') : this.lg.format('FET')]));
				} else {
					this.model.uom = this.wtp.selected.uom;
					this.model.sources = this.dsTransportSource;
					this.model.activities = this.getTransportVal(this.model.selectedActivity);
					this.entity.save('Transport', this.model.id, this.model).then((response) => {
						resolve(response);
					}, error => reject(error));
				}
			}, error => reject(error));
		});
	}
}
