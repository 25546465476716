import { NgModule } from '@angular/core';
import { iBisCommonModule } from '@dohu/ibis-common';
import { CollectComponent } from './collect.component';
import { BrowserModule } from '@angular/platform-browser';
import { DxFormModule } from 'devextreme-angular/ui/form';
import { DxPopupModule } from 'devextreme-angular/ui/popup';
import { CollectEditService } from './collect-edit.service';
import { iBisNavigationModule } from '@dohu/ibis-navigation';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { CollectEditComponent } from './collect-edit.component';
import { DxTextBoxModule } from 'devextreme-angular/ui/text-box';
import { DxDateBoxModule } from 'devextreme-angular/ui/date-box';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { FrameworkModule } from '../../framework/framework.module';
import { DxTextAreaModule } from 'devextreme-angular/ui/text-area';
import { DxValidatorModule } from 'devextreme-angular/ui/validator';
import { DxSelectBoxModule } from 'devextreme-angular/ui/select-box';
import { DxNumberBoxModule } from 'devextreme-angular/ui/number-box';

@NgModule({
	declarations: [
		CollectComponent,
		CollectEditComponent
	],
	imports: [
		DxFormModule,
		DxPopupModule,
		BrowserModule,
		DxButtonModule,
		DxTextBoxModule,
		FrameworkModule,
		DxDateBoxModule,
		iBisCommonModule,
		DxDataGridModule,
		DxTextAreaModule,
		DxValidatorModule,
		DxSelectBoxModule,
		DxNumberBoxModule,
		iBisNavigationModule,
	],
	exports: [
		CollectComponent,
		CollectEditComponent
	],
	providers: [
		CollectEditService
	]
})
export class CollectModule { }
