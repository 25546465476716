import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { iBisCommonModule } from '@dohu/ibis-common';

import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { DxFormModule } from 'devextreme-angular/ui/form';
import { DxPopupModule } from 'devextreme-angular/ui/popup';
import { DxSelectBoxModule } from 'devextreme-angular/ui/select-box';
import { DxTabPanelModule } from 'devextreme-angular/ui/tab-panel';
import { DxTextBoxModule } from 'devextreme-angular/ui/text-box';
import { DxValidationGroupModule } from 'devextreme-angular/ui/validation-group';
import { DxValidatorModule } from 'devextreme-angular/ui/validator';

import { FrameworkModule } from '../../framework/framework.module';
import { AuditorEditComponent } from './auditor/auditor-edit.component';
import { AuditorEditService } from './auditor/auditor-edit.service';
import { AuditorComponent } from './auditor/auditor.component';
import { PermissionEditService } from './auditor/permission/permission-edit.service';
import { PermissionComponent } from './auditor/permission/permission.component';
import { DepartmentEditService } from './department/department-edit.service';
import { DepartmentComponent } from './department/department.component';
import { LocationEditService } from './location/location-edit.service';
import { LocationComponent } from './location/location.component';
import { ProfileCategoryEditService } from './profile/category/profile-category-edit.service';
import { ProfileCategoryComponent } from './profile/category/profile-category.component';
import { ProfileEditService } from './profile/profile-edit.service';
import { ProfileComponent } from './profile/profile.component';

@NgModule({
	declarations: [
		ProfileComponent,
		AuditorComponent,
		LocationComponent,
		PermissionComponent,
		DepartmentComponent,
		AuditorEditComponent,
		ProfileCategoryComponent
	],
	imports: [
		DxFormModule,
		DxPopupModule,
		BrowserModule,
		DxButtonModule,
		DxTextBoxModule,
		FrameworkModule,
		iBisCommonModule,
		DxDataGridModule,
		DxTabPanelModule,
		DxSelectBoxModule,
		DxValidatorModule,
		DxValidationGroupModule
	],
	exports: [
		ProfileComponent,
		AuditorComponent,
		LocationComponent,
		PermissionComponent,
		DepartmentComponent,
		AuditorEditComponent,
		ProfileCategoryComponent
	],
	providers: [
		AuditorEditService,
		ProfileEditService,
		LocationEditService,
		PermissionEditService,
		DepartmentEditService,
		ProfileCategoryEditService
	]
})
export class SafetyAdminModule { }
