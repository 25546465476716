import { Component, ViewChild } from '@angular/core';
import { UnitEditService } from './unit-edit.service';
import { ProxyService } from '../../../service/proxy.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { BreadcrumbViewComponent } from '../../../framework/breadcrumb-view.component';

@Component({
	templateUrl: 'unit.component.html'
})
export class UnitComponent extends BreadcrumbViewComponent {
	@ViewChild('gridUnit') grid: DxDataGridComponent;
	public edit: UnitEditService;

	constructor(public proxy: ProxyService) {
		super(proxy.unit, proxy.lg, proxy.iui, proxy.activatedRoute);
		this.id = 'p_id';
		this.key = 'unit';
	}

	onRowExpanding(event: any) {
		event.component.collapseAll(-1);
		this.proxy.wasteType.workCenterId = event.key;
	}

	onCellPrepared(e: any) {
		if (e.rowType === 'data' && e.column.command === 'expand') {
			if (e.data.p_typeId !== 2) {
				e.cellElement.children[0].classList.remove('dx-datagrid-group-closed');
			}
		}
	}

	onRowPrepared(rowInfo: any) {
		if (rowInfo.rowType === 'data' && rowInfo.data.p_typeId === 1) {
			rowInfo.rowElement.className = rowInfo.rowElement.className + ' bold';
		}
	}

	onCellClick(e: any) {
		if (e.rowType === 'data') {
			if (e.cellElement.classList[0] === 'dx-command-expand' && e.data.p_typeId !== 2) {
				e.component.collapseAll(-1);
			}
		}
	}
}
