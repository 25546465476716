import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { iBisCommonModule } from '@dohu/ibis-common';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { DxFormModule } from 'devextreme-angular/ui/form';
import { DxPopupModule } from 'devextreme-angular/ui/popup';
import { FrameworkModule } from '../../app/framework/framework.module';
import { EcoFireGenerateComponent} from './generate/generate.component.ecofire';
import { EcoFireGenerateEditComponent} from './generate/generate-edit.component.ecofire';
import { EcoFireCollectComponent} from './collect/collect.component.ecofire';
import { EcoFireCollectEditComponent} from './collect/collect-edit.component.ecofire';

@NgModule({
	declarations: [
		EcoFireGenerateComponent,
		EcoFireGenerateEditComponent,
		EcoFireCollectComponent,
		EcoFireCollectEditComponent
	],
	imports: [
		DxFormModule,
		BrowserModule,
		DxPopupModule,
		FrameworkModule,
		DxDataGridModule,
		iBisCommonModule
	],
	exports: [
		EcoFireGenerateComponent,
		EcoFireGenerateEditComponent,
		EcoFireCollectComponent,
		EcoFireCollectEditComponent
	]
})
export class EcoFireModule { }
