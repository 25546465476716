<div class="ibis-content-block" style='background-color: #f7f7f7;'>
	<ibis-breadcrumb></ibis-breadcrumb>
  </div>
<div class="globalContainer">
	<div class="report-form-container">
		<div id="report-form">
			<dx-form #reportExt [colCount]="1" [(formData)]="edit.model" [validationGroup]="edit.validation">
				<dxi-item [label]="{ text: L('REPORT_TYPE')}" dataField="typeId" editorType="dxSelectBox"
					[editorOptions]="{dataSource: edit.reportTypes, displayExpr:'description', valueExpr:'id', showClearButton: true, onSelectionChanged: onReportItemChangedEv}">
					<dxi-validation-rule type="required"></dxi-validation-rule>
				</dxi-item>
				<dxi-item itemType="group" [colSpan]="2" [colCount]="2">
					<dxi-item [colSpan]="2" [label]="{text: L('ENT_WORKC') }" dataField="workCenterId" editorType="dxSelectBox"
					[editorOptions]="{dataSource: proxy.party.ownUnit, valueExpr: 'c_id', displayExpr: 'fullName', searchEnabled: true}">
					<dxi-validation-rule type="required"></dxi-validation-rule>
				</dxi-item>

					<!-- <dxi-item [label]="{ text: L('ENTITY')}" dataField="entityId" editorType="dxSelectBox"
						[editorOptions]="{dataSource: edit.entities, displayExpr:'fullName', valueExpr:'c_id', searchEnabled: true, onValueChanged: onEntityValueChangedEv }">
						<dxi-validation-rule type="required"></dxi-validation-rule>
					</dxi-item>
					<dxi-item [label]="{text: L('WORK_CENTER')}" dataField="workCenterId" editorType="dxSelectBox"
						[editorOptions]="{ dataSource: edit.workCenters, displayExpr:'name', valueExpr:'id', searchEnabled: true, showClearButton: true }">
					</dxi-item> -->

					<dxi-item [label]="{text: L('REPORTING_YEAR')}" dataField="year" editorType="dxNumberBox"
						[colSpan]="edit.interval == 3 ? 2 : 1" [editorOptions]="{min: '1990' }">
						<dxi-validation-rule type="required"></dxi-validation-rule>
					</dxi-item>
					<dxi-item [label]="{text: L('REPORT_MONTH')}" dataField="month" editorType="dxSelectBox"
						[visible]="edit.interval == '1'"
						[editorOptions]="{ dataSource: proxy.archive.months, displayExpr:'value', valueExpr:'id', searchEnabled: true}">
						<dxi-validation-rule type="required"></dxi-validation-rule>
					</dxi-item>
					<dxi-item [label]="{text: L('SEMESTER')}" dataField="sem" editorType="dxSelectBox"
						[visible]="edit.interval == '2'"
						[editorOptions]="{ dataSource: proxy.archive.semesters, displayExpr:'value', valueExpr:'id', searchEnabled: true }">
						<dxi-validation-rule type="required"></dxi-validation-rule>
					</dxi-item>
				</dxi-item>
			</dx-form>

			<div class="report-footer">
				<dx-button [text]="L('GENERATE_REPORT')" (onClick)="edit.onGenerateReportClickEv($event)"
					[validationGroup]="edit.validation"></dx-button>
				<dx-button style="margin-left: 10px;" [text]="L('ARCHIVE_1')" (onClick)="edit.onClickArchive()">
				</dx-button>
			</div>
		</div>
	</div>
</div>