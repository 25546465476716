<dx-tab-panel [selectedIndex]="edit.page" >
	<dxi-item [title]="L('REPORT')" [disabled]="edit.page !== 0">
		<dx-form [formData]="edit.model" [colCount]="2" [validationGroup]="edit.validation" style="padding-top:10px">
			<dxi-item [colSpan]="2" [label]="{ text: L('ENT_WORKC') }" dataField="entityId" editorType="dxSelectBox"
				[editorOptions]="{ dataSource: proxy.party.ownWorkCenter, valueExpr: 'c_id', displayExpr: 'fullName'}">
				<dxi-validation-rule type="required"></dxi-validation-rule>
			</dxi-item>

			<dxi-item [label]="{ text: L('PROFILE') }" dataField="profileId" editorType="dxSelectBox"
				[editorOptions]="{ dataSource: proxy.profile.data, valueExpr: 'id', displayExpr:'name', disabled: !edit.model.entityId}">
				<dxi-validation-rule type="required"></dxi-validation-rule>
			</dxi-item>
			<dxi-item [label]="{text: L('DATE') }" dataField="reportDate" editorType="dxDateBox"
				[editorOptions]="{ displayFormat: 'dd.MM.yyyy', width: '100%'}">
				<dxi-validation-rule type="required"></dxi-validation-rule>
			</dxi-item>
			
			<dxi-item [colSpan]="2" [label]="{ text: L('LOCATION') }" dataField="locationId" editorType="dxSelectBox"
				[editorOptions]="{ dataSource: proxy.location.data, valueExpr:'id', displayExpr:'name', disabled: edit.model.entityId == undefined }">
				<dxi-validation-rule type="required"></dxi-validation-rule>
			</dxi-item>
			<dxi-item [label]="{ text: L('LOCATION_DETAILS') }" [colSpan]="2" dataField="locationDetails"
				editorType="dxTextArea" [editorOptions]="{}"></dxi-item>
		</dx-form>
	</dxi-item>
	<dxi-item [title]="L('PARTICIPANT_LIST')" [disabled]="edit.page !== 1">
		<dx-data-grid [dataSource]="edit.participant" [height]="350" (onInitNewRow)="onNewParticipant($event)">
			<dxo-editing mode="row" [allowDeleting]="true" [allowAdding]="true" [allowUpdating]="true">
			</dxo-editing>
			<dxi-column [caption]="L('AUDITOR')" dataField="auditorId" dataType="string">
				<dxo-lookup [dataSource]="proxy.auditor.data" valueExpr="id" displayExpr="displayName">
				</dxo-lookup>
				<dxi-validation-rule type="required"></dxi-validation-rule>
			</dxi-column>
			<dxi-column [caption]="L('LEADER')" dataField="isLeader" dataType="boolean" width="120">
			</dxi-column>
		</dx-data-grid>
	</dxi-item>
</dx-tab-panel>