/* eslint-disable no-bitwise */
import notify from 'devextreme/ui/notify';
import { Component, ViewChild } from '@angular/core';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { ProxyService } from '../../service/proxy.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { GeneralReportEditService } from './general-report-edit.service';

@Component({
	selector: 'app-general-report',
	templateUrl: './general-report.component.html'
})
export class GeneralReportComponent extends iBisBaseComponent {
	@ViewChild('gridCollect') grid: DxDataGridComponent;
	public edit: GeneralReportEditService;

	constructor(public proxy: ProxyService) {
		super(proxy.generalReport, proxy.lg, proxy.iui, proxy.activatedRoute);
	}

	getActions(type: number, actions: number) {
		if (type === 4) { // Full Transport
			const arr = this.proxy.transport.activity;
			const result = [];
			for (const i of arr) {
				if ((actions & i.id) === i.id) {
					result.push(i.value);
				}
			}
			return result.join(', ');
		} else if (type === 5) { // OUT treatment
			const result = this.proxy.treatment.type.find(x => x.id = actions);
			if (result) {
				return result.value;
			}
		}
	}

	toolbarPreparing(event: any) {
		this.iui.toolbarPreparing(event, 'generalReport');
		event.toolbarOptions.items.unshift({
			widget: 'dxButton',
			options: {
				icon: 'exportxlsx', hint: this.L('EXPORT'),
				onClick: (e: any) => {
					notify(this.L('DOWNLOADING_IN_PROGRESS'), 'info', 3000);
				}
			},
			location: 'after'
		});
	}
}
