<ng-container *ngIf="auth.isAuth; else notAuthorized">

	<ibis-header (toggle)="menuOpened = !menuOpened;"></ibis-header>
	
	<dx-drawer class="layout-body" position="before" [closeOnOutsideClick]="false" openedStateMode="shrink"
		revealMode="expand" minSize="49" maxSize='250' [shading]="false" [(opened)]="menuOpened" height='calc(100vh - 56px)'>

		<div *dxTemplate="let dataMenu of 'panel'" class="dx-swatch-additional">
			<ibis-menu style='width: 250px; height: calc(100vh - 56px)' [compactMode]='!menuOpened' (toggle)="menuOpened = !menuOpened;">
			</ibis-menu>
		</div>
		<dx-scroll-view>
			<div class="ibis-content-block">
			</div>
			<div class="ibis-content">
				<router-outlet></router-outlet>
			</div>
			<div class="ibis-content-block">
        <ibis-footer [version]='version' 
          [app]='app'
          [copy]='{link: "https://www.dohu.ro/", text: "DoHu Consulting"}'>
      </ibis-footer>
			</div>
		</dx-scroll-view>
	</dx-drawer>
</ng-container>

<!-- <ng-container *ngIf="auth.isAuth; else notAuthorized">
  <ibis-toolbar>
    <router-outlet></router-outlet>
    <ibis-footer [version]='version' 
      [app]='{link: "https://www.evidentadeseuri.ro/", text: "EvidentaDeseuri.ro"}'
      [copy]='{link: "https://www.dohu.ro/", text: "DoHu Consulting"}'>
    </ibis-footer>
  </ibis-toolbar>
</ng-container> -->
<ng-template #notAuthorized>
  <app-single-card>
    <router-outlet></router-outlet>
  </app-single-card>
</ng-template>

<ibis-grid-config></ibis-grid-config>

<!-- <div id="loginModal" *ngIf="ds.isLoginModalVisible">
  <app-login></app-login>
</div> -->

<!-- Edit Generate -->
<ibis-edit-popup [edit]="proxy.generate" [showAdd]="true">
  <app-generate-edit></app-generate-edit>
</ibis-edit-popup>

<!-- Edit Unit Waste -->
<ibis-edit-popup [edit]="proxy.unitWaste" [showAdd]="true">
  <app-unit-waste-edit></app-unit-waste-edit>
</ibis-edit-popup>

<!-- Edit Party -->
<ibis-edit-popup [edit]="proxy.party" [showAdd]="true">
  <app-party-edit></app-party-edit>
</ibis-edit-popup>

<!-- Edit Transport -->
<ibis-edit-popup [edit]="proxy.transport" [showAdd]="true">
  <app-transport-edit></app-transport-edit>
</ibis-edit-popup>

<!-- Edit Transfer -->
<ibis-edit-popup [edit]="proxy.transfer" [showAdd]="true">
  <app-transfer-edit></app-transfer-edit>
</ibis-edit-popup>

<!-- Edit Contract -->
<ibis-edit-popup [edit]="proxy.contract" [showAdd]="true">
  <app-contract-edit></app-contract-edit>
</ibis-edit-popup>

<!-- Edit Permit -->
<ibis-edit-popup [edit]="proxy.permit" [showAdd]="true">
  <app-permit-edit></app-permit-edit>
</ibis-edit-popup>

<!-- Edit Transporter -->
<ibis-edit-popup [edit]="proxy.transporter" [showAdd]="true">
  <app-transporter-edit></app-transporter-edit>
</ibis-edit-popup>

<ibis-edit-popup [edit]="proxy.changePassword">
  <ibis-change-password></ibis-change-password>
</ibis-edit-popup>
<!-- Edit Invite User-->
<ibis-edit-popup [edit]="proxy.invite" [showAdd]="true">
  <ibis-invite-user></ibis-invite-user>
</ibis-edit-popup>

<!-- Edit Support-->
<ibis-edit-popup [edit]="proxy.support" [showAdd]="true">
  <ibis-support></ibis-support>
</ibis-edit-popup>

<!--Party Wizard-->
<ibis-edit-popup [edit]="proxy.partyWizard" [showAdd]="false" [hideClose]='true' [height]='500'>
	<app-party-wizard></app-party-wizard>
</ibis-edit-popup>
