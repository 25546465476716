import * as display from '../assets/display.json';
import * as gridLabel from '@dohu/ibis-common/int/ro/label.json';
import * as label from '../assets/label.json';
import * as labelDevEx from '../assets/devExtreme.json';
import * as labelExtra from '../assets/label.extra.json';
import * as nav from '../assets/navigation.json';
import * as ui from '../assets/uiCustomize.json';

import { AccountModule } from './account/account.module';
import { AnalizeModule } from './waste/analize/analize.module';
import { AppComponent } from './app.component';
import { BrowserModule } from '@angular/platform-browser';
import { CollectModule } from './waste/collect/collect.module';
import { ContractModule } from './common/contract/contract.module';
import { ControlModule } from './common/control/control.module';
import { DataService } from './service/data.service';
import { EnumModule } from './common/enum/enum.module';
import { environment } from '../environments/environment';
import { FrameworkModule } from './framework/framework.module';
import { GenerateModule } from './waste/generate/generate.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { iBisAuthModule, iBisAuthService, iBisEditUserService } from '@dohu/ibis-auth';
import {
	iBisCommonModule,
	iBisFileService,
	iBisGridProperties,
	iBisLanguageService,
	iBisUIService
} from '@dohu/ibis-common';
import { iBisEntityModule } from '@dohu/ibis-entity';
import { iBisNavigationModule } from '@dohu/ibis-navigation';
import { IncinerateModule } from './waste/incinerate/incinerate.module';
import { InvoiceModule } from './common/invoice/invoice.module';
import { NgModule } from '@angular/core';
import { PartyModule } from './common/party/party.module';
import { ProxyService } from './service/proxy.service';
import { ReportModule } from './waste/report/report.module';
import { RouterModule } from '@angular/router';
import { routes } from './app.route';
import { SafetyModule } from './safety/safety.module';
import { ScreenService } from './service/screen.service';
import { StockModule } from './waste/stock/stock.module';
import { TransferModule } from './waste/transfer/transfer.module';
import { TransportModule } from './waste/transport/transport.module';
import { TreatmentModule } from './waste/treatment/treatment.module';
import { WasteTypeModule } from './waste/waste-type/waste-type.module';

// TODO:	workaround. to refactor to somehing smarter
import { EcoFireModule } from '../clients/ecofire/ecofire.module';
import { DxDrawerModule, DxScrollViewModule, DxTreeViewModule } from 'devextreme-angular';

@NgModule({
	declarations: [
		AppComponent,
	],
	imports: [
		BrowserModule,
		HttpClientModule,
		RouterModule.forRoot(routes, { useHash: true, enableTracing: false }),

		DxDrawerModule,
		DxTreeViewModule,
		DxScrollViewModule,
		EnumModule,
		StockModule,
		PartyModule,
		ReportModule,
		AccountModule,
		ControlModule,
		InvoiceModule,
		AnalizeModule,
		CollectModule,
		ContractModule,
		GenerateModule,
		TransferModule,
		iBisAuthModule,
		FrameworkModule,
		TransportModule,
		TreatmentModule,
		WasteTypeModule,
		IncinerateModule,
		SafetyModule,
		EcoFireModule,
		iBisEntityModule.forRoot({ key: 'id', server: environment.defaultUrlServer }),
		iBisCommonModule.forRoot({
			display: display.default,
			logo: 'assets/img/logo.svg',
			lang: 'ro',
			label: [labelDevEx.default, gridLabel.default, labelExtra.default, label.default],
			grid: ui.default.grid as iBisGridProperties,
			ui: ui.default.ui
		}, {
			saasServer: environment.saasServer, fileServer: environment.saasServer, authServer: environment.authServer,
			deployId: environment.deployId, useLocalStorage: true, skipLocation: true
		}),
		iBisNavigationModule.forRoot({ navigation: nav.default, shortcut: nav.default.shortcut,
			ignorePath:['/login', '/resetPassword', '/register', '/confirmInvite'], outer: true })
	],
	providers: [
		HttpClient,
		DataService,
		ProxyService,
		ScreenService,
		iBisUIService,
		iBisAuthService,
		iBisFileService,
		iBisEditUserService,
		iBisLanguageService
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
