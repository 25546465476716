import { NgModule } from '@angular/core';
import { iBisCommonModule } from '@dohu/ibis-common';
import { InvoiceComponent } from './invoice.component';
import { BrowserModule } from '@angular/platform-browser';
import { DxFormModule } from 'devextreme-angular/ui/form';
import { InvoiceEditService } from './invoice-edit.service';
import { DxPopupModule } from 'devextreme-angular/ui/popup';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { InvoiceEditComponent } from './invoice-edit.component';
import { FrameworkModule } from '../../framework/framework.module';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { InvoiceItemComponent } from './invoice-item/invoice-item.component';
import { InvoiceItemEditService } from './invoice-item/invoice-item-edit.service';
import { InvoiceItemEditComponent } from './invoice-item/invoice-item-edit.component';

@NgModule({
	declarations: [
		InvoiceComponent,
		InvoiceEditComponent,
		InvoiceItemComponent,
		InvoiceItemEditComponent
	],
	imports: [
		DxFormModule,
		BrowserModule,
		DxPopupModule,
		DxButtonModule,
		FrameworkModule,
		DxDataGridModule,
		iBisCommonModule
	],
	exports: [
		InvoiceComponent,
		InvoiceEditComponent,
		InvoiceItemComponent,
		InvoiceItemEditComponent
	],
	providers: [
		InvoiceEditService,
		InvoiceItemEditService
	]
})
export class InvoiceModule { }
