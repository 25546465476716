<dx-data-grid #gridParty class="gridContainer" [height]="ui.fullHeight" [dataSource]="edit.data"
	[remoteOperations]="true" (onCellPrepared)="onCellPrepared($event)" (onCellClick)="onCellClick($event)"
	(onToolbarPreparing)="toolbarPreparing($event, proxy.allowEdit)">

	<dxo-scrolling [mode]="ui.scrollingMode" [showScrollbar]="ui.showScrollbar" [useNative]="!ui.isWindows">
	</dxo-scrolling>
	<dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" [storageKey]="key">
	</dxo-state-storing>
	<dxo-master-detail [enabled]="true" template="details"></dxo-master-detail>

	<dxi-column type="buttons" fixedPosition="left" [minWidth]="ui.actionWidth" [width]="ui.actionWidth" [fixed]="true"
		[allowResizing]="false" [showInColumnChooser]="false">
		<dxi-button [hint]="L('EDIT')" icon="edit" [onClick]="onEditClick" [visible]="proxy.allowEdit">
		</dxi-button>
	</dxi-column>

	<dxi-column name="generator" headerCellTemplate="gHeader">
		<dxi-column [caption]="L('NAME')" dataField="p_name" dataType="string"></dxi-column>
		<dxi-column [caption]="L('FISCAL_CODE')" dataField="p_cif" dataType="string"></dxi-column>
		<dxi-column [caption]="L('REG_COMERT')" dataField="p_registerNo" dataType="string"></dxi-column>
	</dxi-column>
	<dxi-column name="adress" headerCellTemplate="adressHeader">
		<dxi-column [caption]="L('COUNTY')" dataField="co_name" dataType="string"></dxi-column>
		<dxi-column [caption]="L('LOCALITY')" dataField="loc_name" dataType="string"></dxi-column>
		<dxi-column [caption]="L('ADDRESS')" dataField="p_address" dataType="string"></dxi-column>
	</dxi-column>
	<dxi-column [caption]="L('ACTIVE')" dataField="p_isActiv" dataType="boolean"></dxi-column>
	<dxi-column [caption]="L('IS_A_SUBCONTRACTOR')" dataField="p_isSubcontractor" dataType="boolean">
	</dxi-column>
	<dxi-column [caption]="L('CONTRACTOR')" dataField="parent_name" dataType="string">
	</dxi-column>

	<div *dxTemplate="let party of 'details'">
		<dx-tab-panel height="500">
			<dxi-item [title]="L('WORK_CENTERS')">
				<app-party-work-center [partyId]="party.data.p_id"></app-party-work-center>
			</dxi-item>
			<dxi-item [title]="L('CONTRACTS')">
				<app-party-contract [partyId]="party.data.p_id"></app-party-contract>
			</dxi-item>
			<dxi-item [title]="L('FILES')">
				<ibis-file [entityId]="party.data.p_id" [height]="400" [allowDelete]="proxy.allowEdit" [allowUpload]="proxy.allowEdit">
				</ibis-file>
			</dxi-item>
			<dxi-item [title]="L('GENERATED_CODE')" [visible]="showGenerate">
				<app-party-link [partyId]="party.data.p_id"></app-party-link>
			</dxi-item>
			<dxi-item [title]="L('TRUCKS')" [visible]="showTransporter">
				<app-car [partyId]="party.data.p_id"></app-car>
			</dxi-item>
			<dxi-item [title]="L('DRIVERS')" [visible]="showTransporter">
				<app-driver [partyId]="party.data.p_id"></app-driver>
			</dxi-item>
			<dxi-item [title]="L('PERMITS')" [visible]="showTransporter">
				<app-permit [entityId]="party.data.p_id" [height]="400" [isSubtab]="true"></app-permit>
			</dxi-item>
		</dx-tab-panel>
	</div>
	<div *dxTemplate="let data of 'breadcrumb'">
		<ibis-breadcrumb></ibis-breadcrumb>
	</div>
	<div *dxTemplate="let c of 'adressHeader'" class="dx-datagrid-text-content" role="presentation">
		<i class="fas fa-map-marked-alt"></i> Adresa
	</div>
	<div *dxTemplate="let c of 'gHeader'" class="dx-datagrid-text-content" role="presentation">
		<i class="fa fa-handshake"></i> Partener
	</div>
</dx-data-grid>

<ibis-edit-popup [edit]="edit" [showAdd]="true">
	<app-party-edit></app-party-edit>
</ibis-edit-popup>