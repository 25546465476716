import { iBisViewComponent } from '@dohu/ibis-common';
import { Component, Input, ViewChild } from '@angular/core';
import { ProxyService } from '../../../../service/proxy.service';
import { PermissionEditService } from './permission-edit.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';

@Component({
	selector: 'app-permission',
	templateUrl: './permission.component.html'
})
export class PermissionComponent extends iBisViewComponent {
	@Input() auditorId: any;
	@ViewChild('gridPermission') protected grid: DxDataGridComponent;

	public edit: PermissionEditService;
	constructor(public proxy: ProxyService) {
		super(proxy.permission, proxy.lg, proxy.iui, proxy.activatedRoute);
	}

	onInitNewRow(e: any) {
		e.data.hasPermission = true;
		e.data.auditorId = this.auditorId;
	}

	onGridInit(e: any) {
		if (e.component.option('dataSource') == null) {
			e.component.option('dataSource', this.edit.getDataByAuditor(this.auditorId));
		}
	}
}
