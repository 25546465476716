/* eslint-disable no-bitwise */
import { Component } from '@angular/core';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { DataService } from '../../../service/data.service';
import { ProxyService } from '../../../service/proxy.service';
import { MirrorStockEditService } from './mirror-stock-edit.service';

@Component({
	templateUrl: './mirror-stock.component.html'
})
export class MirrorStockComponent extends iBisBaseComponent {
	public edit: MirrorStockEditService;

	storeOptions: any;

	constructor(public proxy: ProxyService, private ds: DataService) {
		super(null, proxy.lg, proxy.iui, proxy.activatedRoute);

		this.storeOptions = this.edit.getMirrorStockInOptions();
	}

	onRowExpandingEv(event: any) {
		event.component.collapseAll(-1);
		this.edit.getDataOut(event.key);
	}

	onStockClick(e: any) {
		if (e.rowType === 'data') {
			if (e.cellElement.classList[0] === 'dx-command-expand' &&
				(e.data.lastDate === undefined && e.data.lastDate == null)) {
				e.component.collapseAll(-1);
			}
		}
	}

	onMirrorStockInCellPreparedEv(event: any) {
		if (event.rowType === 'data' && event.column && event.column.type === 'detailExpand' &&
			event.data && (event.data.lastDate === undefined && event.data.lastDate == null)) {
			event.cellElement.children[0].classList.remove('dx-datagrid-group-closed');
		}
	}

	onMirrorStockOutCellPreparedEv(event: any) {
		if (event.rowType === 'data' && event.row && event.row.data && event.row.data.typeId) {
			switch (event.row.data.typeId) {
				case 3: // incinerare
					event.cellElement.classList.add('dx-red');
					break;
				case 4: // predare la terti
					event.cellElement.classList.add('dx-yellow');
					break;
				case 5: // tratare/sortare Out
					event.cellElement.classList.add('dx-green');
					break;
			}
		}
	}

	getActions(type: number, actions: number) {
		if (type === 4) { // Full Transport
			// TODO: REMOVE DATASERVICE
			const arr = this.ds.static.transportActions;
			const result = [];
			for (const i of arr) {
				if ((actions & i.id) === i.id) {
					result.push(i.value);
				}
			}
			return result.join(', ');
		} else if (type === 5) { // OUT treatment
			const dsTreatmentType = this.proxy.treatment.type;
			const result = dsTreatmentType.find((x: { id: number }) => x.id = actions);
			if (result) {
				return result.value;
			}
		}
	}

	toolbarPreparing(event: any) {
		this.iui.toolbarPreparing(event, 'mirrorStock');
		event.toolbarOptions.items.unshift({
			widget: 'dxButton',
			options: {
				icon: 'xlsfile', hint: 'Export excel',
				onClick: (ev: any) => {
					if (ev) {
						ev.event.preventDefault();
						ev.event.stopPropagation();
					}
					// TODO: Check if storeOptions give last query
					this.edit.downloadReport(this.storeOptions);
				}
			},
			location: 'after'
		});
	}
}
