import { Injectable } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import { iBisEditService, iBisLanguageService } from '@dohu/ibis-common';

@Injectable({
	providedIn: 'root'
})
export class InvoiceEditService extends iBisEditService {

	public data: CustomStore;
	public type: {id: boolean; value: string}[];

	constructor(private entity: iBisEntityService, lg: iBisLanguageService) {
		super(lg);
		this.title = 'INVOICE';
		this.validation = 'InvoiceValidation';

		this.data = this.getData();
		this.type = this.getType();
	}

	createDefault() {
		return { isSales: true };
	}
	getById(id: string, serverUrl?: string): Promise<any> {
		return new Promise((resolve, reject) => {
			this.entity.getById('Invoice', id).then(result => {
				if (!result) {
					reject();
				}
				resolve(result);
			}, error => {
				this.lg.showError(error);
			});
		});
	}
	onRemove(id: string): Promise<void> {
		return this.entity.remove('Invoice',id);
	}
	onSaveEv(serverUrl?: string): Promise<any> {
		return this.entity.save('Invoice', this.model.id, this.model);
	}
	reset(): void {
		this.model = this.createDefault();
	}

	private getData(): CustomStore {
		const q = new EntityQuery('InvoiceView').addOrderByDesc(['i.date']);
		return this.entity.store(q, false, 'i_id');
	}

	private getType(): {id: boolean; value: string}[] {
		const list = [
			{ id: false, value: this.L('PURCH_INVOICE')},
			{ id: true, value: this.L('SALE_INVOICE')}
		];
		return list;
	}
}
