import { Component } from '@angular/core';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { ProxyService } from '../../../service/proxy.service';
import { SafetyPlanningEditService } from './safety-planning-edit.service';

@Component({
	selector: 'app-safety-planning-edit',
	templateUrl: './safety-planning-edit.component.html'
})
export class SafetyPlanningEditComponent extends iBisBaseComponent {
	public edit: SafetyPlanningEditService;
	constructor(public proxy: ProxyService) {
		super(proxy.safetyPlanning, proxy.lg, proxy.iui, proxy.activatedRoute);
	}
}
