import { iBisBaseComponent } from '@dohu/ibis-common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ProxyService } from '../../../service/proxy.service';
import { DxTagBoxComponent } from 'devextreme-angular/ui/tag-box';
import { DashboardAnalyzesFilterService, WasteTypeItem } from './dashboard-analyzes-filter.service';

@Component({
	selector: 'app-dashboard-analyzes-filter',
	templateUrl: './dashboard-analyzes-filter.component.html'
})
export class DashboardAnalyzesFilterComponent extends iBisBaseComponent implements OnInit {

	@ViewChild('wasteCodeTagBox') tagBox: DxTagBoxComponent;
	public edit: DashboardAnalyzesFilterService;
	constructor(public proxy: ProxyService) {
		super(proxy.dashboardAnalyzes, proxy.lg, proxy.iui, proxy.activatedRoute);
	}
	ngOnInit(): void {
		if (sessionStorage.getItem('dashboard-analyzes')) {
			this.edit.model = JSON.parse(sessionStorage.getItem('dashboard-analyzes'));
			this.edit.refreshWasteTypeData().then((items: any) => {
				this.tagBox.instance.option('items', items);
			});
			this.edit.filterData();
		}
	}

	public onValueChanged(ev: any) {
		if (ev.event) {
			this.edit.model.wasteType = [];
			this.edit.refreshWasteTypeData().then((items: any) => {
				this.tagBox.instance.option('items', items);
			});
		}
	}

	public selectionChangedEv(ev: any) {
		if (WasteTypeItem.uom === undefined && ev.addedItems.length > 0) {
			WasteTypeItem.uom = ev.addedItems[0].uom;
			let items = ev.component.option('items');
			items.forEach(item => {
				item.disabled = WasteTypeItem.uom !== item.uom;
			});
		}
		if (ev.component.option('selectedItems').length == 0) {
			let items = ev.component.option('items');
			items.forEach(item => {
				item.disabled = false;
			});
			WasteTypeItem.uom = undefined;
		}
		this.edit.model.uom = WasteTypeItem.uom;
	}

	public onIntervalChanged = (ev: any) => {
		if (ev.event) {
			const d = new Date(this.edit.model.date);
			this.edit.model.date = new Date(d.getFullYear(), (ev.value === 1 ? (d.getMonth() + 1) : 1));
		}
	};

	public onOpenedEv(ev: any) {
		ev.component.content().querySelector('.dx-list-select-all').style.display = 'none';
	}
}
