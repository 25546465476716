<dx-data-grid #gridContractAnnex height="100%" (onToolbarPreparing)="toolbarPreparing($event, proxy.allowEdit)"
	(onInitialized)="onGridInit($event)" (onRowRemoving)="onAnnexRemoving($event)">
	
	<dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" [storageKey]='key'>
	</dxo-state-storing>
	<dxo-scrolling [mode]="ui.scrollingMode" [showScrollbar]="ui.showScrollbar" [useNative]="!ui.isWindows">
	</dxo-scrolling>

	<dxi-column type="buttons" width="150" fixedPosition="left" [fixed]="true" [allowResizing]="false"
		[showInColumnChooser]="false" [visible]="proxy.showActions">
		<dxi-button [hint]="L('DOWNLOAD')" icon="download" [onClick]="onDownloadClick" [visible]="onFileBtnVisible">
		</dxi-button>
		<dxi-button [hint]="L('UPLOAD')" icon="upload" [onClick]="onUploadClick" [visible]="onFileBtnVisible">
		</dxi-button>
		<dxi-button [hint]="L('EDIT')" icon="edit" [onClick]="onEditClick" [visible]="proxy.allowEdit">
		</dxi-button>
		<dxi-button [hint]="L('DELETE')" icon="trash" [onClick]="onDeleteClick" [visible]="proxy.allowDelete">
		</dxi-button>
	</dxi-column>

	<dxi-column [caption]="L('NO')" dataField="no" dataType="string" [minWidth]='170'></dxi-column>
	<dxi-column [caption]="L('TYPE')" dataField="type" dataType="string" [minWidth]='170'></dxi-column>
	<dxi-column [caption]="L('DATE')" dataField="date" dataType="date" width="170" calculateSortValue="date"
		[calculateCellValue]="iui.formatDate"></dxi-column>
</dx-data-grid>

<ibis-edit-popup [edit]="edit" [showAdd]="true">
	<app-contract-annex-edit></app-contract-annex-edit>
</ibis-edit-popup>