import { Component, ViewChild } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { DataService } from '../../../service/data.service';
import { PartyWizardService } from './party-wizard.service';
import { ProxyService } from '../../../service/proxy.service';
import { environment } from '../../../../environments/environment';
import { DxMultiViewComponent } from 'devextreme-angular/ui/multi-view';

@Component({
	selector: 'app-party-wizard',
	templateUrl: './party-wizard.component.html'
})
export class PartyWizardComponent extends iBisBaseComponent {
	@ViewChild('multiview') multiview: DxMultiViewComponent;

	public manualUrl: string;
	public unitInstance: any;
	public multiViewItems: any;

	public edit: PartyWizardService;
	constructor(public proxy: ProxyService, public ds: DataService) {
		super(proxy.partyWizard, proxy.lg, proxy.iui, proxy.activatedRoute);

		proxy.partyWizard.onDataChanged.subscribe((data) =>{
			if(data === 'nextWizardPage'){
				this.prepareWizard();
			}
		});
		this.manualUrl = environment.manualUrl;
		setTimeout(() => {
			this.initMultiView();
		}, 0);
	}

	initFormUnit(e: any) {
		this.unitInstance = e;
	}

	initMultiView() {
		this.multiViewItems = new DataSource({
			store: [{
				title: this.L('INFO', null)
			}, {
				title: this.L('ADD_ENTITY', null),
			}, {
				title: this.L('ADD_WORKSTATION', null),
			}, {
				title: this.L('ASSOC_WASTE_CODE', null)
			}, {
				title: this.L('FHINISHING', null)
			}]
		});
	}

	private prepareWizard(): void{
		switch(this.edit.previousPage){
			case 0: // Start
				this.fromStartPage();
				break;
			case 1: // Entity
				this.fromEntityPage();
				break;
			case 2: // Wc
				this.fromWorkcenterPage();
				break;
			case 3: // WasteCode
				this.fromWasteCodeAssociationPage();
				break;
			default:
				break;
		}
	}

	private fromStartPage(): void {
		if(this.edit.model.entity){
			Object.assign(this.proxy.unit.model, this.edit.model.entity);
		}
	}
	private fromEntityPage(): void {
		const obj = {};
		if(this.edit.page === 2){
			Object.assign(obj, this.proxy.unit.model);
			this.edit.model.entity = obj;
			this.edit.model.entity.typeId = 1;
			if(this.edit.model.workcenter){
				Object.assign(obj, this.edit.model.workcenter);
				this.proxy.unit.model = obj;
			} else {
				this.proxy.unit.reset();
			}

		}
	}

	private fromWorkcenterPage(): void{
		const obj ={};
		if(this.edit.page === 1){
			if(this.edit.model.entity){
				Object.assign(obj, this.edit.model.entity);
				this.proxy.unit.model = obj;
			} else {
				if(this.edit.model.unitWaste){
					Object.assign(obj, this.edit.model.unitWaste);
					this.proxy.unitWaste.model = obj;
				}
			}
		} else{
			Object.assign(obj, this.proxy.unit.model);
			this.edit.model.workcenter = obj;
			this.edit.model.workcenter.typeId = 2;
			this.proxy.unit.reset();
		}
	}
	private fromWasteCodeAssociationPage(): void{
		const obj = {};
		if(this.edit.page === 2){
			if(this.edit.model.workcenter){
				Object.assign(obj, this.edit.model.workcenter);
				this.proxy.unit.model = obj;
			}
		} else{
			Object.assign(obj, this.proxy.unitWaste.model);
			this.edit.model.unitWaste = obj;
		}
	}
}
