<dx-data-grid class="gridContainer box-shadow" #gridSafetyPlanning [columnAutoWidth]='true' [height]='ui.halfHeight'
    (onToolbarPreparing)='toolbarPreparing($event)' [dataSource]='edit.filteredData' [remoteOperations]='true'>

    <dxo-export [enabled]="true" fileName="Planning"></dxo-export>
    <dxo-scrolling mode="standard" showScrollbar="always" [preloadEnabled]="true" [scrollByContent]="true"
        [useNative]="false"></dxo-scrolling>
    <dxo-column-fixing [enabled]="true"></dxo-column-fixing>

    <dxi-column type="buttons" [width]='90' fixedPosition='left' [fixed]='true' [allowResizing]='false'
        [showInColumnChooser]='false'>
        <dxi-button [hint]='L("EDIT")' icon="edit" [onClick]="onEditClick"></dxi-button>
        <dxi-button [hint]='L("DELETE")' icon="trash" [onClick]="onDeleteClick"></dxi-button>
    </dxi-column>

    <dxi-column [caption]="L('ENT_WORKC')" dataField="fullName" dataType="string"></dxi-column>
    <dxi-column [caption]="L('YEAR')" dataField="p_year" dataType="number"></dxi-column>
    <dxi-column [caption]="L('MONTH')" dataField="p_month" dataTtpe="number"></dxi-column>
    <dxi-column [caption]="L('JOB')" dataField="j_description" dataType="string"></dxi-column>
    <dxi-column [caption]="L('REPORT_COUNT')" dataField="p_planned" dataType="number"></dxi-column>
</dx-data-grid>

<app-safety-planning-except></app-safety-planning-except>

<ibis-edit-popup [edit]="edit" [showAdd]="true">
    <app-safety-planning-edit></app-safety-planning-edit>
</ibis-edit-popup>

<ibis-edit-popup [edit]="proxy.safetyPlanningCopy" [showAdd]="false" [width]='"400px"'>
    <app-safety-planning-copy></app-safety-planning-copy>
</ibis-edit-popup>