import { Component, OnInit } from '@angular/core';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { ProxyService } from '../../../service/proxy.service';
import { TaxAnalyzesFilterService } from './tax-analyzes-filter.service';

@Component({
	selector: 'app-tax-analyzes-filter',
	templateUrl: './tax-analyzes-filter.component.html'
})
export class TaxAnalyzesFilterComponent extends iBisBaseComponent implements OnInit {

	public edit: TaxAnalyzesFilterService;
	constructor(public proxy: ProxyService) {
		super(proxy.taxAnalyzes, proxy.lg, proxy.iui, proxy.activatedRoute);
	}

	ngOnInit(): void {
		if (sessionStorage.getItem('tax-analyzes')) {
			this.edit.model = JSON.parse(sessionStorage.getItem('tax-analyzes'));
			this.edit.filterData();
		}
	}

	public onIntervalChanged = (ev: any) => {
		if (ev.event) {
			const d = new Date(this.edit.model.date);
			this.edit.model.date = new Date(d.getFullYear(), (ev.value === 1 ? (d.getMonth() + 1) : 1));
		}
	};
}
