import { NgModule } from '@angular/core';
import { TransferComponent } from './transfer.component';
import { BrowserModule } from '@angular/platform-browser';
import { DxFormModule } from 'devextreme-angular/ui/form';
import { DxListModule } from 'devextreme-angular/ui/list';
import { DxPopupModule } from 'devextreme-angular/ui/popup';
import { iBisNavigationModule } from '@dohu/ibis-navigation';
import { TransferEditService } from './transfer-edit.service';
import { TransferEditComponent } from './transfer-edit.component';
import { FrameworkModule } from '../../framework/framework.module';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { DxDropDownBoxModule } from 'devextreme-angular/ui/drop-down-box';

@NgModule({
	declarations: [
		TransferComponent,
		TransferEditComponent
	],
	imports: [
		DxFormModule,
		DxListModule,
		BrowserModule,
		DxPopupModule,
		FrameworkModule,
		DxDataGridModule,
		DxDropDownBoxModule,
		iBisNavigationModule
	],
	exports: [
		TransferComponent,
		TransferEditComponent
	],
	providers: [
		TransferEditService
	]
})
export class TransferModule { }
