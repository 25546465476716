<dx-chart (onDrawn)="onGraphInit($event)" id="chart" [dataSource]="typeValue">

    <dxi-series *ngFor="let op of typeOp" [valueField]="op.value" [name]="op.name">
    </dxi-series>

    <dxo-common-series-settings argumentField="day" type="line" palette="Soft Blue" argumentType="numeric">
        <dxo-point hoverMode="allArgumentPoints"></dxo-point>
    </dxo-common-series-settings>
    <dxo-argument-axis [valueMarginsEnabled]="false" discreteAxisDivisionMode="crossLabels">
        <dxo-grid [visible]="true"></dxo-grid>
        <dxo-title [text]="intervalDate"></dxo-title>
    </dxo-argument-axis>
    <dxo-value-axis>
        <dxo-title
            [text]="uom  === 1 ? L('TONES') : uom === 2 ? L('MC', null) : uom === 3 ? L('KG', null) : L('CURRENCY', null)"></dxo-title>
    </dxo-value-axis>
    <dxo-legend verticalAlignment="bottom" horizontalAlignment="center" itemTextPosition="bottom" [visible]="true">
    </dxo-legend>
    <dxo-title [text]="txt.title">
        <dxo-subtitle [text]="txt.description"></dxo-subtitle>
    </dxo-title>
    <dxo-tooltip [enabled]="true"></dxo-tooltip>
    <dxo-export [enabled]="ui.exportEnabled"></dxo-export>
</dx-chart>