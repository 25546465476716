import { Component, ViewChild } from '@angular/core';
import { ProxyService } from '../../../service/proxy.service';
import { EnumTypeEditService } from './enum-type-edit.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { BreadcrumbViewComponent } from '../../../framework/breadcrumb-view.component';

@Component({
	templateUrl: './enum-type.component.html'
})
export class EnumTypeComponent extends BreadcrumbViewComponent {
	@ViewChild('gridEnumType') grid: DxDataGridComponent;

	public edit: EnumTypeEditService;
	constructor(public proxy: ProxyService) {
		super(proxy.enumType, proxy.lg, proxy.iui, proxy.activatedRoute);
		this.key = 'enumType';
	}

	onRowExpanding(event: any) {
		event.component.collapseAll(-1);
	}
}
