<dx-data-grid #gridSanction [height]="250" (onToolbarPreparing)="toolbarPreparing($event, proxy.allowEdit)"
	(onInitialized)="onGridInit($event)">

	<dxo-scrolling [mode]="ui.scrollingMode" [showScrollbar]="ui.showScrollbar" [useNative]="!ui.isWindows">
	</dxo-scrolling>
	<dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" [storageKey]='key'>
	</dxo-state-storing>

	<dxi-column type="buttons" fixedPosition="left" [minWidth]="proxy.actionsWidth" [width]="proxy.actionsWidth" 
		[fixed]="true" [allowResizing]="false" [showInColumnChooser]="false" [visible]="proxy.showActions">
		<dxi-button [hint]="L('EDIT')" icon="edit" [onClick]="onEditClick" [visible]="proxy.allowEdit">
		</dxi-button>
		<dxi-button [hint]="L('DELETE')" icon="trash" [onClick]="onDeleteClick" [visible]="proxy.allowDelete">
		</dxi-button>
	</dxi-column>

	<dxi-column [caption]="L('DESCRIPTION')" dataField="sanctionDescription" dataType="string"
		[minWidth]='170'>
	</dxi-column>
	<dxi-column [caption]="L('STATUS')" dataField="sanctionStatus" dataType="number" [minWidth]='170'>
		<dxo-lookup [dataSource]="edit.status" valueExpr="id" displayExpr="value">
		</dxo-lookup>
	</dxi-column>
	<dxi-column [caption]="L('TERM')" dataField="termSanction" dataType="date" calculateSortValue="termSanction"
		[calculateDisplayValue]="iui.formatDate" [allowHeaderFiltering]="false">
	</dxi-column>
	<dxi-column [caption]="L('OBSERVATIONS')" dataField="notifications" dataType="string"
		[minWidth]='170'>
	</dxi-column>
</dx-data-grid>

<ibis-edit-popup [edit]="edit" [showAdd]="true">
	<app-control-sanction-edit></app-control-sanction-edit>
</ibis-edit-popup>