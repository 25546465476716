import { Component } from '@angular/core';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { ProxyService } from '../../../service/proxy.service';
import { KpiAuditorEditService } from './kpi-auditor-edit.service';

@Component({
	selector: 'app-kpi-auditor',
	templateUrl: './kpi-auditor.component.html'
})
export class KpiAuditorComponent extends iBisBaseComponent {

	public edit: KpiAuditorEditService;
	constructor(public proxy: ProxyService) {
		super(proxy.kpiAuditor, proxy.lg, proxy.iui, proxy.activatedRoute);
	}
}
