<dx-data-grid #gridWC [height]="400" (onToolbarPreparing)="toolbarPreparing($event, proxy.allowEdit)"
	(onInitialized)="onGridInit($event)">

	<dxo-scrolling [mode]="ui.scrollingMode" [showScrollbar]="ui.showScrollbar" [useNative]="!ui.isWindows">
	</dxo-scrolling>
	<dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" [storageKey]="key">
	</dxo-state-storing>

	<dxi-column type="buttons" fixedPosition="left" [minWidth]="proxy.actionsWidth" [width]="proxy.actionsWidth"
		[fixed]="true" [allowResizing]="false" [showInColumnChooser]="false" [visible]="proxy.allowedActions">
		<dxi-button [hint]="L('EDIT')" icon="edit" [onClick]="onEditClick" [visible]="proxy.allowEdit"></dxi-button>
	</dxi-column>

	<dxi-column [caption]="L('NAME')" dataField="p_name" dataType="string" [allowHeaderFiltering]="false">
	</dxi-column>
	<dxi-column [caption]="L('COUNTY')" dataField="co_name" dataType="string"></dxi-column>
	<dxi-column [caption]="L('LOCALITY')" dataField="loc_name" dataType="string"></dxi-column>
	<dxi-column [caption]="L('ADDRESS')" dataField="p_address" dataType="string" [allowHeaderFiltering]="false">
	</dxi-column>
	<dxi-column [caption]="L('OBSERVATIONS')" dataField="p_observations" dataType="string"
		[allowHeaderFiltering]="false">
	</dxi-column>
	<dxi-column [caption]="L('ACTIVE')" dataField="p_isActiv" dataType="boolean"></dxi-column>
</dx-data-grid>

<ibis-edit-popup [edit]="edit" [showAdd]="true">
	<app-party-work-center-edit></app-party-work-center-edit>
</ibis-edit-popup>