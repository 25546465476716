import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxChartModule } from 'devextreme-angular/ui/chart';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { DxDateBoxModule } from 'devextreme-angular/ui/date-box';
import { DxFormModule } from 'devextreme-angular/ui/form';
import { DxiSeriesModule } from 'devextreme-angular/ui/nested';
import { DxPivotGridModule } from 'devextreme-angular/ui/pivot-grid';
import { DxPopupModule } from 'devextreme-angular/ui/popup';
import { DxScrollViewModule } from 'devextreme-angular/ui/scroll-view';
import { DxSelectBoxModule } from 'devextreme-angular/ui/select-box';
import { DxTabPanelModule } from 'devextreme-angular/ui/tab-panel';
import { DxTextBoxModule } from 'devextreme-angular/ui/text-box';
import { DxValidatorModule } from 'devextreme-angular/ui/validator';
import { DxValidationGroupModule } from 'devextreme-angular/ui/validation-group';

import { FrameworkModule } from '../../framework/framework.module';
import { iBisCommonModule } from '@dohu/ibis-common';
import { KpiAuditorComponent } from './kpi-auditor/kpi-auditor.component';
import { KpiAuditorEditService } from './kpi-auditor/kpi-auditor-edit.service';
import { KpiLocationComponent } from './kpi-location/kpi-location.component';
import { KpiLocationEditService } from './kpi-location/kpi-location-edit.service';

@NgModule({
	declarations: [
		KpiAuditorComponent,
		KpiLocationComponent
	],
	imports: [
		DxFormModule,
		BrowserModule,
		DxChartModule,
		DxPopupModule,
		DxButtonModule,
		DxDateBoxModule,
		DxTextBoxModule,
		DxiSeriesModule,
		FrameworkModule,
		DxTabPanelModule,
		DxDataGridModule,
		DxPivotGridModule,
		DxValidatorModule,
		DxSelectBoxModule,
		DxSelectBoxModule,
		DxScrollViewModule,
		DxValidationGroupModule,
		iBisCommonModule
	],
	exports: [
		KpiAuditorComponent,
		KpiLocationComponent
	],
	providers: [
		KpiAuditorEditService,
		KpiLocationEditService
	]
})
export class SafetyKpiModule { }
