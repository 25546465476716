import { Component } from '@angular/core';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { ProxyService } from '../../service/proxy.service';
import { TransferEditService } from './transfer-edit.service';
import { UOMHelper } from '../waste-type/waste-type-edit.service';

@Component({
	selector: 'app-transfer-edit',
	templateUrl: './transfer-edit.component.html'
})
export class TransferEditComponent extends iBisBaseComponent {
	public edit: TransferEditService;

	public uomHelper: UOMHelper;
	private cboWasteType: any;
	private cboCar: any;
	private cboDriver: any;

	constructor(public proxy: ProxyService) {
		super(proxy.transfer, proxy.lg, proxy.iui, proxy.activatedRoute);
		this.uomHelper = new UOMHelper(this.proxy.wasteType);

		proxy.wasteType.onDataChanged.subscribe((data) => {
			if (data === 'code' && this.cboWasteType) {
				this.proxy.resetValidation(this.cboWasteType);
			}
			if (data === 'selected') {
				this.edit.model.wasteType = this.proxy.wasteType.selected;
				if (this.edit.model.uom) {
					this.proxy.wasteType.selected.uom = this.edit.model.uom;
					this.uomHelper.update(this.proxy.wasteType.selectedUomName);
				}
			}
		});

		proxy.transporter.onDataChanged.subscribe((data) => {
			if (data === 'selected' && this.cboCar && this.cboDriver) {
				this.proxy.resetValidation(this.cboCar);
				this.proxy.resetValidation(this.cboDriver);
			}
		});
	}

	onWorkCenterChanged = (ev: any) => {
		if (ev.event) {
			this.edit.model.destinationWorkCenterId = null;
			this.edit.model.quantity = null;
		}
		this.proxy.wasteType.workCenterId = ev.value;
	};

	onWasteTypeChanged = (ev: any) => {
		this.proxy.wasteType.selectedId = ev.value;
	};

	onWasteTypeInit = (ev: any) => {
		this.cboWasteType = ev.component;
	};

	onCarInit = (ev: any) => {
		this.cboCar = ev.component;
	};

	onDriverInit = (ev: any) => {
		this.cboDriver = ev.component;
	};

	onTransporterChanged = (ev: any) => {
		this.proxy.transporter.selectedId = ev.value;
	};

	onCustomItemDriver = (ev: any) => {
		this.edit.model.driverTableId = this.proxy.transporter.onCustomItemDriver(ev, this.edit.model.transporterId);
	};

	onCustomItemCar = (ev: any) => {
		this.edit.model.carId = this.proxy.transporter.onCustomItemCar(ev, this.edit.model.transporterId);
	};

	onActionListChange(e: any, dropdown: any) {
		this.edit.setRecipient(this.edit.model.selectedActivity);
	}
}
