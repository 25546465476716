<dx-data-grid class="gridContainer box-shadow" #gridSafetyPlanningExcept [columnAutoWidth]='true' [height]='ui.halfHeight'
	(onToolbarPreparing)='toolbarPreparing($event)' [dataSource]='edit.filteredData' [remoteOperations]='true'>
 

    <dxo-export [enabled]="true" fileName="PlanningExcept"></dxo-export>
    <dxo-scrolling mode="standard" showScrollbar="always" [preloadEnabled]="true" [scrollByContent]="true"
        [useNative]="false"></dxo-scrolling>
    <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
    <dxo-editing [allowDeleting]="true"></dxo-editing>

    <dxi-column type="buttons" [width]='90' fixedPosition='left' [fixed]='true' [allowResizing]='false'
        [showInColumnChooser]='false'>
        <dxi-button [hint]='L("EDIT")' icon="edit" [onClick]="onEditClick"></dxi-button>
        <dxi-button [hint]='L("DELETE")' icon="trash" [onClick]="onDeleteClick"></dxi-button>
    </dxi-column>

    <dxi-column [caption]="L('YEAR')" dataField="year" dataType="number" [minWidth]="80"></dxi-column>
    <dxi-column [caption]="L('MONTH')" dataField="month" dataTtpe="number" [minWidth]="80"></dxi-column>
    <dxi-column [caption]="L('AUDITOR')" dataField="auditorId" dataType="string">
        <dxo-lookup [dataSource]="proxy.auditor.data" valueExpr="id" displayExpr="displayName"></dxo-lookup>
    </dxi-column>
    <dxi-column [caption]="L('PLANNING')" dataField="planned" dataType="number" [minWidth]="80"></dxi-column>
    <dxi-column [caption]="L('REASON')" dataField="reason" dataType="string"></dxi-column>
</dx-data-grid>

<ibis-edit-popup [edit]="edit" [showAdd]="true">
    <app-safety-planning-except-edit></app-safety-planning-except-edit>
</ibis-edit-popup>