import { Component } from '@angular/core';
import { DataService } from '../../service/data.service';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { ProxyService } from '../../service/proxy.service';

@Component({
	templateUrl: 'user.component.html'
})
export class UserComponent extends iBisBaseComponent {

	constructor(public proxy: ProxyService, public ds: DataService) {
		super(proxy.user, proxy.lg, proxy.iui, proxy.activatedRoute);
	}
}
