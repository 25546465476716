
import { Component, Input } from '@angular/core';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { DataService } from '../../../service/data.service';
import { ProxyService } from '../../../service/proxy.service';

@Component({
	selector: 'app-graph-line',
	templateUrl: 'graph.component.html'
})

export class GraphComponent extends iBisBaseComponent {
	@Input() txt: any;
	@Input() typeOp: any[];
	@Input() typeValue: any[];
	@Input() uom: number;
	@Input() intervalDate: string;

	constructor(public proxy: ProxyService, public ds: DataService) {
		super(null, proxy.lg, proxy.iui, proxy.activatedRoute);
	}

	onGraphInit(event: any) {
		event.component.render();
	}
}
