<dx-form [colCount]="2" [formData]="edit.model" [showValidationSummary]="false" [validationGroup]="edit.validation"
	labelLocation="left" [colCount]="2">
	<dxi-item [label]="{text: L('WASTE_CODE')}" dataField="wasteTypeId" editorType="dxSelectBox"
		[editorOptions]="{dataSource: proxy.wasteType.allCode, valueExpr: 'id', displayExpr: 'code', onValueChanged: onWasteTypeChanged, searchEnabled: true, disabled: edit.model.id !== undefined }">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('ACTIVE')}" dataField="isActiv" editorType="dxCheckBox" [editorOptions]="{ }">
	</dxi-item>

	<dxi-item [label]="{text: L('WASTE_DESTINATION')}" dataField="workCenterId" editorType="dxSelectBox"
		[editorOptions]="{dataSource: proxy.party.ownWorkCenter, valueExpr: 'c_id', displayExpr: 'fullName' }" [colSpan]="2">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>

	<dxi-item [label]="{text: L('U')}" dataField="uomName" editorType="dxTextBox"
		[editorOptions]="{readOnly: true}"></dxi-item>
	<dxi-item [label]="{text: L('ALTERNATE_UOM')}" dataField="defaultUom" editorType="dxSelectBox"
		[editorOptions]="{dataSource: proxy.wasteType.otherUomCode, valueExpr: 'id', displayExpr: 'value', searchEnabled: true, showClearButton:true}">
	</dxi-item>

	<dxi-item [label]="{text: L('NAME') }" dataField="wasteType.name" editorType="dxTextBox" [colSpan]="2" 
		[editorOptions]="{readOnly: true}">
	</dxi-item>

	<dxi-item [label]="{text: L('OBSERVATIONS')}" dataField="observations" editorType="dxTextArea" [colSpan]="2" >
	</dxi-item>
</dx-form>