import { NgModule } from '@angular/core';
import { iBisCommonModule } from '@dohu/ibis-common';
import { AlertComponent } from './alert/alert.component';
import { BrowserModule } from '@angular/platform-browser';
import { DxFormModule } from 'devextreme-angular/ui/form';
import { ReportExtComponent } from './report-ext.component';
import { ReportIntComponent } from './report-int.component';
import { DxPopupModule } from 'devextreme-angular/ui/popup';
import { iBisNavigationModule } from '@dohu/ibis-navigation';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { ArchiveComponent } from './archive/archive.component';
import { ReportExtEditService } from './report-ext-edit.service';
import { DxDateBoxModule } from 'devextreme-angular/ui/date-box';
import { FrameworkModule } from '../../framework/framework.module';
import { DxTreeListModule } from 'devextreme-angular/ui/tree-list';
import { DxTabPanelModule } from 'devextreme-angular/ui/tab-panel';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { GeneralReportComponent } from './general-report.component';
import { ArchiveEditService } from './archive/archive-edit.service';
import { DxValidatorModule } from 'devextreme-angular/ui/validator';
import { DxSelectBoxModule } from 'devextreme-angular/ui/select-box';
import { ArchiveEditComponent } from './archive/archive-edit.component';
import { DxAutocompleteModule } from 'devextreme-angular/ui/autocomplete';
import { DxValidationGroupModule } from 'devextreme-angular/ui/validation-group';
import { DxValidationSummaryModule } from 'devextreme-angular/ui/validation-summary';

@NgModule({
	declarations: [
		AlertComponent,
		ArchiveComponent,
		ReportExtComponent,
		ReportIntComponent,
		ArchiveEditComponent,
		GeneralReportComponent
	],
	imports: [
		DxFormModule,
		BrowserModule,
		DxPopupModule,
		DxButtonModule,
		FrameworkModule,
		DxDateBoxModule,
		DxTreeListModule,
		DxDataGridModule,
		iBisCommonModule,
		iBisNavigationModule,
		DxTabPanelModule,
		DxSelectBoxModule,
		DxValidatorModule,
		DxAutocompleteModule,
		DxValidationGroupModule,
		DxValidationSummaryModule
	],
	exports: [
		AlertComponent,
		ArchiveComponent,
		ReportExtComponent,
		ReportIntComponent,
		ArchiveEditComponent,
		GeneralReportComponent
	],
	providers: [
		ArchiveEditService,
		ReportExtEditService
	]
})
export class ReportModule { }
