<dx-form [formData]="edit.model" [validationGroup]="edit.validation">
    <dxi-item itemType="group" [colCount]="2">
        <dxi-item [label]="{text: L('MARK') }" dataField="mark" editorType="dxNumberBox" [editorOptions]="{ }">
        </dxi-item>
        <dxi-item [label]="{text: L('NAME') }" dataField="displayName" editorType="dxTextBox" [editorOptions]="{ }">
            <dxi-validation-rule type="required"></dxi-validation-rule>
            <dxi-validation-rule type="stringLength"  [max]="80" [message]="L('G_NAME_LENGTH')">
            </dxi-validation-rule>
        </dxi-item>
        <dxi-item [label]="{text: L('USER') }" dataField="userId" editorType="dxSelectBox"
            [editorOptions]="{ dataSource: proxy.user.data, valueExpr: 'u_id', displayExpr: 'u_fullName', searchEnabled: true }">
            <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-item>
        <dxi-item [label]="{text: L('JOB') }" dataField="jobId" editorType="dxSelectBox"
            [editorOptions]="{ dataSource: edit.job, valueExpr: 'id', displayExpr: 'description' }">
            <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-item>
        <dxi-item [label]="{text: L('DEPARTMENT') }" dataField="departmentId" editorType="dxSelectBox"
            [editorOptions]="{ dataSource: proxy.department.data, valueExpr: 'id', displayExpr: 'name' }">
            <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-item>
        <dxi-item itemType="empty"></dxi-item>
        <dxi-item [label]="{text: L('START_DATE') }" dataField="startDate" editorType="dxDateBox"
            [editorOptions]="{ width: '100%', displayFormat: 'dd.MM.yyyy'}">
            <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-item>
        <dxi-item [label]="{text: L('FINISH_DATE') }" dataField="endDate" editorType="dxDateBox"
            [editorOptions]="{ width: '100%', displayFormat: 'dd.MM.yyyy'}">
        </dxi-item>
    </dxi-item>
</dx-form>