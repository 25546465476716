<dx-data-grid #gridProfileCategory [height]="350" (onInitNewRow)="onInitNewRow($event)"
    (onInitialized)="onGridInit($event)" (onToolbarPreparing)="toolbarPreparing($event, false)">

    <dxo-editing mode="row" [allowAdding]="true" [allowUpdating]="true" [allowDeleting]="true" [useIcons]="true">
    </dxo-editing> 
	<dxo-scrolling [mode]="ui.scrollingMode" [showScrollbar]="ui.showScrollbar" [useNative]="!ui.isWindows">
	</dxo-scrolling>
	<dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" [storageKey]="key">
	</dxo-state-storing>

    <dxi-column [caption]="L('POSITION')" dataField="position" dataType="number" [width]="60">
        <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>
    <dxi-column [caption]="L('CATEGORY')" dataField="categoryId">
        <dxo-lookup [dataSource]="edit.category" valueExpr="id" displayExpr="description">
        </dxo-lookup>
        <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>
</dx-data-grid>