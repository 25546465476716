<dx-form [colCount]="2" [formData]="edit.model" [showValidationSummary]="false" [validationGroup]="edit.validation"
	labelLocation="left">
	<dxi-item [label]="{text: L('NO')}" dataField="no" editorType="dxTextBox" [editorOptions]="{ }">
		<dxi-validation-rule type="required"></dxi-validation-rule>
		<dxi-validation-rule type="stringLength"  [max]="256" [message]="L('NAME_LENGTH')">
		</dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('DATE')}" dataField="date" editorType="dxDateBox"
		[editorOptions]="{ format: 'dd.MM.yyyy', width: 'auto' }">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>

	<dxi-item [label]="{text: L('ENT_WORKC')}" dataField="partyId" editorType="dxSelectBox" [colSpan]='2'
		[editorOptions]="{dataSource: proxy.party.ownWorkCenter, valueExpr: 'c_id', displayExpr: 'fullName', searchEnabled: true, showClearButton: true }">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('SUPPLIER')}" dataField="supplierId" editorType="dxSelectBox" [colSpan]='2'
		[editorOptions]="{ dataSource: proxy.party.contractor, valueExpr: 'c_id', displayExpr: 'fullName', searchEnabled: true, showClearButton: true }">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>

	<dxi-item [label]="{text: L('CONTRACT_TYPE')}" dataField="isSales" editorType="dxSelectBox"
		[editorOptions]="{ dataSource: edit.type, valueExpr: 'id', displayExpr: 'value', searchEnabled: true }">
	</dxi-item>
	<dxi-item [label]="{text: L('AMOUNT_WITH_VAT')}" dataField="amount" editorType="dxNumberBox" [editorOptions]="{ }">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('DESCRIPTION')}" dataField="comments" editorType="dxTextArea" [colSpan]='2' [editorOptions]="{ }">
	</dxi-item>
</dx-form>