import { Component } from '@angular/core';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { PartyEditService } from './party-edit.service';
import { ProxyService } from '../../../service/proxy.service';

@Component({
	selector: 'app-party-edit',
	templateUrl: 'party-edit.component.html'
})
export class PartyEditComponent extends iBisBaseComponent {
	public edit: PartyEditService;
	private cboLocality: any;

	constructor(public proxy: ProxyService) {
		super(proxy.party, proxy.lg, proxy.iui, proxy.activatedRoute);

		proxy.party.onDataChanged.subscribe((data) => {
			if (data === 'locality' && this.cboLocality) {
				this.proxy.resetValidation(this.cboLocality);
			}
		});
	}

	onLocalityInitialized = (ev: any) => {
		this.cboLocality = ev.component;
	};

	onCountyValueChanged = (ev: any) => {
		if (ev.event) {
			(this.edit as PartyEditService).countyId = ev.value;
		}
	};

	onSubcoOptionChanged = (ev: any) => {
		if (!ev.value) {
			this.edit.model.parentId = null;
		}
	};
}
