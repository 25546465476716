<dx-form id="finishTransForm" [formData]="edit.model" [showValidationSummary]="false"
    [validationGroup]="edit.validation" (onInitialized)="onFormInit($event)" labelLocation="left"
	(onFieldDataChanged)="setDisable($event)">
	<dxi-item itemType="group" [colCount]="2">
		<dxi-item [label]="{text: L('NO_DATE')}" dataField="info.noDate" editorType="dxTextBox"
			[editorOptions]="{ readOnly: true}">
		</dxi-item>
		<dxi-item itemType="empty"></dxi-item>

		<dxi-item [label]="{text: L('VEHICLE_ID_CARRIER')}" [colSpan]="2" dataField="info.carTransporter" editorType="dxTextBox"
		[editorOptions]="{ readOnly: true }">
		</dxi-item>

		<dxi-item [label]="{text: L('ENT_WORKC')}" [colSpan]="2" dataField="info.fullName" editorType="dxTextBox"
			[editorOptions]="{ readOnly: true }">
		</dxi-item>
		<dxi-item [label]="{text: L('RECIPIENT')}" [colSpan]="2" dataField="info.recipient" editorType="dxTextBox"
			[editorOptions]="{ readOnly: true }">
		</dxi-item>

		<dxi-item [label]="{text: L('WASTE_CODE')}" [colSpan]="edit.wasteType === '2' ? 2 : 1" dataField="info.code"
			editorType="dxTextBox" [editorOptions]="{ readOnly: true }">
		</dxi-item>
		<dxi-item [label]="{text: L('QUANTITY')}" dataField="quantity" editorType="dxNumberBox"
			[editorOptions]="{ readOnly: true, format: edit.fromatQuantity }">
		</dxi-item>
		<dxi-item [label]="{text: L('PIECE')}" dataField="piece" editorType="dxNumberBox" [visible]="edit.wasteType === '2'"
			[editorOptions]="{ readOnly: true}">
		</dxi-item>
	</dxi-item>
	
    <dxi-item itemType="group" [colCount]="edit.wasteType == '0' ? 2 : 4">
        <dxi-item [colSpan]="1" [colCount]="2" itemType="group" [caption]="L('VALORIZATION')">
            <dxi-item [label]="{text: L('QUANTITY'), location: 'top' }"
                dataField="valuedQuantity" editorType="dxNumberBox"
                [editorOptions]="{min: 0, disabled: disableCapitalization && edit.wasteType === '0', format: edit.fromatQuantity, showClearButton: true }">
                <dxo-label location="top">
                </dxo-label>
                <dxi-validation-rule type="custom" [reevaluate]="true"
                    [validationCallback]="vQuantityValidation" [message]="L('NOT_VALID_VALUE_VAL_ELIM')">
                </dxi-validation-rule>
            </dxi-item>
            <dxi-item [label]="{text: L('CODE'), location: 'top'}" dataField="capitalizationId"
                editorType="dxSelectBox" message="."
                [editorOptions]="{dataSource: proxy.wasteType.capitalizationCode, valueExpr: 'id', displayExpr: 'code', disabled: disableCapitalization  && edit.wasteType === '0', showClearButton: true}">
                <dxi-validation-rule type="custom" [reevaluate]="true" [validationCallback]="vCodeValidation">
                </dxi-validation-rule>
            </dxi-item>
        </dxi-item>
        <dxi-item [colSpan]="1" [colCount]="2" itemType="group" [caption]="L('ELIMINATION')"
            [visible]="edit.wasteType === '0' || edit.wasteType === '1'">
            <dxi-item
                [label]="{text: L('QUANTITY'), location: 'top' }"
                dataField="eliminatedQuantity" editorType="dxNumberBox"
                [editorOptions]="{min: 0, disabled: disableElimination  && edit.wasteType == '0', format: edit.fromatQuantity, showClearButton: true }">
                <dxi-validation-rule type="custom" [reevaluate]="true"
                    [validationCallback]="eQuantityValidation" [message]="L('NOT_VALID_VALUE_VAL_ELIM')">
                </dxi-validation-rule>
            </dxi-item>
            <dxi-item [label]="{text: L('CODE'), location: 'top'}" dataField="eliminationId" editorType="dxSelectBox"
                [editorOptions]="{dataSource: proxy.wasteType.eliminationCode, valueExpr: 'id', displayExpr: 'code', showClearButton: true, disabled: disableElimination  && edit.wasteType === '0'}">
                <dxi-validation-rule type="custom" [reevaluate]="true" [validationCallback]="eCodeValidation">
                </dxi-validation-rule>
            </dxi-item>
        </dxi-item>
        <dxi-item [colSpan]="1" [colCount]="2" itemType="group" [caption]="L('RECYCLE')"
            [visible]="edit.wasteType !== '0'">
            <dxi-item
                [label]="{text: L('QUANTITY'), location: 'top' }"
                dataField="recycledQuantity" editorType="dxNumberBox" [editorOptions]="{ min: 0, format: edit.fromatQuantity, showClearButton: true  }">
                <dxi-validation-rule type="custom" [reevaluate]="true"
                    [validationCallback]="recQuantityValidation">
                </dxi-validation-rule>
            </dxi-item>
            <dxi-item [label]="{text: L('CODE'), location: 'top'}" dataField="recycleId" editorType="dxSelectBox"
                [editorOptions]="{dataSource: proxy.wasteType.capitalizationCode, valueExpr: 'id', displayExpr: 'code', showClearButton: true}">
                <dxi-validation-rule type="custom" [reevaluate]="true" [validationCallback]="recCodeValidation">
                </dxi-validation-rule>
            </dxi-item>
        </dxi-item>
        <dxi-item [colSpan]="1" [colCount]="1" itemType="group" [caption]="L('REUSED')"
            [visible]="edit.wasteType === '2'">
            <dxi-item
                [label]="{text: L('QUANTITY'), location: 'top' }"
                dataField="reusedQuantity" editorType="dxNumberBox" [editorOptions]="{ min: 0, format: edit.fromatQuantity, showClearButton: true  }">
                <dxi-validation-rule type="custom" [reevaluate]="true"
                    [validationCallback]="reuQuantityValidation">
                </dxi-validation-rule>
            </dxi-item>
        </dxi-item>
        <dxi-item [colSpan]="1" [colCount]="1" itemType="group" [caption]="L('EXPORT')"
            [visible]="edit.wasteType === '1' || edit.wasteType === '2'">
            <dxi-item
                [label]="{text: L('QUANTITY'), location: 'top' }"
                dataField="exportedQuantity" editorType="dxNumberBox" [editorOptions]="{ min: 0, format: edit.fromatQuantity, showClearButton: true  }">
                <dxi-validation-rule type="custom" [reevaluate]="true"
                    [validationCallback]="expQuantityValidation">
                </dxi-validation-rule>
            </dxi-item>
        </dxi-item>
    </dxi-item>

    <dxi-item itemType="group" [colCount]="2">
        <dxi-item [label]="{text: L('DATE_RECEPTION')}" dataField="receptionDate" editorType="dxDateBox"
            [editorOptions]="{displayFormat: 'dd.MM.yyyy', width: '100%' }">
			    <dxi-validation-rule type="custom" [reevaluate]="true"
                    [validationCallback]="receptionDateValidation" [message]="L('NOT_VALID_RECEPTION_TEMP_DATE')">
                </dxi-validation-rule>
        </dxi-item>
        <dxi-item [label]="{text: L('FINISH_DATE')}" dataField="finishDate" editorType="dxDateBox"
            [editorOptions]="{displayFormat: 'dd.MM.yyyy', width: '100%' }">
        </dxi-item>
        <dxi-item [label]="{text: L('TEMP_STO_DATE')}" dataField="tempStockDate" editorType="dxDateBox"
            [editorOptions]="{displayFormat: 'dd.MM.yyyy', width: '100%' }">
			    <dxi-validation-rule type="custom" [reevaluate]="true"
                    [validationCallback]="tempStockDateValidation" [message]="L('NOT_VALID_RECEPTION_TEMP_DATE')">
                </dxi-validation-rule>
        </dxi-item>
        <dxi-item [label]="{text: L('TREATMENT_TYPE')}" dataField="treatmentTypeId" editorType="dxSelectBox"
            [editorOptions]="{dataSource: edit.ds.common.treatmentTypes, valueExpr: 'id', displayExpr: 'description'}">
        </dxi-item>
    </dxi-item>
	    <dxi-item [label]="{text: L('OBSERVATIONS')}" dataField="observations" editorType="dxTextArea" [editorOptions]="{ }">
    </dxi-item>
</dx-form>