import { iBisViewComponent } from '@dohu/ibis-common';
import { Component, ViewChild, Input } from '@angular/core';
import { ProxyService } from '../../../service/proxy.service';
import { UnitWasteEditService } from './unit-waste-edit.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';

@Component({
	selector: 'app-unit-waste',
	templateUrl: 'unit-waste.component.html'
})
export class UnitWasteComponent extends iBisViewComponent {

	@Input() workCenterId: any;
	@ViewChild('gridUnitWaste') grid: DxDataGridComponent;
	constructor(public proxy: ProxyService) {
		super(proxy.unitWaste, proxy.lg, proxy.iui, proxy.activatedRoute);
		this.id = 'wtp_id';
		if (!this.key) {
			this.key = 'unitWaste';
		}
	}

	onGridInit(e: any) {
		if (e.component.option('dataSource') == null) {
			const uw = this.edit as UnitWasteEditService;
			uw.workCenterId = this.workCenterId;
			e.component.option('dataSource', uw.getData());
		}
	}
}
