import { Component } from '@angular/core';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { ProxyService } from '../../../service/proxy.service';
import { GeographicAreaEditService } from './geographic-area-edit.service';

@Component({
	selector: 'app-geographic-area-edit',
	templateUrl: 'geographic-area-edit.component.html'
})
export class GeographicAreaEditComponent extends iBisBaseComponent {
	public edit: GeographicAreaEditService;

	private cboParent: any;

	constructor(public proxy: ProxyService) {
		super(proxy.geo, proxy.lg, proxy.iui, proxy.activatedRoute);
	}

	onTypeValueChanged = (ev: any) => {
		if (ev.event && this.cboParent) {
			switch (ev.value) {
				case 1:
					this.cboParent.option('dataSource', this.edit.county);
					break;
				case 2:
					this.cboParent.option('dataSource', this.edit.zone);
					break;
				case 3:
					this.cboParent.option('dataSource', null);
					break;
			}
			this.edit.model.parentId = null;
		}
	};

	onParentInitialized = (ev: any) => {
		this.cboParent = ev.component;
	};
}
