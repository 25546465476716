import { PermitEditService } from './permit-edit.service';
import { Component, ViewChild, Input } from '@angular/core';
import { ProxyService } from '../../../service/proxy.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { BreadcrumbViewComponent } from '../../../framework/breadcrumb-view.component';

@Component({
	selector: 'app-permit',
	templateUrl: 'permit.component.html'
})
export class PermitComponent extends BreadcrumbViewComponent {
	@Input() height: any;
	@Input() isSubtab: Boolean = false;
	@Input() entityId: string;
	@ViewChild('permitGrid') grid: DxDataGridComponent;

	public edit: PermitEditService;
	constructor(public proxy: ProxyService) {
		super(proxy.permit, proxy.lg, proxy.iui, proxy.activatedRoute);
		this.id = 'p_id';
		this.key = 'permit';
		if (!this.height) {
			this.height = this.ui.fullHeight;
		}
	}

	onGridInit(e: any) {
		if (this.isSubtab) {
			e.component.option('dataSource', this.edit.getData(this.entityId));
		}
	}

	protected prepareNew(): any {
		return { entityId: this.entityId, isValid: true };
	}
}
