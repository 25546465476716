<dx-data-grid  #gridFullTransport class="gridContainer" [remoteOperations]="true"
	[height]="ui.fullHeight" [dataSource]="edit.fullTransport" (onToolbarPreparing)="toolbarPreparing($event)">
	
	<dxo-export [enabled]="ui.exportEnabled" fileName="Predare"></dxo-export>
	<dxo-scrolling [mode]="ui.scrollingMode" [showScrollbar]="ui.showScrollbar" [useNative]="!ui.isWindows">
	</dxo-scrolling>
	<dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" storageKey="qhseFullTransport">
	</dxo-state-storing>
	<dxo-column-fixing [enabled]="true"></dxo-column-fixing>

	<dxi-column type="buttons" fixedPosition="left"  [minWidth]="proxy.actionsWidth" [width]="proxy.actionsWidth"
		[fixed]="true" [allowResizing]="false" [showInColumnChooser]="false" [visible]="proxy.showActions">
		<dxi-button [hint]="L('EDIT')" icon="edit" [onClick]="onEditClick" [visible]="proxy.allowEdit">
		</dxi-button>
		<dxi-button [hint]="L('DELETE')" icon="trash" [onClick]="onDeleteClick" [visible]="proxy.allowDelete">
		</dxi-button>
	</dxi-column>

	<dxi-column [caption]="L('SENT_DATE')" dataField="t_sendDate" dataType="date" minWidth="100"
		calculateSortValue="t_sendDate" [calculateDisplayValue]="iui.formatDate">
	</dxi-column>
	<dxi-column [caption]="L('WORK_CENTER')" dataField="wc_name" dataType="string"></dxi-column>
	<dxi-column [caption]="L('FAT')" dataField="ta_no" dataType="string"></dxi-column>
	<dxi-column [caption]="L('FET_FID')" dataField="t_fet" dataType="string"></dxi-column>
	<dxi-column [caption]="L('ELIMINATION_TYPE')" cellTemplate="actionsTemplate">
		<div *dxTemplate="let data of 'actionsTemplate'">
			{{displayActivity(data.data.t_actions)}}
		</div>
	</dxi-column>
	<dxi-column [caption]="L('RECIPIENT')" dataField="rt_name" dataType="string"></dxi-column>
	<dxi-column [caption]="L('CARRIER')" dataField="tr_name" dataType="string"></dxi-column>
	<dxi-column [caption]="L('REGISTER_NO')" dataField="t_carNumber" dataType="string"></dxi-column>
	<dxi-column [caption]="L('DRIVER')" dataField="t_driverName" dataType="string"></dxi-column>
	<dxi-column [caption]="L('DESTINATION')" dataField="t_destinationTypeId" dataType="string" [visible]="false">
		<dxo-lookup [dataSource]="edit.destinationType" valueExpr="id" displayExpr="description"></dxo-lookup>
	</dxi-column>
	<dxi-column [caption]="L('CAPITALIZATION_CODE')" dataField="c_code" dataType="string"></dxi-column>
	<dxi-column [caption]="L('ELIMINATION_CODE')" dataField="e_code" dataType="string"></dxi-column>
	<dxi-column [caption]="L('WASTE_CODE')" dataField="wt_code" dataType="string"></dxi-column>
	<dxi-column [caption]="L('WASTE_NAME')" dataField="wt_name" dataType="string"></dxi-column>
	<dxi-column [caption]="L('QUANTITY')" dataField="t_quantity" dataType="number"></dxi-column>
	<dxi-column [caption]="L('U')" dataField="uom" dataType="number">
		<dxo-lookup [dataSource]="proxy.wasteType.uom" valueExpr="id" displayExpr="value"></dxo-lookup>
	</dxi-column>
	<dxi-column [caption]="L('WASTE_TYPE')" dataField="wt_hazard" dataType="number">
		<dxo-lookup [dataSource]="proxy.wasteType.hazard" valueExpr="id" displayExpr="value"></dxo-lookup>
	</dxi-column>
	<dxi-column [caption]="L('PHYSICAL_STATE')" dataField="t_physicalCondition" dataType="number">
		<dxo-lookup [dataSource]="proxy.wasteType.physicalState" valueExpr="id" displayExpr="value"></dxo-lookup>
	</dxi-column>
	<dxi-column [caption]="L('INVOICE_DATE')" dataField='t_invoiceDate' dataType='date'
    	[calculateDisplayValue]='iui.formatDate' calculateSortValue='t_invoiceDate' minWidth='150'></dxi-column>
  	<dxi-column [caption]="L('INVOICE_NO')" dataField='t_invoiceNo' dataType='string' minWidth='150'>
  	</dxi-column>
	<dxi-column [caption]="L('OBSERVATIONS')" dataField="t_observations" dataType="string"></dxi-column>

	<dxo-summary>
		<dxi-total-item column="t_quantity" summaryType="sum" displayFormat="Total: {0}"
			[valueFormat]="{type:'fixedPoint', precision: 6 }"></dxi-total-item>
	</dxo-summary>

	<dxo-summary>
		<dxi-total-item column="t_quantity" summaryType="sum" displayFormat="Total: {0}"
			[valueFormat]="{type:'fixedPoint', precision: 6 }"></dxi-total-item>
	</dxo-summary>

	<div *dxTemplate="let data of 'breadcrumb'">
		<ibis-breadcrumb></ibis-breadcrumb>
	</div>
</dx-data-grid>

<ibis-edit-popup [edit]="edit" [showAdd]="true">
	<app-transport-full-edit></app-transport-full-edit>
</ibis-edit-popup>