import { iBisViewComponent } from '@dohu/ibis-common';
import { Component, Input, ViewChild } from '@angular/core';
import { ProxyService } from '../../../service/proxy.service';
import { EnumTypeEditService } from './enum-type-edit.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';

@Component({
	selector: 'app-enum-value',
	templateUrl: './enum-value.component.html'
})
export class EnumValueComponent extends iBisViewComponent {
	@Input() typeId: any;
	@ViewChild('gridEnumValue') grid: DxDataGridComponent;

	public edit: EnumTypeEditService;
	constructor(public proxy: ProxyService) {
		super(proxy.enumType, proxy.lg, proxy.iui, proxy.activatedRoute);
		this.key = 'enumValue';
	}

	onGridInit(e: any) {
		if (e.component.option('dataSource') == null) {
			e.component.option('dataSource', this.edit.getValue(this.typeId));
		}
	}

	onRowInserting(event: any) {
		event.data.typeId = this.typeId;
	}
}
