import { Component } from '@angular/core';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { ProxyService } from '../../../service/proxy.service';

@Component({
	selector: 'app-transporter-edit',
	templateUrl: './transporter-edit.component.html'
})
export class TransporterEditComponent extends iBisBaseComponent {
	constructor(public proxy: ProxyService) {
		super(proxy.transporter, proxy.lg, proxy.iui, proxy.activatedRoute);
	}
}
