<dx-form [formData]="edit.model" [showValidationSummary]="false" labelLocation="left" [colCount]="2"
	[validationGroup]="edit.validation">
	<dxi-item [label]="{text: L('NO')}" dataField="no" editorType="dxTextBox" [editorOptions]="{ }">
		<dxi-validation-rule type="required"></dxi-validation-rule>
		<dxi-validation-rule type="stringLength"  [max]="256" [message]="L('NAME_LENGTH')">
		</dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('VALID')}" dataField="isValid" editorType="dxCheckBox" [editorOptions]="{ }">
	</dxi-item>
	<dxi-item [label]="{text: L('CONTRACTOR')}" dataField="ownerId" editorType="dxSelectBox"
	[editorOptions]="{dataSource: proxy.party.ownUnit, valueExpr: 'c_id', displayExpr: 'fullName' }">
	<dxi-validation-rule type="required"></dxi-validation-rule>
</dxi-item>
	<dxi-item [label]="{text: L('CONTRACTANT')}" dataField="partyId" editorType="dxSelectBox"
		[editorOptions]="{dataSource: edit.clients, valueExpr: 'c_id', displayExpr: 'fullName' }">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [visible]="edit.partyType != undefined && edit.partyType != null" [label]="{text: L('CONTRACT_TYPE')}"
		dataField="typeId" editorType="dxSelectBox" [colSpan]="2"
		[editorOptions]="{ dataSource: edit.type, valueExpr: 'id', displayExpr: 'description' }">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('INVOICE_START_DATE')}" dataField="startDate" editorType="dxDateBox"
		[editorOptions]="{width: '100%' }">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('INVOICE_END_DATE')}" dataField="endDate" editorType="dxDateBox"
		[editorOptions]="{ width:'100%' }">
		<dxi-validation-rule type="custom" [reevaluate]="true" [validationCallback]="dateComparisonCallBackEv"
			ignoreEmptyValue="true" [message]="L('END_DATE_GREATER_VALIDATION')"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('OBJECTIVE')}" dataField="objective" editorType="dxTextBox" [colSpan]="2" [editorOptions]="{ }">
	</dxi-item>
	<dxi-item [label]="{text: L('OBSERVATIONS')}" dataField="observations" editorType="dxTextArea" [colSpan]="2"
		[editorOptions]="{ }">
	</dxi-item>
</dx-form>