import { Injectable } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import { iBisEditService, iBisLanguageService } from '@dohu/ibis-common';

@Injectable({
	providedIn: 'root'
})
export class SafetyReportWizardService extends iBisEditService {
	public participant: CustomStore;

	private pg: number;
	private rpId: string;
	constructor(private entity: iBisEntityService, lg: iBisLanguageService) {
		super(lg);
		this.pg = 0;
		this.title = 'SAFETY_REPORT';
		this.validation = 'wizardValidation';
	}

	public set page(no: number) {
		this.pg = no;
	}

	public get page(): number {
		return this.pg;
	}

	public set reportId(id: string) {
		this.rpId = id;
	}

	public get reportId(): string {
		return this.rpId;
	}

	createDefault() {
		this.page = 0;
		if (this.popup) {
			this.refreshPopupToolbar();
		}
		return {};
	}
	getById(id: string, serverUrl?: string): Promise<any> {
		throw new Error('Method not implemented.');
	}
	onRemove(id: string): Promise<void> {
		throw new Error('Method not implemented.');
	}
	onSaveEv(serverUrl?: string): Promise<any> {
		throw new Error('Method not implemented.');
	}
	reset(): void {
		this.model = this.createDefault();
	}

	private refreshPopupToolbar(): void {
		this.popup.beginUpdate();
		const tbBtns = [{
			location: 'after',
			options: {
				text: this.page !== 1 ? this.L('NEXT_STEP') : this.L('FINISH'),
				icon: this.page !== 1 ? 'fas fa-arrow-right' : 'fas fa-save',
				type: 'default',
				validationGroup: this.page === 0 ? this.validation : null,
				onClick: (e: any) => {
					if (this.page !== 1) {
						if (e.validationGroup.validate().isValid) {
							this.entity.save('SafetyReport', this.model.id, this.model).then((result) => {
								if (result && result.id) {
									this.page++;
									this.reportId = result.id;
									this.participant = this.getParticipant();
									this.refreshPopupToolbar();
								}
							});
						}
					} else {
						this.hidePopup();
					}
				}
			},
			toolbar: 'bottom',
			widget: 'dxButton'
		}];
		this.popup.option('toolbarItems', tbBtns);
		this.popup.endUpdate();
	}

	private getParticipant(): CustomStore {
		return this.entity.store(new EntityQuery('SafetyReportParticipant').eq('reportId', this.reportId), false, 'id');
	}
}
