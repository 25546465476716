import notify from 'devextreme/ui/notify';
import { Component, Input, ViewChild } from '@angular/core';
import { ProxyService } from '../../../service/proxy.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { iBisFileService, iBisViewComponent } from '@dohu/ibis-common';
import { ContractAnnexEditService } from './contract-annex-edit.service';

@Component({
	selector: 'app-contract-annex',
	templateUrl: './contract-annex.component.html'
})
export class ContractAnnexComponent extends iBisViewComponent {
	@Input() contractId: string;
	@ViewChild('gridContractAnnex') grid: DxDataGridComponent;

	public edit: ContractAnnexEditService;

	public onUploadClick: any;
	public onDownloadClick: any;
	public onFileBtnVisible: any;
	constructor(public proxy: ProxyService, private file: iBisFileService) {
		super(proxy.contractAnnex, proxy.lg, proxy.iui, proxy.activatedRoute);
		this.key = 'contractAnnex';

		this.onUploadClick = this.onUploadClickEv.bind(this);
		this.onDownloadClick = this.onDownloadClickEv.bind(this);
		this.onFileBtnVisible = this.onFileBtnVisibleEv.bind(this);
	}

	// TODO: change dataSource
	onGridInit(e: any) {
		if (e.component.option('dataSource') == null) {
			e.component.option('dataSource', this.edit.getData(this.contractId));
		}
	}

	onFileBtnVisibleEv(e: any) {
		return e.row.data.id ? true : false;
	}

	onDownloadClickEv(ev: any) {
		this.file.download(ev.row.data.id, true).then(
			() => {
				notify(this.L('FILE_DOWNLOADED', null), 'success', 2000);
			},
			() => {
				notify(this.L('DOWNLOAD_ERROR', null), 'error', 3000);
			});
	}

	onUploadClickEv(ev: any) {
		this.file.upload(ev.row.data.id).then(
			() => {
				this.grid.instance.refresh();
				notify(this.L('FILE_UPLOADED', null), 'success', 2000);
			},
			() => {
				notify(this.L('UPLOAD_ERROR', null), 'error', 3000);
			}
		).catch(error => {
			this.lg.showError(error);
		});
	}

	onAnnexRemoving(e: any) {
		// this.edit.getFileInfoSingle(e.key).then(data => {
		// 	if (data && data.id) {
		// 		this.edit.removeFile(data.id);
		// 	}
		// });
	}

	protected prepareNew(): any {
		return { contractId: this.contractId };
	}
}
