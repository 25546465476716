import { Component } from '@angular/core';
import { TaxEditService } from './tax-edit.service';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { ProxyService } from '../../../service/proxy.service';

@Component({
	selector: 'app-tax-edit',
	templateUrl: 'tax-edit.component.html'
})
export class TaxEditComponent extends iBisBaseComponent {
	public edit: TaxEditService;

	constructor(public proxy: ProxyService) {
		super(proxy.tax, proxy.lg, proxy.iui, proxy.activatedRoute);
	}

	public onWorkCenterChanged = (event: any) => {
		if (event.event) {
			this.edit.model.transportId = null;
			this.edit.finishedTransport = this.edit.getFinishedTransports(event.value);
		}
	};

	customTrDisplayExpr(info: any) {
		if (info) {
			return info.no + ' / ' + new Date(info.sendDate).getDate() + '.' +
				(new Date(info.sendDate).getMonth() + 1) + '.' +
				new Date(info.sendDate).getFullYear();
		}
		return '';
	}
}
