import { Component } from '@angular/core';
import { ProxyService } from '../../../app/service/proxy.service';
import { CollectEditComponent } from '../../../app/waste/collect/collect-edit.component';

@Component({
	selector: 'app-ecofire-collect-edit',
	templateUrl: './collect-edit.component.ecofire.html'
})
export class EcoFireCollectEditComponent extends CollectEditComponent {

	constructor(public proxy: ProxyService) {
		super(proxy);
	}
}
