import { iBisViewComponent } from '@dohu/ibis-common';
import { Component, Input, ViewChild } from '@angular/core';
import { ProxyService } from '../../../../service/proxy.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { ProfileCategoryEditService } from './profile-category-edit.service';

@Component({
	selector: 'app-profile-category',
	templateUrl: './profile-category.component.html'
})
export class ProfileCategoryComponent extends iBisViewComponent {
	@Input() profileId: any;
	@ViewChild('gridProfileCategory') protected grid: DxDataGridComponent;

	public edit: ProfileCategoryEditService;
	constructor(public proxy: ProxyService) {
		super(proxy.profileCategory, proxy.lg, proxy.iui, proxy.activatedRoute);
	}

	onInitNewRow(e: any) {
		e.data.profileId = this.profileId;
	}

	onGridInit(e: any) {
		if (e.component.option('dataSource') == null) {
			e.component.option('dataSource', this.edit.getDataByProfile(this.profileId));
		}
	}
}
