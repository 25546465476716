import { Injectable } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import { iBisEditService, iBisLanguageService } from '@dohu/ibis-common';

@Injectable({
	providedIn: 'root'
})
export class SafetyPlanningExceptEditService extends iBisEditService {
	public data: CustomStore;
	public filteredData: CustomStore;

	private date: Date;

	constructor(private entity: iBisEntityService, lg: iBisLanguageService) {
		super(lg);
		this.title = 'PLANNING_EXCEPT';
		this.validation = 'planningExceptValidation';

		this.data = this.getData();
		this.date = new Date();
		this.filteredData = this.getFilteredData();
	}

	public get PlannedDate(){
		return this.date;
	}

	public set PlannedDate(d: Date){
		this.date = d;
		this.filteredData = this.getFilteredData();
	}

	createDefault() {
		return { monthYear: new Date() };
	}

	getById(id: any): Promise<any> {
		return new Promise((resolve, reject) => {
			this.entity.getById('SafetyPlanningExcept', id).then(result => {
				if(!result){
					reject();
				} else{
					result.monthYear = new Date(result.year, result.month - 1, 1);
					resolve(result);
				}
			}, error => {
				this.lg.showError(error);
			});
		});
	}

	onRemove(id: string): Promise<void> {
		return this.entity.remove('SafetyPlanning', id);
	}

	onSaveEv(): Promise<any> {
		this.model.year = new Date(this.model.monthYear).getFullYear();
		this.model.month = new Date(this.model.monthYear).getMonth() + 1;
		return this.entity.save('SafetyPlanningExcept', this.model.id, this.model);
	}

	reset(): void {
		this.model = this.createDefault();
	}

	private getData(): CustomStore {
		const q = new EntityQuery('SafetyPlanningExcept').addOrderByDesc(['year', 'month']);
		return this.entity.store(q, false, 'id');
	}

	private getFilteredData(): CustomStore {
		const q = new EntityQuery('SafetyPlanningExcept').addOrderByDesc(['year', 'month']);
		//.eq('month', this.date.getMonth() + 1).eq('year', this.date.getFullYear()));
		return this.entity.store(q, false, 'id');
	}
}
