import { Router } from '@angular/router';
import { Component } from '@angular/core';

@Component({
	selector: 'app-single-card',
	templateUrl: './single-card.component.html',
	styleUrls: ['./single-card.component.scss']
})
export class SingleCardComponent {
	constructor(public router: Router) {
		setTimeout(() => {
			if (this.router.url === '/') {
				this.router.navigateByUrl('/login', {skipLocationChange: true});
			}
		}, 0);
	}

	checkUrlForDxCard() {
		return (this.router.url.indexOf('/confirmInvite') > -1) || (this.router.url.indexOf('/resetPassword') > -1);
	}
}
