import { Component } from '@angular/core';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { ProxyService } from '../../../service/proxy.service';
import { ContractAnnexEditService } from './contract-annex-edit.service';

@Component({
	selector: 'app-contract-annex-edit',
	templateUrl: './contract-annex-edit.component.html'
})
export class ContractAnnexEditComponent extends iBisBaseComponent {
	public edit: ContractAnnexEditService;

	constructor(public proxy: ProxyService) {
		super(proxy.contractAnnex, proxy.lg, proxy.iui, proxy.activatedRoute);
	}
}
