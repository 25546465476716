
import { TransportBase } from '../transport-base';
import { Component, ViewChild } from '@angular/core';
import { ProxyService } from '../../../service/proxy.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { TransportFinishService } from '../transport-finish/transport-finish.service';

@Component({
	templateUrl: 'transport-history.component.html',
})
export class TransportHistoryComponent extends TransportBase {
	@ViewChild('gridTransportHistory') protected grid: DxDataGridComponent;

	public edit: TransportFinishService;
	constructor(public proxy: ProxyService) {
		super(proxy, proxy.transportFinish);
		this.id = 't_id';
	}

	onTransportHistoryCellPreparedEv(event: any) {
		if (this.edit.tempStock && event.rowType === 'data') {
			const years = event.data.wt_hazard === 0 ? 3 : 1;
			const date = new Date(new Date(new Date(new Date(event.data.t_receptionDate))
				.setFullYear(new Date(new Date(event.data.t_receptionDate)).getFullYear() + years)).setHours(0, 0, 0, 0));
			if (date < new Date()) {
				event.cellElement.classList.add('dx-red');
			}
		}
	}
}
