import { Injectable } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { iBisLanguageService } from '@dohu/ibis-common';
import { DataService } from '../../../service/data.service';
import { UnitEditService } from '../unit/unit-edit.service';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import { GeographicAreaEditService } from '../../enum/geographic-area/geographic-area-edit.service';

@Injectable()
export class PartyWorkCenterEditService extends UnitEditService {

	constructor(entity: iBisEntityService, ds: DataService, protected geo: GeographicAreaEditService, lg: iBisLanguageService) {
		super(entity, ds, geo, lg);
		this.title = 'WORK_CENTER';
		this.validation = 'pwcValidation';
	}

	public getWorkCenter(parentId: string): CustomStore {
		return this.entity.store(new EntityQuery('PartyView').eq('p.parentId', parentId).eq('p.typeId', 4), false, 'p_id');
	}

	createDefault(): any {
		return { isActiv: true, isSubcontractor: false, typeId: 4 };
	}
}
