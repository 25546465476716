
import { Component, ViewChild } from '@angular/core';
import { iBisViewComponent } from '@dohu/ibis-common';
import { ProxyService } from '../../../service/proxy.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { SafetyReportEditService } from './safety-report-edit.service';

@Component({
	templateUrl: './safety-report.component.html'
})
export class SafetyReportComponent extends iBisViewComponent {
	@ViewChild('gridSafetyReport') protected grid: DxDataGridComponent;

	public edit: SafetyReportEditService;
	constructor(public proxy: ProxyService) {

		super(proxy.safetyReport, proxy.lg, proxy.iui, proxy.activatedRoute);
		this.id = 'r_id';
		this.key = 'safetyReport';
	}

	public toolbarPreparing(ev: any) {
		super.toolbarPreparing(ev, false);
		if (this.proxy.allowEdit) {
			ev.toolbarOptions.items.unshift({
				widget: 'dxButton',
				options: {
					icon: 'plus', hint: this.L('ADD_ROW'),
					onClick: () => {
						this.proxy.safetyReportWizard.showPopup(null).then(() => ev.component.refresh(),
							error => this.lg.showError(error));
					}
				},
				location: 'after'
			});
		}
	}

	onCellPrepared(rowInfo: any) {
		if (rowInfo.rowType === 'data' && rowInfo.column.dataField === 'isOk' && rowInfo.data.sr_total) {
			if (rowInfo.data.sr_totalSolved === null || rowInfo.data.sr_totalSolved === 0) {
				rowInfo.cellElement.className = rowInfo.cellElement.className + ' result-red';
			} else if (rowInfo.data.sr_total === rowInfo.data.sr_totalSolved) {
				rowInfo.cellElement.className = rowInfo.cellElement.className + ' result-green';
			} else {
				rowInfo.cellElement.className = rowInfo.cellElement.className + ' result-yellow';
			}
		}
	}

	calculateIsOk = (rowInfo: any) => rowInfo ? (rowInfo.sr_total ?
		((rowInfo.sr_totalSolved ? rowInfo.sr_totalSolved : 0) + '/' + rowInfo.sr_total) : '-') : '';
}
