import { NgModule } from '@angular/core';
import { StockModule } from '../stock/stock.module';
import { iBisCommonModule } from '@dohu/ibis-common';
import { BrowserModule } from '@angular/platform-browser';
import { DxFormModule } from 'devextreme-angular/ui/form';
import { DxPopupModule } from 'devextreme-angular/ui/popup';
import { IncinerateComponent } from './incinerate.component';
import { iBisNavigationModule } from '@dohu/ibis-navigation';
import { IncinerateEditService } from './incinerate-edit.service';
import { FrameworkModule } from '../../framework/framework.module';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { IncinerateEditComponent } from './incinerate-edit.component';

@NgModule({
	declarations: [
		IncinerateComponent,
		IncinerateEditComponent
	],
	imports: [
		StockModule,
		DxFormModule,
		DxPopupModule,
		BrowserModule,
		FrameworkModule,
		iBisCommonModule,
		iBisNavigationModule,
		DxDataGridModule
	],
	exports: [
		IncinerateComponent,
		IncinerateEditComponent
	],
	providers: [
		IncinerateEditService
	]
})
export class IncinerateModule { }
