/* eslint-disable no-bitwise */
import { Injectable } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import { DataService } from '../../service/data.service';
import { WasteTypeEditService } from '../waste-type/waste-type-edit.service';
import { PartyEditService } from '../../common/party/party-edit/party-edit.service';
import { EntityQuery, iBisEntityService, iBisEntityConfig } from '@dohu/ibis-entity';
import { iBisFileService, iBisLanguageService, iBisServerConfig } from '@dohu/ibis-common';
import { iBisAuthService, iBisSecureEditService } from '@dohu/ibis-auth';

@Injectable()
export class TransportEditService extends iBisSecureEditService {

	public data: CustomStore;
	public type: CustomStore;
	public recipient: DataSource = new DataSource<any, any>(new Array<any>());
	public capitalization: CustomStore;
	public scale: CustomStore;
	public eliminate: CustomStore;
	public result: CustomStore;
	public fullTransport: CustomStore;
	public destinationType: CustomStore;
	public dsTransportSource: [];
	public activity: { id: number; value: string }[];

	private wt: any;
	private ac: number;
	private qt: string;
	private ts: boolean;

	constructor(auth: iBisAuthService , protected entity: iBisEntityService, protected ds: DataService, public wtp: WasteTypeEditService,
		public party: PartyEditService, lg: iBisLanguageService, protected entityConfig: iBisEntityConfig, config: iBisServerConfig, protected file: iBisFileService) {
		super(auth, entity, config, lg);
		this.title = 'TRANSPORT_IN_COURSE';
		this.dsTransportSource = [];
		this.validation = 'transportValidation';
	}

	protected load(): void {
		this.scale = this.getScale();
		this.data = this.getData();
		this.fullTransport = this.getTransportFull();
		this.type = this.getType('Transport');
		this.destinationType = this.getType('Destinatie');
		this.setRecipient([2,8]);
	}

	public set tempStock(ts: boolean) {
		if (this.ts === ts) {
			return;
		}
		this.ts = ts;
		this.data = this.getData();
	}
	public get tempStock() {
		return this.ts;
	}

	public hidePopup() {
		super.hidePopup();
		this.wtp.typeId = null;
	}

	public get quantityType(): string {
		return this.qt;
	}

	public set quantityType(qtTp: string) {
		this.qt = qtTp;
		this.result = this.getResult();
	}

	public get actions(): number {
		return this.ac;
	}

	public set actions(action: number) {
		if (this.ac === action) {
			return;
		}
		this.ac = action;
		this.activity = this.getActivity();
	}

	public get wasteType(): any {
		return this.wt;
	}

	public set wasteType(type: any) {
		if (this.wt === type) {
			return;
		}
		this.wt = type;
		this.data = this.getData();
		this.fullTransport = this.getTransportFull();
	}

	public get requiredFAT(): boolean {
		return !this.model.id && this.model.recipientId && this.model.wasteTypeId
			&& this.model.transporterId && this.model.wasteType?.hazard === 1;
	}

	public getFinishedTransports(workCenterId: string = null): CustomStore {
		const q = new EntityQuery('Transport').neq('finishDate', null).addOrderBy(['no']);
		if (workCenterId) {
			q.eq('workCenterId', workCenterId);
		}
		return this.entity.store(q, false, 'id');
	}

	public enumDisplay(info: any) {
		return info ? info.code + ' - ' + (info.description || '') : '';
	}

	public setRecipient(activity: number[]): void {
		if (activity && activity.length > 0 && this.party != null) {
			const act = activity.reduce((a, b) => a + b, 0);
			this.recipient = this.party.getPartyLink([4], act);
			if (this.model.recipientId != null) {
				this.party.hasPartyLink([4], act, this.model.recipientId).then((found: boolean) => {
					if (!found) {
						this.model.recipientId = null;
					}
				});
			}
		} else {
			this.recipient = new DataSource<any, any>(new Array<any>());
			this.model.recipientId = null;
		}
	}

	public get generalDisabled(): Boolean {
		return this.model.id !== undefined && this.model.id !== null;
	}

	public downloadRaportTransport(transportId: string, type: number) {
		const url: string = this.entityConfig.server + 'GenerateReport';
		const obj = {
			reportType: 'REP_TRANSPORT',
			reportCode: type === 2 ? 'ANNEX_2' : 'ANNEX_3',
			transportId: transportId
		};
		this.file.downloadByPost(url, obj).catch(error => this.lg.showError(error));
	}

	public downloadNotice(transportId: string) {
		const url: string = this.entityConfig.server + 'GenerateReport';
		const obj = {
			reportType: 'REP_TRANSPORT',
			reportCode: 'NOTE',
			transportId: transportId
		};
		this.lock(transportId);
		this.file.downloadByPost(url, obj).catch(error => this.lg.showError(error));
	}

	public downloadNoticeBeta(transportId: string) {
		const url: string = this.entityConfig.server + 'GenerateReport';
		const obj = {
			reportType: 'REPX_TRANSPORT',
			reportCode: 'NOTE',
			transportId: transportId,
			BETA: true
		};
		this.lock(transportId);
		this.file.downloadByPost(url, obj).catch(error => this.lg.showError(error));
	}

	public downloadAnnex(transportId: string, wasteHazard: number) {
		const url: string = this.entityConfig.server + 'GenerateReport';
		const obj = {
			reportType: 'REP_TRANSPORT',
			reportCode: wasteHazard ? 'ANNEX_2' : 'ANNEX_3',
			transportId: transportId
		};
		this.lock(transportId);
		this.file.downloadByPost(url, obj).catch(error => this.lg.showError(error));
	}

	public downloadAnnexBeta(transportId: string, wasteHazard: number) {
		const url: string = this.entityConfig.server + 'GenerateReport';
		const obj = {
			reportType: 'REPX_TRANSPORT',
			reportCode: wasteHazard ? 'ANNEX_2' : 'ANNEX_3',
			transportId: transportId,
			BETA: true
		};
		this.lock(transportId);
		this.file.downloadByPost(url, obj).catch(error => this.lg.showError(error));
	}

	createDefault(): any {
		// TODO: default values from config
		const result = { sendDate: new Date(), selectedActivity: [2, 8], estimated: false };
		this.dsTransportSource = [];
		return result;
	}

	getById(id: string): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			this.entity.getById('Transport', id).then((obj) => {
				if (obj === null) {
					reject();
				} else {
					this.dsTransportSource = [];
					obj.selectedActivity = this.setTransportDataSource(obj.activities);
					this.setRecipient(obj.selectedActivity);
					this.wtp.workCenterId = obj.workCenterId;
					this.wtp.selectedId = obj.wasteTypeId;
					resolve(obj);
				}
			}, error => this.lg.showError(error));
		});
	}

	onRemove(id: string): Promise<void> {
		return this.entity.remove('Transport', id);
	}

	onSaveEv(): Promise<any> {
		if (!this.model.finishDate) {
			this.model.activities = this.getTransportVal(this.model.selectedActivity);
		}
		// Finish transport
		if (this.dsTransportSource && this.dsTransportSource.length > 0) {
			this.model.sources = this.dsTransportSource;
		}
		this.model.uom = this.wtp.selected.uom;
		return this.entity.save('Transport', this.model.id, this.model);
	}

	reset(): void {
		this.model = this.createDefault();
	}

	protected setTransportDataSource(actionNo: number) {
		if (!actionNo || !this.activity || this.activity.length === 0) {
			return [];
		}
		const result = [];
		for (let index = this.activity.length - 1; index >= 0; index--) {
			if (this.activity[index].id <= actionNo) {
				result.push(this.activity[index].id);
				actionNo -= this.activity[index].id;
			}
			if (actionNo === 0) {
				break;
			}
		}
		return result;
	}

	protected getData(): CustomStore {
		const q = new EntityQuery('TransportView').eq('t_finishDate', null).eq('t_receptionDate', null)
			.eq('t_tempStockDate', null).addOrderByDesc(['t.sendDate']);
		if (this.wt != null && this.wt !== '') {
			q.link('t.wasteTypeId', 'id', new EntityQuery('WasteType').eq('typeId', this.wt));
		}
		return this.entity.store(q, false, 't_id');
	}

	protected getTransportVal(actionsArr: number[]) {
		if (actionsArr && actionsArr.length > 0) {
			let result = 0;
			for (const act of actionsArr) {
				result += act;
			}
			return result;
		}
		return 0;
	}

	private getType(code: string): CustomStore {
		const q = new EntityQuery('EnumValue').addOrderBy(['orderIndex', 'code']).
			linkEq('typeId', 'id', 'EnumType', 'code', code);
		q.fields.push('id', 'code', 'description');
		return this.entity.store(q, false, 'id');
	}

	private getScale(): CustomStore {
		const q = new EntityQuery('Scale').addOrderByDesc(['scaleDate']);
		return this.entity.store(q, false, 'id');
	}

	private getTransportFull(): CustomStore {
		const q = new EntityQuery('TransportView').addOrderByDesc(['t.sendDate']);
		if (this.wt != null && this.wt !== '') {
			q.link('t.wasteTypeId', 'id', new EntityQuery('WasteType').eq('typeId', this.wt));
		}
		return this.entity.store(q, false, 't_id');
	}

	private getResult(): CustomStore {
		const q = new EntityQuery('TransportView').neq('t_finishDate', null).neq(this.qt, null)
			.gt(this.qt, 0).addOrderByDesc(['t.sendDate']);
		q.link('t.wasteTypeId', 'id', new EntityQuery('WasteType').eq('typeId', this.wt));
		return this.entity.store(q, false, 't_id');
	}

	private getActivity(): { id: number; value: string }[] {
		if (!this.ac) {
			return [];
		}
		const list = [
			{ 'id': 2, 'value': 'COLLECT' },
			{ 'id': 8, 'value': 'VALORIZATION' },
			{ 'id': 16, 'value': 'ELIMINATION' },
			{ 'id': 32, 'value': 'TEMP_STORAGE' },
			{ 'id': 64, 'value': 'TREATMENT' }];
		const result = [];
		this.ds.getEnum('transportActions', list).forEach((ac: { 'id': number; 'value': string }) => {
			if ((this.ac & ac.id) === ac.id) {
				result.push(ac);
			}
		});
		return result;
	}

	private lock(id: any) {
		this.entity.update('EntityTimeInfo', id, {isLocked: true});
	}
}
