import { Component } from '@angular/core';
import { ProxyService } from '../../../app/service/proxy.service';
import { CollectComponent } from '../../../app/waste/collect/collect.component';

@Component({
	templateUrl: './collect.component.ecofire.html'
})
export class EcoFireCollectComponent extends CollectComponent {

	constructor(public proxy: ProxyService) {
		super(proxy);
	}
}
