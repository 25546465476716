import { Component } from '@angular/core';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { AgencyEditService } from './agency-edit.service';
import { ProxyService } from '../../../service/proxy.service';

@Component({
	selector: 'app-agency-edit',
	templateUrl: 'agency-edit.component.html'
})
export class AgencyEditComponent extends iBisBaseComponent {
	public edit: AgencyEditService;
	private cboCounty: any;

	constructor(public proxy: ProxyService) {
		super(proxy.agency, proxy.lg, proxy.iui, proxy.activatedRoute);

		proxy.agency.onDataChanged.subscribe((data) => {
			if (data === 'county' && this.cboCounty) {
				this.proxy.resetValidation(this.cboCounty);
			}
		});
	}

	onCountyInitialized = (ev: any) => {
		this.cboCounty = ev.component;
	};

	onRegionValueChanged = (ev: any) => {
		if(ev.event){
			(this.edit as AgencyEditService).zoneId = ev.value;
		}
	};
}
