import { Component } from '@angular/core';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { ProxyService } from '../../../service/proxy.service';
import { PartyWorkCenterEditService } from './party-work-center-edit.service';

@Component({
	selector: 'app-party-work-center-edit',
	templateUrl: 'party-work-center-edit.component.html'
})
export class PartyWorkCenterEditComponent extends iBisBaseComponent {
	public edit: PartyWorkCenterEditService;
	private cboLocality: any;

	constructor(public proxy: ProxyService) {
		super(proxy.workCenter, proxy.lg, proxy.iui, proxy.activatedRoute);

		proxy.workCenter.onDataChanged.subscribe((data) => {
			if (data === 'locality' && this.cboLocality) {
				this.proxy.resetValidation(this.cboLocality);
			}
		});
	}

	onLocalityInitialized = (ev: any) => {
		this.cboLocality = ev.component;
	};

	onCountyValueChanged = (ev: any) => {
		this.edit.countyId = ev.value;
	};
}
