import { Injectable } from '@angular/core';
import { iBisEditService } from '@dohu/ibis-common';
import CustomStore from 'devextreme/data/custom_store';
import { iBisLanguageService } from '@dohu/ibis-common';
import { DataService } from '../../service/data.service';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import DataSource from 'devextreme/data/data_source';

@Injectable()
export class WasteTypeEditService extends iBisEditService {
	public data: CustomStore;
	public code: CustomStore;
	public allCode: DataSource;
	public hazardCode: CustomStore;
	public party: CustomStore;
	public parent: CustomStore;
	public otherUom: { id: string; wasteTypeId: string; uom: number; factor: number }[];
	public otherUomCode: { id: number; value: string }[];
	public material: CustomStore = null;
	public category: CustomStore = null;
	public eeeCategory: CustomStore;
	public storageType: CustomStore;
	public materialType: CustomStore;
	public categoryType: CustomStore;
	public eliminationCode: CustomStore;
	public capitalizationCode: CustomStore;

	public uom: { 'id': number; 'value': string }[];
	public type: { 'id': number; 'value': string }[];
	public hazard: { 'id': number; 'value': string }[];
	public physicalState: { 'id': number; 'value': string }[];

	public defaultUom: string;

	public selected: {
		name: string; code: string; uom: number; baseUom: number;
		physicalState: number; hazard: number; hazardName: string;
		storageTypeId: string; requireApproval: boolean;
		capitalizationId: string; eliminationId: string;
	};

	private wcId: string;
	private tpId: string;
	private cl = '';

	constructor(private entity: iBisEntityService, private ds: DataService, lg: iBisLanguageService) {
		super(lg);
		this.title = 'WASTE_TYPE';
		this.validation = 'wtValidation';
		this.data = this.getData();
		this.allCode = new DataSource({ store: this.getAllCode(), paginate: true, pageSize: 15 });
		this.party = this.getParty();
		this.parent = this.getParent();
		this.materialType = this.getMaterialType();
		this.categoryType = this.getCategoryType();
		this.storageType = this.ds.getEnumValue('STORAGE_TYPE');
		this.eliminationCode = this.ds.getEnumValue('ELIMINATION');
		this.capitalizationCode = this.ds.getEnumValue('CAPITALIZATION');

		this.getOtherUom(null);
		this.uom = this.getUom();
		this.type = this.getType();
		this.hazard = this.getHazard();
		this.eeeCategory = this.getEeeCategory();
		this.physicalState = this.getPhysicalState();

		this.defaultUom = lg.format(this.ds.static.defaultUom ?? 'TONES');
		this.resetSelected();
	}

	public set typeId(id: any) {
		this.tpId = id;
		this.code = this.getCodes();
		this.onDataChanged.next('code');
	}

	public set workCenterId(id: any) {
		this.wcId = id;
		if (id) {
			this.code = this.getCodes();
			this.hazardCode = this.getHazardCode();
			this.onDataChanged.next('code');
		} else {
			this.resetSelected();
		}
	}

	public set selectedId(id: any) {
		if (!id) {
			this.resetSelected();
			this.onDataChanged.next('selected');
			return;
		}

		this.getOtherUom(id);

		if (this.wcId) {
			this.getSelecteForWC(id);
		} else {
			this.entity.getFieldsById('WasteType', id, ['name', 'hazard', 'uom', 'categoryId']).then((result: any) => {
				this.resetSelected();
				if (result) {
					this.selected.name = result.name;
					this.selected.baseUom = result.uom;
					this.selected.uom = result.uom;
					this.selected.hazard = result.hazard;
					this.selected.hazardName = this.hazard.find(x => x.id === this.selected.hazard)?.value;
					this.setCategoryLabel(result.categoryId);
				}
				this.onDataChanged.next('selected');
			});
		}
	}

	public get categoryLabel(): string {
		return this.cl;
	}

	public removeUom(id: string) {
		this.entity.remove('WasteTypeUom', id);
	}

	public get selectedUomName(): string {
		return this.uom.find(x => x.id === this.selected.uom)?.value;
	}

	public getUomName(id: number) {
		return this.uom.find(x => x.id === id)?.value;
	}

	public storageDisplay(info: any) {
		return info ? info.code + ' - ' + (info.description || '') : '';
	}

	createDefault(): any {
		this.getOtherUom(null);
		return { disabledSource: false };
	}

	getById(id: string): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			this.entity.getById('WasteType', id).then((obj) => {
				if (obj) {
					this.getOtherUom(obj.id);
					resolve(obj);
				} else {
					reject();
				}
			}, error => this.lg.showError(error));
		});
	}

	onRemove(id: string): Promise<void> {
		return this.entity.remove('WasteType', id);
	}

	onSaveEv(): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			this.entity.save('WasteType', this.model.id, this.model).then(result => {
				if (result && this.otherUom) {
					this.otherUom.forEach(uom => {
						uom.wasteTypeId = this.model.id ?? result.id;
						this.entity.save('WasteTypeUom', uom.id, uom);
					});
				}
				resolve(result);
			}, error => reject(error));
		});
	}

	reset(): void {
		this.model = this.createDefault();
	}

	private resetSelected() {
		this.selected = {
			name: '', uom: null, physicalState: 0, code: '', baseUom: null, requireApproval: false,
			hazard: 0, hazardName: '', storageTypeId: '', capitalizationId: '', eliminationId: ''
		};
	}

	private getSelecteForWC(id: any) {
		const wtpQ = new EntityQuery('WasteTypePartyView', false, true).eq('wtp.wasteTypeId', id).eq('wtp.isActiv', true);
		const qq = wtpQ.clone().linkEq('wtp.senderId', 'parentId', 'Party', 'id', this.wcId);
		wtpQ.eq('wtp.workCenterId', this.wcId);
		wtpQ.union(qq);

		this.entity.load(wtpQ).then(result => {
			this.resetSelected();
			if (result && result.length > 0) {
				this.selected.name = result[0].wt_name;
				this.selected.code = result[0].wt_code;
				this.selected.baseUom = result[0].wt_uom;
				this.selected.uom = result[0].uom;
				this.selected.hazard = result[0].wt_hazard;
				this.selected.physicalState = result[0].wt_physicalCondition;
				this.selected.capitalizationId = result[0].wtp_capitalizationId;
				this.selected.eliminationId = result[0].wtp_eliminationId;
				this.selected.storageTypeId = result[0].wtp_storageTypeId;
				this.selected.requireApproval = result[0].wtp_requireApproval;
				this.selected.hazardName = this.hazard.find(x => x.id === this.selected.hazard)?.value;
				this.material = this.getWasteMaterial(id);
				this.category = this.getWasteCategory(id);
				this.setCategoryLabel(result[0].wt_categoryId);
			}
			this.onDataChanged.next('selected');
		});
	}

	private getOtherUom(typeId: string) {
		if (!typeId) {
			this.otherUom = [];
			this.otherUomCode = [];
			return;
		}
		const q = new EntityQuery('WasteTypeUom').eq('wasteTypeId', typeId).addOrderBy(['uom']);
		this.entity.store(q).load().then((result: any) => {
			this.otherUomCode = [];
			result.forEach((u: any) => {
				this.otherUomCode.push({ id: u.uom, value: this.uom.find(x => x.id === u.uom)?.value ?? '' });
			});
			this.otherUom = result;
		}, error => this.lg.format(error));
	}

	private getMaterialType(): CustomStore {
		const q = new EntityQuery('EnumType').push('id', 'code').addOrderBy(['code']);
		return this.entity.store(q);
	}

	private getCategoryType(): CustomStore {
		const q = new EntityQuery('EnumType').push('id', 'code').addOrderBy(['code']);
		return this.entity.store(q);
	}

	private getCodes(): CustomStore {
		const q = new EntityQuery('WasteType', false, true).push('id', 'code').addOrderBy(['code']).
			link('id', 'wasteTypeId', new EntityQuery('WasteTypeParty').eq('isActiv', true));
		if (this.tpId) {
			q.eq('typeId', this.tpId);
		}
		const qq = q.clone();
		qq.links[0].relatedQuery.linkEq('senderId', 'parentId', 'Party', 'id', this.wcId);

		q.links[0].relatedQuery.eq('workCenterId', this.wcId);
		q.union(qq);

		return this.entity.store(q);
	}

	private getAllCode(): CustomStore {
		const q = new EntityQuery('WasteType').push('id', 'code').nlike('code', '%00').addOrderBy(['code']);
		return this.entity.store(q);
	}

	private getHazardCode(): CustomStore {
		const q = new EntityQuery('WasteType', false, true).eq('hazard', 1).push('id', 'code').addOrderBy(['code']).
			link('id', 'wasteTypeId', new EntityQuery('WasteTypeParty').eq('isActiv', true));
		q.links[0].relatedQuery.eq('workCenterId', this.wcId);

		return this.entity.store(q);
	}

	private setCategoryLabel(categoryId: string) {
		this.entity.single(new EntityQuery('EnumType').eq('id', categoryId)).then((data: any) => {
			this.cl = data ? data.description : '';
		});
	}

	private getUom(): { 'id': number; 'value': string }[] {
		const list = [
			{ 'id': 1, 'value': 'TONES' },
			{ 'id': 2, 'value': 'MC' },
			{ 'id': 3, 'value': 'KG' },
			{ 'id': 4, 'value': 'PCS' }
		];
		return this.ds.getEnum('uom', list);
	}

	private getPhysicalState(): { 'id': number; 'value': string }[] {
		const list = [
			{ 'id': 0, 'value': 'SOLID' },
			{ 'id': 1, 'value': 'LIQUID' },
			{ 'id': 2, 'value': 'SEMISOLID' },
			{ 'id': 3, 'value': 'GAS' }
		];
		return this.ds.getEnum('physicalState', list);
	}

	private getHazard(): { 'id': number; 'value': string }[] {
		const list = [{ 'id': 0, 'value': 'HARMLESS' }, { 'id': 1, 'value': 'DANGEROUS' }];
		return this.ds.getEnum('hazard', list);
	}

	private getType(): { 'id': number; 'value': string }[] {
		const list = [{ 'id': 0, 'value': 'GENERAL' }, { 'id': 1, 'value': 'PACKING' }, { 'id': 2, 'value': 'EEE' }];
		return this.ds.getEnum('wasteType', list);
	}

	private getParent(): CustomStore {
		const q = new EntityQuery('WasteType', true).like('code', '%00').like('code', '%00 00').addOrderBy(['code']);
		q.fields.push('id', 'code');
		return this.entity.store(q, false, 'id');
	}

	private getParty(): CustomStore {
		return this.entity.store(new EntityQuery('WasteTypePartyView').eq('wtp.senderId', null).addOrderBy(['wt.code']), false, 'wtp_id');
	}

	private getData(): CustomStore {
		return this.entity.store(new EntityQuery('WasteType').addOrderBy(['code']), false, 'id', null, (item) => {
			if (item.code.endsWith('00 00')) {
				item.parentId = 'null';
			}
			item.hasItems = item.code.endsWith('00');
		});
	}

	private getWasteMaterial(wasteTypeId: string): CustomStore {
		const q = new EntityQuery('EnumValue').linkEq('typeId', 'materialId', 'WasteType', 'id', wasteTypeId).
			push('id', 'code', 'description').addOrderBy(['orderIndex', 'code']);
		return this.entity.store(q, false, 'id');
	}

	private getWasteCategory(wasteTypeId: string): CustomStore {
		const q = new EntityQuery('EnumValue').linkEq('typeId', 'categoryId', 'WasteType', 'id', wasteTypeId).
			push('id', 'code', 'description').addOrderBy(['orderIndex', 'code']);
		return this.entity.store(q, false, 'id');
	}

	private getEeeCategory(): CustomStore {
		const q = new EntityQuery('EnumValue').addOrderBy(['orderIndex', 'code']).
			linkEq('typeId', 'id', 'EnumType', 'code', 'EEE');
		q.fields.push('id', 'code', 'description');
		return this.entity.store(q);
	}
}

export class UOMHelper {
	public options: any;
	private bt: any;

	constructor(public edit: WasteTypeEditService) {
		edit.onDataChanged.subscribe((data) => {
			if (data === 'selected') {
				this.update(this.edit.selectedUomName);
			}
		});
		this.options = {
			text: '', stylingMode: 'text',
			elementAttr: {
				class: 'uom'
			},
			onClick: (event: any) => {
				const i = this.edit.otherUomCode.findIndex(u => u.id === this.edit.selected.uom);
				if (i === this.edit.otherUomCode.length - 1) {
					this.update(this.edit.uom.find(u => u.id === this.edit.selected.baseUom)?.value, this.edit.selected.baseUom);
					return;
				}
				const uom = this.edit.otherUomCode[i < 0 ? 0 : i + 1];
				this.update(uom.value, uom.id);
			},
			onInitialized: (event: any) => {
				this.bt = event.component;
			}
		};
	}

	public update(uomName: string, id: number = null) {
		if (id) {
			this.edit.selected.uom = id;
		}
		this.options.text = uomName;
		if (this.bt) {
			this.bt.option('text', uomName);
			this.bt.repaint();
		}
	}
}
