import { Component, ViewChild } from '@angular/core';
import { ProxyService } from '../../service/proxy.service';
import { WasteTypeEditService } from './waste-type-edit.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { BreadcrumbViewComponent } from '../../framework/breadcrumb-view.component';

@Component({
	templateUrl: 'waste-type.component.html'
})
export class WasteTypeComponent extends BreadcrumbViewComponent {
	@ViewChild('gridWasteType') protected grid: DxDataGridComponent;
	public edit: WasteTypeEditService;

	constructor(public proxy: ProxyService) {
		super(proxy.wasteType, proxy.lg, proxy.iui, proxy.activatedRoute);
		this.key = 'wasteType';
	}
}
