import { Params } from '@angular/router';
import notify from 'devextreme/ui/notify';
import { custom } from 'devextreme/ui/dialog';
import { ProxyService } from '../../service/proxy.service';
import { TransportEditService } from './transport-edit.service';
import { BreadcrumbViewComponent } from '../../framework/breadcrumb-view.component';

export abstract class TransportBase extends BreadcrumbViewComponent {
	public onNoticeClick: any;
	public onAnnexClick: any;

	constructor(public proxy: ProxyService, public edit: TransportEditService) {
		super(edit, proxy.lg, proxy.iui, proxy.activatedRoute);

		this.onNoticeClick = this.onNotice.bind(this);
		this.onAnnexClick = this.onAnnex.bind(this);
	}

	protected queryParams(pr: Params): void {
		super.queryParams(pr);
		this.key = pr['key'];
		// set tempStock first because we don't call getData method after setting it
		this.edit.tempStock = pr['tempStock'] === 'true';
		this.edit.wasteType = pr['wasteType'];
		this.edit.actions = pr['transportActions'];
	}

	protected onAnnex(e: any) {
		const versionDialog = custom({
			title: 'Versiune de descărcare',
			showTitle: false,
			message: 'Selectați extensia dorită',
			buttons: [{
				text: 'Document',
				onClick: (ev: any) => {
					this.edit.downloadAnnex(e.row.data.t_id, e.row.data.wt_hazard);
					e.row.data.ti_isLocked = true;
				}
			}, {
				text: 'PDF',
				onClick: (ev: any) => {
					this.edit.downloadAnnexBeta(e.row.data.t_id, e.row.data.wt_hazard);
					e.row.data.ti_isLocked = true;
				}
			}]
		});
		versionDialog.show();
	}

	protected onNotice(e: any) {
		if (e.row.data.t_ladingBill) {
			const versionDialog = custom({
				title: 'Versiune de descărcare',
				showTitle: false,

				message: 'Selectați extensia dorită',
				buttons: [{
					text: 'Document',
					onClick: (ev: any) => {
						this.edit.downloadNotice(e.row.data.t_id);
						e.row.data.ti_isLocked = true;
					}
				}, {
					text: 'PDF',
					onClick: (ev: any) => {
						this.edit.downloadNoticeBeta(e.row.data.t_id);
						e.row.data.ti_isLocked = true;
					}
				}]
			});
			versionDialog.show();
		} else {
			notify(this.L('NO_LADINGBILL_FOR_DOWNLOAD_NOTE'), 'info', 3000);
		}
	}

	protected onEdit(e: any) {
		this.proxy.wasteType.typeId = this.edit.wasteType;
		if (this.allowEdit(e)) {
			super.onEdit(e);
		}
	}

	protected onDelete(e: any) {
		if (this.allowEdit(e)) {
			super.onDelete(e);
		}
	}

	protected prepareNew(): any {
		this.proxy.wasteType.typeId = this.edit.wasteType;
		return super.prepareNew();
	}

	private allowEdit(e: any): boolean {
		return true;
		// const allow = this.proxy.auth.hasRole('responsabil') || e.row.data.ti_isLocked !== true;
		// if (!allow) {
		// 	alert('Transportul poate fi modificat doar de responsabil', 'Entitate blocata');
		// }
		// return allow;
	}
}
