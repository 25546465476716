<dx-form [colCount]="2" [formData]="edit.model" [showValidationSummary]="false" [validationGroup]="edit.validation"
	labelLocation="left">

	<dxi-item [colSpan]="2" [label]="{text: L('NAME')}" dataField="name" editorType="dxTextBox" [editorOptions]="{}">
		<dxi-validation-rule type="required"></dxi-validation-rule>
		<dxi-validation-rule type="stringLength"  [max]="256" [message]="L('NAME_LENGTH')">
		</dxi-validation-rule>
	</dxi-item>

	<dxi-item [label]="{text: L('ZONE')}" dataField="zoneId" editorType="dxSelectBox" [editorOptions]="{ dataSource: edit.zone, valueExpr: 'id', displayExpr: 'name', 
	onValueChanged: onRegionValueChanged, searchEnabled: true }">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('COUNTY')}" dataField="countyId" editorType="dxSelectBox"
		[editorOptions]="{ dataSource: edit.counties, valueExpr: 'id', displayExpr: 'name',
		onInitialized: onCountyInitialized, disabled: !edit.model.zoneId, showClearButton: true}">
	</dxi-item>

	<dxi-item [label]="{text: L('PHONE')}" dataField="phone" editorType="dxTextBox" [editorOptions]="{}">
	</dxi-item>
	<dxi-item [label]="{text: L('FAX')}" dataField="fax" editorType="dxTextBox" [editorOptions]="{}">
	</dxi-item>
	<dxi-item [label]="{text: L('EMAIL_ADDRESS')}" dataField="email" editorType="dxTextBox" [editorOptions]="{}">
	</dxi-item>
	<dxi-item [label]="{text: L('WEBSITE')}" dataField="website" editorType="dxTextBox" [editorOptions]="{}">
	</dxi-item>
	<dxi-item [colSpan]="2" [label]="{text: L('ADDRESS')}" dataField="address" editorType="dxTextBox"
		[editorOptions]="{}">
	</dxi-item>
</dx-form>