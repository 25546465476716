<dx-data-grid #gridTax class="gridContainer box-shadow" [dataSource]='edit.data' [height]="ui.fullHeight"
    [remoteOperations]="true" (onToolbarPreparing)="toolbarPreparing($event, proxy.allowEdit)">

    <dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" [storageKey]='key'>
    </dxo-state-storing>
    <dxo-scrolling [mode]="ui.scrollingMode" [showScrollbar]="ui.showScrollbar" [useNative]="!ui.isWindows">
    </dxo-scrolling>

    <dxi-column type="buttons" fixedPosition="left" [minWidth]="proxy.actionsWidth" [width]="proxy.actionsWidth" 
        [fixed]="true" [allowResizing]="false" [showInColumnChooser]="false" [visible]="proxy.showActions">
        <dxi-button [hint]="L('EDIT')" icon="edit" [onClick]="onEditClick" [visible]="proxy.allowEdit"></dxi-button>
        <dxi-button [hint]="L('DELETE')" icon="trash" [onClick]="onDeleteClick" [visible]="proxy.allowDelete"></dxi-button>
    </dxi-column>

    <dxi-column [caption]="L('NO')" dataField="t_no" dataType="string">
    </dxi-column>
    <dxi-column [caption]="L('DATE')" dataField="t_sendDate" dataType="date" calculateSortValue="t_sendDate"
        [calculateDisplayValue]="iui.formatDate">
    </dxi-column>
    <dxi-column [caption]="L('ENT_WORKC')" dataField="fullName" dataType="string">
    </dxi-column>
    <dxi-column [caption]="L('AGENCY')" dataField="a_name" dataType="string">
    </dxi-column>
    <dxi-column [caption]="L('VALUE')" dataField="t_value" dataType="number">
    </dxi-column>
    <dxi-column [caption]="L('PAYMENT_DATE')" dataField="t_payDate" dataType="date" calculateSortValue="t_payDate"
        [calculateDisplayValue]="iui.formatDate">
    </dxi-column>
    <dxi-column [caption]="L('TRANSPORT')" dataField="transportNo" dataType="string"></dxi-column>
    <dxi-column [caption]="L('TRANSPORT_INCLUDED')" dataField="t_costIncluded" dataType="boolean"></dxi-column>
    <dxi-column [caption]="L('OBSERVATIONS')" dataField="t_observation" dataType="string"></dxi-column>

    <div *dxTemplate="let data of 'breadcrumb'">
		<ibis-breadcrumb></ibis-breadcrumb>
	</div>
</dx-data-grid>

<ibis-edit-popup [edit]="edit" [showAdd]="true">
    <app-tax-edit></app-tax-edit>
</ibis-edit-popup>