<dx-form #partyForm [formData]="edit.model" [showValidationSummary]="false" labelLocation="left"
	[validationGroup]="edit.validation" [colCount]="2">
	<dxi-item [label]="{text: L('NO')}" dataField="no" editorType="dxTextBox" [editorOptions]="{ }">
		<dxi-validation-rule type="required"></dxi-validation-rule>
		<dxi-validation-rule type="stringLength"  [max]="256" [message]="L('NAME_LENGTH')">
		</dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('VALID')}" dataField="isValid" editorType="dxCheckBox" [editorOptions]="{ }">
	</dxi-item>
	<dxi-item [label]="{text: L('NAME')}" dataField="name" editorType="dxTextBox" [colSpan]="2" [editorOptions]="{ }">
		<dxi-validation-rule type="required"></dxi-validation-rule>
		<dxi-validation-rule type="stringLength" [max]="256" [message]="L('NAME_LENGTH')">
		</dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('CONTRACTOR')}" dataField="fromId" editorType="dxSelectBox"
		[editorOptions]="{ dataSource: proxy.party.ownEntity, valueExpr: 'c_id', displayExpr: 'fullName', searchEnabled: true }">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('CONTRACTANT')}" dataField="toId" editorType="dxSelectBox"
		[editorOptions]="{ dataSource: proxy.party.allEntity, valueExpr: 'c_id', displayExpr: 'fullName', searchEnabled: true }">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('START_DATE')}" dataField="startDate" editorType="dxDateBox"
		[editorOptions]="{ width:'100%' }" calculateDisplayValue="iui.formatDateTime">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('END_DATE')}" dataField="endDate" editorType="dxDateBox"
		[editorOptions]="{ width:'100%' }" calculateDisplayValue="iui.formatDateTime">
		<dxi-validation-rule type="compare" comparisonType=">=" [comparisonTarget]="dateComparison"
			ignoreEmptyValue="true" [message]="L('END_DATE_GREATER_VALIDATION')"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('OBJECTIVE')}" dataField="objective" editorType="dxTextBox"
		[colSpan]='2' [editorOptions]="{ }">
	</dxi-item>
</dx-form>