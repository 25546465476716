<ul class="wizard" style="margin: 5px;">
	<li [class.active]="edit.page == 0" style="width:20%">
		<span>{{L('INFO')}}</span>
	</li>
	<li [class.active]="edit.page == 1" style="width:20%">
		<span>{{L('ADD_ENTITY')}}</span>
	</li>
	<li [class.active]="edit.page == 2" style="width:20%">
		<span>{{L('ADD_WORKSTATION')}}</span>
	</li>
	<li [class.active]="edit.page == 3" style="width:20%">
		<span>{{L('ASSOC_WASTE_CODE')}}</span>
	</li>
	<li [class.active]="edit.page == 4" style="width:20%">
		<span>{{L('FHINISHING')}}</span>
	</li>
</ul>
<br/>
<dx-scroll-view height="100%" width="100%">
	<dx-multi-view #multiview [dataSource]="multiViewItems" [focusStateEnabled]="false" [swipeEnabled]="false"
		itemTemplate="items">
		<div *dxTemplate="let item of 'items'">
			<div style="margin:25px;">
				<div [hidden]='edit.page != 0'style="text-align: center;">
					<p>{{L('HELLO')}}</p>
					<p>{{L('SEV_CONF_APP')}}</p>
				</div>
				<div [hidden]='edit.page > 2 || edit.page == 0'>
					<app-unit-edit [isWizard]="true" (initForm)="initFormUnit($event)"></app-unit-edit>
				</div>
				<div [hidden]='edit.page != 3'>
					<app-unit-waste-edit [isWizard]="true"></app-unit-waste-edit>
				</div>
				<div [hidden]='edit.page != 4'>
					<p>{{L('ADD_ENTITIES_LONG_TEXT')}}</p>
					<p>{{L('MORE_DETAILS')}} <a target="_blank" href="{{manualUrl}}">{{L('USER_MANUAL')}}</a>
						{{L('OF_APP')}}
					</p>
				</div>
			</div>
		</div>
	</dx-multi-view>
</dx-scroll-view>