import { Injectable } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import { PartyEditService } from '../party-edit/party-edit.service';
import { iBisEditService, iBisLanguageService } from '@dohu/ibis-common';
import { Guid } from 'guid-typescript';

@Injectable()
export class TransporterEditService extends iBisEditService {
	public data: CustomStore;
	public name: DataSource;

	public car: DataSource;
	public driver: DataSource;
	public carNo: CustomStore;
	public driverName: CustomStore;

	constructor(private entity: iBisEntityService, party: PartyEditService, lg: iBisLanguageService) {
		super(lg);
		this.title = 'TRANSPORTER';
		this.validation = 'transporterValidation';

		this.data = this.getData();
		this.name = party.getPartyLink([3], 4);

		this.car = new DataSource({ store: this.getCar(null), paginate: true, pageSize: 10 });
		this.driver = new DataSource({ store: this.getDriver(null), paginate: true, pageSize: 10 });
	}

	public set selectedId(id: any) {
		this.car = this.car = new DataSource({ store: this.getCar(id), paginate: true, pageSize: 10 });
		this.driver = new DataSource({ store: this.getDriver(id), paginate: true, pageSize: 10 });
		this.carNo = this.getCarNo(id);
		this.driverName = this.getDriverName(id);
		this.onDataChanged.next('selected');
	}

	public getCar(partyId: string) {
		return this.entity.store(new EntityQuery('Car').eq('transporterId', partyId).addOrderBy(['registerNo']));
	}

	public getDriver(partyId: string) {
		return this.entity.store(new EntityQuery('Driver').eq('transporterId', partyId).addOrderBy(['lastName', 'firstName']));
	}

	public displayDriverName(rowInfo: any): string {
		if (rowInfo) {
			return (rowInfo.lastName ? rowInfo.lastName : '') + ' ' + (rowInfo.firstName ? rowInfo.firstName : '');
		}
		return '';
	}

	public onCustomItemDriver = (ev: any, transporterId: any) => {
		if (ev.text === null || ev.text.trim() === '') {
			return;
		}
		ev.text = ev.text.trim();
		const lastName = ev.text.substring(0, ev.text.indexOf(' '));
		const firstName = ev.text.substring(ev.text.indexOf(' ') + 1);
		ev.customItem = { id: Guid.create().toString(), firstName: firstName, lastName: lastName, transporterId: transporterId };
		this.driver.store().insert(ev.customItem);
		this.driver.reload();

		return ev.customItem.id;
	};

	public onCustomItemCar = (ev: any, transporterId: any) => {
		if (ev.text === null || ev.text.trim() === '') {
			return;
		}
		ev.text = ev.text.trim();
		ev.customItem = { id: Guid.create().toString(), registerNo: ev.text, transporterId: transporterId };
		this.car.store().insert(ev.customItem);
		this.car.reload();

		return ev.customItem.id;
	};

	createDefault(): any {
		return {};
	}

	getById(data: any): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			if (!data.p_id) {
				reject();
			}
			this.entity.getById('TransporterView', data.p_id, 'p.id').then((obj) => {
				if (!obj) {
					reject();
				} else {
					resolve(obj);
				}
			}, error => {
				this.lg.showError(error);
			});
		});
	}

	onRemove(id: string): Promise<void> {
		return this.entity.remove('TransporterView', id);
	}

	onSaveEv(): Promise<any> {
		return this.entity.save('TransporterView', this.model.id, this.model);
	}

	reset(): void {
		this.model = this.createDefault();
	}

	private getCarNo(partyId: string) {
		const q = new EntityQuery('Car').eq('transporterId', partyId).addOrderBy(['registerNo']);
		q.fields.push('id', 'registerNo');
		return this.entity.store(q);
	}

	private getDriverName(partyId: string) {
		const q = new EntityQuery('Driver').eq('transporterId', partyId).addOrderBy(['lastName', 'firstName']);
		q.fields.push('id', 'lastName', 'firstName');
		return this.entity.store(q);
	}

	private getData(): CustomStore {
		const q = new EntityQuery('TransporterView')
			.link('p.id', 'id', new EntityQuery('Party').like('activitiesType', '%Transport%')).addOrderBy(['p.name']);
		return this.entity.store(q, false, 'p_id');
	}
}
