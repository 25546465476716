import { Component } from '@angular/core';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { ProxyService } from '../../../service/proxy.service';
import { SafetyReportWizardService } from './safety-report-wizard.service';

@Component({
	selector: 'app-safety-report-wizard',
	templateUrl: './safety-report-wizard.component.html'
})
export class SafetyReportWizardComponent extends iBisBaseComponent {

	public edit: SafetyReportWizardService;
	constructor(public proxy: ProxyService) {
		super(proxy.safetyReportWizard, proxy.lg, proxy.iui, proxy.activatedRoute);
	}

	onNewParticipant(e: any) {
		e.data.isLeader = false;
		e.data.reportId = this.edit.reportId;
	}
}
