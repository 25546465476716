import { iBisViewComponent } from '@dohu/ibis-common';
import { Component, Input, ViewChild } from '@angular/core';
import { ProxyService } from '../../../service/proxy.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { PartyWorkCenterEditService } from './party-work-center-edit.service';

@Component({
	selector: 'app-party-work-center',
	templateUrl: 'party-work-center.component.html'
})
export class PartyWorkCenterComponent extends iBisViewComponent {
	@Input() partyId: any;
	@ViewChild('gridWC') grid: DxDataGridComponent;

	public edit: PartyWorkCenterEditService;
	constructor(public proxy: ProxyService) {
		super(proxy.workCenter, proxy.lg, proxy.iui, proxy.activatedRoute);
		this.id = 'p_id';
		this.key = 'partyWorkCenter';
	}

	onGridInit(e: any) {
		if (e.component.option('dataSource') == null) {
			e.component.option('dataSource', this.edit.getWorkCenter(this.partyId));
		}
	}

	protected prepareNew(): any {
		const result = this.edit.createDefault();
		result.parentId = this.partyId;
		return result;
	}
}
