import { NgModule } from '@angular/core';
import { TaxComponent } from './tax/tax.component';
import { iBisCommonModule } from '@dohu/ibis-common';
import { ControlComponent } from './control.component';
import { TaxEditService } from './tax/tax-edit.service';
import { BrowserModule } from '@angular/platform-browser';
import { DxFormModule } from 'devextreme-angular/ui/form';
import { ControlEditService } from './control-edit.service';
import { DxPopupModule } from 'devextreme-angular/ui/popup';
import { TaxEditComponent } from './tax/tax-edit.component';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxoLookupModule } from 'devextreme-angular/ui/nested';
import { ControlEditComponent } from './control-edit.component';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { DxTabPanelModule } from 'devextreme-angular/ui/tab-panel';
import { FrameworkModule } from '../../framework/framework.module';
import { ControlMeasureComponent } from './control-measure/control-mesure.component';
import { ControlSanctionComponent } from './control-sanction/control-sanction.component';
import { ControlMeasureEditService } from './control-measure/control-measure-edit.service';
import { ControlSanctionEditService } from './control-sanction/control-sanction-edit.service';
import { ControlMeasureEditComponent } from './control-measure/control-measure-edit.component';
import { ControlSanctionEditComponent } from './control-sanction/control-sanction-edit.component';
import { iBisNavigationModule } from '@dohu/ibis-navigation';

@NgModule({
	declarations: [
		TaxComponent,
		TaxEditComponent,
		ControlComponent,
		ControlEditComponent,
		ControlMeasureComponent,
		ControlSanctionComponent,
		ControlMeasureEditComponent,
		ControlSanctionEditComponent
	],
	imports: [
		DxFormModule,
		BrowserModule,
		DxPopupModule,
		DxButtonModule,
		FrameworkModule,
		DxDataGridModule,
		DxTabPanelModule,
		iBisCommonModule,
		iBisNavigationModule,
		DxoLookupModule
	],
	exports: [
		TaxComponent,
		ControlComponent,
		TaxEditComponent,
		ControlEditComponent,
		ControlMeasureEditComponent,
		ControlSanctionEditComponent
	],
	providers: [
		TaxEditService,
		ControlEditService,
		ControlMeasureEditService,
		ControlSanctionEditService
	]
})
export class ControlModule { }
