<dx-tab-panel>
    <dxi-item [title]="L('INCONSITENT')">
        <dx-form [formData]="edit.model" [validationGroup]="edit.validation" [colCount]="2">
            <dxi-item [label]="{text: L('REPORT') }" dataField="reportId" editorType="dxSelectBox"
                [editorOptions]="{ dataSource: proxy.safetyReport.data, valueExpr: 'r_id', displayExpr: 'r_refNumber', readOnly: true }">
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-item>
            <dxi-item [label]="{text: L('CATEGORY') }" dataField="categoryId" editorType="dxSelectBox"
                [editorOptions]="{ dataSource: edit.category, valueExpr: 'id', displayExpr: 'description', readOnly: true }">
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-item>
            <dxi-item [label]='{text: L("TYPE") }' dataField='typeId' editorType='dxSelectBox'
                [editorOptions]='{ dataSource: edit.type, valueExpr: "id", displayExpr: "description" }'>
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-item>
            <dxi-item [label]='{text: L("REASON") }' dataField='reasonId' editorType='dxSelectBox'
                [editorOptions]='{ dataSource: edit.reason, valueExpr: "id", displayExpr: "description" }'>
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-item>
            <dxi-item [label]='{text: L("AUDITOR") }' dataField='auditorId' editorType='dxSelectBox'
                [editorOptions]='{ dataSource: proxy.auditor.data, valueExpr: "id", displayExpr: "displayName"}'>
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-item>
            <dxi-item [label]="{text: L('DEADLINE') }" dataField="deadlineDate" editorType="dxDateBox"
                [editorOptions]="{ width: '100%', displayFormat: 'dd.MM.yyyy'}">
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-item>
            <dxi-item [colSpan]='2' [label]='{text: L("ASCERTAINMENT") }' dataField='observation'
                editorType='dxTextArea' [editorOptions]='{ }'></dxi-item>
            <dxi-item [colSpan]='2' [label]='{text: L("RECOMANDATION") }' dataField='recomandation'
                editorType='dxTextArea' [editorOptions]='{ }'></dxi-item>
            <dxi-item [label]='{text: L("CONDITION") }' dataField='stateId' editorType='dxSelectBox'
                [editorOptions]='{ dataSource: edit.state, valueExpr: "id", displayExpr: "description" }'>
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-item>
            <dxi-item [label]='{text: L("SOLVING_DATE") }' dataField='solvedDate' editorType='dxDateBox'
                [editorOptions]='{ width: "100%", displayFormat: "dd.MM.yyyy"}'>
            </dxi-item>
        </dx-form>
    </dxi-item>
    <dxi-item [title]='L("HISTORY")' [disabled]='edit.model.id == undefined'>
        <dx-data-grid [dataSource]='edit.auditRecord' [height]='390'>
            <!-- <dxi-column [caption]='L("FIELD_NAME")' dataField='fieldName' dataType='string'></dxi-column> -->
            <dxi-column [caption]='L("NEW_VALUE")' dataField='newValue' dataType='string'></dxi-column>
            <dxi-column [caption]='L("USER")' dataField='userName' dataType='string'></dxi-column>
            <dxi-item [label]='{text: L("UPDATE_TIME") }' dataField='updateTime' editorType='dxDateBox'
                [editorOptions]='{ width: "100%", displayFormat: "dd.MM.yyyy"}'>
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-item>
        </dx-data-grid>
    </dxi-item>
</dx-tab-panel>