<dx-form [colCount]="2" [formData]="edit.model" [showValidationSummary]="false" [validationGroup]="edit.validation"
	labelLocation="left">
	<dxi-item [colSpan]="2" [label]="{text: L('NAME')}" dataField="name" editorType="dxTextBox" [editorOptions]="{ }">
		<dxi-validation-rule type="required"></dxi-validation-rule>
		<dxi-validation-rule type="stringLength"  [max]="256" [message]="L('NAME_LENGTH')">
		</dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('ZONE_TYPE')}" dataField="typeId" editorType="dxSelectBox"
		[editorOptions]="{ onValueChanged: onTypeValueChanged, dataSource: edit.zoneType, valueExpr: 'id', displayExpr: 'value' }">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('PARENT_ZONE')}" dataField="parentId" editorType="dxSelectBox"
		[editorOptions]="{ onInitialized: onParentInitialized, valueExpr: 'id', displayExpr: 'name', disabled: edit.model.typeId == 3 }">
	</dxi-item>
</dx-form>