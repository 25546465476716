<dx-form [colCount]="2" [formData]="edit.model" [showValidationSummary]="false" [validationGroup]="edit.validation"
	labelLocation="left">

	<dxi-item [label]="{text: L('NAME')}" dataField="name" editorType="dxTextBox" [editorOptions]="{}">
		<dxi-validation-rule type="required"></dxi-validation-rule>
		<dxi-validation-rule type="stringLength"  [max]="256" [message]="L('NAME_LENGTH')">
		</dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('ACTIVE')}" dataField="isActiv" editorType="dxCheckBox" [editorOptions]="{ }">
	</dxi-item>
	<dxi-item [label]="{text: L('COUNTY')}" dataField="countyId" editorType="dxSelectBox"
		[editorOptions]="{ dataSource: edit.county, valueExpr: 'id', displayExpr: 'name', onValueChanged: onCountyValueChanged }">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('LOCALITY')}" dataField="localityId" editorType="dxSelectBox" [editorOptions]="{ dataSource: edit.localities, valueExpr: 'id', displayExpr: 'name', 
			onInitialized: onLocalityInitialized, searchEnabled: true }">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('ADDRESS')}" [colSpan]="2" dataField="address" editorType="dxTextBox">
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-item>
	<dxi-item [label]="{text: L('OBSERVATIONS')}" [colSpan]="2" dataField="observations" editorType="dxTextArea">
	</dxi-item>
</dx-form>