import { Params } from '@angular/router';
import { Component } from '@angular/core';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { ProxyService } from '../../service/proxy.service';
import { ContractEditService } from './contract-edit.service';

@Component({
	selector: 'app-contract-edit',
	templateUrl: './contract-edit.component.html'
})
export class ContractEditComponent extends iBisBaseComponent {
	public edit: ContractEditService;

	constructor(public proxy: ProxyService) {
		super(proxy.contract, proxy.lg, proxy.iui, proxy.activatedRoute);
	}

	dateComparisonCallBackEv = (ev: any) => {
		const ds = new Date(this.edit.model.startDate);
		ds.setDate(ds.getDate() - 1);
		const df = new Date(ev.value);
		return df > ds;
	};

	protected queryParams(params: Params): void {
		super.queryParams(params);
		this.edit.partyType = params['partyType'];
	}
}
