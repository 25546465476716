import { Component } from '@angular/core';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { ProxyService } from '../../../service/proxy.service';
import { ActualStockEditService } from './actual-stock-edit.service';

@Component({
	selector: 'app-actual-stock',
	templateUrl: './actual-stock.component.html'
})
export class ActualStockComponent extends iBisBaseComponent {
	public edit: ActualStockEditService;

	constructor(public proxy: ProxyService) {
		super(null, proxy.lg, proxy.iui, proxy.activatedRoute);
	}

	onCellPreparedEv(event: any) {
		if (event.rowType === 'data' && event.row && event.row.data) {
			if (event.row.data.remain < 0) {
				event.cellElement.classList.add('dx-red');
			}
			if (event.row.data.remain === 0.0) {
				event.cellElement.classList.add('dx-bold');
			}
		}
	}
}
