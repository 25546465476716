<dx-form [colCount]="1" [formData]="edit.model" [showValidationSummary]="false" [validationGroup]="edit.validation"
	labelLocation="left">
	<dxi-item itemType="group" [colSpan]="3" [colCount]="3">
		<dxi-item [label]="{text: L('INVOICE_NO')}" dataField="invoiceId" editorType="dxSelectBox"
			[editorOptions]="{dataSource: edit.dsInvoices, valueExpr: 'id', displayExpr: 'no', searchEnabled: true, showClearButton: true }">
			<dxi-validation-rule type="required"></dxi-validation-rule>
		</dxi-item>
	</dxi-item>

	<dxi-item itemType="group" [colSpan]="3" [colCount]="3">
		<dxi-item [label]="{text: L('COMPONENT_CODE')}" dataField="partNumber" editorType="dxTextBox"
			[editorOptions]="{ }">
			<dxi-validation-rule type="required"></dxi-validation-rule>
		</dxi-item>
		<dxi-item [label]="{text: L('DESCRIPTION')}" dataField="productName" editorType="dxTextBox"
			[editorOptions]="{ }">
			<dxi-validation-rule type="required"></dxi-validation-rule>
		</dxi-item>
		<dxi-item [label]="{text: L('QUANTITY')}" dataField="quantity" editorType="dxNumberBox" [editorOptions]="{}">
			<dxi-validation-rule type="required"></dxi-validation-rule>
		</dxi-item>
	</dxi-item>

	<dxi-item itemType="group" [colSpan]="2" [colCount]="2">
		<dxi-item [label]="{text: L('U')}" dataField="uom" editorType="dxSelectBox"
			[editorOptions]="{ dataSource: ds.static.uom, valueExpr: 'id', displayExpr: 'value', showClearButton: true }">
			<dxi-validation-rule type="required"></dxi-validation-rule>
		</dxi-item>
		<dxi-item [label]="{text: L('UNIT_COST')}" dataField="unitCost" editorType="dxNumberBox" [editorOptions]="{ }">
		</dxi-item>
	</dxi-item>
</dx-form>