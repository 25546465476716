/* eslint-disable no-bitwise */
import { Injectable } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import { DataService } from '../../service/data.service';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import { iBisEditService, iBisLanguageService } from '@dohu/ibis-common';
import { WasteTypeEditService } from '../waste-type/waste-type-edit.service';
import { PartyEditService } from '../../common/party/party-edit/party-edit.service';

@Injectable()
export class TransferEditService extends iBisEditService {

	public data: CustomStore;
	public recipient: DataSource = new DataSource<any, any>(new Array<any>());
	public activity: { id: number; value: string }[];

	private wt: any;
	private ac: number;

	constructor(public party: PartyEditService, private entity: iBisEntityService, protected ds: DataService,
		private wtp: WasteTypeEditService, lg: iBisLanguageService) {
		super(lg);
		this.title = 'TRANSFER';
		this.validation = 'trfValidation';
		this.setRecipient([2, 8]);
	}

	public hidePopup() {
		super.hidePopup();
		this.wtp.typeId = null;
	}

	public get wasteType(): any {
		return this.wt;
	}

	public set wasteType(type: any) {
		this.wt = type;
		this.data = this.getData();
	}

	public get actions(): number {
		return this.ac;
	}

	public set actions(action: number) {
		if (this.ac === action) {
			return;
		}
		this.ac = action;
		this.activity = this.getActivity();
	}

	public get generalDisabled(): Boolean {
		return this.model.id !== undefined && this.model.id !== null;
	}

	createDefault(): any {
		return { selectedActivity: [2, 8] };
	}

	getById(id: string): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			this.entity.getById('Transfer', id).then((obj) => {
				if (obj === null) {
					reject();
				} else {
					obj.selectedActivity = this.setTransportDataSource(obj.activities);
					this.setRecipient(obj.selectedActivity);
					this.wtp.workCenterId = obj.workCenterId;
					this.wtp.selectedId = obj.wasteTypeId;
					resolve(obj);
				}
			}, error => this.lg.showError(error));
		});
	}

	onRemove(id: string): Promise<void> {
		return this.entity.remove('Transfer', id);
	}

	onSaveEv(): Promise<any> {
		this.model.uom = this.wtp.selected.uom;
		let obj: any = {};
		Object.assign(obj, this.model);
		obj.activities = this.getTransportVal(obj.selectedActivity);
		return this.entity.save('Transfer', obj.id, obj);
	}

	reset(): void {
		this.model = this.createDefault();
	}

	public setRecipient(activity: number[]): void {
		if (activity && activity.length > 0) {
			const act = activity.reduce((a, b) => a + b, 0);
			this.recipient = this.party.getPartyLink([2], act);
			if (this.model.destinationWorkCenterId != null) {
				this.party.hasPartyLink([2], act, this.model.destinationWorkCenterId).then((found: boolean) => {
					if (!found) {
						this.model.destinationWorkCenterId = null;
					}
				});
			}
		} else {
			this.recipient = new DataSource<any, any>(new Array<any>());
			this.model.destinationWorkCenterId = null;
		}
	}

	protected setTransportDataSource(actionNo: number) {
		if (actionNo == undefined || !actionNo || !this.activity || this.activity.length === 0) {
			return [];
		}
		const result = [];
		for (let index = this.activity.length - 1; index >= 0; index--) {
			if (this.activity[index].id <= actionNo) {
				result.push(this.activity[index].id);
				actionNo -= this.activity[index].id;
			}
			if (actionNo === 0) {
				break;
			}
		}
		return result;
	}

	protected getTransportVal(actionsArr: number[]) {
		if (actionsArr && actionsArr.length > 0) {
			let result = 0;
			for (const act of actionsArr) {
				result += act;
			}
			return result;
		}
		return 0;
	}

	private getData(): CustomStore {

		const q = new EntityQuery('TransferView').addOrderByDesc(['t.sendDate']);
		if (this.wt != null && this.wt !== '') {
			q.link('t.wasteTypeId', 'id', new EntityQuery('WasteType').eq('typeId', this.wt));
		}
		return this.entity.store(q, false, 't_id');
	}

	private getActivity(): { id: number; value: string }[] {
		if (!this.ac) {
			return [];
		}
		const list = [
			{ 'id': 2, 'value': 'COLLECT' },
			{ 'id': 8, 'value': 'VALORIZATION' },
			{ 'id': 16, 'value': 'ELIMINATION' },
			{ 'id': 32, 'value': 'TEMP_STORAGE' },
			{ 'id': 64, 'value': 'TREATMENT' }];
		const result = [];
		this.ds.getEnum('transportActions', list).forEach((ac: { 'id': number; 'value': string }) => {
			if ((this.ac & ac.id) === ac.id) {
				result.push(ac);
			}
		});
		return result;
	}
}
