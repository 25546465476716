import { TransportBase } from './transport-base';
import { Component, ViewChild } from '@angular/core';
import { ProxyService } from '../../service/proxy.service';
import { TransportEditService } from './transport-edit.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { TransportFinishService } from './transport-finish/transport-finish.service';

@Component({
	templateUrl: 'transport.component.html',
})
export class TransportComponent extends TransportBase {
	@ViewChild('gridEntryTransport') protected grid: DxDataGridComponent;
	public edit: TransportEditService;
	public onFinishClick: any;

	constructor(public proxy: ProxyService, public finish: TransportFinishService) {
		super(proxy, proxy.transport);

		this.id = 't_id';
		this.key = 'transport';
		this.onFinishClick = this.onFinish.bind(this);
	}

	public actionsWidth(): number {
		return (this.proxy.allowedActions + 3) * (this.iui.config.ui.actionWidth ?? 35);
	}

	protected onFinish(e: any) {
		e.event.preventDefault();
		this.finish.wasteType = this.edit.wasteType;
		this.finish.showPopup(e.row.data.t_id, true).then(() => {
			this.refresh();
		}, error => this.proxy.lg.showError(error));
	}
}
