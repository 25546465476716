import { Injectable } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { iBisLanguageService } from '@dohu/ibis-common';
import { PartyBaseService } from '../party-base.service';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import { GeographicAreaEditService } from '../../enum/geographic-area/geographic-area-edit.service';

@Injectable({
	providedIn: 'root'
})
export class AgencyEditService extends PartyBaseService {
	public data: CustomStore;
	public name: CustomStore;

	constructor(protected entity: iBisEntityService, geo: GeographicAreaEditService, lg: iBisLanguageService) {
		super(entity, geo, lg);
		this.title = 'AGENCY';
		this.validation = 'agencyValidation';

		this.data = this.getData();
		this.name = this.getData(['a.id', 'a.name']);
	}

	createDefault(): any {
		return {};
	}

	getById(id: string): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			this.entity.getById('Agency', id).then(result => {
				if (!result) {
					reject();
				} else {
					this.entity.getById('GeographicArea', result.localityId).then(locality => {
						if(locality.typeId == 2){
							this.zoneId = locality.parentId;
							result.zoneId = locality.parentId;
							result.countyId = locality.id;
						} else {
							result.zoneId = locality.id;
							this.zoneId = locality.id;
						}
						resolve(result);
					}, (error) => {
						this.lg.showError(error);
					});
				}
			}, (error) => {
				this.lg.showError(error);
			});
		});
	}

	onRemove(id: string): Promise<void> {
		return this.entity.remove('Agency', id);
	}

	onSaveEv(): Promise<any> {
		this.model.localityId = this.model.countyId ?? this.model.zoneId;
		return this.entity.save('Agency', this.model.id, this.model);
	}

	reset(): void {
		this.model = this.createDefault();
	}

	private getData(fields: string[] = null): CustomStore {
		const q = new EntityQuery('AgencyView').addOrderBy(['a.name']);
		if (fields) {
			fields.forEach(f => q.fields.push(f));
		}
		return this.entity.store(q, false, 'a_id');
	}
}
