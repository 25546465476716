import { Component } from '@angular/core';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { ProxyService } from '../../../service/proxy.service';
import { IncomeAnalyzesFilterService } from './income-analyzes-filter.service';

@Component({
	selector: 'app-income-analyzes-filter',
	templateUrl: './income-analyzes-filter.component.html'
})
export class IncomeAnalyzesFilterComponent extends iBisBaseComponent {

	public edit: IncomeAnalyzesFilterService;
	constructor(public proxy: ProxyService) {
		super(proxy.incomeAnalyzes, proxy.lg, proxy.iui, proxy.activatedRoute);
	}
}
