import { Injectable } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import { iBisEditService, iBisLanguageService } from '@dohu/ibis-common';

@Injectable({
	providedIn: 'root'
})
export class LocationEditService extends iBisEditService {

	public data: CustomStore;
	constructor(private entity: iBisEntityService, lg: iBisLanguageService){
		super(lg);
		this.title = 'LOCATION';
		this.validation = 'locationValidation';

		this.data = this.getData();
	}
	createDefault() {
		return {};
	}
	getById(id: string, serverUrl?: string): Promise<any> {
		throw new Error('Method not implemented.');
	}
	onRemove(id: string): Promise<void> {
		throw new Error('Method not implemented.');
	}
	onSaveEv(serverUrl?: string): Promise<any> {
		throw new Error('Method not implemented.');
	}
	reset(): void {
		this.model = this.createDefault();
	}

	private getData() {
		return this.entity.store(new EntityQuery('Location').addOrderBy(['name']), false, 'id');
	}

	// getLocation(locationId: string, entityId: string, reportDate: Date): CustomStore {
	// 	const q = new EntityQuery('Location').addOrderBy(['name']);
	// 	if (locationId) {
	// 		q.linkEq('partyId', 'partyId', 'Location', 'id', locationId);
	// 	}
	// 	if (entityId) {
	// 		q.linkEq('partyId', 'id', 'Party', 'id', entityId);
	// 	}
	// 	if (reportDate) {
	// 		q.lte('startDate', EntityStore.toDateTimeFilter(new Date(reportDate)));
	// 		q.gte('endDate', EntityStore.toDateTimeFilter(new Date(reportDate)));
	// 	}
	// 	return new CustomStore(EntityStore.store(q, false, 'id'));
	// }
}
