import notify from 'devextreme/ui/notify';
import { Injectable } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import { iBisAuthService, iBisSecureEditService } from '@dohu/ibis-auth';
import { iBisLanguageService, iBisServerConfig } from '@dohu/ibis-common';

@Injectable({
	providedIn: 'root'
})
export class KPIAnalyzesFilterService extends iBisSecureEditService {

	public data: any;
	public type: ReportType[];
	public interval: { id: number; text: string }[];
	public wasteTypeByWorkCenter: DataSource;

	private rt: ReportType;

	constructor(auth: iBisAuthService, entity: iBisEntityService, config: iBisServerConfig, lg: iBisLanguageService) {
		super(auth, entity, config, lg);
	}

	public set reportType(id: any) {
		this.data = null;
		this.rt = this.type.find(rt => rt.id === id);
	}

	public get reportType(): ReportType {
		return this.rt;
	}

	public filterData = (): void => {
		if (this.model.wasteType && this.model.wasteType.length < 1) {
			notify('Selectați coduri de deșeu pentru analiză.', 'error', 3000);
		}
		if (this.model.workCenter && this.model.workCenter.length < 1) {
			notify('Selectați punct de lucru pentru analiză.', 'error', 3000);
		}
		this.reportType = this.model.reportType;
		sessionStorage.setItem('kpi-analyzes', JSON.stringify(this.model));
		const obj: any = {};
		Object.assign(obj, this.model);
		obj.fromDate = this.entity.toDateFilter(new Date(this.model.fromDate));
		if (obj.thruDate) {
			obj.thruDate = this.entity.toDateFilter(new Date(this.model.thruDate));
		}
		this.entity.execute('GetKPIAnalyzes', obj).then((result) => {
			if (result) {
				this.data = result.data;
				if (result.response !== 'OK') {
					notify('Vă rugăm să vă asigurați că indicatorii pentru punctele de lucru selectate au fost configurați',
						'warning', 3000);
				}
			} else {
				this.data = null;
			}
		});
	};

	public refreshWasteTypeData() {
		const q = new EntityQuery('WasteTypePartyView').addOrderBy(['wt.code']);
		if (this.model.workCenter && this.model.workCenter.length > 0) {
			q.in('wtp.workCenterId', this.model.workCenter ?? [null])
		} else {
			q.eq('wtp.workCenterId', null);
		}
		q.fields.push('wtp.wasteTypeId', 'wt.code');
		q.distinct = true;

		this.wasteTypeByWorkCenter = new DataSource({ store: this.entity.store(q, false, 'wtp.id'), paginate: true, pageSize: 10 });
	}

	createDefault() {
		return { workCenter: [], wasteType: [], reportType: 0, interval: 0 };
	}
	getById(id: string, serverUrl?: string): Promise<any> {
		throw new Error('Method not implemented.');
	}
	onRemove(id: string): Promise<void> {
		throw new Error('Method not implemented.');
	}
	onSaveEv(serverUrl?: string): Promise<any> {
		throw new Error('Method not implemented.');
	}
	reset(): void {
		this.model = this.createDefault();
	}

	protected load(): void {
		this.interval = this.getInterval();
		this.type = this.getType();
		this.rt = this.type[0];
	}

	private getType(): ReportType[] {
		return [
			{
				id: 0,
				text: 'Anual',
				title: 'Rata de valorificare pe România%',
				series: [{
					type: 'bar',
					argumentField: 'year',
					valueField: 'value',
					color: '#000099',
					name: 'Rata de valorificare',
				}],
			}, {
				id: 1,
				text: 'Lunar',
				title: 'Rată de valorificare a deșeurilor',
				series: [{
					name: 'Media ratei de valorificare',
					type: 'bar',
					argumentField: 'month',
					valueField: 'value3',
					axisName: 'totalWaste',
					color: '#CC0066'
				}, {
					name: 'Total deșeu evacuat',
					type: 'bar',
					argumentField: 'month',
					valueField: 'value',
					axisName: 'totalWaste',
					color: '#666666'
				}, {
					name: 'Țintă',
					type: 'bar',
					argumentField: 'month',
					valueField: 'value2',
					axisName: 'totalWaste',
					color: '#009900'
				}, {
					name: 'Rată de valorificare',
					type: 'line',
					argumentField: 'month',
					valueField: 'value4',
					axisName: 'wasteRecovery',
					color: '#CC0066'
				}],
				axis: [{
					name: 'wasteRecovery',
					position: 'left',
					visibile: true,
					title: '% Rată de valorificare a deșeurilor',
					showZero: false,
					tickInterval: 20
				}, {
					name: 'totalWaste',
					position: 'right',
					visibile: true,
					title: 'Deșeu evacuat [tone]',
					showZero: true,
					autoBreaksEnabled: true,
					breakStyle: { line: 'straight', width: 0 }
				}]
			}
		];
	}

	private getInterval(): { id: number; text: string }[] {
		return [{ id: 0, text: 'Calendaristic' }, { id: 1, text: 'Bugetar' }];
	}
}

export class ReportType {
	id: number;
	text?: string;
	title?: string;
	series?: Serie[];
	axis?: Axis[];
}

export class Serie {
	name?: string;
	color?: string;
	valueField?: string;
	argumentField?: string;
	type?: string;
	axisName?: string;
}

export class Axis {
	name: string;
	position: string;
	title?: string;
	visibile: boolean;
	showZero: boolean;
	tickInterval?: number;
	autoBreaksEnabled?= false;
	breakStyle?: { line?: string; width?: number };
}
