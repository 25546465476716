<dx-form [colCount]="2" [formData]="edit.model" [showValidationSummary]="false" [validationGroup]="edit.validation"
	labelLocation="left">
	<dxi-item [label]="{text: L('DESCRIPTION')}" dataField="sanctionDescription" editorType="dxTextBox"
		[editorOptions]="{ }">
	</dxi-item>
	<dxi-item [label]="{text: L('STATUS')}" dataField="sanctionStatus" editorType="dxSelectBox"
		[editorOptions]="{dataSource: edit.status, valueExpr: 'id', displayExpr: 'value' }">
	</dxi-item>
	<dxi-item [label]="{text: L('TERM')}" dataField="termSanction" editorType="dxDateBox"
		[editorOptions]="{ displayFormat: 'dd.MM.yyyy', width: '100%'}">
	</dxi-item>
	<dxi-item [label]="{text: L('OBSERVATIONS')}" dataField="notifications" editorType="dxTextBox"
		[editorOptions]="{ }">
	</dxi-item>
</dx-form>