import { Component } from '@angular/core';
import { ProxyService } from '../../../app/service/proxy.service';
import { GenerateComponent } from '../../../app/waste/generate/generate.component';

@Component({
	templateUrl: './generate.component.ecofire.html',
})
export class EcoFireGenerateComponent extends GenerateComponent {

	constructor(public proxy: ProxyService) {
		super(proxy);
	}
}
