import { Component } from '@angular/core';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { ProxyService } from '../../../service/proxy.service';
import { UOMHelper } from '../../waste-type/waste-type-edit.service';
import { MonthlyStockEditService } from './monthly-stock-edit.service';

@Component({
	selector: 'app-monthly-stock-edit',
	templateUrl: 'monthly-stock-edit.component.html'
})
export class MonthlyStockEditComponent extends iBisBaseComponent {
	public edit: MonthlyStockEditService;
	public uomHelper: UOMHelper;
	public year: { min: number; current: number };
	// onWasteChange: any;
	// wasteCodeInst: any;
	// // onEntityChange: any;
	// workCenterInst: any;
	// onWasteCodeInit: any;
	// onWorkCenterInit: any;
	// onWorkCenterChange: any;

	private cboWasteType: any;


	constructor(public proxy: ProxyService) {
		super(proxy.monthlyStock, proxy.lg, proxy.iui, proxy.activatedRoute);
		this.uomHelper = new UOMHelper(this.proxy.wasteType);

		// this.onWasteChange = this.onWasteChangeEv.bind(this);
		// this.onEntityChange = this.onEntityChangeEv.bind(this);
		// this.onWasteCodeInit = this.onWasteCodeInitEv.bind(this);
		// this.onWorkCenterInit = this.onWorkCenterInitEv.bind(this);
		// this.onWorkCenterChange = this.onWorkCenterChangeEv.bind(this);
		this.year = { min: new Date().getFullYear() - 15, current: new Date().getFullYear() };

		proxy.wasteType.onDataChanged.subscribe((data) => {
			if (data === 'code' && this.cboWasteType) {
				this.proxy.resetValidation(this.cboWasteType);
			}
			if (data === 'selected') {
				this.edit.model.wasteType = this.proxy.wasteType.selected;
			}
		});
	}

	onWasteTypeInit = (ev: any) => {
		this.cboWasteType = ev.component;
	};

	onWorkCenterChanged = (ev: any) => {
		this.proxy.wasteType.workCenterId = ev.value;
	};

	onWasteTypeChanged = (ev: any) => {
		this.proxy.wasteType.selectedId = ev.value;
	};

	// onWasteCodeInitEv(event: any) {
	// 	this.wasteCodeInst = event.component;
	// }

	// onWorkCenterInitEv(event: any) {
	// 	this.workCenterInst = event.component;
	// }

	// onEntityChangeEv(event: any) {
	// 	if (event.event) {
	// 		this.resetValidation(this.workCenterInst);
	// 		this.resetValidation(this.wasteCodeInst);
	// 		this.setWasteInfo();
	// 		this.edit.dspWorkCenter = this.ds.getPartyMemberById(event.value);
	// 	}
	// }

	// onWorkCenterChange = (event: any) => {
	// 	if (event.event) {
	// 		this.resetValidation(this.wasteCodeInst);
	// 		this.setWasteInfo();
	// 		this.edit.dsWasteType = this.edit.getWasteTypeForWorkCenter(event.value);
	// 	}
	// }

	// setWasteInfo(hazardName = null, wt_name = null) {
	// 	this.edit.model.hazardName = hazardName;
	// 	this.edit.model.wt_name = wt_name;
	// }

	// onWasteChangeEv(event: any) {
	// 	if (event.value) {
	// 		const items = this.wasteCodeInst.getDataSource().items();
	// 		if (items.length) {
	// 			const item = items.filter((x: any) => x.id === event.value);
	// 			const hazard = this.proxy.wasteType.hazard.find(h => h.id = item[0].hazard).value;
	// 			this.setWasteInfo(hazard, item[0].name);
	// 		}
	// 	}
	// }

	// resetValidation(component: any) {
	// 	if (component) {
	// 		component.reset();
	// 		component.option('isValid', true);
	// 	}
	// }
}
