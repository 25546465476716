<dx-data-grid #gridTransportResult class="gridContainer" [dataSource]="edit.result"
    [height]="ui.fullHeight" [remoteOperations]="true" (onToolbarPreparing)="toolbarPreparing($event, false)">

    <dxo-export [enabled]="ui.exportEnabled" [fileName]="key"></dxo-export>
    <dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" [storageKey]="key">
    </dxo-state-storing>
    <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
    <dxo-scrolling [mode]="ui.scrollingMode" [showScrollbar]="ui.showScrollbar" [useNative]="!ui.isWindows">
    </dxo-scrolling>

    <dxi-column name='transporter' headerCellTemplate='transHeader'>
        <dxi-column [caption]="L('TRANSPORT_NO')" dataField="t_no" dataType="string"></dxi-column>
        <dxi-column [caption]="L('FROM_DATE')" dataField="t_sendDate" dataType="date" calculateSortValue="t_sendDate"
            [calculateDisplayValue]="iui.formatDate">
        </dxi-column>
    </dxi-column>
    <dxi-column name='eliminator' headerCellTemplate='eliminatorHeader'>
        <dxi-column [caption]="L('ENTITY')" dataField="ent_name" dataType="string"></dxi-column>
        <dxi-column [caption]="L('WORK_CENTER')" dataField="wc_name" dataType="string"></dxi-column>
        <dxi-column [caption]="L('DATE')" dataField="t_finishDate" dataType="date" calculateSortValue="t_finishDate"
            [calculateDisplayValue]="iui.formatDate">
        </dxi-column>
    </dxi-column>
    <dxi-column name='waste' headerCellTemplate='wasteHeader'>
        <dxi-column [caption]="L('WASTE_CODE')" dataField="wt_code" dataType="string"></dxi-column>
        <dxi-column [caption]="L('WASTE_TYPE')" dataField="wt_hazard" dataType="string">
            <dxo-lookup [dataSource]="proxy.wasteType.hazard" valueExpr="id" displayExpr="value"> </dxo-lookup>
        </dxi-column>
        <dxi-column [caption]="edit.wasteType == '2' ? L('QUANTITY_EEE_GIVEN') : L('QUANTITY_GIVEN')"
            dataField="t_quantity" dataType="number"></dxi-column>
        <dxi-column [caption]="L('UNIT_WEIGHT')" dataField="t_piece" dataType="number"></dxi-column>
        <!--Elimination-->
        <dxi-column [caption]="L('ELIMINATED_QUANTITY')" dataField="t_eliminatedQuantity" dataType="number">
        </dxi-column>
        <dxi-column [caption]="L('CODE')" dataField="e_code" dataType="string"></dxi-column>
        <!--Capitalization-->
        <dxi-column [caption]="L('QUANTITY_CAPITALIZED')" dataField="t_valuedQuantity" dataType="number"></dxi-column>
        <!--RECYCLED-->
        <dxi-column [caption]="L('QUANTITY_RECYCLED')" dataField="t_recycledQuantity" dataType="number"></dxi-column>
        <!--REUSED-->
        <dxi-column [caption]="L('QUANTITY_REUSED')" dataField="t_reusedQuantity" dataType="number"></dxi-column>
        <!--EXPORTED-->
        <dxi-column [caption]="L('QUANTITY_EXPORTED')" dataField="t_exportedQuantity" dataType="number"></dxi-column>
        <dxi-column [caption]="L('U')" dataField="uom" dataType="number">
            <dxo-lookup [dataSource]="proxy.wasteType.uom" valueExpr="id" displayExpr="value"> </dxo-lookup>
        </dxi-column>
        <dxi-column [caption]="L('CODE')" dataField="c_code" dataType="string"></dxi-column>
    </dxi-column>
    <dxi-column [caption]="L('PROCESSOR')" dataField="rt_name" dataType="string"></dxi-column>

    <div *dxTemplate="let data of 'breadcrumb'">
        <ibis-breadcrumb></ibis-breadcrumb>
    </div>

    <div *dxTemplate="let c of 'wasteHeader'" class="dx-datagrid-text-content" role="presentation">
		<i class='fas fa-recycle'></i> Deseu
	</div>
    <div *dxTemplate="let c of 'transHeader'" class="dx-datagrid-text-content" role="presentation">
		<i class="fas fa-truck-moving"></i> Transport
	</div>
    <div *dxTemplate="let c of 'eliminatorHeader'" class="dx-datagrid-text-content" role="presentation">
		<i class="fas fa-warehouse"></i> Eliminator
	</div>
</dx-data-grid>